<template>
    <div class="content">
        <Header
            :title="$t('pages.homepage.title')"
        />

        <div
            class="container-fluid mt--6"
            data-cy="homepageContent"
        >
            <StatisticsWidgets
                :currency="currency"
                :statistics-widgets-data="widgets.data"
                :year="String(widgets.year)"
            />
            <StatisticsCharts
                :currency="currency"
                :labels="charts.labels"
                :gross-revenues-data="charts.grossRevenues"
                :costs-data="charts.costs"
                :profits-data="charts.profits"
            />
        </div>

        <LoadingBar v-show="loading" />
    </div>
</template>
<script>
import { statisticsClient } from "@/clients/statisticsClient";
import Header from "@/components/Header";
import formatterMixins from "@/mixins/formatterMixins";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import StatisticsWidgets from "@/views/Pages/Components/Statistics/Widgets";
import StatisticsCharts from "@/views/Pages/Components/Statistics/Charts";
import yearSelectorMixins from "@/mixins/yearSelectorMixins";

export default {
    name: 'HomePage',
    components: {
        Header,
        LoadingBar,
        StatisticsWidgets,
        StatisticsCharts
    },
    mixins: [
        formatterMixins,
        exceptionsMixins,
        yearSelectorMixins
    ],
    data() {
        return {
            loading: false,
            currency: 'EUR',
            widgets: {
                year: this.$store.getters.yearSelectedFilter,
                data: {
                    revenue: null,
                    costs: null,
                    profit: null,
                    profitabilityIndex: null,
                    credits: null,
                    debits: null,
                },
            },
            charts: {
                grossRevenues: null,
                costs: null,
                profits: null
            }
        }
    },
    mounted() {
        if (this.$store.getters.activeRegistryId) {
            this.loadData();
        }
    },
    methods: {
        loadData() {
            this.loading = true;

            let fromDate = new Date();
            fromDate.setMonth(fromDate.getMonth() - 12);

            let todayDate = new Date();

            if (this.$store.getters.yearSelectedFilter !== new Date().getFullYear()) {
                fromDate = this.getDefaultFromDate();
                todayDate = this.getDefaultToDate();
            }

            Promise.all([
                statisticsClient.getSummary(this.$store.getters.activeRegistryId, this.widgets.year),
                statisticsClient.getCharts(this.$store.getters.activeRegistryId, this.formatFrontendDate(fromDate), this.formatFrontendDate(todayDate))
            ]).then(results => {
                const summaryResponse = results[0];

                this.widgets.data.revenue = summaryResponse.data.revenue;
                this.widgets.data.costs = summaryResponse.data.costs;
                this.widgets.data.profit = summaryResponse.data.profit;
                this.widgets.data.profitabilityIndex = summaryResponse.data.profitabilityIndex;
                this.widgets.data.credits = summaryResponse.data.credits;
                this.widgets.data.debits = summaryResponse.data.debits;

                const chartsResponse = results[1];

                this.charts.labels = this.generateChartLabels(chartsResponse.data.labels);
                this.charts.grossRevenues = chartsResponse.data.revenuesChart;
                this.charts.costs = chartsResponse.data.costsChart;
                this.charts.profits = chartsResponse.data.profitsChart;
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loading = false;
            });
        },
        generateChartLabels(labels) {
            return labels.reduce((result, element) => {
                result.push(
                    `${element.month}/${element.year}`
                );

                return result;
            }, []);
        }
    }
};
</script>
