export const PresetDates = Object.freeze({
    SEVEN_DAYS: 0,
    ONE_MONTH: 1,
    TWO_MONTHS: 2,
    THIS_MONTH: 3,
    LAST_MONTH: 4,
    YESTERDAY: 5,
    TODAY: 6,
});

export const PeriodAsDays = Object.freeze({
    YESTERDAY: 1,
    SEVEN_DAYS: 7,
    ONE_MONTH: 30,
    TWO_MONTHS: 60,
});
