import { headersBuilder } from "@/clients/headersBuilder";
import { SupportedEntity } from "@/clients/constants/clientConstants";
import axios, { AxiosResponse } from 'axios';
import dataCleaner from "@/clients/support/dataCleaner";

/**
 * baseEntityClient.
 */
export const baseEntityClient = {
    /**
     * Retrieve the entity by the provided entity name and id.
     *
     * @param entityName {string}
     * @param entityId {number}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getEntityById(entityName, entityId) {
        this.checkIsEntitySupportedByName(entityName);

        return axios.get(process.env.VUE_APP_ADMINISTRATION_BASE_URL + `/api/v1/${entityName}/${entityId}`,
            {
                headers: headersBuilder.buildAuthenticatedHeaders()
            });
    },
    /**
     * Retrieve the entities filtered by the optional filter provided.
     *
     * @param entityName {string}
     * @param filters {object}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getEntityByFilters(entityName, filters= {}) {
        this.checkIsEntitySupportedByName(entityName);

        return axios.get(process.env.VUE_APP_ADMINISTRATION_BASE_URL + `/api/v1/${entityName}`,
            {
                headers: headersBuilder.buildAuthenticatedHeaders(),
                params: dataCleaner(filters)
            });
    },
    /**
     * Delete the entity by the name and id provided.
     *
     * @param entityName
     * @param entityId
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteEntityById(entityName, entityId) {
        this.checkIsEntitySupportedByName(entityName);

        return axios.delete(process.env.VUE_APP_ADMINISTRATION_BASE_URL + `/api/v1/${entityName}/${entityId}`,
            {
                headers: headersBuilder.buildAuthenticatedHeaders()
            });
    },
    /**
     * Update the entity by the name, id and the data provided.
     *
     * @param entityName {string}
     * @param entityId {number}
     * @param data {object}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateEntity(entityName, entityId, data) {
        this.checkIsEntitySupportedByName(entityName);

        return axios.put( process.env.VUE_APP_ADMINISTRATION_BASE_URL + `/api/v1/${entityName}/${entityId}`,
            dataCleaner(data),
            {
                headers: headersBuilder.buildAuthenticatedHeaders()
            });
    },
    /**
     * Create entity by the name and data provided.
     *
     * @param entityName {string}
     * @param data {object}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    createEntity(entityName, data) {
        this.checkIsEntitySupportedByName(entityName);

        return axios.post(process.env.VUE_APP_ADMINISTRATION_BASE_URL + `/api/v1/${entityName}`,
            dataCleaner(data),
            {
                headers: headersBuilder.buildAuthenticatedHeaders()
            });
    },
    /**
     * If the entity name provided is not supported it will throw an error message.
     *
     * @param entityName {string}
     */
    checkIsEntitySupportedByName(entityName) {
        if (!Object.values(SupportedEntity).includes(entityName)) {
            throw `The entity name: ${entityName} provided is not supported.`;
        }
    }
}
