/**
 * yearSelectorMixins.
 */
export default {
    methods: {
        getDefaultFromDate() {
            return new Date(this.$store.getters.yearSelectedFilter, 0, 1);
        },
        getDefaultToDate() {
            const currentYear = new Date().getFullYear();

            if (this.$store.getters.yearSelectedFilter === currentYear) {
                return new Date();
            }

            return new Date(this.$store.getters.yearSelectedFilter, 11, 31);
        },
        getDefaultDatesRange() {
            const dates = {};

            dates.fromDateToSend = this.formatFrontendDate(this.getDefaultFromDate());
            dates.toDateToSend = this.formatFrontendDate(this.getDefaultToDate());

            dates.range = dates.fromDateToSend + this.$t("timeTerms.to") + dates.toDateToSend;

            return dates;
        }
    }
}
