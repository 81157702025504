/**
 * Clear all key that are "empty" of every object provided.
 *
 * @param object {Object}
 *
 * @returns {*}
 */
const dataCleaner = function (object) {
    const purifiedObject = {...object};
    for (const key in purifiedObject) {
        if (purifiedObject.hasOwnProperty(key)) {
            if (typeof purifiedObject[key] === 'string') {
                purifiedObject[key] = String(purifiedObject[key])?.length > 0 ? purifiedObject[key] : null;
            }
        }

        if (typeof purifiedObject[key] === 'object' && purifiedObject[key] != null) {
            purifiedObject[key] = dataCleaner(purifiedObject[key]);
        }
    }

    return purifiedObject;
};

export default dataCleaner;
