export const CashFlowTypes = Object.freeze({
    TP01: 'TP01',
    TP02: 'TP02',
    TP04: 'TP04',
});

export const CashFlowTags = Object.freeze({
    TG01: 'TG01',
    TG02: 'TG02',
    TG03: 'TG03',
    TG04: 'TG04',
    TG05: 'TG05',
    TG06: 'TG06',
    TG07: 'TG07',
    TG08: 'TG08',
    TG09: 'TG09',
    TG10: 'TG10',
    TG11: 'TG11',
    TG12: 'TG12',
    TG14: 'TG14',
    TG15: 'TG15',
});

export const CashFlowExceptionCodes = Object.freeze({
    ALREADY_USED_NUMBER: 13006,
    NOT_VALID_NUMBER: 13007,
});
