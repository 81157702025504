var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col p-0"},[_c('card',{staticClass:"m-0",attrs:{"body-classes":"p-0","footer-classes":"pb-2"}},[_c('BaseTable',{attrs:{"table-data":_vm.tableData,"default-sort":{prop: 'expenseDate', order: 'descending'},"search-keys":[
                'sender',
                'recipient.name',
                'tag',
                'type',
            ],"search-threshold":0.1}},[_vm._l((_vm.tableColumns),function(column){return _c('el-table-column',_vm._b({key:column.label,attrs:{"formatter":column.formatter}},'el-table-column',column,false))}),_c('el-table-column',{attrs:{"align":"right","label":_vm.$t('tables.column.actions'),"min-width":80},scopedSlots:_vm._u([{key:"default",fn:function({$index, row}){return _c('div',{staticClass:"d-flex flex-wrap"},[_c('base-button',{staticClass:"edit",attrs:{"data-cy":'buttonUpdateCashFlowId'+row.id,"type":"primary","size":"sm","icon":""},on:{"click":function($event){return _vm.openEditModal(row.id)}}},[_c('i',{staticClass:"fas fa-edit text-white"})]),_c('base-button',{staticClass:"remove btn-link",attrs:{"data-cy":'buttonDeleteCashFlowId'+row.id,"type":"danger","size":"sm","icon":""},on:{"click":function($event){return _vm.openDeleteModal(row.id, row.income, row.number)}}},[_c('i',{staticClass:"fas fa-trash-alt text-white"})]),_c('base-button',{staticClass:"remove btn-link border",attrs:{"data-cy":'buttonDetailsCashFlowId'+row.id,"type":"secondary","size":"sm","icon":""},on:{"click":function($event){return _vm.$router.push(
                            {
                                name:'CASHFLOW-DETAIL',
                                params: {
                                    id: row.id
                                }
                            }
                        );}}},[_c('i',{staticClass:"fas fa-info-circle"})])],1)}}])})],2)],1),(_vm.deleteModal.showDeleteModal)?_c('DeleteCashFlow',{attrs:{"delete-cash-flow-id":_vm.deleteModal.idCashFlowToDelete,"is-income":_vm.deleteModal.isIncome,"number":_vm.deleteModal.number},on:{"close-delete":function($event){_vm.deleteModal.showDeleteModal = false;},"data-changed":function($event){return _vm.$emit('data-changed');}}}):_vm._e(),(_vm.editModal.showEditModal)?_c('EditCashFlow',{attrs:{"edit-cash-flow-id":_vm.editModal.editCashFlowId},on:{"close-edit":function($event){_vm.editModal.showEditModal = false;},"data-changed":function($event){return _vm.$emit('data-changed');}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }