<template>
    <div
        :data-cy="'expectedInvoicesTable'"
    >
        <card
            class="no-border-card"
            body-classes="p-0"
            footer-classes="pb-2"
        >
            <BaseTable
                :table-data="tableData"
                :default-sort="{prop: 'month', order: 'ascending'}"
                :search-keys="[]"
            >
                <el-table-column
                    v-for="column in tableColumns"
                    :key="column.label"
                    v-bind="column"
                    :formatter="column.formatter"
                />
            </BaseTable>
        </card>
    </div>
</template>

<script>
import { Option, Select, Table, TableColumn } from "element-ui";
import formatterMixins from "@/mixins/formatterMixins";
import BaseTable from "@/views/Pages/Components/Table/BaseTable";

export default {
    name: "ExpectedInvoicesTable",
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        BaseTable
    },
    mixins: [
        formatterMixins
    ],
    props: {
        tableData: {
            type: Array
        }
    },
    data() {
        return {
            tableColumns: [
                {
                    prop: "month",
                    label: this.$t("tables.column.month"),
                    minWidth: 80,
                    sortable: true,
                    formatter: row => this.$t(`tables.monthNumberToMonthName.${row.month}`)
                },
                {
                    prop: "amount",
                    label: this.$t("tables.column.amount"),
                    minWidth: 80,
                    sortable: true,
                    formatter: row => this.formatCurrency(row.currency, row.amount)
                },
            ],
        };
    }
};
</script>
