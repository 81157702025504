import DashboardLayout from "@/views/Layout/DashboardLayout";
import Bank from "@/views/Pages/Bank";
import DetailBank from "@/views/Pages/Components/Bank/Detail";

const bankPages = {
    path: "/bank",
    component: DashboardLayout,
    meta: {
        name: "pages.bank.breadcrumb.banks",
    },
    children: [
        {
            path: ":id",
            name: "BANK-DETAIL",
            component: DetailBank,
            meta: {
                name: "pages.bank.breadcrumb.detail",
                area: "REGISTRY-BANK"
            }
        },
        {
            path: "",
            name: "BANK-LIST",
            component: Bank,
            meta: {
                name: "pages.bank.breadcrumb.bankList",
                area: "REGISTRY-BANK"
            }
        },
    ],
};

export default bankPages;
