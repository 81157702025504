<template>
    <div class="content">
        <Header
            :title="$t('pages.fiscalDocument.breadcrumb.detail')"
        />
        <LoadingBar v-show="!loaded" />
        <div
            v-if="fiscalDocument"
            v-show="loaded"
            class="container-fluid mt--6 pt-4"
            :data-cy="'fiscalDocumentDetailsContainer'"
        >
            <card>
                <div class="d-flex justify-content-between align-items-start">
                    <h1 class="card-title mb-4">
                        {{ $t("pages.fiscalDocument.detailPage.title") + fiscalDocument.number }}
                    </h1>
                    <div>
                        <base-button
                            v-if="canBePaid(fiscalDocument.status)"
                            class="edit"
                            type="primary"
                            size="sm"
                            icon
                            @click="openCreatePaymentModal()"
                        >
                            <i class="fas fa-money-bill-alt" />
                        </base-button>
                    </div>
                </div>
                <div class="card-text mb-4 row">
                    <div class="col-md-6 col-12">
                        <dl class="row">
                            <dt class="col-sm-4">
                                {{ $t("forms.fields.sender") }}
                            </dt>
                            <dd class="col-sm-8">
                                <a :href="'/registry/' + senderId">{{ senderName }}</a>
                            </dd>
                            <dt class="col-sm-4">
                                {{ $t("forms.fields.recipient") }}
                            </dt>
                            <dd class="col-sm-8">
                                <a :href="'/registry/' + recipientId">
                                    {{ recipientName }}
                                </a>
                            </dd>
                            <dt class="col-sm-4">
                                {{ $t("forms.fields.purpose") }}
                            </dt>
                            <dd class="col-sm-8">
                                {{ fiscalDocument.purpose }}
                            </dd>
                            <dt class="col-sm-4">
                                {{ $t("forms.fields.number") }}
                            </dt>
                            <dd class="col-sm-8">
                                {{ fiscalDocument.number }}
                            </dd>
                            <dt class="col-sm-4">
                                {{ $t("forms.fields.type") }}
                            </dt>
                            <dd class="col-sm-8">
                                {{ $t(`choices.fiscalDocument.type.${fiscalDocument.type}`) }}
                            </dd>
                            <dt class="col-sm-4">
                                {{ $t("forms.fields.status") }}
                            </dt>
                            <dd class="col-sm-8">
                                {{ $t(`choices.fiscalDocument.status.${fiscalDocument.status}`).toUpperCase() }}
                            </dd>
                            <dt class="col-sm-4">
                                {{ $t("forms.fields.docType") }}
                            </dt>
                            <dd class="col-sm-8">
                                {{
                                    fiscalDocument.documentType + ' | ' + $t(`choices.fiscalDocument.document.type.${fiscalDocument.documentType}`)
                                }}
                            </dd>
                            <dt class="col-sm-4">
                                {{ $t("forms.fields.date") }}
                            </dt>
                            <dd class="col-sm-8">
                                {{ formatBackendDate(new Date(fiscalDocument.date)) }}
                            </dd>
                        </dl>
                    </div>
                    <div class="col-md-6 col-12">
                        <dl class="row">
                            <dt class="col-sm-4">
                                {{ $t("forms.fields.totalImport") }}
                            </dt>
                            <dd class="col-sm-8">
                                {{ formatCurrency(fiscalDocument.currency, fiscalDocument.totalImport) }}
                            </dd>
                            <dt class="col-sm-4">
                                {{ $t("forms.fields.revenueStampAmount") }}
                            </dt>
                            <dd class="col-sm-8">
                                {{ formatCurrency(fiscalDocument.currency, fiscalDocument.revenueStamp ) }}
                            </dd>
                            <dt class="col-sm-4">
                                {{ $t("forms.fields.amountPaid") }}
                            </dt>
                            <dd class="col-sm-8">
                                {{ formatCurrency(fiscalDocument.currency, fiscalDocument.amountPaid ) }}
                            </dd>
                            <dt class="col-sm-4">
                                {{ $t("forms.fields.amountResidual") }}
                            </dt>
                            <dd class="col-sm-8">
                                {{ formatCurrency(fiscalDocument.currency, fiscalDocument.amountResidual ) }}
                            </dd>
                        </dl>
                    </div>
                </div>
                <hr>
                <div class="card-text mb-4 row">
                    <div class="col">
                        <div class="d-flex justify-content-between align-items-start">
                            <h2 class="card-title mb-4">
                                {{ $t("pages.fiscalDocument.detailPage.withholdingLines") }}
                            </h2>
                        </div>
                        <DetailWithholdingLinesTable
                            :table-data="fiscalDocument.withholdingLines"
                            :currency="fiscalDocument.currency"
                        />
                    </div>
                </div>
                <hr>
                <div class="card-text mb-4 row">
                    <div class="col">
                        <div class="d-flex justify-content-between align-items-start">
                            <h2 class="card-title mb-4">
                                {{ $t("pages.fiscalDocument.detailPage.detailLines") }}
                            </h2>
                        </div>
                        <DetailLinesTable
                            :table-data="fiscalDocument.detailLines"
                            :currency="fiscalDocument.currency"
                        />
                    </div>
                </div>
                <hr>
                <div class="card-text mb-4 row">
                    <div class="col">
                        <div class="d-flex justify-content-between align-items-start">
                            <h2 class="card-title mb-4">
                                {{ $t("pages.fiscalDocument.detailPage.summaryLines") }}
                            </h2>
                        </div>
                        <SummaryLinesTable
                            :table-data="fiscalDocument.summaryLines"
                            :currency="fiscalDocument.currency"
                        />
                    </div>
                </div>
                <hr>
                <div class="card-text mb-4 row">
                    <div class="col">
                        <div class="d-flex justify-content-between align-items-start">
                            <h2 class="card-title mb-4">
                                {{ $t("pages.fiscalDocument.detailPage.paymentLines") }}
                            </h2>
                        </div>
                        <div v-show="fiscalDocument.paymentLines !== null && fiscalDocument.paymentLines.length === 0">
                            <p>{{ $t("pages.fiscalDocument.detailPage.noPaymentLines") }}</p>
                        </div>
                        <PaymentLinesTable
                            :table-data="fiscalDocument.paymentLines"
                            :currency="fiscalDocument.currency"
                        />
                    </div>
                </div>
                <hr>
                <div class="card-text mb-4 row">
                    <div class="col">
                        <div class="d-flex justify-content-between align-items-start">
                            <h2 class="card-title mb-4">
                                {{ isPassiveFiscalDocument ? $t("pages.fiscalDocument.detailPage.madePayments") : $t("pages.fiscalDocument.detailPage.receivedPayments") }}
                            </h2>
                        </div>
                        <div v-show="fiscalDocument.payments !== null && fiscalDocument.payments.length === 0">
                            <p>{{ $t("pages.fiscalDocument.detailPage.noPayments") }}</p>
                        </div>
                        <PaymentsTable
                            :table-data="fiscalDocument.payments"
                            :fiscal-document-number="fiscalDocument.number"
                            :fiscal-document-amount-residual="fiscalDocument.amountResidual"
                            @data-changed="loadData"
                        />
                    </div>
                </div>
                <hr>
                <div class="card-text mb-4 row">
                    <div class="col">
                        <div class="d-flex justify-content-between align-items-start">
                            <h2 class="card-title mb-4">
                                {{ $t("pages.fiscalDocument.detailPage.attachments") }}
                            </h2>
                        </div>
                        <div v-show="fiscalDocument.attachments !== null && fiscalDocument.attachments.length === 0">
                            <p>{{ $t("pages.fiscalDocument.detailPage.noAttachments") }}</p>
                        </div>
                        <Attachments :attachments="fiscalDocument.attachments" />
                    </div>
                </div>
                <hr>
                <div class="card-text mb-4 row">
                    <div class="col">
                        <div class="d-flex justify-content-between align-items-start">
                            <h2 class="card-title mb-4">
                                {{ isInvoice ? $t("pages.fiscalDocument.detailPage.creditNotes") : $t("pages.fiscalDocument.detailPage.invoice") }}
                            </h2>
                        </div>
                        <div v-show="fiscalDocument.children !== null && fiscalDocument.children.length === 0">
                            <p>{{ $t("pages.fiscalDocument.detailPage.noChildren") }}</p>
                        </div>
                        <ul class="pl-3">
                            <template
                                v-for="creditNote in fiscalDocument.children"
                                v-if="isInvoice"
                            >
                                <li :key="creditNote.id">
                                    <a :href="`/fiscalDocument/${creditNote.id}`">{{ creditNote.number }}</a>
                                </li>
                            </template>
                            <template v-if="!isInvoice && parentId !== null">
                                <li>
                                    <a :href="`/fiscalDocument/${parentId}`">{{ parentNumber }}</a>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </card>
        </div>

        <CreatePayment
            v-if="createPaymentModal.showModal"
            :fiscal-document-id="parseInt($route.params.id)"
            :fiscal-document-number="fiscalDocument.number"
            :fiscal-document-amount-residual="fiscalDocument.amountResidual"
            :show-modal="createPaymentModal.showModal"
            :type="isPassiveFiscalDocument ? 'passive' : 'active'"
            @close-modal="createPaymentModal.showModal = false"
            @data-changed="loadData"
        />
    </div>
</template>

<script>
import { fiscalDocumentClient } from "@/clients/fiscalDocumentClient";
import fiscalDocumentMixins from "@/mixins/fiscalDocumentMixins";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import formatterMixins from "@/mixins/formatterMixins";
import Header from "@/components/Header";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import DetailLinesTable from "@/views/Pages/Components/FiscalDocument/DetailLinesTable";
import DetailWithholdingLinesTable from "@/views/Pages/Components/FiscalDocument/DetailWithholdingLinesTable";
import SummaryLinesTable from "@/views/Pages/Components/FiscalDocument/SummaryLinesTable";
import PaymentLinesTable from "@/views/Pages/Components/FiscalDocument/PaymentLinesTable";
import Attachments from "@/views/Pages/Components/Attachments/Attachments";
import CreatePayment from "@/views/Pages/Components/FiscalDocument/Modal/CreatePayment";
import PaymentsTable from "@/views/Pages/Components/FiscalDocument/PaymentsTable";

export default {
    name: 'DetailFiscalDocument',
    components: {
        Header,
        LoadingBar,
        DetailWithholdingLinesTable,
        DetailLinesTable,
        SummaryLinesTable,
        PaymentLinesTable,
        Attachments,
        CreatePayment,
        PaymentsTable
    },
    mixins: [
        exceptionsMixins,
        formatterMixins,
        fiscalDocumentMixins
    ],
    data() {
        return {
            loaded: false,
            fiscalDocument: {
                number: null,
                sender: null,
                recipient: null,
                purpose: null,
                type: null,
                status: null,
                documentType: null,
                date: null,
                paymentLines: null,
                currency: null,
                paymentAmount: null,
                totalImport: null,
                revenueStamp: null,
                detailLines: null,
                summaryLines: null,
                attachments: null,
                children: null,
                parent: null,
                payments: null,
                amountPaid: null,
                amountResidual: null,
                withholdingLines: []
            },
            createPaymentModal: {
                showModal: false,
            }
        };
    },
    computed: {
        isPassiveFiscalDocument() {
            const isPassiveInvoice = this.$store.getters.activeRegistryId === this.recipientId && this.isInvoice;
            const isPassiveCreditNote = this.$store.getters.activeRegistryId === this.senderId && !this.isInvoice;

            return isPassiveInvoice || isPassiveCreditNote;
        },
        isInvoice() {
            return this.isInvoiceType(this.fiscalDocument.documentType);
        },
        senderId() {
            return this.fiscalDocument.sender?.id;
        },
        senderName() {
            return this.fiscalDocument.sender?.name;
        },
        recipientId() {
            return this.fiscalDocument.recipient?.id;
        },
        recipientName() {
            return this.fiscalDocument.recipient?.name;
        },
        parentId() {
            return this.fiscalDocument.parent?.id;
        },
        parentNumber() {
            return this.fiscalDocument.parent?.number;
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.loaded = false;

            fiscalDocumentClient.getById(this.$route.params.id).then((result) => {
                this.fiscalDocument.number = result.data.number;
                this.fiscalDocument.sender = result.data.sender;
                this.fiscalDocument.recipient = result.data.recipient;
                this.fiscalDocument.purpose = result.data.purpose;
                this.fiscalDocument.type = result.data.type;
                this.fiscalDocument.status = result.data.status;
                this.fiscalDocument.documentType = result.data.documentType;
                this.fiscalDocument.date = result.data.date;
                this.fiscalDocument.currency = result.data.currency;
                this.fiscalDocument.paymentAmount = result.data.paymentAmount;
                this.fiscalDocument.totalImport = result.data.totalImport;
                this.fiscalDocument.revenueStamp = result.data.revenueStamp;
                this.fiscalDocument.detailLines = result.data.detailLines;
                this.fiscalDocument.summaryLines = result.data.summaryLines;
                this.fiscalDocument.paymentLines = result.data.paymentLines;
                this.fiscalDocument.attachments = result.data.attachments;
                this.fiscalDocument.children = result.data.children;
                this.fiscalDocument.parent = result.data.parent;
                this.fiscalDocument.payments = result.data.payments;
                this.fiscalDocument.amountPaid = result.data.amountPaid;
                this.fiscalDocument.amountResidual = result.data.amountResidual;
                this.fiscalDocument.withholdingLines = result.data.withholdingLines;
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loaded = true;
            });
        },
        openCreatePaymentModal() {
            this.createPaymentModal.showModal = true;
        },
    }
}
</script>
