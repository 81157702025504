export default class FiscalDocumentDetailLineOutcomingDto {
    #quantity;
    #description;
    #unitPrice;
    #vat;
    #nature;

    /**
     * Constructor.
     *
     * @param quantity {number}
     * @param description {string}
     * @param unitPrice {number}
     * @param vat {number}
     * @param nature {string|null}
     */
    constructor(
        quantity,
        description,
        unitPrice,
        vat,
        nature
    ) {
        this.#quantity = quantity;
        this.#description = description;
        this.#unitPrice = unitPrice;
        this.#vat = vat;
        this.#nature = nature;
    }

    get quantity() {
        return this.#quantity;
    }

    get description() {
        return this.#description;
    }

    get unitPrice() {
        return this.#unitPrice;
    }

    get vat() {
        return this.#vat;
    }

    get nature() {
        return this.#nature;
    }
}
