<template>
    <div
        class="col"
        :data-cy="'contactTable'"
    >
        <card
            class="no-border-card"
            body-classes="p-0"
            footer-classes="pb-2"
        >
            <BaseTable
                :table-data="tableData"
                :search-keys="[
                    'name',
                    'email',
                    'phoneNumber'
                ]"
                :search-threshold="0.1"
            >
                <el-table-column
                    v-for="column in tableColumns"
                    :key="column.label"
                    v-bind="column"
                    :formatter="column.formatter"
                />
                <el-table-column
                    :label="$t('pages.contact.associatedRegistries')"
                    :min-width="160"
                    :class-name="'registriesColumn'"
                >
                    <template
                        #default="{row}"
                    >
                        <badge
                            v-for="(registry, index) in row.registries"
                            :key="'n' + index + 'registry-id-' + registry.id"
                            class="badge-dot mr-4"
                            type=""
                        >
                            <i :class="`bg-brand-color label-contact-area`">{{ registry.area.charAt(0).toUpperCase() }}</i>
                            <a
                                class="registry text-dark"
                                :href="'/registry/'+ registry.id"
                            >{{ registry.name }}</a>
                        </badge>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    :label="$t('tables.column.actions')"
                    :min-width="100"
                >
                    <div
                        slot-scope="{$index, row}"
                        class="d-flex flex-wrap"
                    >
                        <base-button
                            :data-cy="'buttonUpdateContactId'+row.id"
                            class="edit"
                            type="primary"
                            size="sm"
                            icon
                            @click="openEditModal(row.id)"
                        >
                            <i class="fas fa-edit text-white" />
                        </base-button>
                        <base-button
                            :data-cy="'buttonDeleteContactId'+row.id"
                            class="remove btn-link"
                            type="danger"
                            size="sm"
                            icon
                            @click="openDeleteModal(row.id)"
                        >
                            <i class="fas fa-trash-alt text-white" />
                        </base-button>
                        <base-button
                            :data-cy="'buttonDetailsContactId'+row.id"
                            class="remove btn-link border"
                            type="secondary"
                            size="sm"
                            icon
                            @click="$router.push(
                                {
                                    name:'CONTACT-DETAIL',
                                    params: {
                                        id: row.id
                                    }
                                }
                            );"
                        >
                            <i class="fas fa-info-circle" />
                        </base-button>
                    </div>
                </el-table-column>
            </BaseTable>
        </card>

        <DeleteContact
            :delete-contact-id="deleteModal.idContactToDelete"
            :show-delete="deleteModal.showDeleteModal"
            :contact-name="deleteModal.contactName"
            @close-delete="deleteModal.showDeleteModal = false;"
            @data-changed="$emit('data-changed');"
        />

        <EditContact
            :edit-contact-id="editModal.editContactId"
            :show-edit="editModal.showEditModal"
            @close-edit="editModal.showEditModal = false;"
            @data-changed="$emit('data-changed');"
        />
    </div>
</template>

<script>
import { Option, Select, Table, TableColumn } from "element-ui";
import clientPaginationMixin from "@/views/Mixins/clientPaginationMixin";
import BaseButton from "@/components/BaseButton";
import DeleteContact from "@/views/Pages/Components/Contact/Modal/Delete";
import EditContact from "@/views/Pages/Components/Contact/Modal/Edit";
import BaseTable from "@/views/Pages/Components/Table/BaseTable";

export default {
    name: "ContactTable",
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        DeleteContact,
        EditContact,
        BaseButton,
        BaseTable
    },
    mixins: [
        clientPaginationMixin,
    ],
    props: {
        tableData: {
            type: Array
        }
    },
    data() {
        return {
            tableColumns: [
                {
                    prop: "name",
                    label: this.$t("tables.column.name"),
                    minWidth: 130,
                    sortable: true,
                },
                {
                    prop: "email",
                    label: this.$t("tables.column.email"),
                    minWidth: 100,
                },
                {
                    prop: "telephone",
                    label: this.$t("tables.column.telephone"),
                    minWidth: 60,
                },
            ],
            deleteModal: {
                showDeleteModal: false,
                idContactToDelete: null,
                contactName: '',
                loading: false,
            },
            editModal: {
                showEditModal: false,
                editContactId: null
            },
        };
    },
    methods: {
        openEditModal(id) {
            this.editModal.showEditModal = true;
            this.editModal.editContactId = Number(id);
        },
        openDeleteModal(id) {
            this.deleteModal.idContactToDelete = Number(id);
            this.deleteModal.showDeleteModal = true;
            this.deleteModal.contactName = (this.tableData.filter((value) => {
                return value.id === id;
            })[0]).name;
        },
    }
};
</script>

<style lang="scss">
td.registriesColumn {
    .cell {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}
i.label-contact-area {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    text-align: center;
    display: flex!important;
    justify-content: center;
    align-items: center;
    width: 1.375rem;
    height: 1.375rem;
}
.badge-dot {
    display: flex;
    align-items: center;
}
</style>
