var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"steps"},[_vm._l((_vm.steps),function(value,key){return [_c('li',{key:key,class:{
                'step': true,
                'step--incomplete': !_vm.isCompletedStep(Number(key)),
                'step--complete': _vm.isCompletedStep(Number(key)),
                'step--inactive': Number(key) !== _vm.activeStep,
                'step--active': Number(key) === _vm.activeStep
            }},[_c('i',{staticClass:"fas fa-check step__icon"}),_c('span',{staticClass:"step__label"},[_vm._v(_vm._s(value))])])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }