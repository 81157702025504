/**
 * sortingFunctionsMixin
 */
export default {
    methods: {
        sortDatesDesc(a, b) {
            if (a === b) {
                return 0;
            }
            else if (a === null) {
                return 1;
            }
            else if (b === null) {
                return -1;
            }
            else {
                const aDate = new Date(a);
                const bDate = new Date(b);
                return bDate - aDate;
            }
        },
        sortDateAsc(a, b) {
            if (a === b) {
                return 0;
            }
            else if (a === null) {
                return 1;
            }
            else if (b === null) {
                return -1;
            }
            else {
                const aDate = new Date(a);
                const bDate = new Date(b);
                return aDate - bDate;
            }
        }
    }
}
