<template>
    <base-nav
        container-classes="container-fluid"
        :class="{
            'navbar-top border-bottom navbar-expand talks-bg-color': true,
            'navbar-dark': type === 'default'
        }"
    >
        <!-- Navbar links -->
        <ul class="navbar-nav align-items-center">
            <li class="nav-item d-xl-none">
                <!-- Sidenav toggler -->
                <div
                    class="pr-3 sidenav-toggler"
                    :class="{active: $sidebar.showSidebar, 'sidenav-toggler-dark': type === 'default', 'sidenav-toggler-light': type === 'light'}"
                    @click="toggleSidebar"
                >
                    <div class="sidenav-toggler-inner">
                        <i class="sidenav-toggler-line" />
                        <i class="sidenav-toggler-line" />
                        <i class="sidenav-toggler-line" />
                    </div>
                </div>
            </li>
        </ul>
        <v-select
            v-model="registryId"
            label="name"
            class="form-control form-control-alternative w-auto registry-selector mr-md-auto"
            :options="registryList"
            :reduce="registry => registry.id"
            @option:selected="registrySelected"
        />
        <v-select
            v-model="year"
            class="form-control form-control-alternative w-auto year-selector mr-md-auto"
            :options="yearList"
            @option:selected="yearSelected"
        />
        <ul class="navbar-nav align-items-center ml-auto ml-md-0">
            <base-dropdown
                menu-on-right
                class="nav-item"
                tag="li"
                title-tag="a"
                title-classes="nav-link pr-0"
            >
                <a
                    slot="title-container"
                    :data-cy="'linkPersonalMenu'"
                    href="#"
                    class="nav-link pr-0"
                    @click.prevent
                >
                    <div class="media align-items-center">
                        <span class="avatar avatar-sm rounded-circle">
                            <img
                                alt="Image placeholder"
                                src="/img/theme/blank-profile-picture.png"
                            >
                        </span>
                        <div class="media-body ml-2 d-none d-lg-block">
                            <span class="mb-0 text-sm  font-weight-bold">{{ $store.getters.email }}</span>
                        </div>
                    </div>
                </a>

                <a
                    href="#"
                    class="dropdown-item"
                    :data-cy="'linkPersonalMenuLogout'"
                    @click="logoutModal"
                >
                    <i class="fas fa-sign-out-alt" />
                    <span>{{ $t("authentications.logout") }}</span>
                </a>
            </base-dropdown>
        </ul>

        <modal :show.sync="modals.logout">
            <template slot="header">
                <h5
                    id="exampleModalLabel"
                    class="modal-title"
                >
                    {{ $t("authentications.logout") }}
                </h5>
            </template>
            <div>
                {{ $t("authentications.logoutMessage") }}
            </div>
            <template slot="footer">
                <base-button
                    type="neutral"
                    @click="modals.logout = false"
                >
                    {{ $t("authentications.abort") }}
                </base-button>
                <base-button
                    type="success"
                    :data-cy="'buttonLogout'"
                    @click="logout"
                >
                    {{ $t("authentications.logout") }}
                </base-button>
            </template>
        </modal>
    </base-nav>
</template>

<script>
import { BaseNav } from '@/components';
import { authenticationService } from "@/services/authenticationService";
import { registryClient } from "@/clients/registryClient";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import companyMixins from "@/mixins/companyMixins";
import store from "@/main";
import vSelect from 'vue-select';
import notificationsMixins from "@/mixins/notificationsMixins";

export default {
    components: {
        BaseNav,
        vSelect,
    },
    mixins: [
        companyMixins,
        exceptionsMixins,
        notificationsMixins
    ],
    props: {
        type: {
            type: String,
            default: 'default', // default|light
            description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
        }
    },
    data() {
        return {
            activeNotifications: false,
            showMenu: false,
            searchModalVisible: false,
            searchQuery: '',
            modals: {
                logout: false,
            },
            registryList: [],
            registryId: null,
            year: this.$store.getters.yearSelectedFilter,
        };
    },
    computed: {
        routeName() {
            const { name } = this.$route;
            return this.capitalizeFirstLetter(name);
        },
        yearList() {
            let yearList = [];
            for (let fromYear = new Date().getFullYear(); fromYear >= 2013; fromYear--) {
                yearList.push({
                    code: fromYear,
                    label: fromYear
                });
            }

            return yearList;
        }
    },
    mounted() {
        registryClient.getByFilters(null, true)
            .then(response => {
                this.registryList = response.data;

                if (this.registryList.length === 0) {
                    this.showSwal(
                        'warning',
                        this.$t("notifications.failed.noRegistryOwned"),
                        this.$t("notifications.failed.noRegistryOwnedMessage")
                    );
                }

                if (this.$store.getters.activeRegistryId === null) {
                    this.registrySelected(this.registryList[0], true);
                }
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.registryId = this.$store.getters.activeRegistryId;
            });
    },
    methods: {
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        toggleNotificationDropDown() {
            this.activeNotifications = !this.activeNotifications;
        },
        closeDropDown() {
            this.activeNotifications = false;
        },
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        hideSidebar() {
            this.$sidebar.displaySidebar(false);
        },
        logoutModal() {
            this.modals.logout = true;
        },
        logout() {
            this.modals.logout = false;
            authenticationService.removeAuthenticationToken();
            store.dispatch("resetAuthenticationsParameters");
            location.replace("/");
        },
        registrySelected(selectedOption, firstSet) {
            store.dispatch("setActiveRegistry", selectedOption);
            if (firstSet === undefined) {
                window.location.reload();
            }
        },
        yearSelected(selectedOption) {
            store.dispatch("setYearSelected", selectedOption.code);
        }
    }
};
</script>
