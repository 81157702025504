export const FiscalDocumentStatus = Object.freeze({
    DRAFT: 'DRAFT',
    CREATED: 'CREATED',
    RECEIVED: 'RECEIVED',
    ERROR: 'ERROR',
    SENT: 'SENT',
    NOT_SENT: 'NOT-SENT',
    PAID: 'PAID',
    PARTIALLY_PAID: 'PARTIALLY_PAID',
    CANCELLED: 'CANCELLED',
    DONE: 'DONE'
});

export const FiscalDocumentDocumentType = Object.freeze({
    TD01: 'TD01',
    TD02: 'TD02',
    TD03: 'TD03',
    TD04: 'TD04',
    TD05: 'TD05',
    TD06: 'TD06',
    TD16: 'TD16',
    TD17: 'TD17',
    TD18: 'TD18',
    TD19: 'TD19',
    TD20: 'TD20',
    TD21: 'TD21',
    TD22: 'TD22',
    TD23: 'TD23',
    TD24: 'TD24',
    TD25: 'TD25',
    TD26: 'TD26',
    TD27: 'TD27',
});

export const FiscalDocumentPaymentType = Object.freeze({
    TP01: 'TP01',
    TP02: 'TP02',
    TP03: 'TP03',
});

export const FiscalDocumentPaymentMode = Object.freeze({
    MP01: 'MP01',
    MP02: 'MP02',
    MP03: 'MP03',
    MP04: 'MP04',
    MP05: 'MP05',
    MP06: 'MP06',
    MP07: 'MP07',
    MP08: 'MP08',
    MP09: 'MP09',
    MP10: 'MP10',
    MP11: 'MP11',
    MP12: 'MP12',
    MP13: 'MP13',
    MP14: 'MP14',
    MP15: 'MP15',
    MP16: 'MP16',
    MP17: 'MP17',
    MP18: 'MP18',
    MP19: 'MP19',
    MP20: 'MP20',
    MP21: 'MP21',
    MP22: 'MP22',
    MP23: 'MP23',
});

export const FiscalDocumentExceptionCodes = Object.freeze({
    NOT_VALID_NUMBER: 9027,
});

export const FiscalDocumentSummaryLineNatureCodes = Object.freeze({
    N1: 'N1',
    N2: 'N2',
    N2_1: 'N2.1',
    N2_2: 'N2.2',
    N3: 'N3',
    N3_1: 'N3.1',
    N3_2: 'N3.2',
    N3_3: 'N3.3',
    N3_4: 'N3.4',
    N3_5: 'N3.5',
    N3_6: 'N3.6',
    N4: 'N4',
    N5: 'N5',
    N6: 'N6',
    N6_1: 'N6.1',
    N6_2: 'N6.2',
    N6_3: 'N6.3',
    N6_4: 'N6.4',
    N6_5: 'N6.5',
    N6_6: 'N6.6',
    N6_7: 'N6.7',
    N6_8: 'N6.8',
    N6_9: 'N6.9',
    N7: 'N7',
});

export const FiscalDocumentSummaryLineVatEnforceabilityCodes = Object.freeze({
    IMMEDIATE: 'I',
    DEFERRED: 'D',
    SPLIT_PAYMENTS: 'S',
});

export const FiscalDocumentVatNatureMapping = Object.freeze({
    VN0: { vat: 24, nature: null },
    VN1: { vat: 23, nature: null },
    VN2: { vat: 22, nature: null },
    VN4: { vat: 21, nature: null },
    VN5: { vat: 20, nature: null },
    VN6: { vat: 8, nature: null },
    VN7: { vat: 5, nature: null },
    VN8: { vat: 4, nature: null },
    VN9: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N4 },
    VN10: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N2_2 },
    VN11: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N2_2 },
    VN12: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N2_1 },
    VN13: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N6_9 },
    VN14: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N1 },
    VN15: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N3_1 },
    VN16: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N3_4 },
    VN17: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N3_6 },
    VN18: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N3_2 },
    VN19: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N3_4 },
    VN20: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N2_2 },
    VN21: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N4 },
    VN22: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N1 },
    VN23: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N1 },
    VN24: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N5 },
    VN25: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N4 },
    VN26: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N4 },
    VN27: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N3_4 },
    VN28: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N4 },
    VN29: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N5 },
    VN30: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N1 },
    VN31: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N1 },
    VN32: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N3_5 },
    VN33: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N2_1 },
    VN34: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N2_1 },
    VN35: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N4 },
    VN36: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N2_1 },
    VN37: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N3_1 },
    VN38: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N2_1 },
    VN39: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N4 },
    VN40: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N4 },
    VN41: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N3_6 },
    VN42: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N3_2 },
    VN43: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N3_1 },
    VN44: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N3_4 },
    VN45: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N2_2 },
    VN46: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N2_2 },
    VN47: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N6_1 },
    VN48: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N6_2 },
    VN49: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N6_3 },
    VN50: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N6_4 },
    VN51: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N6_5 },
    VN52: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N6_6 },
    VN53: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N6_7 },
    VN54: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N6_8 },
    VN55: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N3_4 },
    VN56: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N3_3 },
    VN57: { vat: 0, nature: FiscalDocumentSummaryLineNatureCodes.N2_2 },
});

export const FiscalDocumentCustomizablePaymentDeadlineDate = Object.freeze({
    CUSTOM: 'CUSTOM'
});
