<template>
    <div class="content">
        <Header
            :title="$t('pages.bank.breadcrumb.detail')"
        />

        <LoadingBar v-show="!loaded" />
        <div
            v-show="loaded"
            class="container-fluid mt--6 pt-4"
            :data-cy="'bankDetailsContainer'"
        >
            <card>
                <div class="d-flex justify-content-between align-items-start">
                    <h1 class="card-title mb-4">
                        {{ $t("pages.bank.detailPage.title") + bank.name }}
                    </h1>
                    <div>
                        <base-button
                            :data-cy="'buttonUpdateBankId'+$route.params.id"
                            class="edit mb-2 mb-sm-0"
                            type="primary"
                            size="sm"
                            icon
                            @click="openEditModal($route.params.id)"
                        >
                            <i class="fas fa-edit text-white" />
                        </base-button>
                        <base-button
                            :data-cy="'buttonDeleteBankId'+$route.params.id"
                            class="remove btn-link mb-2 mb-sm-0"
                            type="danger"
                            size="sm"
                            icon
                            @click="openDeleteModal($route.params.id)"
                        >
                            <i class="fas fa-trash-alt text-white" />
                        </base-button>
                    </div>
                </div>
                <div class="card-text mb-4">
                    <dl class="row">
                        <dt class="col-sm-2">
                            {{ $t("forms.fields.name") }}
                        </dt>
                        <dd class="col-sm-10">
                            {{ bank.name }}
                        </dd>
                        <dt class="col-sm-2">
                            {{ $t("forms.fields.swiftBic") }}
                        </dt>
                        <dd class="col-sm-10">
                            {{ bank.swiftBic }}
                        </dd>
                    </dl>
                </div>
                <hr>
                <div :data-cy="'bankLocationContainer'">
                    <h2>{{ $t("pages.bank.location") }}</h2>
                    <dl class="row">
                        <dt class="col-sm-2">
                            {{ $t("forms.fields.address") }}
                        </dt>
                        <dd class="col-sm-10">
                            {{ bank.locationAddress }}
                        </dd>
                        <dt class="col-sm-2">
                            {{ $t("forms.fields.city") }}
                        </dt>
                        <dd class="col-sm-10">
                            {{ bank.locationCity }}
                        </dd>
                        <dt class="col-sm-2">
                            {{ $t("forms.fields.zip") }}
                        </dt>
                        <dd class="col-sm-10">
                            {{ bank.locationZip }}
                        </dd>
                        <dt class="col-sm-2">
                            {{ $t("forms.fields.state") }}
                        </dt>
                        <dd class="col-sm-10">
                            {{ bank.locationState }}
                        </dd>
                        <dt class="col-sm-2">
                            {{ $t("forms.fields.country") }}
                        </dt>
                        <dd class="col-sm-10">
                            {{ bank.locationCountry }}
                        </dd>
                    </dl>
                </div>
            </card>
        </div>

        <DeleteBank
            :delete-bank-id="deleteModal.idBankToDelete"
            :show-delete="deleteModal.showDeleteModal"
            :bank-name="deleteModal.bankName"
            @close-delete="deleteModal.showDeleteModal = false;"
            @data-changed="backToBankListing"
        />

        <EditBank
            :edit-bank-id="editModal.editBankId"
            :show-edit="editModal.showEditModal"
            @close-edit="editModal.showEditModal = false;"
            @data-changed="loadData"
        />
    </div>
</template>

<script>
import { bankClient } from "@/clients/bankClient";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import DeleteBank from "@/views/Pages/Components/Bank/Modal/Delete";
import EditBank from "@/views/Pages/Components/Bank/Modal/Edit";
import Header from "@/components/Header";

export default {
    name: 'DetailBank',
    components: {
        LoadingBar,
        DeleteBank,
        EditBank,
        Header,
    },
    mixins: [
        exceptionsMixins
    ],
    data() {
        return {
            bank: {
                name: '',
                swiftBic: '',
                locationAddress: '',
                locationCity: '',
                locationState: '',
                locationZip: '',
                locationCountry: '',
            },
            loaded: false,
            deleteModal: {
                showDeleteModal: false,
                idBankToDelete: null,
                bankName: '',
                loading: false,
            },
            editModal: {
                showEditModal: false,
                editBankId: null
            },
        };
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.loaded = false;
            bankClient.getById(this.$route.params.id).then((result) => {
                this.bank.name = result.data.name;
                this.bank.swiftBic = result.data.swiftBic;

                this.bank.locationAddress = '';
                this.bank.locationCity = '';
                this.bank.locationState = '';
                this.bank.locationZip = '';
                this.bank.locationCountry =  '';
                if (result.data.location !== null) {
                    this.bank.locationAddress = result.data.location.address ? result.data.location.address : '';
                    this.bank.locationCity = result.data.location.city ? result.data.location.city : '';
                    this.bank.locationState = result.data.location.state ? result.data.location.state : '';
                    this.bank.locationZip = result.data.location.zip ? result.data.location.zip : '';
                    this.bank.locationCountry = result.data.location.country ? result.data.location.country : '';
                }
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loaded = true;
            });
        },
        openEditModal(id) {
            this.editModal.showEditModal = true;
            this.editModal.editBankId = Number(id);
        },
        openDeleteModal(id) {
            this.deleteModal.idBankToDelete = Number(id);
            this.deleteModal.showDeleteModal = true;
            this.deleteModal.bankName = this.bank.name;
        },
        backToBankListing() {
            this.$router.push({name:'BANK-LIST'});
        }
    }
}
</script>
