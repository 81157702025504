<template>
    <div
        class="col"
        :data-cy="'firstAccountingTable'"
    >
        <card
            class="no-border-card"
            body-classes="p-0"
            footer-classes="pb-2"
        >
            <BaseTable
                :table-data="tableData"
                :default-sort="{prop: 'date', order: 'descending'}"
                :search-keys="[
                    'cashflow.sender',
                    'cashflow.recipient',
                    'invoice.sender',
                    'invoice.recipient',
                    'cashflow.number',
                    'invoice.number'
                ]"
            >
                <el-table-column
                    :label="$t('tables.column.date')"
                    :min-width="30"
                    :sortable="true"
                    :prop="'date'"
                >
                    <template
                        #default="{row}"
                    >
                        {{ formatBackendDate(new Date(row.date)) }}
                    </template>
                </el-table-column>
                <el-table-column
                    :label="$t('tables.column.detail')"
                    :min-width="150"
                >
                    <template
                        #default="{row}"
                    >
                        <div>{{ isCashFlow(row) ? getCashFlowLabel(row) : getFiscalDocumentLabel(row) }}</div>
                        <a
                            class="text-dark"
                            :href="isCashFlow(row) ? `/cashflow/${row.cashflow.id}` : `/fiscalDocument/${row.invoice.id}`"
                        >{{ isCashFlow(row) ? getCashFlowDetail(row) : getFiscalDocumentDetail(row) }}</a>
                    </template>
                </el-table-column>
                <el-table-column
                    v-for="column in tableColumns"
                    :key="column.label"
                    v-bind="column"
                    :formatter="column.formatter"
                />
            </BaseTable>
        </card>
    </div>
</template>

<script>
import { Option, Select, Table, TableColumn } from "element-ui";
import formatterMixins from "@/mixins/formatterMixins";
import fiscalDocumentMixins from "@/mixins/fiscalDocumentMixins";
import BaseTable from "@/views/Pages/Components/Table/BaseTable";

export default {
    name: "FirstAccountingTable",
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        BaseTable
    },
    mixins: [
        formatterMixins,
        fiscalDocumentMixins
    ],
    props: {
        tableData: {
            type: Array
        }
    },
    data() {
        return {
            tableColumns: [
                {
                    prop: "amount",
                    label: this.$t("tables.column.incomeAmount"),
                    minWidth: 80,
                    sortable: true,
                    formatter: row => (row.isIncome) ? this.formatCurrency(row.currency, row.amount) : ''
                },
                {
                    prop: "amount",
                    label: this.$t("tables.column.outcomeAmount"),
                    minWidth: 80,
                    sortable: true,
                    formatter: row => (!row.isIncome) ? this.formatCurrency(row.currency, row.amount) : ''
                },
            ],
        };
    },
    methods: {
        isCashFlow(rowData) {
            return rowData.cashflow !== null;
        },
        getCashFlowDetail(row) {
            return this.cashFlowDetailTitle(row) + ': ' + row.cashflow.number;
        },
        getFiscalDocumentDetail(row) {
            return this.isCreditNoteType(row.invoice.type) ?
                this.creditNoteDetailTitle() + ': ' + row.invoice.number :
                this.invoiceDetailTitle() + ': ' + row.invoice.number
            ;
        },
        creditNoteDetailTitle() {
            return this.$t('pages.firstAccounting.detailColumn.creditNote');
        },
        invoiceDetailTitle() {
            return this.$t('pages.firstAccounting.detailColumn.invoice');
        },
        cashFlowDetailTitle(row) {
            if (row.isIncome) {
                return this.$t('pages.firstAccounting.detailColumn.cashFlow.income');
            }

            return this.$t('pages.firstAccounting.detailColumn.cashFlow.outcome');
        },
        getCashFlowLabel(row) {
            return row.cashflow.sender;
        },
        getFiscalDocumentLabel(row) {
            const isIncome = row.isIncome;
            const invoice = row.invoice;

            if (isIncome && this.isInvoiceType(invoice.type)) {
                return invoice.recipient;
            }

            if (isIncome && this.isCreditNoteType(invoice.type)) {
                return invoice.sender;
            }

            if (!isIncome && this.isInvoiceType(invoice.type)) {
                return invoice.sender;
            }

            if (!isIncome && this.isCreditNoteType(invoice.type)) {
                return invoice.recipient;
            }
        },
    }
};
</script>
