import { baseEntityClient } from "@/clients/baseEntityClient";
import { SupportedEntity } from "@/clients/constants/clientConstants";
import axios, { AxiosResponse } from "axios";
import { headersBuilder } from "@/clients/headersBuilder";

/**
 * cashFlowClient.
 */
export const cashFlowClient = {
    /**
     * Call get cashFlow endpoint with provided id.
     *
     * @param id {number}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getById(id) {
        return baseEntityClient.getEntityById(SupportedEntity.CASH_FLOW, id);
    },
    /**
     * Call get cashFlow endpoint with filters.
     *
     * @param recipientRegistryId {number|null}
     * @param senderRegistryName {string|null}
     * @param number {string|null}
     * @param tag {string|null}
     * @param type {string|null}
     * @param fromExpenseDate {string|null} (Y-m-d)
     * @param toExpenseDate {string|null} (Y-m-d)
     *
     * @returns {Promise<AxiosResponse<*>>}
     */
    getByFilters(
        recipientRegistryId = null,
        senderRegistryName = null,
        number = null,
        tag = null,
        type = null,
        fromExpenseDate = null,
        toExpenseDate = null
    ) {
        const filters = {
            recipientRegistryId,
            senderRegistryName,
            number,
            tag,
            type,
            fromExpenseDate,
            toExpenseDate
        };

        return baseEntityClient.getEntityByFilters(SupportedEntity.CASH_FLOW, filters);
    },
    /**
     * Call the delete cashFlow endpoint.
     *
     * @param id {number}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete(id) {
        return baseEntityClient.deleteEntityById(SupportedEntity.CASH_FLOW, id);
    },
    /**
     * Call update cashFlow endpoint.
     *
     * @param id {number}
     * @param cashFlowDto {CashFlowDto}
     */
    update(id, cashFlowDto) {
        const data = {
            senderRegistryName: cashFlowDto.registrySenderName,
            tag: cashFlowDto.tag,
            type: cashFlowDto.type,
            description: cashFlowDto.description,
            amount: parseFloat(cashFlowDto.amount),
            vat: parseFloat(cashFlowDto.vat),
            tax: parseFloat(cashFlowDto.tax),
            currency: cashFlowDto.currency,
            expenseDate: cashFlowDto.expenseDate
        };

        return baseEntityClient.updateEntity(SupportedEntity.CASH_FLOW, id, data);
    },
    /**
     * Call create cashFlow endpoint.
     *
     * @param cashFlowDto {CashFlowDto}
     */
    create(cashFlowDto) {
        const data = {
            number: cashFlowDto.number,
            recipientRegistryId: cashFlowDto.registryRecipientId,
            senderRegistryName: cashFlowDto.registrySenderName,
            tag: cashFlowDto.tag,
            type: cashFlowDto.type,
            description: cashFlowDto.description,
            amount: parseFloat(cashFlowDto.amount),
            vat: parseFloat(cashFlowDto.vat),
            tax: parseFloat(cashFlowDto.tax),
            currency: cashFlowDto.currency,
            expenseDate: cashFlowDto.expenseDate,
            income: cashFlowDto.income
        };

        return baseEntityClient.createEntity(SupportedEntity.CASH_FLOW, data);
    },
    /**
     * Call create cashFlowAttachment endpoint.
     *
     * @param cashFlowId {number}
     * @param cashFlowAttachmentDto {CashFlowAttachmentDto}
     */
    createAttachment(cashFlowId, cashFlowAttachmentDto) {
        const data = {
            encodedData: cashFlowAttachmentDto.encodedData
        };

        return axios.post(
            process.env.VUE_APP_ADMINISTRATION_BASE_URL +
            `/api/v1/${SupportedEntity.CASH_FLOW}/${cashFlowId}/attachment`,
            data,
            {
                headers: headersBuilder.buildAuthenticatedHeaders()
            });
    },
    /**
     * Call delete cashFlowAttachment endpoint.
     *
     * @param cashFlowId {number}
     * @param cashFlowAttachmentId {number}
     */
    deleteAttachment(cashFlowId, cashFlowAttachmentId) {
        return axios.delete(
            process.env.VUE_APP_ADMINISTRATION_BASE_URL +
            `/api/v1/${SupportedEntity.CASH_FLOW}/${cashFlowId}/attachment/${cashFlowAttachmentId}`,
            {
                headers: headersBuilder.buildAuthenticatedHeaders()
            });
    }
}
