<template>
    <div class="content">
        <Header
            :title="$t('pages.bank.title')"
        >
            <base-button
                type="neutral"
                :data-cy="'buttonCreateBank'"
                @click="openNewModal"
            >
                {{ $t("actions.new") }}
            </base-button>
        </Header>

        <NewBank
            :key="newBankKey"
            :show-new="showNewBank"
            @close-new="showNewBank = false;"
            @added="loadData"
        />
        <LoadingBar v-show="loading" />
        <div class="container-fluid mt--6">
            <BankTable
                :table-data="bankTableData"
                @data-changed="loadData"
            />
        </div>
    </div>
</template>

<script>
import { bankClient } from "@/clients/bankClient";
import BankTable from "@/views/Pages/Components/Bank/Table";
import NewBank from "@/views/Pages/Components/Bank/Modal/New";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import Header from "@/components/Header";
import exceptionsMixins from "@/mixins/exceptionsMixins";

export default {
    name: "Bank",
    components: {
        BankTable,
        NewBank,
        LoadingBar,
        Header,
    },
    mixins: [
        exceptionsMixins
    ],
    data() {
        return {
            bankTableData: [],
            loading: true,
            showNewBank: false,
            newBankKey: 0,
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        openNewModal() {
            this.newBankKey++;
            this.showNewBank = true;
        },
        loadData() {
            this.loading = true;
            bankClient.getByFilters().then((response) => {
                this.bankTableData = response.data;
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loading = false;
            });
        }
    }
}
</script>
