/**
 * labelsMixins.
 */
export default {
    methods: {
        /**
         * Retrieve the label of the status by the code provided.
         *
         * @param {string} statusCode
         */
        getLabelStatusFiscalDocumentByStatusCode(statusCode) {
            return this.$t(`choices.fiscalDocument.status.${statusCode}`).toUpperCase();
        },
        /**
         * Retrieve the label of the documentType by the code provided.
         *
         * @param {string} documentTypeCode
         */
        getLabelDocumentTypeFiscalDocumentByDocumentTypeCode(documentTypeCode) {
            return this.$t(`choices.fiscalDocument.documentType.${documentTypeCode}`);
        },
    }
}
