var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"modal-classes":"modal-secondary","data-cy":'createPaymentModal',"show":_vm.showModal},on:{"close":_vm.closeModal}},[_c('template',{slot:"header"},[_c('h1',[_vm._v(_vm._s(_vm.$t("pages.fiscalDocument.createPayment")))])]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"col-12",attrs:{"data-cy":'createPaymentMessage'}},[_vm._v(" "+_vm._s(_vm.$t("pages.fiscalDocument.createPaymentMessage") + _vm.fiscalDocumentNumber)+" ")]),_c('base-input',{staticClass:"col-6",attrs:{"label":_vm.$t('forms.fields.paymentType'),"placeholder":_vm.$t('forms.actions.select') + _vm.$t('forms.fields.paymentType'),"input-classes":"form-control-alternative","name":_vm.$t('forms.fields.paymentType'),"rules":{
                required: true,
                length: 4,
            },"data-cy":'createPaymentPaymentTypeField'}},[_c('v-select',{staticClass:"form-control form-control-alternative",attrs:{"options":_vm.paymentTypes,"reduce":paymentType => paymentType.code},model:{value:(_vm.model.paymentType),callback:function ($$v) {_vm.$set(_vm.model, "paymentType", $$v)},expression:"model.paymentType"}})],1),_c('base-input',{staticClass:"col-12",attrs:{"addon-left-icon":"ni ni-calendar-grid-58","label":_vm.$t('forms.fields.date'),"name":_vm.$t('forms.fields.date'),"placeholder":_vm.$t('forms.actions.select') + _vm.$t('forms.fields.date'),"data-cy":'createPaymentPaymentDateField',"rules":{required:true}},scopedSlots:_vm._u([{key:"default",fn:function({focus, blur}){return _c('flat-picker',{staticClass:"form-control datepicker payment-date",attrs:{"config":_vm.configDate},on:{"on-open":focus,"on-close":blur,"on-change":_vm.formatDate},model:{value:(_vm.model.paymentDate),callback:function ($$v) {_vm.$set(_vm.model, "paymentDate", $$v)},expression:"model.paymentDate"}})}}])}),_c('base-input',{staticClass:"col-6",attrs:{"label":_vm.$t('forms.fields.currency'),"placeholder":_vm.$t('forms.actions.select') + _vm.$t('forms.fields.currency'),"input-classes":"form-control-alternative","name":_vm.$t('forms.fields.currency'),"rules":{
                required: true,
                length: 3
            },"data-cy":'createPaymentPaymentCurrencyField'}},[_c('v-select',{staticClass:"form-control form-control-alternative",attrs:{"options":_vm.currencyList,"reduce":currency => currency.code},model:{value:(_vm.model.paymentCurrency),callback:function ($$v) {_vm.$set(_vm.model, "paymentCurrency", $$v)},expression:"model.paymentCurrency"}})],1),_c('base-input',{staticClass:"col-6",attrs:{"label":_vm.$t('forms.fields.paymentAmount'),"name":_vm.$t('forms.fields.paymentAmount'),"input-classes":"form-control-alternative","data-cy":'createPaymentPaymentAmountField',"rules":{
                required: true,
                decimal: true,
                max_value: _vm.fiscalDocumentAmountResidual
            }},model:{value:(_vm.model.paymentAmount),callback:function ($$v) {_vm.$set(_vm.model, "paymentAmount", $$v)},expression:"model.paymentAmount"}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pb-4"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('pages.fiscalDocument.residualImport') + ': ' + _vm.formatCurrency(_vm.model.paymentCurrency, _vm.calculateFiscalDocumentAmountResidual)))])])]),(_vm.type === 'passive' && _vm.canAddBankFee)?_c('div',{staticClass:"row"},[_c('base-checkbox',{staticClass:"col-12 pl-5 form-group",attrs:{"label":_vm.$t('pages.fiscalDocument.showBankFee'),"data-cy":'createPaymentShowBankFee',"name":_vm.$t('pages.fiscalDocument.showBankFee')},model:{value:(_vm.showBankFee),callback:function ($$v) {_vm.showBankFee=$$v},expression:"showBankFee"}},[_vm._v(" "+_vm._s(_vm.$t('pages.fiscalDocument.showBankFee'))+" ")]),_c('base-input',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBankFee),expression:"showBankFee"}],staticClass:"col-12",attrs:{"label":_vm.$t('forms.fields.currency'),"placeholder":_vm.$t('forms.actions.select') + _vm.$t('forms.fields.currency'),"input-classes":"form-control-alternative","name":_vm.$t('forms.fields.currency'),"rules":{
                required: _vm.showBankFee,
                length: 3
            },"data-cy":'createPaymentBankFeeCurrencyField'}},[_c('v-select',{staticClass:"form-control form-control-alternative",attrs:{"options":_vm.currencyList,"reduce":currencyChoice => currencyChoice.code},model:{value:(_vm.model.bankFeeCurrency),callback:function ($$v) {_vm.$set(_vm.model, "bankFeeCurrency", $$v)},expression:"model.bankFeeCurrency"}})],1),_c('base-input',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBankFee),expression:"showBankFee"}],staticClass:"col-6",attrs:{"label":_vm.$t('forms.fields.bankFee'),"name":_vm.$t('forms.fields.bankFee'),"input-classes":"form-control-alternative","data-cy":'createPaymentBankFeeAmountField',"rules":{
                required: _vm.showBankFee,
                decimal: true
            }},model:{value:(_vm.model.bankFeeAmount),callback:function ($$v) {_vm.$set(_vm.model, "bankFeeAmount", $$v)},expression:"model.bankFeeAmount"}}),_c('base-input',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBankFee),expression:"showBankFee"}],staticClass:"col-6",attrs:{"label":_vm.$t('forms.fields.vatAmount'),"name":_vm.$t('forms.fields.vatAmount'),"input-classes":"form-control-alternative","data-cy":'createPaymentBankFeeVatField',"rules":{
                required: _vm.showBankFee,
                decimal: true
            }},model:{value:(_vm.model.bankFeeVat),callback:function ($$v) {_vm.$set(_vm.model, "bankFeeVat", $$v)},expression:"model.bankFeeVat"}})],1):_vm._e(),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"native-type":"submit","type":"primary","loading":_vm.loading,"data-cy":'buttonCreatePayment'},on:{"click":_vm.createPayment}},[_vm._v(" "+_vm._s(_vm.$t("actions.register"))+" ")]),_c('base-button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-cy":'buttonStop'},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t("actions.cancel"))+" ")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }