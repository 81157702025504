<template>
    <div class="content">
        <Header
            :title="$t('pages.registry.breadcrumb.detail')"
        />

        <LoadingBar v-show="!loaded" />
        <div
            v-show="loaded"
            class="container-fluid mt--6 pt-4"
            :data-cy="'registryDetailsContainer'"
        >
            <card>
                <div class="d-flex justify-content-between align-items-start">
                    <h1 class="card-title mb-4">
                        {{ $t("pages.registry.detailPage.title") + registry.name }}
                    </h1>
                    <div>
                        <base-button
                            :data-cy="'buttonUpdateRegistryId'+$route.params.id"
                            class="edit mb-2 mb-sm-0"
                            type="primary"
                            size="sm"
                            icon
                            @click="openEditModal($route.params.id)"
                        >
                            <i class="fas fa-edit text-white" />
                        </base-button>
                        <base-button
                            :data-cy="'buttonDeleteRegistryId'+$route.params.id"
                            class="remove btn-link mb-2 mb-sm-0"
                            type="danger"
                            size="sm"
                            icon
                            @click="openDeleteModal($route.params.id)"
                        >
                            <i class="fas fa-trash-alt text-white" />
                        </base-button>
                    </div>
                </div>
                <div class="card-text mb-4 row">
                    <div class="col-md-6 col-12">
                        <dl class="row">
                            <dt class="col-sm-3">
                                {{ $t("forms.fields.name") }}
                            </dt>
                            <dd class="col-sm-9">
                                {{ registry.name }}
                            </dd>
                            <dt class="col-sm-3">
                                {{ $t("forms.fields.email") }}
                            </dt>
                            <dd class="col-sm-9">
                                {{ registry.email }}
                            </dd>
                            <dt class="col-sm-3">
                                {{ $t("forms.fields.telephone") }}
                            </dt>
                            <dd class="col-sm-9">
                                {{ registry.telephone }}
                            </dd>
                            <dt class="col-sm-3">
                                {{ $t("forms.fields.type") }}
                            </dt>
                            <dd class="col-sm-9">
                                {{ $t(`choices.registry.type.${registry.type}`) }}
                            </dd>
                            <dt class="col-sm-3">
                                {{ $t("forms.fields.vat") }}
                            </dt>
                            <dd class="col-sm-9">
                                {{ registry.vat }}
                            </dd>
                            <dt class="col-sm-3">
                                {{ $t("forms.fields.nationalIdentificationNumber") }}
                            </dt>
                            <dd class="col-sm-9">
                                {{ registry.nationalIdentificationNumber }}
                            </dd>
                            <dt class="col-sm-3">
                                {{ $t("forms.fields.taxRegime") }}
                            </dt>
                            <dd class="col-sm-9">
                                {{ registry.taxRegime }}
                            </dd>
                            <dt class="col-sm-3">
                                {{ $t("forms.fields.feCode") }}
                            </dt>
                            <dd class="col-sm-9">
                                {{ registry.feCode }}
                            </dd>
                        </dl>
                    </div>
                    <div class="col-md-6 col-12">
                        <dl class="row">
                            <dt class="col-sm-4">
                                {{ $t("forms.fields.address") }}
                            </dt>
                            <dd class="col-sm-8">
                                {{ registry.locationAddress }}
                            </dd>
                            <dt class="col-sm-4">
                                {{ $t("forms.fields.city") }}
                            </dt>
                            <dd class="col-sm-8">
                                {{ registry.locationCity }}
                            </dd>
                            <dt class="col-sm-4">
                                {{ $t("forms.fields.state") }}
                            </dt>
                            <dd class="col-sm-8">
                                {{ registry.locationState }}
                            </dd>
                            <dt class="col-sm-4">
                                {{ $t("forms.fields.zip") }}
                            </dt>
                            <dd class="col-sm-8">
                                {{ registry.locationZip }}
                            </dd>
                            <dt class="col-sm-4">
                                {{ $t("forms.fields.country") }}
                            </dt>
                            <dd class="col-sm-8">
                                {{ registry.locationCountry }}
                            </dd>
                        </dl>
                    </div>
                    <div
                        v-show="showAlertLocationEmpty"
                        class="col-12"
                    >
                        <base-alert
                            type="warning"
                            icon="ni ni-like-2"
                        >
                            {{ $t("pages.registry.detailPage.alertLocationMessage") }}
                        </base-alert>
                    </div>
                </div>
                <hr>
                <div class="card-text mb-4 row">
                    <div class="col">
                        <h2>{{ $t("pages.registry.detailPage.bankDetails") }}</h2>
                        <dl class="row">
                            <dt class="col-sm-3">
                                {{ $t("forms.fields.beneficiaryName") }}
                            </dt>
                            <dd class="col-sm-9">
                                {{ registry.bankAccountBeneficiaryName }}
                            </dd>
                            <dt class="col-sm-3">
                                {{ $t("forms.fields.iban") }}
                            </dt>
                            <dd class="col-sm-9">
                                {{ registry.bankAccountIban }}
                            </dd>
                            <dt
                                v-show="registry.bankId !== ''"
                                class="col-sm-3"
                            >
                                {{ $t("forms.fields.bank") }}
                            </dt>
                            <dd
                                v-show="registry.bankId !== ''"
                                class="col-sm-9"
                            >
                                <a :href="'/bank/'+registry.bankId">
                                    {{ registry.bankName }}
                                </a>
                            </dd>
                        </dl>
                    </div>
                </div>
                <hr>
                <div class="card-text mb-4 row">
                    <div class="col">
                        <h2>{{ $t("pages.registry.detailPage.contactsAssociated") }}</h2>
                        <dl class="row">
                            <dt class="col-sm-3">
                                {{ $t("forms.fields.administrativeContact") }}
                            </dt>
                            <dd class="col-sm-9">
                                <a :href="'/contact/'+ registry.administrativeContactId">
                                    {{ registry.administrativeContactName }}
                                </a>
                            </dd>
                            <dt class="col-sm-3">
                                {{ $t("forms.fields.technicalContact") }}
                            </dt>
                            <dd class="col-sm-9">
                                <a :href="'/contact/'+ registry.technicalContactId">
                                    {{ registry.technicalContactName }}
                                </a>
                            </dd>
                        </dl>
                    </div>
                </div>
                <hr>
                <div class="card-text mb-4 row">
                    <div class="col">
                        <tabs
                            tab-nav-classes="nav-fill flex-column flex-sm-row nav-wrapper"
                        >
                            <tab-pane
                                id="fiscalDocument"
                                :data-cy="'fiscalDocumentTab'"
                            >
                                <span
                                    slot="title"
                                    :data-cy="'fiscalDocumentTabButton'"
                                >
                                    <i class="fas fa-file-invoice-dollar mr-1" />{{ $t("pages.registry.detailPage.fiscalDocuments") }}
                                </span>
                                <tabs
                                    tab-nav-classes="nav-fill flex-column flex-sm-row nav-wrapper"
                                    tab-content-classes="card shadow"
                                >
                                    <tab-pane
                                        id="activeFiscalDocument"
                                        :data-cy="'activeFiscalDocumentTab'"
                                    >
                                        <span
                                            slot="title"
                                            :data-cy="'activeFiscalDocumentTabButton'"
                                        >
                                            <i class="fas fa-arrow-alt-circle-down mr-1" />{{ $t("pages.fiscalDocument.tabs.title.active") }}
                                        </span>
                                        <ActiveFiscalDocumentTable
                                            :table-data="activeFiscalDocumentTable.tableData"
                                            :total-elements="activeFiscalDocumentTable.totalElements"
                                            :pagination="activeFiscalDocumentTable.pagination"
                                            @data-changed="loadData"
                                            @update:pagination="changeActivePagination"
                                        />
                                    </tab-pane>
                                    <tab-pane
                                        id="passiveFiscalDocument"
                                        :data-cy="'passiveFiscalDocumentTab'"
                                    >
                                        <span
                                            slot="title"
                                            :data-cy="'passiveFiscalDocumentTabButton'"
                                        >
                                            <i class="fas fa-arrow-alt-circle-up mr-1" />{{ $t("pages.fiscalDocument.tabs.title.passive") }}
                                        </span>
                                        <PassiveFiscalDocumentTable
                                            :table-data="passiveFiscalDocumentTable.tableData"
                                            :total-elements="passiveFiscalDocumentTable.totalElements"
                                            :pagination="passiveFiscalDocumentTable.pagination"
                                            @data-changed="loadData"
                                            @update:pagination="changePassivePagination"
                                        />
                                    </tab-pane>
                                </tabs>
                            </tab-pane>
                        </tabs>
                    </div>
                </div>
                <hr>
                <div
                    v-if="showExpectedInvoices"
                    class="card-text mb-4 row"
                >
                    <div class="col">
                        <div class="d-flex justify-content-between align-items-start">
                            <h2 class="card-title mb-4">
                                {{ $t("pages.registry.detailPage.expectedInvoices") }}
                            </h2>
                        </div>
                        <ExpectedInvoicesTable
                            :table-data="expectedInvoices"
                            class="col p-0"
                        />
                    </div>
                </div>
            </card>
        </div>

        <DeleteRegistry
            :delete-registry-id="deleteModal.idRegistryToDelete"
            :show-delete="deleteModal.showDeleteModal"
            :registry-name="deleteModal.registryName"
            @close-delete="deleteModal.showDeleteModal = false;"
            @data-changed="backToRegistryListing"
        />

        <EditRegistry
            :show-edit="editModal.showEditModal"
            :edit-registry-id="editModal.editRegistryId"
            @data-changed="loadData"
            @close-edit="editModal.showEditModal = false;"
        />
    </div>
</template>

<script>
import { registryClient } from "@/clients/registryClient";
import { bankClient } from "@/clients/bankClient";
import { contactClient } from "@/clients/contactClient";
import { fiscalDocumentClient } from "@/clients/fiscalDocumentClient";
import { expectedInvoicesClient } from "@/clients/expectedInvoicesClient";
import { RegistryTypes } from "@/constants/registryContants";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import formatterMixins from "@/mixins/formatterMixins";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import DeleteRegistry from "@/views/Pages/Components/Registry/Modal/Delete";
import Header from "@/components/Header";
import EditRegistry from "@/views/Pages/Components/Registry/Modal/Edit";
import TabPane from "@/components/Tabs/Tab";
import Tabs from "@/components/Tabs/Tabs";
import ActiveFiscalDocumentTable from "@/views/Pages/Components/FiscalDocument/ActiveTable";
import PassiveFiscalDocumentTable from "@/views/Pages/Components/FiscalDocument/PassiveTable";
import ExpectedInvoicesTable from "@/views/Pages/Components/ExpectedInvoices/Table";
import yearSelectorMixins from "@/mixins/yearSelectorMixins";
import PaginationDto from "@/clients/dto/PaginationDto";

export default {
    name: 'DetailRegistry',
    components: {
        LoadingBar,
        DeleteRegistry,
        Header,
        EditRegistry,
        Tabs,
        TabPane,
        ActiveFiscalDocumentTable,
        PassiveFiscalDocumentTable,
        ExpectedInvoicesTable,
    },
    mixins: [
        exceptionsMixins,
        formatterMixins,
        yearSelectorMixins,
    ],
    data() {
        return {
            registry: {
                name: '',
                email: '',
                telephone: '',
                type: '',
                vat: '',
                nationalIdentificationNumber: '',
                taxRegime: '',
                feCode: '',
                locationAddress: '',
                locationCity: '',
                locationState: '',
                locationZip: '',
                locationCountry: '',
                administrativeContactId: '',
                technicalContactId: '',
                bankAccountBeneficiaryName: '',
                bankAccountIban: '',
                bankId: '',
                bankName: '',
                administrativeContactName: '',
                technicalContactName: '',
            },
            loaded: false,
            deleteModal: {
                showDeleteModal: false,
                idRegistryToDelete: null,
                registryName: '',
                loading: false,
            },
            editModal: {
                showEditModal: false,
                editRegistryId: null,
                key: 0,
            },
            showAlertLocationEmpty: false,
            filters: {
                fromDateToSend: this.formatFrontendDate(this.getDefaultFromDate()),
                toDateToSend: this.formatFrontendDate(this.getDefaultToDate()),
            },
            expectedInvoices: [],
            activeFiscalDocumentTable: {
                tableData: [],
                pagination: {
                    sortBy: ["date"],
                    sortDesc: [ true ],
                    page: 1,
                    itemsPerPage: 30,
                },
                totalElements: 0,
            },
            passiveFiscalDocumentTable: {
                tableData: [],
                pagination: {
                    sortBy: ["date"],
                    sortDesc: [ true ],
                    page: 1,
                    itemsPerPage: 30,
                },
                totalElements: 0,
            }
        };
    },
    computed: {
        showExpectedInvoices() {
            return this.registry.type === RegistryTypes.PUBLISHER;
        },
        activeSortDir() {
            return this.activeFiscalDocumentTable.pagination.sortDesc[0] ? "DESC" : "ASC";
        },
        passiveSortDir() {
            return this.passiveFiscalDocumentTable.pagination.sortDesc[0] ? "DESC" : "ASC";
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        changeActivePagination(pagination) {
            this.activeFiscalDocumentTable.pagination = pagination;
            this.loadData();
        },
        changePassivePagination(pagination) {
            this.activeFiscalDocumentTable.pagination = pagination;
            this.loadData();
        },
        loadData() {
            this.loaded = false;

            const companySenderFiscalDocumentPaginationDto = new PaginationDto(
                this.activeFiscalDocumentTable.pagination.page,
                this.activeFiscalDocumentTable.pagination.itemsPerPage,
                this.activeFiscalDocumentTable.pagination.sortBy[0],
                this.activeSortDir,
            );

            const companyRecipientFiscalDocumentPaginationDto = new PaginationDto(
                this.passiveFiscalDocumentTable.pagination.page,
                this.passiveFiscalDocumentTable.pagination.itemsPerPage,
                this.passiveFiscalDocumentTable.pagination.sortBy[0],
                this.passiveSortDir,
            );

            Promise.all([
                this.loadRegistryData(),
                fiscalDocumentClient.getByFilters(
                    companySenderFiscalDocumentPaginationDto,
                    this.$route.params.id,
                    this.$store.getters.activeRegistryId,
                    null,
                    null,
                    this.filters.fromDateToSend,
                    this.filters.toDateToSend
                ),
                fiscalDocumentClient.getByFilters(
                    companyRecipientFiscalDocumentPaginationDto,
                    this.$store.getters.activeRegistryId,
                    this.$route.params.id,
                    null,
                    null,
                    this.filters.fromDateToSend,
                    this.filters.toDateToSend
                )
            ]).then(result => {
                this.activeFiscalDocumentTable.tableData = result[1].data.data;
                this.activeFiscalDocumentTable.totalElements = result[1].data.meta.totalElements;
                this.passiveFiscalDocumentTable.tableData = result[2].data.data;
                this.passiveFiscalDocumentTable.totalElements = result[2].data.meta.totalElements;
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loaded = true;
            });
        },
        loadRegistryData() {
            return new Promise((resolve, reject) => {
                registryClient.getById(this.$route.params.id).then((result) => {
                    this.registry.name = result.data.name;
                    this.registry.email = result.data.email;
                    this.registry.telephone = result.data.telephone;
                    this.registry.type = result.data.type;
                    this.registry.vat = result.data.vat;
                    this.registry.nationalIdentificationNumber = result.data.nationalIdentificationNumber;
                    this.registry.taxRegime = result.data.taxRegime;
                    this.registry.feCode = result.data.feCode;

                    if (result.data.location === null) {
                        this.showAlertLocationEmpty = true;
                    } else {
                        this.registry.locationAddress = result.data.location.address;
                        this.registry.locationCity = result.data.location.city;
                        this.registry.locationState = result.data.location.state;
                        this.registry.locationZip = result.data.location.zip;
                        this.registry.locationCountry = result.data.location.country;
                    }

                    this.registry.administrativeContactId = (result.data.administrativeContact) ? result.data.administrativeContact.id : '';
                    this.registry.technicalContactId = (result.data.technicalContact) ? result.data.technicalContact.id : '';

                    if (result.data.bankAccount) {
                        this.registry.bankAccountBeneficiaryName = result.data.bankAccount.name;
                        this.registry.bankAccountIban = result.data.bankAccount.iban;
                        this.registry.bankId = result.data.bankAccount.bank.id;
                    }

                    if (this.registry.bankId) {
                        return bankClient.getById(this.registry.bankId);
                    }

                    return null;
                }).then((response) => {
                    if (response) {
                        this.registry.bankName = response.data.name;
                    }

                    if (this.registry.administrativeContactId) {
                        return contactClient.getById(this.registry.administrativeContactId);
                    }

                    return null;
                }).then((response) => {
                    if (response) {
                        this.registry.administrativeContactName = response.data.name;
                    }

                    if (this.registry.technicalContactId) {
                        return contactClient.getById(this.registry.technicalContactId);
                    }

                    return null;
                }).then((response) => {
                    if (response) {
                        this.registry.technicalContactName = response.data.name;
                    }

                    if (this.showExpectedInvoices) {
                        return expectedInvoicesClient.getExpectedInvoices(
                            this.filters.fromDateToSend,
                            this.filters.toDateToSend,
                            this.$route.params.id
                        );
                    }

                    return null;
                }).then((response) => {
                    if (response) {
                        let data = [...response.data]
                        this.expectedInvoices = data.filter(element => element.isDefinitive === true);
                    }

                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        openEditModal(id) {
            this.editModal.key++;
            this.editModal.editRegistryId = Number(id);
            this.editModal.showEditModal = true;
        },
        openDeleteModal(id) {
            this.deleteModal.idRegistryToDelete = Number(id);
            this.deleteModal.showDeleteModal = true;
            this.deleteModal.registryName = this.registry.name;
        },
        backToRegistryListing() {
            this.$router.push({name: 'REGISTRY-LIST'});
        },
    }
}
</script>
