import { headersBuilder } from "@/clients/headersBuilder";
import axios, { AxiosResponse } from 'axios';

export const userClient = {
    /**
     * Call getUserAreas endpoint.
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getUserAreas() {
        return axios.get(process.env.VUE_APP_ADMINISTRATION_BASE_URL + "/api/v1/user/areas", {
            headers: headersBuilder.buildAuthenticatedHeaders()
        });
    }
}
