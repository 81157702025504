import axios, { AxiosResponse } from "axios";
import { headersBuilder } from "@/clients/headersBuilder";
import dataCleaner from "@/clients/support/dataCleaner";

/**
 * statisticsClient.
 */
export const statisticsClient = {
    /**
     * Call get summary statistics endpoint.
     *
     * @param registryId {number}
     * @param year {number}
     * @returns {Promise<AxiosResponse<any>>}
     */
    getSummary(registryId, year) {
        return axios.get(process.env.VUE_APP_ADMINISTRATION_BASE_URL + `/api/v1/statistics/summary`,
            {
                headers: headersBuilder.buildAuthenticatedHeaders(),
                params: dataCleaner({
                    registryId,
                    year
                })
            });
    },
    /**
     * Call get charts statistics endpoint.
     *
     * @param registryId {number}
     * @param fromDate {string} (Y-m-d)
     * @param toDate {string} (Y-m-d)
     * @returns {Promise<AxiosResponse<any>>}
     */
    getCharts(registryId, fromDate, toDate) {
        return axios.get(process.env.VUE_APP_ADMINISTRATION_BASE_URL + `/api/v1/statistics/charts`,
            {
                headers: headersBuilder.buildAuthenticatedHeaders(),
                params: dataCleaner({
                    registryId,
                    fromDate,
                    toDate
                })
            });
    }
}
