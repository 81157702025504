<template>
    <div class="row">
        <div class="col-xl-4 col-lg-6">
            <stats-card
                :title="$tc('pages.homepage.widgets.netTurnover')"
                type="gradient-green"
                :sub-title="formatCurrency(currency, statisticsWidgetsData.revenue)"
                icon="ni ni-briefcase-24"
            >
                <template slot="footer">
                    <span class="text-nowrap">{{ year }}</span>
                </template>
            </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
            <stats-card
                :title="$tc('pages.homepage.widgets.totalCosts')"
                type="gradient-red"
                :sub-title="formatCurrency(currency, statisticsWidgetsData.costs)"
                icon="ni ni-building"
            >
                <template slot="footer">
                    <span class="text-nowrap">{{ year }}</span>
                </template>
            </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
            <stats-card
                :title="$tc('pages.homepage.widgets.businessProfit')"
                type="gradient-blue"
                :sub-title="formatCurrency(currency, statisticsWidgetsData.profit)"
                icon="ni ni-chart-bar-32"
            >
                <template slot="footer">
                    <span class="text-nowrap">{{ year }}</span>
                </template>
            </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
            <stats-card
                :title="$tc('pages.homepage.widgets.profitabilityIndex')"
                type="gradient-purple"
                :sub-title="formatPercentage(statisticsWidgetsData.profitabilityIndex)"
                icon="ni ni-compass-04"
            >
                <template slot="footer">
                    <span class="text-nowrap">{{ year }}</span>
                </template>
            </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
            <stats-card
                :title="$tc('pages.homepage.widgets.receivablesFromCustomers')"
                type="gradient-blue"
                :sub-title="formatCurrency(currency, statisticsWidgetsData.credits)"
                icon="ni ni-diamond"
            >
                <template slot="footer">
                    <span class="text-nowrap">{{ year }}</span>
                </template>
            </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
            <stats-card
                :title="$tc('pages.homepage.widgets.payablesToSuppliers')"
                type="gradient-orange"
                :sub-title="formatCurrency(currency, statisticsWidgetsData.debits)"
                icon="ni ni-ungroup"
            >
                <template slot="footer">
                    <span class="text-nowrap">{{ year }}</span>
                </template>
            </stats-card>
        </div>
    </div>
</template>

<script>
import formatterMixins from "@/mixins/formatterMixins";

export default {
    name: 'StatisticsWidgets',
    mixins: [
        formatterMixins
    ],
    props: {
        statisticsWidgetsData: {
            type: Object
        },
        currency: {
            type: String
        },
        year: {
            type: String
        }
    }
}
</script>
