import { headersBuilder } from "@/clients/headersBuilder";
import axios, { AxiosResponse } from "axios";
import dataCleaner from "@/clients/support/dataCleaner";

/**
 * firstAccountingClient.
 */
export const firstAccountingClient = {
    /**
     * Call get first accounting endpoint with filters.
     *
     * @param registryId {number}
     * @param type {string|null}
     * @param fromDate {string|null} (Y-m-d)
     * @param toDate {string|null} (Y-m-d)
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getByFilters(
        registryId,
        type = null,
        fromDate = null,
        toDate = null)
    {
        const filters = {
            type,
            fromDate,
            toDate
        };

        return axios.get(process.env.VUE_APP_ADMINISTRATION_BASE_URL + `/api/v1/first-accounting/${registryId}`,
            {
                headers: headersBuilder.buildAuthenticatedHeaders(),
                params: dataCleaner(filters)
            });
    },
}
