<template>
    <SlideYUpTransition :duration="animationDuration">
        <div
            v-show="show"
            class="modal fade"
            :class="[{'show d-block': show}, {'d-none': !show}, {'modal-mini': type === 'mini'}]"
            tabindex="-1"
            role="dialog"
            :aria-hidden="!show"
        >
            <div
                class="modal-dialog modal-dialog-centered"
                :class="[{'modal-notice': type === 'notice', [`modal-${size}`]: size}, modalClasses]"
            >
                <div
                    class="modal-content"
                    :class="[gradient ? `bg-gradient-${gradient}` : '',modalContentClasses]"
                >
                    <div
                        v-if="$slots.header"
                        class="modal-header"
                        :class="[headerClasses]"
                    >
                        <slot name="header" />
                        <slot name="close-button">
                            <button
                                v-if="showClose"
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                @click="closeModal"
                            >
                                <span :aria-hidden="!show">×</span>
                            </button>
                        </slot>
                    </div>

                    <div
                        class="modal-body"
                        :class="bodyClasses"
                    >
                        <slot />
                    </div>

                    <div
                        v-if="$slots.footer"
                        class="modal-footer"
                        :class="footerClasses"
                    >
                        <slot name="footer" />
                    </div>
                </div>
            </div>
        </div>
    </SlideYUpTransition>
</template>
<script>
import { SlideYUpTransition } from "vue2-transitions";

export default {
    name: "Modal",
    components: {
        SlideYUpTransition
    },
    props: {
        show: Boolean,
        showClose: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            default: "",
            validator(value) {
                let acceptedValues = ["", "notice", "mini"];
                return acceptedValues.indexOf(value) !== -1;
            },
            description: 'Modal type (notice|mini|"") '
        },
        modalClasses: {
            type: [Object, String],
            description: "Modal dialog css classes"
        },
        size: {
            type: String,
            description: 'Modal size',
            validator(value) {
                let acceptedValues = ["", "sm", "lg"];
                return acceptedValues.indexOf(value) !== -1;
            },
        },
        modalContentClasses: {
            type: [Object, String],
            description: "Modal dialog content css classes"
        },
        gradient: {
            type: String,
            description: "Modal gradient type (danger, primary etc)"
        },
        headerClasses: {
            type: [Object, String],
            description: "Modal Header css classes"
        },
        bodyClasses: {
            type: [Object, String],
            description: "Modal Body css classes"
        },
        footerClasses: {
            type: [Object, String],
            description: "Modal Footer css classes"
        },
        animationDuration: {
            type: Number,
            default: 500,
            description: "Modal transition duration"
        }
    },
    watch: {
        show(val) {
            let documentClasses = document.body.classList;
            if (val) {
                documentClasses.add("modal-open");
            } else {
                documentClasses.remove("modal-open");
            }
        }
    },
    methods: {
        closeModal() {
            this.$emit("update:show", false);
            this.$emit("close");
        }
    }
};
</script>
<style>
.modal {
    overflow: auto !important;
}
.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
