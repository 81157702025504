import {
    FiscalDocumentDocumentType,
    FiscalDocumentStatus
} from "@/constants/fiscalDocumentCostants";

/**
 * fiscalDocumentMixins.
 */
export default {
    methods: {
        /**
         * Check if the type provided correspond to a invoice.
         *
         * @param {string} type
         *
         * @returns {boolean}
         */
        isInvoiceType(type) {
            return type === FiscalDocumentDocumentType.TD01;
        },
        /**
         * Check if the type provided correspond to a credit note.
         *
         * @param {string} type
         *
         * @returns {boolean}
         */
        isCreditNoteType(type) {
            return type === FiscalDocumentDocumentType.TD04;
        },
        /**
         * Check if the status of the fiscal document allows the change of status to paid.
         *
         * @param {string} status
         *
         * @returns {boolean}
         */
        canBePaid(status) {
            const fiscalDocumentStatusThatCanBePaid = [FiscalDocumentStatus.SENT, FiscalDocumentStatus.RECEIVED, FiscalDocumentStatus.PARTIALLY_PAID];
            return fiscalDocumentStatusThatCanBePaid.includes(status);
        },
        /**
         * Check if the status of the fiscal document allows the change of status to cancelled.
         *
         * @param {string} documentType
         * @param {string} status
         * @param {array}  children
         *
         * @returns {boolean}
         */
        canBeCancelled(documentType, status, children) {
            if (this.isInvoiceType(documentType) && children) {
                const childrenCancellable = children.filter(child => this.checkStatusCanBeCancelled(child.status));
                const statusCheck = this.checkStatusCanBeCancelled(status);

                return statusCheck && childrenCancellable.length > 0;
            }

            return false;
        },
        /**
         * Check if the status provided allows document to be cancelled.
         *
         * @param {string} status
         * @returns {boolean}
         */
        checkStatusCanBeCancelled(status) {
            const fiscalDocumentStatusThatCanBeCancelled = [FiscalDocumentStatus.SENT, FiscalDocumentStatus.RECEIVED];

            return fiscalDocumentStatusThatCanBeCancelled.includes(status);
        }
    }
}
