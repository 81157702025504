var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col",attrs:{"data-cy":'firstAccountingTable'}},[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"p-0","footer-classes":"pb-2"}},[_c('BaseTable',{attrs:{"table-data":_vm.tableData,"default-sort":{prop: 'date', order: 'descending'},"search-keys":[
                'cashflow.sender',
                'cashflow.recipient',
                'invoice.sender',
                'invoice.recipient',
                'cashflow.number',
                'invoice.number'
            ]}},[_c('el-table-column',{attrs:{"label":_vm.$t('tables.column.date'),"min-width":30,"sortable":true,"prop":'date'},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_vm._v(" "+_vm._s(_vm.formatBackendDate(new Date(row.date)))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('tables.column.detail'),"min-width":150},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_c('div',[_vm._v(_vm._s(_vm.isCashFlow(row) ? _vm.getCashFlowLabel(row) : _vm.getFiscalDocumentLabel(row)))]),_c('a',{staticClass:"text-dark",attrs:{"href":_vm.isCashFlow(row) ? `/cashflow/${row.cashflow.id}` : `/fiscalDocument/${row.invoice.id}`}},[_vm._v(_vm._s(_vm.isCashFlow(row) ? _vm.getCashFlowDetail(row) : _vm.getFiscalDocumentDetail(row)))])]}}])}),_vm._l((_vm.tableColumns),function(column){return _c('el-table-column',_vm._b({key:column.label,attrs:{"formatter":column.formatter}},'el-table-column',column,false))})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }