export default class PaginationDto {
    #page;
    #pageElements;
    #sortingField;
    #sortingDirection;

    /**
     * Constructor.
     *
     * @param {number} page
     * @param {number} pageElements
     * @param {string|null} sortingField
     * @param {string|null} sortingDirection "DESC" | "ASC"
     */
    constructor(page, pageElements, sortingField, sortingDirection) {
        this.#page = page;
        this.#pageElements = pageElements;
        this.#sortingField = sortingField;
        this.#sortingDirection = sortingDirection;
    }

    get page() {
        return this.#page;
    }

    get pageElements() {
        return this.#pageElements;
    }

    get sortingField() {
        return this.#sortingField;
    }

    get sortingDirection() {
        return this.#sortingDirection;
    }
}
