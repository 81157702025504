export default class FiscalDocumentIncomingDto {
    #senderRegistryId;
    #recipientRegistryId;
    #number;
    #documentType;
    #paymentType;
    #paymentMode;
    #paymentAmount;
    #currency;
    #iban;
    #date;
    #paymentDeadlineDate;
    #detailLines;
    #summaryLines;
    #revenueStamp;
    #invoiceParentId;
    #purpose;

    /**
     * Constructor.
     *
     * @param senderRegistryId {number}
     * @param recipientRegistryId {number}
     * @param number {string}
     * @param documentType {string}
     * @param paymentType {string}
     * @param paymentMode {string}
     * @param paymentAmount {number}
     * @param currency {string}
     * @param iban {string|null}
     * @param date {string}
     * @param paymentDeadlineDate {string} (Y-m-D)
     * @param detailLines {FiscalDocumentDetailLineIncomingDto[]}
     * @param summaryLines {FiscalDocumentSummaryLineIncomingDto[]}
     * @param revenueStamp {number|null}
     * @param invoiceParentId {number|null}
     * @param purpose {string|null}
     */
    constructor(
        senderRegistryId,
        recipientRegistryId,
        number,
        documentType,
        paymentType,
        paymentMode,
        paymentAmount,
        currency,
        iban = null,
        date,
        paymentDeadlineDate,
        detailLines,
        summaryLines,
        revenueStamp = null,
        invoiceParentId = null,
        purpose = null
    ) {
        this.#senderRegistryId = senderRegistryId;
        this.#recipientRegistryId = recipientRegistryId;
        this.#number = number;
        this.#documentType = documentType;
        this.#paymentType = paymentType;
        this.#paymentMode = paymentMode;
        this.#paymentAmount = paymentAmount;
        this.#currency = currency;
        this.#iban = iban;
        this.#date = date;
        this.#paymentDeadlineDate = paymentDeadlineDate;
        this.#detailLines = detailLines;
        this.#summaryLines = summaryLines;
        this.#revenueStamp = revenueStamp;
        this.#invoiceParentId = invoiceParentId;
        this.#purpose = purpose;
    }

    get senderRegistryId() {
        return this.#senderRegistryId;
    }

    get recipientRegistryId() {
        return this.#recipientRegistryId;
    }

    get number() {
        return this.#number;
    }

    get documentType() {
        return this.#documentType;
    }

    get paymentType() {
        return this.#paymentType;
    }

    get paymentMode() {
        return this.#paymentMode;
    }

    get paymentAmount() {
        return this.#paymentAmount;
    }

    get currency() {
        return this.#currency;
    }

    get iban() {
        return this.#iban;
    }

    get date() {
        return this.#date;
    }

    get paymentDeadlineDate() {
        return this.#paymentDeadlineDate;
    }

    get detailLines() {
        return this.#detailLines;
    }

    get summaryLines() {
        return this.#summaryLines;
    }

    get revenueStamp() {
        return this.#revenueStamp;
    }

    get invoiceParentId() {
        return this.#invoiceParentId;
    }

    get purpose() {
        return this.#purpose;
    }
}
