import DashboardLayout from "@/views/Layout/DashboardLayout";
import CashFlow from "@/views/Pages/CashFlow";
import DetailCashFlow from "@/views/Pages/Components/CashFlow/Detail";

const cashFlowPages = {
    path: "/cashflow",
    component: DashboardLayout,
    meta: {
        name: "pages.cashFlow.breadcrumb.cashFlows",
    },
    children: [
        {
            path: ':id',
            name: 'CASHFLOW-DETAIL',
            component: DetailCashFlow,
            meta: {
                name: "pages.cashFlow.breadcrumb.detail",
                area: "CASHFLOW"
            }
        },
        {
            path: '',
            name: 'CASHFLOW-LIST',
            component: CashFlow,
            meta: {
                name: "pages.cashFlow.breadcrumb.cashFlowList",
                area: "CASHFLOW"
            }
        },
    ]
};

export default cashFlowPages;
