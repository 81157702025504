export const AuthenticationRequestKeys = Object.freeze({
    KEY_LOGIN_EMAIL: "email",
    KEY_LOGIN_PASSWORD: "password",
    KEY_TWO_FACTOR_TOKEN: "twoFactorToken",
    KEY_TOKEN: "token",
    KEY_CODE: "code",
});

export const AuthenticationResponseKeys = Object.freeze({
    KEY_TOKEN: "token",
    KEY_TWO_FACTOR_TOKEN_REQUIRED: "twoFactorTokenRequired",
    KEY_TELEPHONE_NUMBER: "telephoneNumber",
});

export const AuthenticationLocalStorageKeys = Object.freeze({
    KEY_TOKEN: "token",
    KEY_TWO_FACTOR_TOKEN: "twoFactorToken",
});

export const AuthenticationStoreKeys = Object.freeze({
    KEY_LAST_TREE_CHAR_TELEPHONE_NUMBER: "lastTreeCharTelephoneNumber",
    KEY_LOGIN_EMAIL: "email",
    KEY_LOGIN_PASSWORD: "password",
});

export const AuthenticationExceptionCodes = Object.freeze({
    AUTHENTICATION_GENERAL: 1000,
    INVALID_CREDENTIALS: 1002,
    NOT_VALID_AUTHENTICATION_TOKEN: 1003,
    TWO_FACTOR_CODE_INVALID: 1004,
});

export const AuthenticationConfigurationsData = Object.freeze({
    CODE_LENGTH: 6,
    MAX_ATTEMPTS: 3,
});
