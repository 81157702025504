<template>
    <div
        class="timeline"
        :class="{[`timeline-${type}`]: type}"
    >
        <slot />
    </div>
</template>
<script>
export default {
    name: 'TimeLine',
    props: {
        type: {
            type: String,
            default: ''
        }
    }
};
</script>
<style></style>
