import { countryList } from "@/util/country-list";
import { RegistryTaxRegimes, RegistryTypes } from "@/constants/registryContants";
import { CashFlowTags, CashFlowTypes } from "@/constants/cashFlowContants";
import {
    FiscalDocumentCustomizablePaymentDeadlineDate,
    FiscalDocumentDocumentType,
    FiscalDocumentPaymentMode,
    FiscalDocumentPaymentType,
    FiscalDocumentStatus,
    FiscalDocumentSummaryLineNatureCodes,
    FiscalDocumentSummaryLineVatEnforceabilityCodes,
    FiscalDocumentVatNatureMapping
} from "@/constants/fiscalDocumentCostants";
import { FirstAccountingDocumentType } from "@/constants/firstAccountingCostants";
import { PaymentTypes } from "@/constants/paymentsContants";
import formatterMixins from "@/mixins/formatterMixins";

/**
 * choicesMixins.
 */
export default {
    computed: {
        taxRegimeChoicesList() {
            return this.getTaxRegimesChoices();
        },
        countryList() {
            return countryList.map(country => {
                const countryChoice = {...country};
                countryChoice.label = this.$t(`choices.country.${country.code}`);

                return countryChoice;
            });
        },
        registryTypeList() {
            return Object.values(RegistryTypes).reduce((result, type) => {
                if (type !== 'UNDEFINED') {
                    result.push({
                        code: type,
                        label: this.$t(`choices.registry.type.${type}`)
                    });
                }

                return result;
            }, []);
        },
        registryTypeListComplete() {
            return Object.values(RegistryTypes).reduce((result, type) => {
                result.push({
                    code: type,
                    label: this.$t(`choices.registry.type.${type}`)
                });

                return result;
            }, []);
        },
        cashFlowTypeList() {
            return this.getCashFlowTypeChoices();
        },
        cashFlowTypeListComplete() {
            const typeChoices = this.getCashFlowTypeChoices();

            typeChoices.push({
                code: 'TP03',
                label: 'TP03' + ' | ' + this.$t('choices.cashFlow.types.TP03')
            });

            this.sortChoicesByCodeDigits(typeChoices);

            return typeChoices;
        },
        cashFlowTagList() {
            return this.getCashFlowTagChoices();
        },
        cashFlowTagListComplete() {
            const tagChoices = this.getCashFlowTagChoices();

            tagChoices.push({
                code: 'TG13',
                label: 'TG13' + ' | ' + this.$t('choices.cashFlow.tags.TG13')
            });

            this.sortChoicesByCodeDigits(tagChoices);

            return tagChoices;
        },
        fiscalDocumentPaymentTypeList() {
            return this.getFiscalDocumentPaymentTypeChoices();
        },
        fiscalDocumentPaymentModeList() {
            return this.getFiscalDocumentPaymentModeChoices();
        },
        fiscalDocumentStatusList() {
            return this.getFiscalDocumentStatusChoices();
        },
        fiscalDocumentDocumentTypeList() {
            return this.getFiscalDocumentDocumentTypeChoices();
        },
        fiscalDocumentVatNatureList() {
            return this.getFiscalDocumentVatNatureChoices();
        },
        paymentTypes() {
            return this.buildChoicesLabels('choices.payments.type', Object.values(PaymentTypes), false);
        },
        currencyList() {
            return [
                {
                    code: 'EUR',
                    label: 'EUR'
                }
            ]
        },
        firstAccountingDocumentTypeList() {
            return this.getFirstAccountingDocumentTypeChoices();
        },
        paymentDocumentsVatAmounts() {
            return [
                {
                    code: 22,
                    label: '22%'
                },
                {
                    code: 10,
                    label: '10%'
                },
                {
                    code: 4,
                    label: '4%'
                },
                {
                    code: 0,
                    label: '0%'
                },
            ];
        }
    },
    methods: {
        /**
         * Method that build the choices of taxRegimes with translated labels.
         */
        getTaxRegimesChoices() {
            return this.buildChoicesLabels('choices.taxRegime', Object.values(RegistryTaxRegimes));
        },
        /**
         * Method that build the choices of cash flow type with translated labels.
         */
        getCashFlowTypeChoices() {
            return this.buildChoicesLabels('choices.cashFlow.types', Object.values(CashFlowTypes));
        },
        /**
         * Method that build the choices of cash flow tag with translated labels.
         */
        getCashFlowTagChoices() {
            return this.buildChoicesLabels('choices.cashFlow.tags', Object.values(CashFlowTags));
        },
        /**
         * Method that build the choices of fiscal document payment type with translated labels.
         */
        getFiscalDocumentPaymentTypeChoices() {
            return this.buildChoicesLabels('choices.fiscalDocument.payment.type', Object.values(FiscalDocumentPaymentType));
        },
        /**
         * Method that build the choices of fiscal document payment mode with translated labels.
         */
        getFiscalDocumentPaymentModeChoices() {
            return this.buildChoicesLabels('choices.fiscalDocument.payment.mode', Object.values(FiscalDocumentPaymentMode));
        },
        /**
         * Method that build the choices of fiscal document status with translated labels.
         */
        getFiscalDocumentStatusChoices() {
            return this.buildChoicesLabels('choices.fiscalDocument.status', Object.values(FiscalDocumentStatus), false);
        },
        /**
         * Method that build the choices of fiscal document document type with translated labels.
         */
        getFiscalDocumentDocumentTypeChoices() {
            return this.buildChoicesLabels('choices.fiscalDocument.documentType', Object.values(FiscalDocumentDocumentType));
        },
        /**
         * Method that build the choices of fiscal document summary line nature with translated labels.
         */
        getFiscalDocumentSummaryLineNatureChoices() {
            return this.buildChoicesLabels('choices.fiscalDocument.summaryLine.nature', Object.values(FiscalDocumentSummaryLineNatureCodes), false);
        },
        /**
         * Method that build the choices of fiscal document vat with nature related with translated labels.
         */
        getFiscalDocumentVatNatureChoices() {
            return this.buildChoicesLabels('choices.fiscalDocument.vatNature', Object.keys(FiscalDocumentVatNatureMapping), false);
        },
        /**
         * Method that build the choices of fiscal document summary line nature with translated labels.
         */
        getFiscalDocumentSummaryLineVatEnforceabilityChoices() {
            return this.buildChoicesLabels('choices.fiscalDocument.summaryLine.vatEnforceability', Object.values(FiscalDocumentSummaryLineVatEnforceabilityCodes), false, true);
        },
        /**
         * Retrieve the Cash Flow type label by provided choice name
         *
         * @param choiceCode {string}
         */
        getCashFlowTypeLabelByChoiceCode(choiceCode) {
            if (!choiceCode) {
                return '';
            }

            return this.$t(`choices.cashFlow.types.${choiceCode}`);
        },
        /**
         * Retrieve the Cash Flow tag label by provided choice name
         *
         * @param {string} choiceCode
         */
        getCashFlowTagLabelByChoiceCode(choiceCode) {
            if (!choiceCode) {
                return '';
            }

            return this.$t(`choices.cashFlow.tags.${choiceCode}`);
        },
        /**
         * Method that build the choices for first accounting type filter with translated labels.
         */
        getFirstAccountingDocumentTypeChoices() {
            return this.buildChoicesLabels('choices.firstAccounting.documentType', Object.values(FirstAccountingDocumentType), false);
        },
        /**
         * Method that build the choices of every codes provided and add translated labels.
         *
         * @param {string} translations
         * @param {string[]} codes
         * @param {boolean} addCodesNearLabel
         * @param {boolean} useCodeWithoutTranslations
         *
         * @returns {Object[]}
         */
        buildChoicesLabels(translations, codes, addCodesNearLabel = true, useCodeWithoutTranslations = false) {
            let choices = [];

            for (let i = 0; i < codes.length; i++) {
                let label = codes[i];
                if (!useCodeWithoutTranslations) {
                    const cleanedCode = codes[i].replaceAll('.', '-');
                    const translatedLabel = this.$t(translations + '.' + cleanedCode);
                    label = addCodesNearLabel ? codes[i] + ' | ' + translatedLabel : translatedLabel;
                }

                choices.push({
                    code: codes[i],
                    label: label
                });
            }

            return choices;
        },
        /**
         * Method that generate the label for every entity inside the array provided.
         *
         * @param {Array} list
         */
        generateRegistryLabel(list) {
            list.forEach(element => {
                const email = element.email ? ' (' + element.email + ')' : '';

                element.customLabel = element.name + email;
            });

            list.sort((a, b) => a.customLabel.localeCompare(b.customLabel));
        },
        /**
         * Method that generate the label for every fiscal document provided.
         *
         * @param {Array} list
         */
        generateFiscalDocumentLabel(list) {
            return list.map(element => {
                const invoice = {...element};
                invoice.label = invoice.number + ' (' + formatterMixins.methods.formatBackendDate(new Date(invoice.date)) + ')';

                return invoice;
            });
        },
        /**
         * Method that sort the choices by the code.
         *
         * @param {Array} codesList
         */
        sortChoicesByCodeDigits(codesList) {
            codesList.sort((a, b) => {
                return parseInt(a.code.substring(2, 4)) - parseInt(b.code.substring(2, 4));
            });
        },
    }
}
