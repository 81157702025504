import { headersBuilder } from "@/clients/headersBuilder";
import axios, { AxiosResponse } from 'axios';

/**
 * expectedInvoicesClient.
 */
export const expectedInvoicesClient = {
    /**
     * Get expected invoices of registry provided in an interval of time.
     *
     * @param from {string}
     * @param to {string}
     * @param registryId {number}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getExpectedInvoices(from, to, registryId) {
        return axios.get(process.env.VUE_APP_ADMINISTRATION_BASE_URL + `/api/v1/registry/${registryId}/expected-invoices`, {
            headers: headersBuilder.buildAuthenticatedHeaders(),
            params: {
                from,
                to
            }
        });
    }
}
