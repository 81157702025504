<template>
    <modal
        modal-classes="modal-secondary"
        :data-cy="'updatePaymentModal'"
        :show="showModal"
        @close="closeModal"
    >
        <template slot="header">
            <h1>{{ $t("pages.fiscalDocument.updatePayment") }}</h1>
        </template>
        <div class="row">
            <p
                class="col-12"
                :data-cy="'updatePaymentMessage'"
            >
                {{ $t("pages.fiscalDocument.updatePaymentMessage") + fiscalDocumentNumber }}
            </p>
            <base-input
                :label="$t('forms.fields.paymentType')"
                :placeholder="$t('forms.actions.select') + $t('forms.fields.paymentType')"
                input-classes="form-control-alternative"
                :name="$t('forms.fields.paymentType')"
                class="col-6"
                :rules="{
                    required: true,
                    length: 4,
                }"
                :data-cy="'updatePaymentTypeField'"
            >
                <v-select
                    v-model="model.paymentType"
                    class="form-control form-control-alternative"
                    :options="paymentTypes"
                    :reduce="paymentType => paymentType.code"
                />
            </base-input>
            <base-input
                addon-left-icon="ni ni-calendar-grid-58"
                :label="$t('forms.fields.date')"
                :name="$t('forms.fields.date')"
                :placeholder="$t('forms.actions.select') + $t('forms.fields.date')"
                :data-cy="'updatePaymentDateField'"
                class="col-12"
                :rules="{required:true}"
            >
                <flat-picker
                    v-model="model.paymentDate"
                    slot-scope="{focus, blur}"
                    :config="configDate"
                    class="form-control datepicker payment-date"
                    @on-open="focus"
                    @on-close="blur"
                    @on-change="formatDate"
                />
            </base-input>
            <base-input
                :label="$t('forms.fields.currency')"
                :placeholder="$t('forms.actions.select') + $t('forms.fields.currency')"
                input-classes="form-control-alternative"
                :name="$t('forms.fields.currency')"
                class="col-6"
                :rules="{
                    required: true,
                    length: 3
                }"
                :data-cy="'updatePaymentCurrencyField'"
            >
                <v-select
                    v-model="model.paymentCurrency"
                    class="form-control form-control-alternative"
                    :options="currencyList"
                    :reduce="currency => currency.code"
                />
            </base-input>
            <base-input
                v-model="model.paymentAmount"
                :label="$t('forms.fields.paymentAmount')"
                :name="$t('forms.fields.paymentAmount')"
                input-classes="form-control-alternative"
                class="col-6"
                :data-cy="'updatePaymentAmountField'"
                :rules="{
                    required: true,
                    decimal: true,
                    max_value: calculateFiscalDocumentAmountMaxAmount
                }"
            />
        </div>
        <div class="row">
            <div class="col-12 pb-4">
                <span> {{ $t('pages.fiscalDocument.residualImport') + ': ' + formatCurrency(model.paymentCurrency, calculateFiscalDocumentAmountResidual) }}</span>
            </div>
        </div>
        <template slot="footer">
            <base-button
                native-type="submit"
                type="primary"
                :loading="loading"
                :data-cy="'buttonUpdatePayment'"
                @click="updatePayment"
            >
                {{ $t("actions.update") }}
            </base-button>
            <base-button
                type="button"
                class="btn btn-secondary"
                :data-cy="'buttonStop'"
                @click="closeModal"
            >
                {{ $t("actions.cancel") }}
            </base-button>
        </template>
    </modal>
</template>

<script>
import { fiscalDocumentClient } from "@/clients/fiscalDocumentClient";
import { localeService } from "@/services/localeService";
import vSelect from 'vue-select';
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// All the supported languages must be added to let the flatPicker work correctly.
import "flatpickr/dist/l10n/it";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import formatterMixins from "@/mixins/formatterMixins";
import choicesMixins from "@/mixins/choicesMixins";

export default {
    name: 'UpdatePayment',
    components: {
        vSelect,
        flatPicker,
    },
    mixins: [
        exceptionsMixins,
        formatterMixins,
        choicesMixins
    ],
    props: {
        showModal: {
            type: Boolean,
        },
        fiscalDocumentId: {
            type: Number,
        },
        fiscalDocumentNumber: {
            type: String,
        },
        payment: {
            type: Object,
        },
        fiscalDocumentAmountResidual: {
            type: Number,
        }
    },
    data() {
        return {
            configDate: {
                allowInput: true,
                mode: "single",
                locale: localeService.getLocale(),
                altInput: true,
                altFormat: "d-m-Y",
                showMonths: 1,
                maxDate: new Date()
            },
            showBankFee: false,
            loading: false,
            model: {
                paymentDate: this.formatFrontendDate(new Date()),
                paymentCurrency: 'EUR',
                paymentAmount: null,
                paymentType: null,
            },
            paymentId: null,
            oldRegisteredAmount: null,
        };
    },
    computed: {
        calculateFiscalDocumentAmountResidual() {
            return this.model.paymentAmount ? this.calculateFiscalDocumentAmountMaxAmount - this.model.paymentAmount : this.fiscalDocumentAmountResidual;
        },
        calculateFiscalDocumentAmountMaxAmount() {
            return this.fiscalDocumentAmountResidual + this.oldRegisteredAmount;
        }
    },
    created() {
        this.model.paymentType = this.payment.type;
        this.model.paymentAmount = this.payment.amount;
        this.model.paymentCurrency = this.payment.currency;

        this.oldRegisteredAmount = this.payment.amount;
    },
    methods: {
        updatePayment() {
            this.loading = true;

            fiscalDocumentClient.updatePayment(
                this.fiscalDocumentId,
                this.payment.id,
                this.model.paymentType,
                this.model.paymentDate,
                this.model.paymentAmount,
                this.model.paymentCurrency
            ).then(() => {
                this.$notify({
                    type: "success",
                    icon: "ni ni-notification-70",
                    message: this.$t("notifications.success.updated")
                });
                this.$emit('data-changed');
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loading = false;
                this.closeModal();
            });
        },
        closeModal() {
            this.$emit('close-modal');
        },
        formatDate(selectedDate) {
            this.model.paymentDate = this.formatFrontendDate(selectedDate[0]);
        },
    },
}
</script>
