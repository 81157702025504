import Vuex from "vuex";
import Vue from "vue";
import { mutations } from "./mutations";
import { actions } from "@/store/actions";
import { getters } from "@/store/getters";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        authentication: {
            login: {
                stepEnabled: true
            },
            email: "",
            password: "",
            twoFactor: {
                stepEnabled: false,
                obscuredTelephoneNumber: null,
            },
            technicalAssistance: false,
        },
        company: {
            registryEmail: "",
            registryId: null,
        },
        authorization: {
            areas: [],
        },
        previousRoutePath: null,
        yearSelectedFilter: new Date().getFullYear(),
        keyReloader: 0,
        fiscalDocumentFilters: {
            dateRange: null,
            status: null,
            documentType: null,
            registryToFilterId: null,
            number: null
        },
        registryFilters: {
            type: null,
            country: null,
        },
        cashFlowFilters: {
            senderRegistryName: null,
            number: null,
            type: null,
            tag: null,
            dateRange: null,
        },
    },
    actions,
    mutations,
    getters,
    plugins: [
        createPersistedState({
            paths: [
                'authentication.email',
                'authorization.areas',
                'previousRoutePath',
                'company.registryId',
                'company.registryEmail',
                'yearSelectedFilter',
                'fiscalDocumentFilters',
                'registryFilters',
                'cashFlowFilters',
            ]
        })
    ],
});
