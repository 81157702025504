var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',{attrs:{"logo":_vm.getLogoImgPath}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.homepage'),
                    icon: 'fas fa-home text-primary',
                    path: '/homepage'
                },"data-cy":'buttonHomepage'}}),_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.registry'),
                    icon: 'fas fa-user-edit text-primary',
                },"data-cy":'dropDownRegistryMenu'}},[_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isGranted('REGISTRY-REGISTRY')),expression:"isGranted('REGISTRY-REGISTRY')"}],attrs:{"link":{ name: _vm.$t('menu.registries'), path: '/registry' },"data-cy":'buttonRegistrySection'}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isGranted('REGISTRY-PENDING-REGISTRY')),expression:"isGranted('REGISTRY-PENDING-REGISTRY')"}],attrs:{"link":{ name: _vm.$t('menu.pendingRegistries'), path: '/pendingRegistry' },"data-cy":'buttonPendingRegistrySection'}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isGranted('REGISTRY-BANK')),expression:"isGranted('REGISTRY-BANK')"}],attrs:{"link":{ name: _vm.$t('menu.banks'), path: '/bank' },"data-cy":'buttonBankSection'}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isGranted('REGISTRY-CONTACT')),expression:"isGranted('REGISTRY-CONTACT')"}],attrs:{"link":{ name: _vm.$t('menu.contacts'), path: '/contact' },"data-cy":'buttonContactSection'}})],1),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isGranted('INVOICE')),expression:"isGranted('INVOICE')"}],attrs:{"link":{
                    name: _vm.$t('menu.fiscalDocument'),
                    icon: 'fas fa-file-invoice-dollar text-primary',
                    path: '/fiscalDocument'
                },"data-cy":'buttonFiscalDocument'}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isGranted('EXPECTED-INVOICE')),expression:"isGranted('EXPECTED-INVOICE')"}],attrs:{"link":{
                    name: _vm.$t('menu.expectedInvoices'),
                    icon: 'fas fa-euro-sign text-primary',
                    path: '/expected-invoices'
                },"data-cy":'buttonExpectedInvoices'}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isGranted('CASHFLOW')),expression:"isGranted('CASHFLOW')"}],attrs:{"link":{
                    name: _vm.$t('menu.cashflow'),
                    path: '/cashflow',
                    icon: 'fas fa-receipt text-primary',
                },"data-cy":'buttonCashFlow'}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isGranted('FIRST-ACCOUNTING')),expression:"isGranted('FIRST-ACCOUNTING')"}],attrs:{"link":{
                    name: _vm.$t('menu.firstAccounting'),
                    path: '/firstAccounting',
                    icon: 'fas fa-clipboard text-primary',
                },"data-cy":'buttonFirstAccounting'}})],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view',{key:_vm.$store.getters.keyReloaderContent})],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }