import { PresetDates, PeriodAsDays } from "@/constants/presetDatesConstants";

/**
 * presetDatesMixins.
 */
export default {
    methods: {
        calculateFromAndToDateBasedOnRequiredPeriod(type = PresetDates.THIS_MONTH) {
            const outputDates = {
                fromDate: null,
                toDate: null,
            };

            const date = new Date();

            let fromDate;
            let toDate;

            switch (type) {
            case PresetDates.YESTERDAY:
                fromDate = new Date();
                toDate = new Date();

                fromDate.setDate(fromDate.getDate() - PeriodAsDays.YESTERDAY);
                toDate.setDate(toDate.getDate() - 1);

                break;
            case PresetDates.SEVEN_DAYS:
                fromDate = new Date();
                toDate = new Date();

                fromDate.setDate(fromDate.getDate() - PeriodAsDays.SEVEN_DAYS);

                break;
            case PresetDates.THIS_MONTH:
                fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
                toDate = new Date();

                break;
            case PresetDates.LAST_MONTH:
                fromDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
                toDate = new Date(date.getFullYear(), date.getMonth(), 0);

                break;
            case PresetDates.ONE_MONTH:
                fromDate = new Date();
                toDate = new Date();

                fromDate.setDate(fromDate.getDate() - PeriodAsDays.ONE_MONTH);

                break;
            case PresetDates.TWO_MONTHS:
                fromDate = new Date();
                toDate = new Date();

                fromDate.setDate(fromDate.getDate() - PeriodAsDays.TWO_MONTHS);

                break;
            case PresetDates.TODAY:
                fromDate = new Date();
                toDate = new Date();

                break;
            }

            outputDates.fromDate = fromDate;
            outputDates.toDate = toDate;

            return outputDates;
        },
    }
}
