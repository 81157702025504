<template>
    <div class="wrapper">
        <notifications />
        <side-bar :logo="getLogoImgPath">
            <template slot="links">
                <sidebar-item
                    :link="{
                        name: $t('menu.homepage'),
                        icon: 'fas fa-home text-primary',
                        path: '/homepage'
                    }"
                    :data-cy="'buttonHomepage'"
                />
                <sidebar-item
                    :link="{
                        name: $t('menu.registry'),
                        icon: 'fas fa-user-edit text-primary',
                    }"
                    :data-cy="'dropDownRegistryMenu'"
                >
                    <sidebar-item
                        v-show="isGranted('REGISTRY-REGISTRY')"
                        :link="{ name: $t('menu.registries'), path: '/registry' }"
                        :data-cy="'buttonRegistrySection'"
                    />
                    <sidebar-item
                        v-show="isGranted('REGISTRY-PENDING-REGISTRY')"
                        :link="{ name: $t('menu.pendingRegistries'), path: '/pendingRegistry' }"
                        :data-cy="'buttonPendingRegistrySection'"
                    />
                    <sidebar-item
                        v-show="isGranted('REGISTRY-BANK')"
                        :link="{ name: $t('menu.banks'), path: '/bank' }"
                        :data-cy="'buttonBankSection'"
                    />
                    <sidebar-item
                        v-show="isGranted('REGISTRY-CONTACT')"
                        :link="{ name: $t('menu.contacts'), path: '/contact' }"
                        :data-cy="'buttonContactSection'"
                    />
                </sidebar-item>
                <sidebar-item
                    v-show="isGranted('INVOICE')"
                    :link="{
                        name: $t('menu.fiscalDocument'),
                        icon: 'fas fa-file-invoice-dollar text-primary',
                        path: '/fiscalDocument'
                    }"
                    :data-cy="'buttonFiscalDocument'"
                />
                <sidebar-item
                    v-show="isGranted('EXPECTED-INVOICE')"
                    :link="{
                        name: $t('menu.expectedInvoices'),
                        icon: 'fas fa-euro-sign text-primary',
                        path: '/expected-invoices'
                    }"
                    :data-cy="'buttonExpectedInvoices'"
                />
                <sidebar-item
                    v-show="isGranted('CASHFLOW')"
                    :link="{
                        name: $t('menu.cashflow'),
                        path: '/cashflow',
                        icon: 'fas fa-receipt text-primary',
                    }"
                    :data-cy="'buttonCashFlow'"
                />
                <sidebar-item
                    v-show="isGranted('FIRST-ACCOUNTING')"
                    :link="{
                        name: $t('menu.firstAccounting'),
                        path: '/firstAccounting',
                        icon: 'fas fa-clipboard text-primary',
                    }"
                    :data-cy="'buttonFirstAccounting'"
                />
            </template>
        </side-bar>
        <div class="main-content">
            <dashboard-navbar :type="$route.meta.navbarType" />

            <div @click="$sidebar.displaySidebar(false)">
                <fade-transition
                    :duration="200"
                    origin="center top"
                    mode="out-in"
                >
                    <!-- your content here -->
                    <router-view :key="$store.getters.keyReloaderContent" />
                </fade-transition>
            </div>
            <content-footer v-if="!$route.meta.hideFooter" />
        </div>
    </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
    if (hasElement(className)) {
        new PerfectScrollbar(`.${className}`);
    } else {
    // try to init it later in case this component is loaded async
        setTimeout(() => {
            initScrollbar(className);
        }, 100);
    }
}

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import { FadeTransition } from 'vue2-transitions';
import authorizationsMixins from "@/mixins/authorizationsMixins";
import companyMixins from "@/mixins/companyMixins";

export default {
    components: {
        DashboardNavbar,
        ContentFooter,
        FadeTransition
    },
    mixins: [
        authorizationsMixins,
        companyMixins
    ],
    mounted() {
        this.initScrollbar()
    },
    methods: {
        initScrollbar() {
            let isWindows = navigator.platform.startsWith('Win');
            if (isWindows) {
                initScrollbar('sidenav');
            }
        }
    }
};
</script>
<style lang="scss">
</style>
