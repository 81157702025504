export default class ContactDto {
    #name;
    #email;
    #telephone;

    /**
     * Constructor.
     *
     * @param name {string}
     * @param email {string}
     * @param telephone {string}
     */
    constructor(name, email, telephone) {
        this.#name = name;
        this.#email = email;
        this.#telephone = telephone;
    }

    get name() {
        return this.#name;
    }

    get email() {
        return this.#email;
    }

    get telephone() {
        return this.#telephone;
    }
}
