<template>
    <div class="content">
        <Header
            :title="$t('pages.expectedInvoices.title')"
        />

        <div class="container-fluid mt--6">
            <div class="row">
                <div class="col-12">
                    <card
                        class="mt-2 mb-4 p-2"
                        body-classes="p-0"
                        footer-classes="pb-2"
                    >
                        <div class="container-fluid">
                            <div class="row align-items-center m-0">
                                <div class="col-12">
                                    <h2>{{ $t('tables.column.filters') }}</h2>
                                </div>
                                <base-input
                                    :label="$t('forms.fields.publisher')"
                                    :name="$t('forms.fields.publisher')"
                                    :placeholder="$t('forms.actions.select') + $t('forms.fields.publisher')"
                                    input-classes="form-control-alternative"
                                    :data-cy="'publisherExpectedInvoicesFilter'"
                                    class="col-lg-12 col-xl-7"
                                >
                                    <v-select
                                        v-model="filters.registryToFilterId"
                                        label="customLabel"
                                        class="form-control form-control-alternative"
                                        :options="registryToFilterList"
                                        :reduce="registry => registry.id"
                                    />
                                </base-input>
                                <base-button
                                    native-type="submit"
                                    type="primary"
                                    class="search"
                                    :data-cy="'searchByFilters'"
                                    :disabled="loading"
                                    @click="loadData"
                                >
                                    {{ $t('actions.search') }}
                                </base-button>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
            <div class="row">
                <ExpectedInvoicesTable
                    :table-data="tableData"
                    class="col"
                />
            </div>
        </div>

        <LoadingBar v-show="loading" />
    </div>
</template>
<script>
import { expectedInvoicesClient } from "@/clients/expectedInvoicesClient";
import { registryClient } from "@/clients/registryClient";
import { RegistryTypes } from "@/constants/registryContants";
import Header from "@/components/Header";
import vSelect from 'vue-select';
import ExpectedInvoicesTable from "@/views/Pages/Components/ExpectedInvoices/Table";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import choicesMixins from "@/mixins/choicesMixins";
import yearSelectorMixins from "@/mixins/yearSelectorMixins";
import formatterMixins from "@/mixins/formatterMixins";

export default {
    name: 'ExpectedInvoices',
    components: {
        LoadingBar,
        ExpectedInvoicesTable,
        vSelect,
        Header
    },
    mixins: [
        exceptionsMixins,
        choicesMixins,
        yearSelectorMixins,
        formatterMixins
    ],
    data() {
        return {
            tableData: [],
            loading: false,
            filters: {
                registryToFilterId: null,
                fromDateToSend: this.formatFrontendDate(this.getDefaultFromDate()),
                toDateToSend: this.formatFrontendDate(this.getDefaultToDate()),
            },
            registryToFilterList: []
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        async loadData() {
            this.loading = true;
            if (this.registryToFilterList.length === 0) {
                await this.loadRegistryList();
            }

            if (null === this.filters.registryToFilterId) {
                this.filters.registryToFilterId = this.registryToFilterList[0].id;
            }

            this.loadExpectedInvoices();
        },
        async loadRegistryList() {
            await registryClient.getByFilters(null, false, RegistryTypes.PUBLISHER)
                .then((results) => {
                    this.registryToFilterList = results.data;
                    this.generateRegistryLabel(this.registryToFilterList);
                }).catch((error) => {
                    this.manageException(error.response);
                });
        },
        loadExpectedInvoices() {
            expectedInvoicesClient.getExpectedInvoices(
                this.filters.fromDateToSend,
                this.filters.toDateToSend,
                this.filters.registryToFilterId
            ).then((results) => {
                let data = [...results.data]
                this.tableData = data.filter(element => element.isDefinitive === true);
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loading = false;
            })
        }
    }

}
</script>
