import Fuse from "fuse.js";

/**
 * clientPaginationMixin.
 */
export default {
    computed: {
        queriedData() {
            if (!this.tableData) {
                return [];
            }

            let result = this.tableData;

            if (this.searchedData.length > 0) {
                result = this.searchedData;
            } else {
                if (this.searchQuery) {
                    result = [];
                }
            }

            return result.slice(this.from, this.to);
        },
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            if (!this.searchedData || !this.tableData) {
                return 0;
            }

            return this.searchedData.length > 0
                ? this.searchedData.length
                : this.tableData.length;
        }
    },
    data() {
        return {
            pagination: {
                perPage: 50,
                currentPage: 1,
                perPageOptions: [15, 30, 50, 100],
                total: 0
            },
            searchQuery: "",
            searchedData: [],
            fuseSearch: null
        };
    },
    methods: {
        parseSearchedData(searchedData) {
            return searchedData.map(a => a.item);
        },
        clearSearch() {
            this.searchQuery = "";
            this.searchedData = [];
        },
    },
    watch: {
        searchQuery(value) {
            let result = this.tableData;
            if (value !== "") {
                result = (new Fuse(
                    this.tableData, {
                        keys: this.searchKeys,
                        threshold: this.searchThreshold,
                    })).search(this.searchQuery);

                this.searchedData = this.parseSearchedData(result);
            } else {
                this.searchedData = result;
            }
        },
        tableData() {
            this.clearSearch();
        }
    }
};
