<template>
    <div class="content">
        <Header
            :title="$t('pages.contact.breadcrumb.detail')"
        />

        <LoadingBar v-show="!loaded" />
        <div
            v-show="loaded"
            class="container-fluid mt--6 pt-4"
            :data-cy="'contactDetailsContainer'"
        >
            <card>
                <div class="d-flex justify-content-between align-items-start">
                    <h1 class="card-title mb-4">
                        {{ $t("pages.contact.detailPage.title") + contact.name }}
                    </h1>
                    <div>
                        <base-button
                            :data-cy="'buttonUpdateContactId'+$route.params.id"
                            class="edit mb-2 mb-sm-0"
                            type="primary"
                            size="sm"
                            icon
                            @click="openEditModal($route.params.id)"
                        >
                            <i class="fas fa-edit text-white" />
                        </base-button>
                        <base-button
                            :data-cy="'buttonDeleteContactId'+$route.params.id"
                            class="remove btn-link mb-2 mb-sm-0"
                            type="danger"
                            size="sm"
                            icon
                            @click="openDeleteModal($route.params.id)"
                        >
                            <i class="fas fa-trash-alt text-white" />
                        </base-button>
                    </div>
                </div>
                <div class="card-text mb-4">
                    <dl class="row">
                        <dt class="col-sm-2">
                            {{ $t("forms.fields.name") }}
                        </dt>
                        <dd class="col-sm-10">
                            {{ contact.name }}
                        </dd>
                        <dt class="col-sm-2">
                            {{ $t("forms.fields.email") }}
                        </dt>
                        <dd class="col-sm-10">
                            {{ contact.email }}
                        </dd>
                        <dt
                            v-show="contact.telephone !== null"
                            class="col-sm-2"
                        >
                            {{ $t("forms.fields.telephone") }}
                        </dt>
                        <dd
                            v-show="contact.telephone !== null"
                            class="col-sm-10"
                        >
                            {{ contact.telephone }}
                        </dd>
                    </dl>
                </div>
                <hr>
                <div class="row">
                    <div
                        v-show="contact.administrativeRegistries.length > 0"
                        :data-cy="'administrativeRegistriesContainer'"
                        class="col-6"
                    >
                        <h2>{{ $t('pages.contact.detailPage.administrativeAssociated') }}</h2>
                        <dl class="row">
                            <template v-for="registry in contact.administrativeRegistries">
                                <dt
                                    :key="registry.id"
                                    class="col-sm-12"
                                >
                                    <a :href="'/registry/'+registry.id">{{ registry.name }}</a>
                                </dt>
                            </template>
                        </dl>
                    </div>
                    <div
                        v-show="contact.technicalRegistries.length > 0"
                        :data-cy="'technicalRegistriesContainer'"
                        class="col-6"
                    >
                        <h2>{{ $t('pages.contact.detailPage.technicalAssociated') }}</h2>
                        <dl class="row">
                            <template v-for="registry in contact.technicalRegistries">
                                <dt
                                    :key="registry.id"
                                    class="col-sm-12"
                                >
                                    <a :href="'/registry/'+registry.id">{{ registry.name }}</a>
                                </dt>
                            </template>
                        </dl>
                    </div>
                </div>
            </card>
        </div>

        <DeleteContact
            :delete-contact-id="deleteModal.idContactToDelete"
            :show-delete="deleteModal.showDeleteModal"
            :contact-name="deleteModal.contactName"
            @close-delete="deleteModal.showDeleteModal = false;"
            @data-changed="backToContactListing"
        />

        <EditContact
            :edit-contact-id="editModal.editContactId"
            :show-edit="editModal.showEditModal"
            @close-edit="editModal.showEditModal = false;"
            @data-changed="loadData"
        />
    </div>
</template>

<script>
import { contactClient } from "@/clients/contactClient";
import { RegistryContactAreaKeys } from "@/constants/contactConstants";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import DeleteContact from "@/views/Pages/Components/Contact/Modal/Delete";
import EditContact from "@/views/Pages/Components/Contact/Modal/Edit";
import Header from "@/components/Header";

export default {
    name: 'DetailContact',
    components: {
        LoadingBar,
        DeleteContact,
        EditContact,
        Header,
    },
    mixins: [
        exceptionsMixins
    ],
    data() {
        return {
            contact: {
                name: '',
                email: '',
                telephone: '',
                administrativeRegistries: [],
                technicalRegistries: [],
            },
            loaded: false,
            deleteModal: {
                showDeleteModal: false,
                idContactToDelete: null,
                contactName: '',
                loading: false,
            },
            editModal: {
                showEditModal: false,
                editContactId: null
            },
        };
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.loaded = false;
            contactClient.getById(this.$route.params.id).then((result) => {
                this.contact.name = result.data.name;
                this.contact.email = result.data.email;
                this.contact.telephone = result.data.telephone;

                this.contact.administrativeRegistries = [];
                this.contact.technicalRegistries = [];
                for (let i = 0; i < result.data.registries.length; i++) {
                    if (result.data.registries[i].area === RegistryContactAreaKeys.ADMINISTRATIVE) {
                        this.contact.administrativeRegistries.push(result.data.registries[i]);
                    }

                    if (result.data.registries[i].area === RegistryContactAreaKeys.TECHNICAL) {
                        this.contact.technicalRegistries.push(result.data.registries[i]);
                    }
                }
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loaded = true;
            });
        },
        openEditModal(id) {
            this.editModal.showEditModal = true;
            this.editModal.editContactId = Number(id);
        },
        openDeleteModal(id) {
            this.deleteModal.idContactToDelete = Number(id);
            this.deleteModal.showDeleteModal = true;
            this.deleteModal.contactName = this.contact.name;
        },
        backToContactListing() {
            this.$router.push({name:'CONTACT-LIST'});
        }
    }
}
</script>
