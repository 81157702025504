import { AuthenticationRequestKeys } from '@/constants/authenticationConstants';
import { headersBuilder } from "@/clients/headersBuilder";
import axios, { AxiosResponse } from 'axios';

/**
 * authenticationClient.
 */
export const authenticationClient = {
    /**
   * Call login endpoint.
   *
   * @param {Object} loginDto
   * @param {string} loginDto.email
   * @param {string} loginDto.password
   * @param {string} loginDto.companyName
   * @param {string} loginDto.applicationClientName
   * @param {string|null} loginDto.twoFactorToken
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
    login({email, password, twoFactorToken= null, companyName = 'Talks Media', applicationClientName}) {

        const data = {
            email: email,
            password: password,
            companyName: companyName,
            applicationClientName: applicationClientName,

        };

        if(twoFactorToken) {
            data.twoFactorToken = twoFactorToken;
        }

        return axios.post(`${process.env.VUE_APP_ADMINISTRATION_BASE_URL}/api/v1/authentication/login`,
            data,
            {
                headers: headersBuilder.buildHeaders(),
            });
    },
    /**
     * Call two factor verification endpoint.
     *
     * @param {Object} twoFactorVerificationDto
     * @param {string} twoFactorVerificationDto.token
     * @param {string} twoFactorVerificationDto.code
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    twoFactorVerification({ token, code }) {
        const data = {
            token: token,
            code: code,
        };

        return axios.post(
            `${process.env.VUE_APP_ADMINISTRATION_BASE_URL}/api/v1/authentication/2fa-verification`,
            data,
            {
                headers: headersBuilder.buildHeaders(),
            }
        );
    },
    /**
     * Call refresh two factor code endpoint.
     *
     * @param {Object} refreshTwoFactorCodeDto
     * @param {string} refreshTwoFactorCodeDto.token
     * @param {string} refreshTwoFactorCodeDto.companyName
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    refreshTwoFactorCode({ token, companyName }) {
        const data = {
            token: token,
            companyName: companyName,
        };

        return axios.post(
            `${process.env.VUE_APP_ADMINISTRATION_BASE_URL}/api/v1/authentication/refresh-2fa-code`,
            data,
            {
                headers: headersBuilder.buildHeaders(),
            }
        );
    },
    /**
   * Call check-2fa-code endpoint.
   *
   * @param token {string}
   * @param code {string}
     *
   * @returns {Promise<AxiosResponse<any>>}
   */
    check2faCode(token, code) {
        return axios.post(process.env.VUE_APP_ADMINISTRATION_BASE_URL + "/api/v1/authentication/check-2fa-code",
            {
                [AuthenticationRequestKeys.KEY_TOKEN]: token,
                [AuthenticationRequestKeys.KEY_CODE]: code,
            },
            {
                headers: headersBuilder.buildHeaders(),
            })
    },
    /**
   * Call send-2fa-code endpoint.
   *
   * @param token {string}
     *
   * @returns {Promise<AxiosResponse<any>>}
   */
    send2faCode(token) {
        return axios.post(process.env.VUE_APP_ADMINISTRATION_BASE_URL + "/api/v1/authentication/send-2fa-code",
            {
                [AuthenticationRequestKeys.KEY_TOKEN]: token
            },
            {
                headers: headersBuilder.buildHeaders(),
            })
    },
    /**
   * Call check-authentication-tokens.
   *
   * @param token
   * @param twoFactorToken
     *
   * @returns {Promise<AxiosResponse<any>>}
   */
    checkAuthenticationTokens(token, twoFactorToken) {
        return axios.post(process.env.VUE_APP_ADMINISTRATION_BASE_URL + "/api/v1/authentication/are-valid-authentication-tokens",
            {
                "token": token,
                "twoFactorToken": twoFactorToken
            },
            {
                headers: headersBuilder.buildHeaders()
            });
    },
};
