<template>
    <div
        class="content"
        :data-cy="'contactStep'"
    >
        <validation-observer
            v-slot="{handleSubmit}"
            ref="formValidator"
        >
            <form
                class="new-event-form"
                @submit.prevent="handleSubmit(nextStep)"
            >
                <div class="form-group">
                    <base-input
                        :label="$t('forms.fields.administrativeContact')"
                        :name="$t('forms.fields.administrativeContact')"
                        :placeholder="$t('forms.actions.select') + $t('forms.fields.administrativeContact')"
                        input-classes="form-control-alternative"
                        :data-cy="'newRegistryAdminContactField'"
                    >
                        <v-select
                            v-model="model.administrativeContactId"
                            label="customLabel"
                            class="form-control form-control-alternative"
                            :options="contactList"
                            :reduce="contact => contact.id"
                        />
                    </base-input>
                    <base-input
                        :label="$t('forms.fields.technicalContact')"
                        :name="$t('forms.fields.technicalContact')"
                        :placeholder="$t('forms.actions.select') + $t('forms.fields.technicalContact')"
                        input-classes="form-control-alternative"
                        :data-cy="'newRegistryTechContactField'"
                    >
                        <v-select
                            v-model="model.technicalContactId"
                            label="customLabel"
                            class="form-control form-control-alternative"
                            :options="contactList"
                            :reduce="contact => contact.id"
                        />
                    </base-input>
                    <div class="row align-items-center pb-2">
                        <div class="col-lg-8 col-9">
                            <h6 class="d-inline-block mb-0">
                                <em>{{ $t("pages.registry.cantFindContactQuestion") }}</em>
                            </h6>
                        </div>
                        <div class="col-lg-4 col-3 text-right">
                            <base-button
                                size="sm"
                                type="neutral"
                                :data-cy="'buttonCreateContact'"
                                @click="openNewModal"
                            >
                                {{ $t("actions.add") }}
                            </base-button>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-start">
                    <slot />
                </div>
            </form>
        </validation-observer>
        <LoadingBar v-show="loading" />
        <NewContact
            :key="newContactKey"
            :show-new="showNewContact"
            @close-new="showNewContact = false;"
            @data-changed="loadContacts()"
        />
    </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { contactClient } from "@/clients/contactClient";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import NewContact from "@/views/Pages/Components/Contact/Modal/New";
import vSelect from 'vue-select';

export default {
    name: 'ContactStep',
    components: {
        ValidationObserver,
        LoadingBar,
        NewContact,
        vSelect
    },
    mixins: [
        exceptionsMixins
    ],
    data() {
        return {
            model: {
                administrativeContact: null,
                administrativeContactId: null,
                technicalContact: null,
                technicalContactId: null,
                administrativeContactName: null,
                technicalContactName: null,
            },
            contactList: [],
            showNewContact: false,
            newContactKey: 0,
            loading: false,
        }
    },
    created() {
        this.loadContacts();
    },
    methods: {
        openNewModal() {
            this.newContactKey++;
            this.showNewContact = true;
        },
        nextStep() {
            this.model.administrativeContactName = (this.contactList.filter((value) => {
                return value.id === this.model.administrativeContactId;
            })[0])?.name;

            this.model.technicalContactName = (this.contactList.filter((value) => {
                return value.id === this.model.technicalContactId;
            })[0])?.name;

            this.$emit('next-step', this.model);
        },
        loadContacts() {
            this.loading = true;
            contactClient.getByFilters().then((response) => {
                this.contactList = response.data;
                this.generateLabelInList(this.contactList);
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loading = false;
            });
        },
        generateLabelInList(list) {
            list.forEach(element => element.customLabel = element.name + ' ('+element.email+')');
        }
    }
}
</script>
