import DashboardLayout from "@/views/Layout/DashboardLayout";
import DetailContact from "@/views/Pages/Components/Contact/Detail";
import Contact from "@/views/Pages/Contact";

const contactPages = {
    path: "/contact",
    component: DashboardLayout,
    meta: {
        name: "pages.contact.breadcrumb.contacts",
    },
    children: [
        {
            path: ':id',
            component: DetailContact,
            name: 'CONTACT-DETAIL',
            meta: {
                name: "pages.contact.breadcrumb.detail",
                area: "REGISTRY-CONTACT"
            }
        },
        {
            path: '',
            component: Contact,
            name: 'CONTACT-LIST',
            meta: {
                name: "pages.contact.breadcrumb.contactList",
                area: "REGISTRY-CONTACT"
            }
        }
    ],
};

export default contactPages;
