export default class FiscalDocumentSummaryLineIncomingDto {
    #nature;
    #taxableImport;
    #tax;
    #vat;
    #vatEnforceability;

    /**
     * Constructor.
     *
     * @param nature {string|null}
     * @param taxableImport {number}
     * @param tax {number}
     * @param vat {number}
     * @param vatEnforceability {string|null}
     */
    constructor(
        nature,
        taxableImport,
        tax,
        vat,
        vatEnforceability
    ) {
        this.#nature = nature;
        this.#taxableImport = taxableImport;
        this.#tax = tax;
        this.#vat = vat;
        this.#vatEnforceability = vatEnforceability;
    }

    get nature() {
        return this.#nature;
    }

    get taxableImport() {
        return this.#taxableImport;
    }

    get tax() {
        return this.#tax;
    }

    get vat() {
        return this.#vat;
    }

    get vatEnforceability() {
        return this.#vatEnforceability;
    }
}
