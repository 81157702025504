export default class CashFlowDto {
    #number;
    #registrySenderName;
    #registryRecipientId;
    #tag;
    #type;
    #description;
    #amount;
    #vat;
    #tax;
    #currency;
    #expenseDate;
    #income;

    /**
     * Constructor.
     *
     * @param number {number}
     * @param registrySenderName {string}
     * @param registryRecipientId {number}
     * @param tag {string}
     * @param type {string}
     * @param description {string}
     * @param amount {number}
     * @param vat {number}
     * @param tax {number}
     * @param currency {string}
     * @param expenseDate {string}
     * @param income {boolean|null}
     */
    constructor(
        number,
        registrySenderName,
        registryRecipientId,
        tag,
        type,
        description,
        amount,
        vat,
        tax,
        currency,
        expenseDate,
        income= null
    ) {
        this.#number = number;
        this.#registrySenderName = registrySenderName;
        this.#registryRecipientId = registryRecipientId;
        this.#tag = tag;
        this.#type = type;
        this.#description = description;
        this.#amount = amount;
        this.#vat = vat;
        this.#tax = tax;
        this.#currency = currency;
        this.#expenseDate = expenseDate;
        this.#income = income;
    }

    get number() {
        return this.#number;
    }

    get registrySenderName() {
        return this.#registrySenderName;
    }

    get registryRecipientId() {
        return this.#registryRecipientId;
    }

    get tag() {
        return this.#tag;
    }

    get type() {
        return this.#type;
    }

    get description() {
        return this.#description;
    }

    get amount() {
        return this.#amount;
    }

    get vat() {
        return this.#vat;
    }

    get tax() {
        return this.#tax;
    }

    get currency() {
        return this.#currency;
    }

    get expenseDate() {
        return this.#expenseDate;
    }

    get income() {
        return this.#income;
    }
}
