<template>
    <div
        v-show="showDelete"
        class="delete-modal"
    >
        <modal
            :show="showDelete"
            modal-classes="modal-secondary"
            :data-cy="'deleteModal'"
            @close="$emit('close-delete')"
        >
            <template slot="header">
                <h1>{{ $t("pages.bank.deleteModalTitle") }}</h1>
            </template>

            <p>
                {{ $t("pages.bank.deleteMessage", { bankName: bankName }) }}
            </p>
            <p>
                {{ $t("modalMessages.unrecoverableOperationMessage") }}
            </p>

            <template slot="footer">
                <base-button
                    type="submit"
                    class="btn btn-danger"
                    :loading="loading"
                    :data-cy="'buttonDeleteConfirm'"
                    @click="deleteBank"
                >
                    {{ $t("actions.delete") }}
                </base-button>
                <base-button
                    type="button"
                    class="btn btn-neutral"
                    :data-cy="'buttonDeleteStop'"
                    @click="$emit('close-delete')"
                >
                    {{ $t("actions.cancel") }}
                </base-button>
            </template>
        </modal>
    </div>
</template>

<script>
import { bankClient } from "@/clients/bankClient";
import exceptionsMixins from "@/mixins/exceptionsMixins";

export default {
    name: 'DeleteBank',
    mixins: [
        exceptionsMixins
    ],
    props: {
        deleteBankId: {
            type: Number,
        },
        bankName: {
            type: String,
        },
        showDelete: {
            type: Boolean,
        }
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        deleteBank() {
            this.loading = true;
            bankClient.deleteBank(this.deleteBankId).then(() => {
                this.$notify({
                    type: "success",
                    icon: "ni ni-notification-70",
                    message: this.$t("notifications.success.deleted")
                });
                this.$emit('data-changed');
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loading = false;
                this.$emit('close-delete');
            });
        },
    }
}
</script>
