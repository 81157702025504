<template>
    <div
        class="cancel-modal"
    >
        <modal
            :show="true"
            modal-classes="modal-secondary"
            :data-cy="'cancelFiscalDocumentModal'"
            @close="$emit('close-modal')"
        >
            <template slot="header">
                <h1>{{ $t("pages.fiscalDocument.cancelTitle") }}</h1>
            </template>
            <p>
                {{ $t("pages.fiscalDocument.cancelMessage", {number: fiscalDocument.number}) }}
            </p>
            <p>
                {{ $t("modalMessages.unrecoverableOperationMessage") }}
            </p>
            <validation-observer
                v-slot="{handleSubmit}"
                ref="formValidator"
            >
                <form
                    ref="cancelForm"
                    @submit.prevent="handleSubmit(cancelFiscalDocument)"
                >
                    <base-input
                        :label="$t('forms.fields.creditNote')"
                        :name="$t('forms.fields.creditNote')"
                        :rules="{required: true}"
                        input-classes="form-control-alternative"
                        :data-cy="'creditNoteToCancel'"
                        class="col"
                    >
                        <v-select
                            v-model="model.creditNoteId"
                            label="number"
                            class="form-control form-control-alternative"
                            :placeholder="$t('forms.actions.select') + ' ' + $t('forms.fields.creditNote').toLowerCase()"
                            :options="creditNoteChoices"
                            :reduce="creditNote => creditNote.id"
                        />
                    </base-input>

                    <div class="d-flex justify-content-end">
                        <base-button
                            native-type="submit"
                            type="submit"
                            class="btn btn-primary"
                            :loading="loading"
                            :data-cy="'buttonCancelFiscalDocumentConfirm'"
                        >
                            {{ $t("actions.update") }}
                        </base-button>
                        <base-button
                            type="button"
                            class="btn btn-secondary"
                            :data-cy="'buttonCancelFiscalDocumentStop'"
                            @click="$emit('close-modal')"
                        >
                            {{ $t("actions.cancel") }}
                        </base-button>
                    </div>
                </form>
            </validation-observer>
        </modal>
    </div>
</template>

<script>
import { fiscalDocumentClient } from "@/clients/fiscalDocumentClient";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import fiscalDocumentMixins from "@/mixins/fiscalDocumentMixins";
import vSelect from 'vue-select';

export default {
    name: 'CancelFiscalDocument',
    components: {
        vSelect
    },
    mixins: [
        exceptionsMixins,
        fiscalDocumentMixins
    ],
    props: {
        fiscalDocument: {
            type: Object,
        },
    },
    data() {
        return {
            loading: false,
            model: {
                creditNoteId: null
            }
        };
    },
    computed: {
        creditNoteChoices() {
            return this.fiscalDocument.children.filter(child => this.isCreditNoteType(child.type) && this.checkStatusCanBeCancelled(child.status));
        }
    },
    methods: {
        cancelFiscalDocument() {
            this.loading = true;
            fiscalDocumentClient.cancelFiscalDocument(this.fiscalDocument.id, this.model.creditNoteId).then(() => {
                this.$notify({
                    type: "success",
                    icon: "ni ni-notification-70",
                    message: this.$t("notifications.success.updated")
                });
                this.$emit('data-changed');
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loading = false;
                this.$emit('close-modal');
            });
        },
    }
}
</script>
