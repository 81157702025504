<template>
    <div class="edit-modal">
        <LoadingBar v-show="showEdit && !loaded" />
        <modal
            :show="showEdit && loaded"
            modal-classes="modal-secondary"
            :size="'lg'"
            :data-cy="'updateModal'"
            @close="closeEdit"
        >
            <template slot="header">
                <h1>{{ $t("actions.update") + ' ' + $t("pages.contact.contact") }}</h1>
            </template>
            <validation-observer
                v-slot="{handleSubmit}"
                ref="formValidator"
            >
                <form
                    class="edit-event-form"
                    @submit.prevent="handleSubmit(updateContact)"
                >
                    <div class="form-group container">
                        <div class="row">
                            <base-input
                                v-model="model.name"
                                :label="$t('forms.fields.name')"
                                class="col-6"
                                :placeholder="$t('forms.fields.name')"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.name')"
                                :rules="{required:true}"
                                :data-cy="'editContactNameField'"
                            />

                            <base-input
                                v-model="model.email"
                                :label="$t('forms.fields.email')"
                                class="col-6"
                                :placeholder="$t('forms.fields.email')"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.email')"
                                :rules="{required:true, email: true}"
                                :data-cy="'editContactEmailField'"
                            />
                            <base-input
                                :label="$t('forms.fields.telephoneNumberPlaceholder')"
                                input-classes="form-control-alternative"
                                class="col-12"
                                :name="$t('forms.fields.telephoneNumberPlaceholder')"
                                :rules="{
                                    required: false
                                }"
                            >
                                <vue-tel-input
                                    v-model="model.telephone"
                                    :input-options="{placeholder: $t('forms.fields.telephoneNumberPlaceholder')}"
                                    mode="international"
                                    class="mb-3"
                                    :data-cy="'editContactTelephoneField'"
                                />
                            </base-input>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <base-button
                            native-type="submit"
                            type="primary"
                            class="new-event--add"
                            :data-cy="'update-edit-contact'"
                            :disabled="updating"
                            :loading="updating"
                        >
                            {{ $t("actions.update") }}
                        </base-button>
                        <base-button
                            type="link"
                            :data-cy="'close-edit-contact'"
                            @click="closeEdit"
                        >
                            {{ $t("actions.cancel") }}
                        </base-button>
                    </div>
                </form>
            </validation-observer>
        </modal>
    </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { contactClient } from "@/clients/contactClient";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import BaseButton from "@/components/BaseButton";
import Modal from "@/components/Modal";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import ContactDto from "@/clients/dto/ContactDto";

export default {
    name: "EditContact",
    components: {
        BaseButton,
        Modal,
        LoadingBar,
        ValidationObserver,
        VueTelInput,
    },
    mixins: [
        exceptionsMixins
    ],
    props: {
        editContactId: {
            type: Number,
        },
        showEdit: {
            type: Boolean,
        }
    },
    data() {
        return {
            model: {
                name: null,
                email: null,
                telephone: '',
            },
            loaded: false,
            updating: false,
        }
    },
    watch: {
        showEdit() {
            if (this.editContactId) {
                let contact = null;
                this.loaded = false;
                contactClient.getById(this.editContactId).then((result) => {
                    contact = result.data;
                    this.model.name = contact.name;
                    this.model.email = contact.email
                    this.model.telephone = contact.telephone ? String(contact.telephone) : '';
                }).catch((error) => {
                    this.manageException(error.response);
                }).then(() => {
                    this.loaded = true;
                });
            }
        },
    },
    mounted() {
        this.$emit('close-edit');
    },
    methods: {
        closeEdit() {
            this.showEditModal = false;
            this.$emit('close-edit');
        },
        updateContact() {
            this.updating = true;
            const contactDto = new ContactDto(
                this.model.name,
                this.model.email,
                this.model.telephone?.replace(/ /g, '')
            );

            contactClient.updateContact(this.editContactId, contactDto).then(() => {
                this.$notify({
                    type: "success",
                    icon: "ni ni-notification-70",
                    message: this.$t("notifications.success.updated")
                });
                this.$emit('data-changed');
                this.closeEdit();
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.updating = false;
            });
        }
    }
}
</script>
