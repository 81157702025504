export default class FiscalDocumentAttachmentDto {
    #encodedData;

    /**
     * Constructor.
     *
     * @param encodedData {string}
     */
    constructor(
        encodedData
    ) {
        this.#encodedData = encodedData;
    }

    get encodedData() {
        return this.#encodedData;
    }
}
