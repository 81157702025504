import DashboardLayout from "@/views/Layout/DashboardLayout";
import PendingRegistry from "@/views/Pages/PendingRegistry";

const pendingRegistryPages = {
    path: "/pendingRegistry",
    component: DashboardLayout,
    meta: {
        name: "pages.pendingRegistry.breadcrumb.pendingRegistries",
    },
    children: [
        {
            path: '',
            name: 'PENDING-REGISTRY-LIST',
            component: PendingRegistry,
            meta: {
                name: "pages.pendingRegistry.breadcrumb.pendingRegistryList",
                area: "REGISTRY-PENDING-REGISTRY"
            }
        },
    ]
};

export default pendingRegistryPages;
