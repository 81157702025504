<template>
    <modal
        :show="true"
        modal-classes="modal-secondary"
        :size="'lg'"
        :data-cy="'newCashFlowModal'"
        @close="closeNew"
    >
        <template slot="header">
            <h1 v-show="!showNewForm">
                {{ $t("pages.cashFlow.creationQuestion") }}
            </h1>
            <h1 v-show="showNewForm">
                {{ $t('actions.creating') + ' ' + typeTitle }}
            </h1>
        </template>

        <div v-show="!showNewForm">
            <div class="row">
                <div class="col-12">
                    <base-button
                        native-type="submit"
                        type="primary"
                        :data-cy="'incomingCashFlowChoice'"
                        @click="onIncomingCashFlowChoice"
                    >
                        {{ $t("pages.cashFlow.cashFlow.incoming") }}
                    </base-button>
                    <base-button
                        native-type="submit"
                        type="primary"
                        :data-cy="'outcomingCashFlowChoice'"
                        @click="onOutcomingCashFlowChoice"
                    >
                        {{ $t("pages.cashFlow.cashFlow.outcoming") }}
                    </base-button>
                </div>
            </div>
        </div>

        <validation-observer
            v-slot="{handleSubmit}"
            ref="formValidator"
        >
            <form
                v-show="showNewForm"
                class="new-event-form"
                @submit.prevent="handleSubmit(create)"
            >
                <div class="form-group">
                    <base-input
                        v-model="model.registrySenderName"
                        :label="senderLabel"
                        :name="senderLabel"
                        input-classes="form-control-alternative"
                        :rules="{required: true}"
                        :data-cy="'newCashFlowSenderRegistryField'"
                    />
                    <base-input
                        :loading="loadingNextNumber"
                        :value="cashFlowNumber"
                        disabled
                        :label="$t('forms.fields.number')"
                        :name="$t('forms.fields.number')"
                        input-classes="form-control-alternative"
                        :rules="{
                            required: true,
                            alpha_dash: true,
                        }"
                        :data-cy="'newCashFlowNumberField'"
                        class="col-lg-6 col-xl-1"
                    />
                    <base-input
                        :label="$t('forms.fields.tag')"
                        :placeholder="$t('forms.actions.select') + $t('forms.fields.tag')"
                        input-classes="form-control-alternative"
                        :name="$t('forms.fields.tag')"
                        :rules="{
                            required: true,
                            alpha_dash: true
                        }"
                        :data-cy="'newCashFlowTagField'"
                    >
                        <v-select
                            v-model="model.tag"
                            class="form-control form-control-alternative"
                            :options="cashFlowTagList"
                            :reduce="tag => tag.code"
                        />
                    </base-input>
                    <base-input
                        :label="$t('forms.fields.type')"
                        :placeholder="$t('forms.actions.select') + $t('forms.fields.type')"
                        input-classes="form-control-alternative"
                        :name="$t('forms.fields.type')"
                        :rules="{
                            required: true,
                            alpha_dash: true
                        }"
                        :data-cy="'newCashFlowTypeField'"
                    >
                        <v-select
                            v-model="model.type"
                            class="form-control form-control-alternative"
                            :options="cashFlowTypeList"
                            :reduce="type => type.code"
                        />
                    </base-input>
                    <base-input
                        :label="$t('forms.fields.description')"
                        :name="$t('forms.fields.description')"
                        input-classes="form-control-alternative"
                        :rules="{
                            required: false,
                        }"
                        :data-cy="'newCashFlowDescriptionField'"
                    >
                        <textarea
                            v-model="model.description"
                            class="form-control"
                            rows="3"
                        />
                    </base-input>
                    <div class="row">
                        <base-input
                            :label="$t('forms.fields.currency')"
                            :placeholder="$t('forms.actions.select') + $t('forms.fields.currency')"
                            input-classes="form-control-alternative"
                            class="col-lg-6"
                            :name="$t('forms.fields.currency')"
                            :rules="{
                                required: true,
                                length: 3
                            }"
                            :data-cy="'newCashFlowCurrencyField'"
                        >
                            <v-select
                                v-model="model.currency"
                                class="form-control form-control-alternative"
                                :options="currencyList"
                                :reduce="currency => currency.code"
                            />
                        </base-input>
                        <base-input
                            :label="$t('forms.fields.vatAmount')"
                            :name="$t('forms.fields.vatAmount')"
                            input-classes="form-control-alternative"
                            class="col-6"
                            :data-cy="'newCashFlowVatField'"
                            :rules="{
                                required: true,
                                decimal: true
                            }"
                        >
                            <v-select
                                v-model="model.vat"
                                class="form-control form-control-alternative"
                                :options="paymentDocumentsVatAmounts"
                                :reduce="vat => vat.code"
                            />
                        </base-input>
                    </div>
                    <div class="row">
                        <base-input
                            v-model="model.amount"
                            :label="$t('forms.fields.amountTaxIncluded')"
                            :name="$t('forms.fields.amountTaxIncluded')"
                            class="col-lg-6"
                            :rules="{
                                required: true,
                                decimal: true
                            }"
                            :data-cy="'newCashFlowAmountField'"
                        />
                        <base-input
                            addon-left-icon="ni ni-calendar-grid-58"
                            :label="isIncome ? $t('forms.fields.collectionDate') : $t('forms.fields.paymentDate')"
                            :name="isIncome ? $t('forms.fields.collectionDate') : $t('forms.fields.paymentDate')"
                            :data-cy="'newCashFlowExpenseDateField'"
                            class="col-6"
                            :rules="{
                                required:true
                            }"
                        >
                            <flat-picker
                                v-model="model.expenseDate"
                                slot-scope="{focus, blur}"
                                :config="configDate"
                                class="form-control datepicker"
                                @on-open="focus"
                                @on-close="blur"
                                @on-change="formatDate"
                            />
                        </base-input>
                        <base-checkbox
                            v-if="!isIncome"
                            v-model="bankCommission.show"
                            :label="$t('forms.fields.cashFlowBankCommission')"
                            :class="{
                                'col-12 pl-5 form-group': true,
                                'pb-3': !bankCommission.show
                            }"
                            :data-cy="'newCashFlowBankCommissionCheckBox'"
                            :name="$t('forms.fields.cashFlowBankCommission')"
                        >
                            {{ $t('forms.fields.cashFlowBankCommission') }}
                        </base-checkbox>
                        <base-input
                            v-if="bankCommission.show"
                            v-model="bankCommission.amount"
                            :label="$t('forms.fields.bankFee')"
                            :name="$t('forms.fields.bankFee')"
                            class="col-6"
                            :rules="{
                                required: bankCommission.show,
                                decimal: true
                            }"
                            :data-cy="'newCashFlowBankCommissionAmountField'"
                        />
                        <div class="col-12">
                            <label class="form-control-label">
                                {{ $tc('forms.section.attachments') }}
                            </label>
                            <DropzoneFileUpload
                                :accepted-files="'application/pdf'"
                                @encodedFilesChanged="encodedFilesChanged"
                            />
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <base-button
                        native-type="submit"
                        type="primary"
                        class="new-event--add"
                        :data-cy="'createNewCashFlowButton'"
                        :disabled="creating"
                        :loading="creating"
                    >
                        {{ $t("actions.new") }}
                    </base-button>
                    <base-button
                        type="link"
                        @click="closeNew"
                    >
                        {{ $t("actions.cancel") }}
                    </base-button>
                </div>
            </form>
        </validation-observer>

        <LoadingBar v-show="loading || creating" />
    </modal>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { cashFlowClient } from "@/clients/cashFlowClient";
import { registryClient } from "@/clients/registryClient";
import { bankClient } from "@/clients/bankClient";
import { CashFlowExceptionCodes, CashFlowTags, CashFlowTypes } from "@/constants/cashFlowContants";
import { localeService } from "@/services/localeService";
import choicesMixins from "@/mixins/choicesMixins";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import formatterMixins from "@/mixins/formatterMixins";
import BaseButton from "@/components/BaseButton";
import Modal from "@/components/Modal";
import CashFlowDto from "@/clients/dto/CashFlowDto";
import vSelect from 'vue-select';
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// All the supported languages must be added to let the flatPicker work correctly.
import "flatpickr/dist/l10n/it";
import vatCalculationMixins from "@/mixins/vatCalculationMixins";
import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import DropzoneFileUpload from "@/views/Pages/Components/FileUpload/DropzoneFileUpload";
import CashFlowAttachmentDto from "@/clients/dto/CashFlowAttachmentDto";

export default {
    name: "NewCashFlow",
    components: {
        BaseButton,
        Modal,
        ValidationObserver,
        vSelect,
        LoadingBar,
        flatPicker,
        DropzoneFileUpload
    },
    mixins: [
        exceptionsMixins,
        choicesMixins,
        vatCalculationMixins,
        formatterMixins,
    ],
    data() {
        return {
            showNewForm: false,
            configDate: {
                allowInput: true,
                mode: "single",
                locale: localeService.getLocale(),
                altInput: true,
                altFormat: "d-m-Y",
                showMonths: 1,
                maxDate: new Date()
            },
            model: {
                number: null,
                registrySenderName: null,
                registryRecipientId: this.$store.getters.activeRegistryId,
                tag: null,
                type: null,
                description: null,
                amount: null,
                vat: null,
                currency: 'EUR',
                expenseDate: null,
                income: null
            },
            bankCommission: {
                show: false,
                amount: 2
            },
            encodedFiles: [],
            loading: false,
            creating: false,
            loadingNextNumber: false,
        }
    },
    computed: {
        senderLabel() {
            return this.isIncome ? this.$t("tables.column.debtor") : this.$t("tables.column.creditor");
        },
        typeTitle() {
            return this.isIncome ? this.$t("pages.cashFlow.cashFlow.incoming") : this.$t("pages.cashFlow.cashFlow.outcoming");
        },
        cashFlowNumber() {
            return this.model.number;
        },
        isIncome() {
            return this.model.income;
        },
        createBankCommission() {
            return this.bankCommission.show;
        }
    },
    created() {
        this.fetchNextNumber();
    },
    methods: {
        encodedFilesChanged(encodedFiles) {
            this.encodedFiles = encodedFiles;
        },
        onIncomingCashFlowChoice() {
            this.model.income = true;
            this.showNewForm = true;
        },
        onOutcomingCashFlowChoice() {
            this.model.income = false;
            this.showNewForm = true;
        },
        registerTelephoneNumber(event) {
            const telephoneNumber = JSON.parse(JSON.stringify(event));

            if (telephoneNumber["isValid"]) {
                this.model.telephone = telephoneNumber["formattedNumber"];
            }
        },
        closeNew() {
            this.$emit('close-new');
        },
        create() {
            this.creating = true;

            const cashFlowDto = new CashFlowDto(
                this.model.number,
                this.model.registrySenderName,
                this.model.registryRecipientId,
                this.model.tag,
                this.model.type,
                this.model.description,
                this.model.amount,
                this.model.vat,
                this.calculateTaxFromAmountWithVat(parseFloat(this.model.amount), parseFloat(this.model.vat)),
                this.model.currency,
                this.model.expenseDate,
                this.model.income
            );

            let idParentCashFlow = null;
            cashFlowClient.create(cashFlowDto).then((response) => {
                idParentCashFlow = response.data.id;
                return this.createBankCommissionCashFlow(response.data.number);
            }).then(() => {
                return this.addAttachments(idParentCashFlow);
            }).then(() => {
                this.$notify({
                    type: "success",
                    icon: "ni ni-notification-70",
                    message: this.$t("pages.cashFlow.notification.created")
                });

                this.$emit('data-changed');
                this.closeNew();
            }).catch((error) => {
                this.manageException(error.response);

                if (
                    this.getExceptionCodeFromErrorResponse(error) === CashFlowExceptionCodes.NOT_VALID_NUMBER ||
                    this.getExceptionCodeFromErrorResponse(error) === CashFlowExceptionCodes.ALREADY_USED_NUMBER
                ) {
                    const oldNumber = this.model.number;
                    this.fetchNextNumber((nextNumber) => {
                        swal.fire({
                            title: oldNumber,
                            text: this.$t("pages.cashFlow.numberUsed", { nextNumber }),
                            buttonsStyling: false,
                            customClass: {
                                confirmButton: 'btn btn-default'
                            },
                            icon: 'question'
                        }).then(result => {
                            if (result.isConfirmed) {
                                this.create();
                            }
                        });
                    });
                }
            }).then(() => {
                this.creating = false;
            });
        },
        addAttachments(parentCashFlowId) {
            return new Promise((resolve, reject) => {
                if (this.encodedFiles.length === 0) {
                    resolve();
                }

                this.encodedFiles.forEach((encodedData, index) => {
                    this.creating = true;
                    const attachmentDto = new CashFlowAttachmentDto(encodedData);
                    cashFlowClient.createAttachment(parentCashFlowId, attachmentDto).then(() => {
                    }).catch((error) => {
                        reject(error);
                    }).then(() => {
                        if (index === (this.encodedFiles.length - 1)) {
                            resolve();
                        }
                    });
                });
            });
        },
        createBankCommissionCashFlow(parentCashFlowNumber) {
            return new Promise((resolve, reject) => {
                if (!this.createBankCommission) {
                    resolve();
                    return;
                }

                registryClient.getNextCashFlowNumber(this.$store.getters.activeRegistryId).then((responseNumber) => {
                    registryClient.getById(this.$store.getters.activeRegistryId).then((registryResponse) => {
                        bankClient.getById(registryResponse.data.bankAccount.bank.id).then((bankResponse) => {
                            const cashFlowDto = new CashFlowDto(
                                responseNumber.data.nextNumber,
                                bankResponse.data.name,
                                this.$store.getters.activeRegistryId,
                                CashFlowTags.TG05,
                                CashFlowTypes.TP02,
                                `Commissione bancaria per uscita: ${parentCashFlowNumber}`,
                                this.bankCommission.amount,
                                0,
                                0,
                                this.model.currency,
                                this.model.expenseDate,
                                false
                            );

                            cashFlowClient.create(cashFlowDto).then((response) => {
                                resolve(response);
                            }).catch((error) => {
                                this.$notify({
                                    type: "warning",
                                    icon: "ni ni-notification-70",
                                    message: this.$t("pages.cashFlow.notification.cantCreateBankCommission")
                                });

                                reject(error);
                            });
                        }).catch((error) => {
                            reject(error);
                        });
                    }).catch((error) => {
                        reject(error);
                    });
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        formatDate(selectedDate) {
            this.model.expenseDate = this.formatFrontendDate(selectedDate[0]);
        },
        generateLabelInList(list) {
            list.forEach(element => element.customLabel = element.name + ' (' + element.email + ')');
        },
        fetchNextNumber(callback) {
            this.loadingNextNumber = true;
            registryClient.getNextCashFlowNumber(this.$store.getters.activeRegistryId).then((response) => {
                this.model.number = response.data.nextNumber;

                if (callback) {
                    callback(response.data.nextNumber);
                }
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loadingNextNumber = false;
            });
        }
    }
}
</script>
