export const RegistryTypes = Object.freeze({
    PUBLISHER: 'PUBLISHER',
    SSP: 'SSP',
    SUPPLIER: 'SUPPLIER',
    CUSTOMER: 'CUSTOMER',
    MEDIACENTER: 'MEDIA-CENTER',
    EMPLOYEE: 'EMPLOYEE',
    UNDEFINED: 'UNDEFINED'
});

export const RegistryTaxRegimes = Object.freeze({
    RF01: 'RF01',
    RF02: 'RF02',
    RF04: 'RF04',
    RF05: 'RF05',
    RF06: 'RF06',
    RF07: 'RF07',
    RF08: 'RF08',
    RF09: 'RF09',
    RF10: 'RF10',
    RF11: 'RF11',
    RF12: 'RF12',
    RF13: 'RF13',
    RF14: 'RF14',
    RF15: 'RF15',
    RF16: 'RF16',
    RF17: 'RF17',
    RF18: 'RF18',
    RF19: 'RF19',
});
