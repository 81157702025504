import { baseEntityClient } from "@/clients/baseEntityClient";
import { SupportedEntity } from "@/clients/constants/clientConstants";
import { headersBuilder } from "@/clients/headersBuilder";
import axios, { AxiosResponse } from "axios";

/**
 * registryClient.
 */
export const registryClient = {
    /**
     * Call get registry endpoint with provided id.
     *
     * @param registryId {number}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getById(registryId) {
        return baseEntityClient.getEntityById(SupportedEntity.REGISTRY, registryId);
    },
    /**
     * Call get registry endpoint with filters.
     *
     * @param email {string|null}
     * @param owned {boolean|null}
     * @param type {string|null}
     * @param country {string|null}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getByFilters(
        email = null,
        owned = null,
        type = null,
        country = null
    ) {
        const filters = {
            email,
            owned,
            type,
            country
        };

        return baseEntityClient.getEntityByFilters(SupportedEntity.REGISTRY, filters);
    },
    /**
     * Call get registry metadata endpoint.
     *
     * @returns {Promise<AxiosResponse<*>>}
     */
    getMetadata() {
        return baseEntityClient.getEntityByFilters(SupportedEntity.REGISTRY_METADATA);
    },
    /**
     * Call the delete registry endpoint.
     *
     * @param id {number}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteRegistry(id) {
        return baseEntityClient.deleteEntityById(SupportedEntity.REGISTRY, id);
    },
    /**
     * Call update registry endpoint.
     *
     * @param id {number}
     * @param registryDto {RegistryDto}
     */
    updateRegistry(id, registryDto) {
        const data = {
            registryName: registryDto.name,
            registryEmail: registryDto.email,
            registryTelephone: registryDto.telephone,
            registryType: registryDto.type,
            vat: registryDto.vat,
            nationalIdentificationNumber: registryDto.nationalIdentificationNumber,
            taxRegime: registryDto.taxRegime,
            feCode: registryDto.feCode,
            locationAddress: registryDto.locationAddress,
            locationCity: registryDto.locationCity,
            locationState: registryDto.locationState,
            locationZip: registryDto.locationZip,
            locationCountry: registryDto.locationCountry,
            administrativeContactId: registryDto.administrativeContactId,
            technicalContactId: registryDto.technicalContactId,
            bankAccountBeneficiaryName: registryDto.bankAccountBeneficiaryName,
            bankAccountIban: registryDto.bankAccountIban,
            bankId: registryDto.bankId,
        };

        return baseEntityClient.updateEntity(SupportedEntity.REGISTRY, id, data);
    },
    /**
     * Call create registry endpoint.
     *
     * @param registryDto {RegistryDto}
     */
    createRegistry(registryDto) {
        const data = {
            registryName: registryDto.name,
            registryEmail: registryDto.email,
            registryTelephone: registryDto.telephone,
            registryType: registryDto.type,
            vat: registryDto.vat,
            nationalIdentificationNumber: registryDto.nationalIdentificationNumber,
            taxRegime: registryDto.taxRegime,
            feCode: registryDto.feCode,
            locationAddress: registryDto.locationAddress,
            locationCity: registryDto.locationCity,
            locationState: registryDto.locationState,
            locationZip: registryDto.locationZip,
            locationCountry: registryDto.locationCountry,
            administrativeContactId: registryDto.administrativeContactId,
            technicalContactId: registryDto.technicalContactId,
            bankAccountBeneficiaryName: registryDto.bankAccountBeneficiaryName,
            bankAccountIban: registryDto.bankAccountIban,
            bankId: registryDto.bankId,
            pendingRegistryId: registryDto.pendingRegistryId
        };

        return baseEntityClient.createEntity(SupportedEntity.REGISTRY, data);
    },
    /**
     * Call get next cash flow number endpoint.
     *
     * @param registryId {number}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getNextCashFlowNumber(registryId) {
        return axios.get(
            process.env.VUE_APP_ADMINISTRATION_BASE_URL +
            `/api/v1/${SupportedEntity.REGISTRY}/${registryId}/next-cash-flow-number`,
            {
                headers: headersBuilder.buildAuthenticatedHeaders(),
            }
        );
    },
    /**
     * Call get next fiscal document number endpoint.
     *
     * @param registryId {number}
     * @param year {number}
     * @param documentType {string}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getNextFiscalDocumentNumber(registryId, year, documentType) {
        return axios.get(
            process.env.VUE_APP_ADMINISTRATION_BASE_URL +
            `/api/v1/${SupportedEntity.REGISTRY}/${registryId}/next-fiscal-document-number/${year}/${documentType}`,
            {
                headers: headersBuilder.buildAuthenticatedHeaders(),
            }
        );
    }
}
