<template>
    <div
        class="col p-0"
        :data-cy="'detailLinesTable'"
    >
        <card
            class="no-border-card"
            body-classes="p-0"
            footer-classes="pb-2"
        >
            <div>
                <el-table
                    :data="queriedData"
                    row-key="lineNumber"
                    header-row-class-name="thead-light"
                >
                    <el-table-column
                        v-for="column in tableColumns"
                        :key="column.label"
                        v-bind="column"
                        :formatter="column.formatter"
                    />
                </el-table>
            </div>
        </card>
    </div>
</template>

<script>
import { Option, Select, Table, TableColumn } from "element-ui";
import clientPaginationMixin from "@/views/Mixins/clientPaginationMixin";
import BaseButton from "@/components/BaseButton";
import Modal from "@/components/Modal";
import formatterMixins from "@/mixins/formatterMixins";

export default {
    name: "DetailLinesTable",
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
    },
    mixins: [
        clientPaginationMixin,
        BaseButton,
        Modal
    ],
    props: {
        tableData: {
            type: Array
        },
        currency: {
            type: String
        }
    },
    data() {
        return {
            tableColumns: [
                {
                    prop: "lineNumber",
                    label: "#",
                    minWidth: 80,
                    sortable: true,
                },
                {
                    prop: "nature",
                    label: this.$t("tables.column.nature"),
                    minWidth: 160,
                    formatter: (row) => {
                        if (row.nature === null) {
                            return '';
                        }
                        const cleanedCode = row.nature.replaceAll('.', '-');
                        return row.nature + ' | ' +this.$t(`choices.fiscalDocument.summaryLine.nature.${cleanedCode}`)
                    }
                },
                {
                    prop: "description",
                    label: this.$t("tables.column.description"),
                    minWidth: 160,
                },
                {
                    prop: "vat",
                    label: this.$t("tables.column.vatAmount"),
                    minWidth: 120,
                    sortable: true,
                    formatter: (row) => {
                        return formatterMixins.methods.formatPercentage(row.vat);
                    }
                },
                {
                    prop: "unitPrice",
                    label: this.$t("tables.column.unitPrice"),
                    minWidth: 120,
                    sortable: true,
                    formatter: (row) => {
                        return formatterMixins.methods.formatCurrency(this.currency, row.unitPrice);
                    }
                },
                {
                    prop: "quantity",
                    label: this.$t("tables.column.quantity"),
                    minWidth: 120,
                    sortable: true,
                },
                {
                    prop: "totalPrice",
                    label: this.$t("tables.column.totalPrice"),
                    minWidth: 120,
                    sortable: true,
                    formatter: (row) => {
                        return formatterMixins.methods.formatCurrency(this.currency, row.totalPrice);
                    }
                },
            ],
            searchQuery: "",
            searchKeys: [
                'description',
            ],
            searchThreshold: 0.3,
        };
    },
};
</script>
