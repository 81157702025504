<template>
    <ul class="steps">
        <template v-for="(value, key) in steps">
            <li
                :key="key"
                :class="{
                    'step': true,
                    'step--incomplete': !isCompletedStep(Number(key)),
                    'step--complete': isCompletedStep(Number(key)),
                    'step--inactive': Number(key) !== activeStep,
                    'step--active': Number(key) === activeStep
                }"
            >
                <i class="fas fa-check step__icon" />
                <span class="step__label">{{ value }}</span>
            </li>
        </template>
    </ul>
</template>

<script>
export default {
    name: 'StepProgressBar',
    props: {
        steps: Object,
        activeStep: Number,
    },
    methods: {
        isCompletedStep(id) {
            return this.activeStep > id;
        }
    }
}
</script>

<style lang="scss">
@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

$charcoal: #B3DBB8;
$green: #41A551;
$text-step-color: #2f2f2f;
$base-background-color: $charcoal;
$base-font-color: $text-step-color;
$base-muted-color: #B3DBB8;

@keyframes bounce {
    0% {
        transform: scale(1);
    }

    33% {
        transform: scale(0.9);
    }

    66% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.steps {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0 0 2rem 0;
    list-style: none;
}

.step {
    @include flex-center;
    flex-direction: column;
    flex: 1;
    position: relative;

    &--active,
    &--complete {
        cursor: pointer;
        pointer-events: all;
    }

    &:not(:last-child) {
        &:before,
        &:after {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            height: 0.25rem;
            content: '';
            transform: translateY(-50%);
            will-change: width;
            z-index: 0;
        }
    }

    &:before {
        width: 100%;
        background-color: $base-muted-color;
    }

    &:after {
        width: 0;
        background-color: $green;
    }

    &--complete {
        &:after {
            width: 100% !important;
            opacity: 1;
            transition: width 0.6s ease-in-out,
            opacity 0.6s ease-in-out;
        }
    }
}

.step__icon {
    @include flex-center;
    position: relative;
    width: 3rem;
    height: 3rem;
    background-color: $base-background-color;
    border: 0.25rem solid $base-muted-color;
    border-radius: 50%;
    color: transparent;
    font-size: 2rem;
    z-index: 1;

    &:before {
        display: block;
        color: #fff;
        font-size: 18px;
    }

    .step--complete.step--active & {
        color: green;
        transition: background-color 0.3s ease-in-out,
        border-color 0.3s ease-in-out,
        color 0.3s ease-in-out;
    }

    .step--incomplete.step--active & {
        border-color: $green;
        transition-delay: 0.5s;
    }

    .step--complete & {
        animation: bounce 0.5s ease-in-out;
        background-color: $green;
        border-color: $green;
        color: #fff;
    }
}

.step__label {
    position: absolute;
    bottom: -2rem;
    left: 50%;
    margin-top: 1rem;
    font-size: 0.8rem;
    text-transform: uppercase;
    transform: translateX(-50%);

    .step--incomplete.step--inactive & {
        color: $base-muted-color;
    }

    .step--incomplete.step--active & {
        color: $green;
    }

    .step--complete.step--inactive & {
        color: $green;
    }

    .step--active & {
        transition: color 0.3s ease-in-out;
        transition-delay: 0.5s;
    }
}
</style>
