<template>
    <div
        class="col p-0"
        :data-cy="'paymentsTable'"
    >
        <card
            class="no-border-card"
            body-classes="p-0"
            footer-classes="pb-2"
        >
            <div>
                <el-table
                    :data="queriedData"
                    row-key="deadlineDate"
                    header-row-class-name="thead-light"
                >
                    <el-table-column
                        v-for="column in tableColumns"
                        :key="column.label"
                        v-bind="column"
                        :formatter="column.formatter"
                    />

                    <el-table-column
                        align="right"
                        :label="$t('tables.column.actions')"
                        :min-width="100"
                    >
                        <div
                            slot-scope="{$index, row}"
                            class="d-flex flex-wrap"
                        >
                            <base-button
                                class="edit"
                                type="primary"
                                size="sm"
                                icon
                                :data-cy="`editPaymentId${row.id}`"
                                @click="openUpdatePaymentModal(row)"
                            >
                                <i class="fas fa-edit text-white" />
                            </base-button>

                            <base-button
                                :data-cy="`deletePaymentId${row.id}`"
                                class="remove btn-link"
                                type="danger"
                                size="sm"
                                icon
                                @click="openDeletePaymentModal(row.id)"
                            >
                                <i class="fas fa-trash-alt text-white" />
                            </base-button>
                        </div>
                    </el-table-column>
                </el-table>
            </div>
        </card>

        <UpdatePayment
            v-if="updatePaymentModal.showModal"
            :fiscal-document-id="parseInt($route.params.id)"
            :fiscal-document-number="fiscalDocumentNumber"
            :fiscal-document-amount-residual="fiscalDocumentAmountResidual"
            :show-modal="updatePaymentModal.showModal"
            :payment="updatePaymentModal.payment"
            @close-modal="updatePaymentModal.showModal = false"
            @data-changed="$emit('data-changed')"
        />

        <DeletePayment
            v-if="deletePaymentModal.showModal"
            :fiscal-document-id="parseInt($route.params.id)"
            :fiscal-document-number="fiscalDocumentNumber"
            :payment-id="deletePaymentModal.paymentId"
            @close-modal="deletePaymentModal.showModal = false"
            @data-changed="$emit('data-changed')"
        />
    </div>
</template>

<script>
import { Option, Select, Table, TableColumn } from "element-ui";
import clientPaginationMixin from "@/views/Mixins/clientPaginationMixin";
import BaseButton from "@/components/BaseButton";
import Modal from "@/components/Modal";
import formatterMixins from "@/mixins/formatterMixins";
import UpdatePayment from "@/views/Pages/Components/FiscalDocument/Modal/UpdatePayment";
import DeletePayment from "@/views/Pages/Components/FiscalDocument/Modal/DeletePayment";

export default {
    name: "PaymentsTable",
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        UpdatePayment,
        DeletePayment,
    },
    mixins: [
        clientPaginationMixin,
        BaseButton,
        Modal
    ],
    props: {
        tableData: {
            type: Array
        },
        fiscalDocumentNumber: {
            type: String
        },
        fiscalDocumentAmountResidual: {
            type: Number
        }
    },
    data() {
        return {
            tableColumns: [
                {
                    prop: "type",
                    label: this.$t("tables.column.paymentType"),
                    minWidth: 120,
                    sortable: true,
                    formatter: (row) => {
                        return row.type + ' | ' + this.$t(`choices.payments.type.${row.type}`);
                    }
                },
                {
                    prop: "amount",
                    label: this.$t("tables.column.amount"),
                    minWidth: 80,
                    sortable: true,
                    formatter: (row) => {
                        return formatterMixins.methods.formatCurrency(row.currency, row.amount);
                    }
                },
                {
                    prop: "date",
                    label: this.$t('tables.column.date'),
                    minWidth: 80,
                    sortable: true,
                    formatter: (row) => {
                        return row.date ? formatterMixins.methods.formatBackendDate(new Date(row.date)) : '';
                    }
                },
            ],
            updatePaymentModal: {
                showModal: false,
                payment: null
            },
            deletePaymentModal: {
                showModal: false,
                paymentId: null
            }
        };
    },
    methods: {
        openUpdatePaymentModal(payment) {
            this.updatePaymentModal.showModal = true;
            this.updatePaymentModal.payment = payment;
        },
        openDeletePaymentModal(paymentId) {
            this.deletePaymentModal.showModal = true;
            this.deletePaymentModal.paymentId = paymentId;
        }
    }
};
</script>
