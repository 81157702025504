<template>
    <div
        class="col p-0"
        :data-cy="'fiscalDocumentTable'"
    >
        <card
            class="m-0"
            body-classes="p-0"
            footer-classes="pb-2"
        >
            <v-app>
                <v-data-table
                    :data-cy="'activeFiscalDocumentsTable'"
                    :headers="tableColumns"
                    :items="tableData"
                    :server-items-length="totalElements"
                    :loading="loading"
                    :options="pagination"
                    :footer-props="footer"
                    must-sort
                    item-key="number"
                    class="elevation-1"
                    @update:options="$emit('update:pagination', $event)"
                >
                    <template #item.entity="{ item }">
                        <a
                            class="registry text-dark"
                            :href="`/registry/${item.sender.id}`"
                        >{{ item.sender.name }}</a>
                    </template>
                    <template
                        v-for="header in formattedColumns"
                        #[`item.${header.value}`]="{ column, value }"
                    >
                        {{ header.formatter(value) }}
                    </template>
                    <template #item.paymentAmount="{ item }">
                        <badge
                            class="badge-dot mr-4"
                        >
                            <i class="bg-brand-color label-contact-area">L</i>
                            <span> {{ formatCurrency(item.currency, item.totalImport) }}</span>
                        </badge>
                        <badge
                            class="badge-dot mr-4"
                        >
                            <i class="bg-brand-color label-contact-area">N</i>
                            <span> {{ formatCurrency(item.currency, item.netImport) }}</span>
                        </badge>
                    </template>
                    <template #item.paymentDeadlineDates="{ item }">
                        <badge
                            v-for="(paymentLine, index) in item.paymentLines"
                            v-show="paymentLine.deadlineDate !== null"
                            :key="'n' + index + 'deadlineDate-' + paymentLine.deadlineDate"
                            class="badge-dot mr-4"
                            type=""
                        >
                            <i :class="`bg-brand-color`" />
                            <span class="text-dark">{{ paymentLine.deadlineDate ? formatBackendDate(new Date(paymentLine.deadlineDate)): '' }}</span>
                        </badge>
                    </template>
                    <template #item.actions="{ item }">
                        <div
                            class="d-flex flex-wrap"
                        >
                            <base-button
                                v-if="canBePaid(item.status)"
                                :data-cy="'buttonCreatePaymentFiscalDocumentId'+item.id"
                                class="edit"
                                type="primary"
                                size="sm"
                                icon
                                @click="openCreatePaymentModal(item.id)"
                            >
                                <i class="fas fa-money-bill-alt" />
                            </base-button>
                            <base-button
                                :data-cy="'buttonDetailsInvoiceId'+item.id"
                                class="remove btn-link border"
                                type="secondary"
                                size="sm"
                                icon
                                @click="$router.push(
                                    {
                                        name:'FISCAL-DOCUMENT-DETAIL',
                                        params: {
                                            id: item.id
                                        }
                                    }
                                );"
                            >
                                <i class="fas fa-info-circle" />
                            </base-button>
                            <base-button
                                v-if="canBeCancelled(item.documentType, item.status, item.children)"
                                :data-cy="'buttonCancelFiscalDocumentId'+item.id"
                                class="remove btn-link border"
                                type="secondary"
                                size="sm"
                                icon
                                @click="openCancelModal(item.id)"
                            >
                                <i class="fas fa-eraser text-primary" />
                            </base-button>
                        </div>
                    </template>
                </v-data-table>
            </v-app>
        </card>

        <CreatePayment
            v-if="createPaymentModal.showModal"
            :fiscal-document-id="parseInt(createPaymentModal.fiscalDocumentId)"
            :fiscal-document-number="createPaymentModal.fiscalDocumentNumber"
            :fiscal-document-amount-residual="createPaymentModal.fiscalDocumentAmountResidual"
            :show-modal="createPaymentModal.showModal"
            :type="'passive'"
            @close-modal="createPaymentModal.showModal = false"
            @data-changed="$emit('data-changed')"
        />
        <CancelFiscalDocument
            v-if="cancelModal.showModal"
            :fiscal-document="cancelModal.fiscalDocument"
            @close-modal="cancelModal.showModal = false"
            @data-changed="$emit('data-changed')"
        />
    </div>
</template>

<script>
import { Option, Select, Table, TableColumn } from "element-ui";
import formatterMixins from "@/mixins/formatterMixins";
import labelsMixins from "@/mixins/labelsMixins";
import fiscalDocumentMixins from "@/mixins/fiscalDocumentMixins";
import CreatePayment from "@/views/Pages/Components/FiscalDocument/Modal/CreatePayment";
import CancelFiscalDocument from "@/views/Pages/Components/FiscalDocument/Modal/CancelFiscalDocument";

export default {
    name: "PassiveFiscalDocumentTable",
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        CreatePayment,
        CancelFiscalDocument,
    },
    mixins: [
        formatterMixins,
        labelsMixins,
        fiscalDocumentMixins
    ],
    props: {
        tableData: {
            type: Array
        },
        totalElements: {
            type: Number
        },
        pagination: {
            type: Object
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tableColumns: [
                {
                    value: "entity",
                    text: this.$t('tables.column.registry')
                },
                {
                    value: "number",
                    text: this.$t("tables.column.number"),
                },
                {
                    value: "documentType",
                    text: this.$t("tables.column.documentType"),
                    formatter: this.getLabelDocumentTypeFiscalDocumentByDocumentTypeCode
                },
                {
                    value: "status",
                    text: this.$t("tables.column.status").charAt(0).toUpperCase() + this.$t("tables.column.status").slice(1),
                    formatter: this.getLabelStatusFiscalDocumentByStatusCode
                },
                {
                    value: "paymentDate",
                    text: this.$t("tables.column.paymentDate"),
                    formatter: row => row ? this.formatBackendDate(new Date(row)) : '',
                },
                {
                    value: "date",
                    text: this.$t("tables.column.date"),
                    formatter: (row) => {
                        const date = new Date(row);
                        return this.formatBackendDate(date);
                    },
                },
                {
                    value: "paymentAmount",
                    text: this.$t("tables.column.paymentAmount"),
                },
                {
                    value: "paymentDeadlineDates",
                    text: this.$t('tables.column.paymentDeadlineDates')
                },
                {
                    value: "actions",
                    text: this.$t('tables.column.actions')
                }
            ],
            footer: {
                'items-per-page-options': [10, 20, 30]
            },
            createPaymentModal: {
                key: 0,
                fiscalDocumentId: null,
                fiscalDocumentNumber: null,
                fiscalDocumentAmountResidual: null,
                showModal: false,
            },
            cancelModal: {
                fiscalDocument: null,
                showModal: false,
            },
        };
    },
    computed: {
        formattedColumns() {
            return this.tableColumns.filter((column) => column.hasOwnProperty('formatter'));
        }
    },
    methods: {
        openCreatePaymentModal(id) {
            this.createPaymentModal.fiscalDocumentId = Number(id);
            const fiscalDocument = this.tableData.filter(fiscalDocument => fiscalDocument.id === id)[0];
            this.createPaymentModal.fiscalDocumentNumber = fiscalDocument.number;
            this.createPaymentModal.fiscalDocumentAmountResidual = parseFloat(fiscalDocument.amountResidual);
            this.createPaymentModal.showModal = true;
        },
        openCancelModal(id) {
            this.cancelModal.fiscalDocument = this.tableData.filter(fiscalDocument => fiscalDocument.id === id)[0];
            this.cancelModal.showModal = true;
        },
    }
};
</script>
