import DashboardLayout from "@/views/Layout/DashboardLayout";
import DetailFiscalDocument from "@/views/Pages/Components/FiscalDocument/Detail";
import FiscalDocument from "@/views/Pages/FiscalDocument";

const fiscalDocumentPages = {
    path: "/fiscalDocument",
    component: DashboardLayout,
    meta: {
        name: "pages.fiscalDocument.breadcrumb.fiscalDocuments",
    },
    children: [
        {
            path: ':id',
            component: DetailFiscalDocument,
            name: 'FISCAL-DOCUMENT-DETAIL',
            meta: {
                name: "pages.fiscalDocument.breadcrumb.detail",
                area: "INVOICE"
            }
        },
        {
            path: '',
            component: FiscalDocument,
            name: 'FISCAL-DOCUMENT-LIST',
            meta: {
                name: "pages.fiscalDocument.breadcrumb.fiscalDocumentList",
                area: "INVOICE"
            }
        },
    ],
};

export default fiscalDocumentPages;
