import ExpectedInvoices from "@/views/Pages/ExpectedInvoices";
import DashboardLayout from "@/views/Layout/DashboardLayout";

const expectedInvoicesPages = {
    path: "/expected-invoices",
    component: DashboardLayout,
    children: [{
        path: '/expected-invoices',
        component: ExpectedInvoices,
        name: 'EXPECTED-INVOICE',
        meta: {
            name: "pages.fiscalDocument.breadcrumb.expectedInvoices",
            area: "EXPECTED-INVOICE"
        }
    }]
};

export default expectedInvoicesPages;
