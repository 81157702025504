<template>
    <div class="content">
        <Header
            :title="$t('pages.pendingRegistry.title')"
        />

        <div class="container-fluid mt--6">
            <PendingRegistryTable
                :table-data="pendingRegistryTableData"
                @create-registry="openNewRegistry"
            />
        </div>

        <NewRegistry
            :key="'PendingRegistryNew' + newRegistryKey"
            :show-new="showNewRegistry"
            :pending-registry="pendingRegistry"
            @close-new="showNewRegistry = false;"
            @data-changed="loadData"
        />
        <LoadingBar v-show="loading" />
    </div>
</template>

<script>
import { pendingRegistryClient } from "@/clients/pendingRegistryClient";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import PendingRegistryTable from "@/views/Pages/Components/PendingRegistry/Table";
import Header from "@/components/Header";
import NewRegistry from "@/views/Pages/Components/Registry/Modal/New";

export default {
    name: 'PendingRegistry',
    components: {
        LoadingBar,
        PendingRegistryTable,
        Header,
        NewRegistry
    },
    mixins: [
        exceptionsMixins,
    ],
    data() {
        return {
            loading: false,
            pendingRegistryTableData: [],
            pendingRegistry: null,
            showNewRegistry: false,
            newRegistryKey: 0,
        };
    },
    created() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.loading = true;
            pendingRegistryClient.getByFilters().then((response) => {
                this.pendingRegistryTableData = response.data;
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loading = false;
            });
        },
        openNewRegistry(id) {
            this.newRegistryKey++;
            this.pendingRegistry = this.pendingRegistryTableData.filter(pendingRegistry => pendingRegistry.id === id)[0];
            this.showNewRegistry = true;
        },
    }
}
</script>
