import { localeService } from "@/services/localeService";
import { authenticationService } from "@/services/authenticationService";

/**
 * headersBuilder.
 */
export const headersBuilder = {
    /**
     * Build the headers for the request that not need to provide the authentication tokens.
     *
     * @returns {{"Accept-Language": string, "Content-Type": string}}
     */
    buildHeaders() {
        return {
            "Content-Type": "application/json",
            "Accept-Language": localeService.getLocale(),
        }
    },
    /**
     * Build the headers for the request that need authentication token.
     *
     * @returns {{"Accept-Language": string, "Authorization": *, "Content-Type": string}}
     */
    buildAuthenticatedHeaders() {
        return this.buildHeadersByTokens(authenticationService.getToken());
    },
    /**
     * Build the headers for the request that need authentication token by provided ones.
     *
     * @param token
     *
     * @returns {{"Accept-Language": string, "Authorization": *, "Content-Type": string}}
     */
    buildHeadersByTokens(token) {
        if (!token || 0 === token.length) {
            throw 'BuildHeadersByToken exception token must be provided.';
        }

        const baseHeaders = this.buildHeaders();

        return {
            ...baseHeaders,
            Authorization: 'Bearer ' + token,
        };
    },
}
