<template>
    <div>
        <div
            v-show="showCodeMessage"
            class="text-center"
            :data-cy="'messageCodeCountDownTimer'"
        >
            <small>{{
                $t("authentications.codeValidity", {
                    telephoneNumber:
                        computeObscuredTelephoneNumber, seconds: countDown
                })
            }} </small>
        </div>

        <slot>
            <!-- Here fill with the provided submit button -->
        </slot>

        <div
            v-if="retrySendCode"
            class="text-center"
            :data-cy="'actionRetrySendCode'"
            @click="sendAnotherCode"
        >
            <i
                v-if="retryCodeSpinner"
                class="fa fa-spinner fa-pulse fa-3x fa-fw"
            />
            <small
                v-if="retrySendingCodeMessageElement"
            >
                {{ $t('authentications.missingCode') + ' ' }} <a
                    href="#"
                ><b>{{ $t('authentications.retrySendingCodeMessage') }}</b></a>
            </small>
        </div>
    </div>
</template>

<script>
import { AuthenticationConfigurationsData } from "@/constants/authenticationConstants";
import { authenticationService } from "@/services/authenticationService";
import { authenticationClient } from "@/clients/authenticationClient";
import store from "@/main";
import companyMixins from "@/mixins/companyMixins";

export default {
    name: "CodeCountDownTimer",
    mixins: [companyMixins],
    props: {
        lastThreeNumberOfTelephoneNumber: {
            type: String,
            required: true,
            validator: val => val.length === 3,
        },
    },
    data() {
        return {
            sendCodeAttempts: 0,
            countDown: 60,
            timeout: 60,
            retrySendCode: false,
            retrySendingCodeMessageElement: false,
            retryCodeSpinner: false,
            showCodeMessage: true,
        };
    },
    computed: {
        computeObscuredTelephoneNumber() {
            return 'xxxxxxx' + this.lastThreeNumberOfTelephoneNumber;
        }
    },
    mounted() {
        this.countDownTimer();
    },
    methods: {
        restartCountDownMessageAndTimer() {
            this.showCodeMessage = true;
            this.retrySendCode = false;
            this.countDownTimer();
        },
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeout = setTimeout(() => {
                    this.countDown -= 1;
                    this.countDownTimer();
                }, 1000);
            } else {
                if (this.sendCodeAttempts > AuthenticationConfigurationsData.MAX_ATTEMPTS) {
                    store.dispatch("moveToTechnicalAssistanceStep");
                } else {
                    this.sendCodeAttempts++;
                    this.showCodeMessage = false;
                    this.retrySendCode = true;
                    this.retrySendingCodeMessageElement = true;
                    this.countDown = 60;
                }
            }
        },
        sendAnotherCode() {
            if (authenticationService.getTwoFactorToken() === null) {
                this.retryCodeSpinner = true;
                this.retrySendingCodeMessageElement = false;

                authenticationClient
                    .refreshTwoFactorCode({
                        token: authenticationService.getToken(),
                        companyName: this.getCompanyName(),
                    }).then(() => {
                        this.retryCodeSpinner = false;
                        this.restartCountDownMessageAndTimer();
                    }).catch(() => {
                        store.dispatch('moveToTechnicalAssistanceStep');
                    });
            }
        },
    }
};
</script>


