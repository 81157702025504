<template>
    <div class="new-modal">
        <modal
            :show="showNew"
            modal-classes="modal-secondary"
            :size="'lg'"
            :data-cy="'newBankModal'"
            @close="closeNew"
        >
            <template slot="header">
                <h1>{{ $t('actions.creating') + ' ' + $t("pages.bank.bank") }} </h1>
            </template>
            <validation-observer
                v-slot="{handleSubmit}"
                ref="formValidator"
            >
                <form
                    class="new-event-form"
                    @submit.prevent="handleSubmit(newBank)"
                >
                    <div class="form-group container">
                        <div class="row">
                            <base-input
                                v-model="model.name"
                                :label="$t('forms.fields.name')"
                                :placeholder="$t('forms.fields.name')"
                                class="col-12"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.name')"
                                :rules="{required:true}"
                                :data-cy="'newBankNameField'"
                            />

                            <base-input
                                v-model="model.swiftBic"
                                :label="$t('forms.fields.swiftBic')"
                                class="col-6"
                                :placeholder="$t('forms.fields.swiftBic')"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.swiftBic')"
                                :rules="{
                                    required:true,
                                    alpha_num: true
                                }"
                                :data-cy="'newBankSwiftBicField'"
                            />
                            <base-input
                                v-model="model.locationAddress"
                                :label="$t('forms.fields.address')"
                                class="col-6"
                                :placeholder="$t('forms.actions.insert') + ' ' + $t('forms.fields.address')"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.address')"
                                :data-cy="'newBankAddressField'"
                            />
                            <base-input
                                v-model="model.locationCity"
                                :label="$t('forms.fields.city')"
                                class="col-6"
                                :placeholder="$t('forms.actions.insert') + ' ' + $t('forms.fields.city')"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.city')"
                                :data-cy="'newBankCityField'"
                            />
                            <base-input
                                v-model="model.locationZip"
                                :label="$t('forms.fields.zip')"
                                class="col-6"
                                :placeholder="$t('forms.actions.insert') + ' ' + $t('forms.fields.zip')"
                                input-classes="form-control-alternative"
                                :rules="{max:20}"
                                :name="$t('forms.fields.zip')"
                                :data-cy="'newBankZipField'"
                            />
                            <base-input
                                v-model="model.locationState"
                                :label="$t('forms.fields.state')"
                                class="col-6"
                                :placeholder="$t('forms.actions.insert') + ' ' + $t('forms.fields.state')"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.state')"
                                :rules="{min:2,max:2}"
                                :data-cy="'newBankStateField'"
                            />
                            <base-input
                                :label="$t('forms.fields.country')"
                                class="col-6"
                                :placeholder="$t('forms.actions.select') + ' ' + $t('forms.fields.country')"
                                input-classes="form-control-alternative"
                                :data-cy="'newBankCountryField'"
                                :name="$t('forms.fields.country')"
                            >
                                <v-select
                                    v-model="model.locationCountry"
                                    class="form-control form-control-alternative"
                                    :options="countryList"
                                    :reduce="country => country.code"
                                />
                            </base-input>
                        </div>
                        <div class="d-flex justify-content-end">
                            <base-button
                                native-type="submit"
                                type="primary"
                                class="new-event--add"
                                :data-cy="'createNewBankButton'"
                                :disabled="creating"
                                :loading="creating"
                            >
                                {{ $t("actions.new") }}
                            </base-button>
                            <base-button
                                type="link"
                                @click="closeNew"
                            >
                                {{ $t("actions.cancel") }}
                            </base-button>
                        </div>
                    </div>
                </form>
            </validation-observer>
        </modal>
    </div>
</template>

<script>
import { bankClient } from "@/clients/bankClient";
import { countryList } from "@/util/country-list";
import { ValidationObserver } from "vee-validate";
import BaseButton from "@/components/BaseButton";
import Modal from "@/components/Modal";
import BankDto from "@/clients/dto/BankDto";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import vSelect from 'vue-select';

export default {
    name: "NewBank",
    components: {
        BaseButton,
        Modal,
        ValidationObserver,
        vSelect
    },
    mixins: [
        exceptionsMixins
    ],
    props: {
        showNew: {
            type: Boolean,
        }
    },
    data() {
        return {
            model: {
                name: null,
                swiftBic: null,
                locationAddress: null,
                locationCity: null,
                locationState: null,
                locationZip: null,
                locationCountry: null,
            },
            loaded: false,
            creating: false,
        }
    },
    computed: {
        countryList() {
            return countryList;
        }
    },
    methods: {
        closeNew() {
            this.$emit('close-new');
        },
        newBank() {
            this.creating = true;
            const bankDto = new BankDto(
                this.model.name,
                this.model.swiftBic,
                this.model.locationAddress,
                this.model.locationCity,
                this.model.locationState,
                this.model.locationZip,
                this.model.locationCountry
            );

            bankClient.createBank(bankDto).then((response) => {
                this.$notify({
                    type: "success",
                    icon: "ni ni-notification-70",
                    message: this.$t("pages.bank.notifications.success.created")
                });
                this.$emit('added', response.data.id);
                this.closeNew();
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.creating = false;
            });
        }
    }
}
</script>
