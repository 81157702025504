import DashboardLayout from "@/views/Layout/DashboardLayout";
import FirstAccounting from "@/views/Pages/FirstAccounting";

const firstAccountingPage = {
    path: "/firstAccounting",
    component: DashboardLayout,
    meta: {
        name: "pages.firstAccounting.breadcrumb.accounting",
    },
    children: [
        {
            path: '',
            name: 'FIRST-ACCOUNTING',
            component: FirstAccounting,
            meta: {
                name: "pages.firstAccounting.breadcrumb.firstAccountingPage",
                area: "FIRST-ACCOUNTING"
            }
        },
    ]
};

export default firstAccountingPage;
