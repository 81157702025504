<template>
    <div
        class="col p-0"
        :data-cy="'pendingRegistryTable'"
    >
        <card
            class="m-0"
            body-classes="p-0"
            footer-classes="pb-2"
        >
            <BaseTable
                :table-data="tableData"
                :search-keys="[
                    'name',
                ]"
            >
                <el-table-column
                    v-for="column in tableColumns"
                    :key="column.label"
                    v-bind="column"
                    :formatter="column.formatter"
                />
                <el-table-column
                    align="right"
                    :label="$t('tables.column.actions')"
                    :min-width="80"
                >
                    <div
                        slot-scope="{$index, row}"
                        class="d-flex flex-wrap"
                    >
                        <base-button
                            :data-cy="'buttonCreatePendingRegistryId'+row.id"
                            class="edit"
                            type="primary"
                            size="sm"
                            icon
                            :title="$t('pages.pendingRegistry.tooltips.create')"
                            @click="openNewRegistryWithPendingRegistry(row.id)"
                        >
                            <i class="fas fa-plus-square text-white" />
                        </base-button>
                    </div>
                </el-table-column>
            </BaseTable>
        </card>
    </div>
</template>

<script>
import { Option, Select, Table, TableColumn } from "element-ui";
import formatterMixins from "@/mixins/formatterMixins";
import BaseTable from "@/views/Pages/Components/Table/BaseTable";

export default {
    name: 'PendingRegistryTable',
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        BaseTable
    },
    mixins: [
        formatterMixins
    ],
    props: {
        tableData: {
            type: Array
        }
    },
    data() {
        return {
            tableColumns: [
                {
                    prop: "name",
                    label: this.$t("tables.column.name"),
                    minWidth: 110,
                    sortable: true,
                },
                {
                    prop: "taxRegime",
                    label: this.$t("tables.column.taxRegime"),
                    minWidth: 80,
                    sortable: true,
                },
                {
                    prop: "vat",
                    label: this.$t("tables.column.vat"),
                    minWidth: 80,
                },
                {
                    prop: "nationalIdentificationNumber",
                    label: this.$t("tables.column.nationalIdentificationNumber"),
                    minWidth: 80,
                }
            ],
        };
    },
    methods: {
        openNewRegistryWithPendingRegistry(id) {
            this.$emit('create-registry', id);
        },
    }
}
</script>
