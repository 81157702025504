export const IT_TRANSLATIONS = {
    authentications: {
        login: 'Accedi',
        wrongCredentials: 'Credenziali errate',
        codeButton: 'Invia codice',
        invalidCode: 'Codice non valido',
        userNotExists: 'L\'utente non esiste!',
        codeValidity: "Il codice inviato al numero %{telephoneNumber} è valido ancora per %{seconds} secondi, al termine dei quali potrai richiedere un nuovo codice",
        missingCode: "Il codice non ti è arrivato?",
        retrySendingCodeMessage: "Richiedi un nuovo codice",
        codeInputPlaceholder: "Inserisci il codice ricevuto via SMS",
        returnToLogin: "Torna al login",
        assistanceMessage: "Contatta l'assistenza per supporto tecnico.",
        logout: "Logout",
        logoutMessage: "Sei sicuro di voler effettuare la logout?",
        abort: "Annulla",
    },
    actions: {
        new: 'Crea',
        delete: 'Elimina',
        update: 'Modifica',
        search: 'Cerca',
        cancel: 'Annulla',
        creating: 'Creazione',
        seeDetails: 'Vedi dettagli',
        next: 'Prosegui',
        add: 'Aggiungi',
        back: 'Indietro',
        reset: 'Reset',
        register: 'Registra',
        loadMore: 'Carica altro...'
    },
    generalTerms: {
        searchTerms: {
            from: 'Da',
            to: 'a',
            on: 'su',
        },
    },
    modalMessages: {
        deleteMessage: 'Sei sicuro di voler procedere con l\'eliminazione?',
        unrecoverableOperationMessage: 'Questa operazione non è reversibile.',
    },
    forms: {
        actions: {
            select: 'Seleziona',
            insert: 'Inserisci',
        },
        fields: {
            creditNote: 'Nota di credito',
            withholding: 'Ritenuta d\'acconto',
            withholdingLines: 'Ritenute d\'acconto',
            publisher: 'Editore',
            amountPaid: 'Importo pagato',
            amountResidual: 'Importo da pagare',
            immediate: 'Immediato',
            endOfMonth: 'Fine mese',
            customizable: 'Personalizzabile',
            cashFlowBankCommission: 'Commissione bancaria',
            lineNumber: 'Numero linea',
            totalImport: 'Importo Totale',
            totalPrice: 'Totale',
            attachments: 'Allegati',
            name: 'Nome',
            swiftBic: 'SWIFT BIC',
            state: 'Provincia',
            country: 'Stato',
            address: 'Indirizzo',
            city: 'Città',
            email: 'Email',
            zip: 'Codice postale',
            telephone: 'Telefono',
            telephoneNumberPlaceholder: 'Numero di telefono',
            type: 'Tipo',
            tag: 'Tag',
            vat: 'P.IVA',
            vatAmount: 'IVA',
            nationalIdentificationNumber: 'Identificativo nazionale',
            taxRegime: 'Regime tasse',
            feCode: 'Codice Destinatario FE',
            beneficiaryName: 'Nome beneficiario',
            iban: 'IBAN',
            bank: 'Banca',
            administrativeContact: 'Contatto amministrativo',
            technicalContact: 'Contatto tecnico',
            sender: 'Mittente',
            recipient: 'Destinatario',
            collectionDate: 'Data Incasso',
            paymentDate: 'Data Pagamento',
            description: 'Descrizione',
            amount: 'Importo',
            amountTaxIncluded: 'Importo (Lordo)',
            tax: 'Importo tassa',
            currency: 'Valuta',
            status: 'Stato',
            docType: 'Tipo documento',
            date: 'Data',
            paymentDeadlineDate: 'Data scadenza pagamento',
            paymentType: 'Tipo pagamento',
            paymentMode: 'Modalità pagamento',
            paymentIban: 'IBAN',
            paymentAmount: 'Importo pagamento',
            invoiceAmount: 'Totale fattura',
            invoicePrice: 'Prezzo totale',
            taxableImport: 'Imponibile',
            number: 'Numero',
            documentType: 'Tipo documento',
            dateRange: 'Intervallo di tempo',
            detailLine: 'Linea di dettaglio',
            sendEmail: 'Invia email',
            quantity: 'Quantità',
            unitPrice: 'Prezzo (Netto)',
            paymentDeadLineDays: 'Giorni alla scadenza del pagamento',
            relatedInvoice: 'Fattura allegata',
            showRevenueStamp: 'Aggiungi bollo',
            revenueStampAmount: 'Importo Bollo',
            bankFee: 'Importo commissione',
            purpose: 'Causale',
            vatEnforceability: 'Esecutività IVA',
            nature: 'Natura',
            expenseDate: 'Data pagamento'
        },
        section: {
            detailLines: 'Linee di dettaglio',
            summary: 'Riepilogo',
            summaryLines: 'Linee di riepilogo',
            attachments: 'Allegati',
        },
        validationMessage: {
            invalidVat: 'La P.IVA inserita non è valida!',
            invalidIban: 'L\'IBAN inserito non è valido!'
        },
        helper: {
            vatWithCountryMessage: 'Selezionare lo stato per inserire la P.IVA'
        }
    },
    menu: {
        registry: 'Anagrafica',
        registries: 'Anagrafiche',
        banks: 'Banche',
        contacts: 'Contatti',
        cashflow: 'Entrate - Uscite',
        fiscalDocument: 'Documenti Fiscali',
        expectedInvoices: 'Guadagni editori',
        pendingRegistries: 'Anagrafiche in attesa',
        firstAccounting: 'Prima Nota',
        homepage: 'Home',
    },
    pages: {
        homepage: {
            title: 'Homepage',
            widgets: {
                netTurnover: 'Ricavi',
                totalCosts: 'Costi',
                businessProfit: 'Utile d\'impresa',
                profitabilityIndex: 'Indice redditività',
                receivablesFromCustomers: 'Crediti',
                payablesToSuppliers: 'Debiti',
                sinceLastMonth: 'ultimi 31 giorni'
            },
            grossRevenuesAndCost: 'Ricavi e costi',
            netRevenues: 'Utile',
            legends: {
                grossRevenues: 'Ricavo',
                cost: 'Costo',
                netRevenues: 'Utile'
            }
        },
        firstAccounting: {
            breadcrumb: {
                accounting: 'Contabilità',
                firstAccountingPage: 'Prima Nota'
            },
            title: 'Prima Nota',
            detailColumn: {
                cashFlow: {
                    income: 'Entrata',
                    outcome: 'Uscita'
                },
                invoice: 'Fattura',
                creditNote: 'N.D.C.'
            },
            incomes: 'Entrate',
            outcomes: 'Uscite',
            source: 'Fonte',
            recipient: 'Destinatario',
        },
        expectedInvoices: {
            title: 'Guadagni editori'
        },
        fiscalDocument: {
            cancelTitle: 'Storno fattura',
            cancelMessage: 'Stai contrassegnando la fattura %{number} come stornata.',
            attachmentsAdded: 'Allegati caricati correttamente.',
            fiscalDocument: 'Documento fiscale',
            expectedInvoices: 'Guadagni editori',
            creationQuestion: 'Creazione documento fiscale',
            passive: 'Uscite',
            active: 'Entrate',
            emitInvoice: 'Emetti fattura',
            registerCreditNote: 'Registra nota di credito',
            registerInvoice: 'Registra fattura',
            emitCreditNote: 'Emetti nota di credito',
            creating: {
                emitInvoice: 'Emessione fattura',
                registerCreditNote: 'Registrazione nota di credito',
                registerInvoice: 'Registrazione fattura',
                emitCreditNote: 'Emessione nota di credito',
                registryWithoutLocationTitle: 'Dati mancanti',
                registryWithoutLocationMessage:
                    'L\'anagrafica %{registryName} non ha i dati relativi alla locazione e per questo motivo non è possibile proseguire con la creazione del documento fiscale.',
                registryWithoutLocationCtaMessage: 'Cliccando sul pulsante si aprirà la schermata per inserire questi dati.'
            },
            cantFindRegistryQuestion: 'Non trovi l\'anagrafica? Clicca sul bottone qui di fianco per aggiungerla.',
            tabs: {
                title: {
                    active: 'Inviate',
                    passive: 'Ricevute'
                }
            },
            title: 'Documenti fiscali',
            breadcrumb: {
                detail: 'Dettaglio documenti fiscali',
                fiscalDocuments: 'Documenti fiscali',
                fiscalDocumentList: 'Lista documenti fiscali',
                expectedInvoices: 'Lista guadagni editore',
            },
            detailPage: {
                title: 'Dettaglio documento fiscale: ',
                detailLines: 'Dettaglio',
                summaryLines: 'Riepilogo IVA',
                attachments: 'Allegati',
                creditNotes: 'Note di credito',
                invoice: 'Fattura',
                paymentLines: 'Scadenze pagamenti',
                receivedPayments: 'Pagamenti ricevuti',
                madePayments: 'Pagamenti effettuati',
                noChildren: 'Nessun documento trovato.',
                noAttachments: 'Nessun allegato.',
                noPayments: 'Nessun pagamento registrato.',
                noPaymentLines: 'Non sono presenti scadenze pagamenti.',
                withholdingLines: 'Dettaglio ritenute d\'acconto',
            },
            notifications: {
                success: {
                    created: 'Documento fiscale creato correttamente!'
                }
            },
            numberUsed: 'Cliccando sul pulsante si andrà a creare il documento fiscale utilizzando il seguente numero %{nextNumber}.',
            createPayment: 'Registra pagamento',
            createPaymentMessage: 'Stai registrando un pagamento per il documento: ',
            showBankFee: 'Aggiungi commissione bancaria',
            residualImport: 'Importo residuo',
            updatePayment: 'Modifica pagamento',
            updatePaymentMessage: 'Stai modificando un pagamento per il documento: ',
            deletePaymentTitle: 'Eliminazione pagamento',
            deletePaymentMessage: 'Stai eliminando il pagamento per il documento: %{number}'
        },
        bank: {
            breadcrumb: {
                detail: 'Dettaglio banca',
                bankList: 'Lista banche',
                banks: 'Banche',
            },
            bank: 'Banca',
            location: 'Posizione',
            title: 'Registro banche',
            notifications: {
                success: {
                    created: 'Creazione banca effettuata con successo.',
                },
            },
            deleteMessage: 'Stai per eliminare la banca {bankName}.',
            deleteModalTitle: 'Eliminazione banca',
            detailPage: {
                title: 'Dettaglio banca: ',
            }
        },
        contact: {
            breadcrumb: {
                detail: 'Dettaglio contatto',
                contactList: 'Lista contatti',
                contacts: 'Contatti',
            },
            contact: 'Contatto',
            title: 'Registro contatti',
            deleteModalTitle: 'Eliminazione contatto',
            deleteMessage: 'Stai per eliminare il contatto {contactName}.',
            notifications: {
                success: {
                    created: 'Creazione contatto effettuata con successo.',
                },
            },
            associatedRegistries: 'Registri associati',
            detailPage: {
                title: 'Dettaglio contatto: ',
                administrativeAssociated: 'Contatto amministrativo per i seguenti registri',
                technicalAssociated: 'Contatto tecnico per i seguenti registri',
            }
        },
        registry: {
            breadcrumb: {
                detail: 'Dettaglio anagrafica',
                registryList: 'Lista anagrafiche',
                registries: 'Anagrafiche',
            },
            registry: 'Anagrafica',
            title: 'Anagrafiche',
            bankAccount: 'Dati bancari',
            contacts: 'Contatti',
            deleteMessage: 'Stai per eliminare l\'anagrafica {registryName}.',
            deleteModalTitle: 'Eliminazione anagrafica',
            detailPage: {
                title: 'Dettaglio anagrafica: ',
                bankDetails: 'Informazioni bancarie',
                contactsAssociated: 'Contatti associati',
                administrativeAssociated: 'Contatto amministrativo per i seguenti registri',
                technicalAssociated: 'Contatto tecnico per i seguenti registri',
                cashFlows: 'Entrate - Uscite',
                fiscalDocuments: 'Documenti fiscali',
                alertLocationMessage: 'Per poter creare documenti fiscali è necessario aggiungere i dati relativi alla locazione.',
                expectedInvoices: 'Lista guadagni editore',
            },
            cantFindBankQuestion: 'Non trovi la banca? Clicca sul bottone qui di fianco per aggiungerla.',
            cantFindContactQuestion: 'Non trovi il contatto? Clicca sul bottone qui di fianco per aggiungerlo.',
            recapStepTitle: 'Riepilogo dati inseriti',
            notifications: {
                success: {
                    created: 'Creazione anagrafica effettuata con successo.',
                },
            },
            editTabs: {
                location: 'Posizione',
                bank: 'Dati Bancari',
                contact: 'Contatti'
            }
        },
        pendingRegistry: {
            breadcrumb: {
                pendingRegistries: 'Anagrafiche in attesa',
                pendingRegistryList: 'Lista anagrafiche in attesa'
            },
            title: 'Anagrafiche in attesa',
            tooltips: {
                create: 'Crea una nuova anagrafica'
            }
        },
        cashFlow: {
            breadcrumb: {
                detail: 'Dettaglio entrate - uscite',
                cashFlowList: 'Lista entrate - uscite',
                cashFlows: 'Entrate - Uscite',
            },
            detailPage: {
                title: 'Dettaglio',
                attachments: 'Allegati',
            },
            title: 'Entrate - Uscite',
            cashFlow: {
                incoming: 'Entrata',
                outcoming: 'Uscita'
            },
            creationQuestion: 'Vuoi creare una entrata o uscita?',
            deleteModalTitle: {
                incoming: 'Eliminazione entrata',
                outcoming: 'Eliminazione uscita'
            },
            deleteMessage: {
                incoming: 'Stai per eliminare l\'entrata: %{number}.',
                outcoming: 'Stai per eliminare l\'uscita: %{number}.'
            },
            numberUsed: 'Cliccando sul pulsante si andrà a creare utilizzando il seguente numero %{nextNumber}.',
            deleteAttachment: {
                title: 'Eliminazione allegato',
                message: 'Stai eliminando l\'allegato %{attachmentName}',
                warningMessage: 'L\'eliminazione dell\'allegato è permanente!',
            },
            notification: {
                created: 'Registrazione effettuata con successo.',
                cantCreateBankCommission: 'Non è stato possibile creare l\'uscita per la commissione bancaria. Procedere alla registrazione manualmente.'
            }
        },
    },
    tables: {
        column: {
            registry: 'Anagrafica',
            paymentDeadlineDates: 'Scadenze',
            iban: 'IBAN',
            paymentType: 'Tipo',
            paymentMode: 'Modalità',
            actions: 'Azioni',
            swiftBic: 'SWIFT BIC',
            name: 'Nome',
            email: 'Email',
            telephone: 'Telefono',
            type: 'Tipo',
            rate: 'Rate',
            purpose: 'Scopo',
            country: 'Stato',
            vat: 'P.IVA',
            vatAmount: 'Iva',
            tag: 'Tag',
            sender: 'Mittente',
            recipient: 'Destinatario',
            expenseDate: 'Emissione',
            amount: 'Importo',
            number: 'Numero',
            description: 'Descrizione',
            unitPrice: 'Prezzo unitario',
            quantity: 'Quantità',
            totalPrice: 'Prezzo totale',
            paymentAmount: 'Importo',
            paymentDeadlineDate: 'Scadenza',
            status: 'stato',
            filters: 'Filtri',
            documentType: 'Tipo doc.',
            date: 'Data',
            createdDate: 'Data creazione',
            taxRegime: 'Regime',
            nationalIdentificationNumber: 'Identificativo nazionale',
            detail: 'Dettaglio',
            source: 'Fonte',
            incomeAmount: 'Entrata',
            outcomeAmount: 'Uscita',
            creditor: 'Creditore',
            debtor: 'Debitore',
            paymentDate: 'Data pagamento',
            taxableImport: 'Importo tassabile',
            taxAmount: 'Tasse',
            vatEnforceability: 'E. IVA',
            nature: 'Natura',
            year: 'Anno',
            month: 'Mese'
        },
        monthNumberToMonthName: {
            1: 'Gennaio',
            2: 'Febbraio',
            3: 'Marzo',
            4: 'Aprile',
            5: 'Maggio',
            6: 'Giugno',
            7: 'Luglio',
            8: 'Agosto',
            9: 'Settembre',
            10: 'Ottobre',
            11: 'Novembre',
            12: 'Dicembre'
        }
    },
    notifications: {
        success: {
            updated: 'Aggiornamento effettuato con successo.',
            deleted: 'Eliminazione effettuata con successo.'
        },
        failed: {
            noRegistryOwned: 'Nessun Registry gestito',
            noRegistryOwnedMessage: 'Non abbiamo trovato nessun Registry gestito, contattare l\'assistenza notificando questo messaggio.',
            technicalProblem: 'Errore tecnico contattare l\'assistenza.',
        },
    },
    timeTerms: {
        days: "Giorni",
        month: "Mese",
        months: "Mesi",
        to: " al ",
    },
    dates: {
        periods: {
            sevenDays: "7 giorni",
            oneMonth: "1 mese",
            twoMonths: "2 mesi",
            threeMonths: "3 mesi",
            sixMonths: "6 mesi",
            yesterday: "Ieri",
            thisMonth: "Mese corrente",
            lastMonth: "Mese scorso",
            today: "Oggi",
        }
    },
    choices: {
        taxRegime: {
            RF01: "Ordinario",
            RF02: "Contribuenti minimi (art.1, c.96-117, L. 244/07)",
            RF04: "Agricoltura e attività connesse e pesca (artt.34 e 34-bis, DPR 633/72)",
            RF05: "Vendita sali e tabacchi (art.74, c.1, DPR. 633/72)",
            RF06: "Commercio fiammiferi (art.74, c.1, DPR  633/72)",
            RF07: "Editoria (art.74, c.1, DPR  633/72)",
            RF08: "Gestione servizi telefonia pubblica (art.74, c.1, DPR 633/72)",
            RF09: "Rivendita documenti di trasporto pubblico e di sosta (art.74, c.1, DPR  633/72)",
            RF10: "Intrattenimenti, giochi e altre attività di cui alla tariffa allegata al DPR 640/72 (art.74, c.6, DPR 633/72)",
            RF11: "Agenzie viaggi e turismo (art.74-ter, DPR 633/72)",
            RF12: "Agriturismo (art.5, c.2, L. 413/91)",
            RF13: "Vendite a domicilio (art.25-bis, c.6, DPR  600/73)",
            RF14: "Rivendita beni usati, oggetti d’arte, d’antiquariato o da collezione (art.36, DL 41/95)",
            RF15: "Agenzie di vendite all’asta di oggetti d’arte, antiquariato o da collezione (art.40-bis, DL 41/95)",
            RF16: "IVA per cassa P.A. (art.6, c.5, DPR 633/72)",
            RF17: "IVA per cassa (art. 32-bis, DL 83/2012)",
            RF18: "Altro",
            RF19: "Regime forfettario (art.1, c.54-89, L. 190/2014)",
        },
        cashFlow: {
            types: {
                TP01: 'Cassa',
                TP02: 'Conto Corrente',
                TP03: 'Non definito',
                TP04: 'Carta di credito',
            },
            tags: {
                TG01: 'Ristorazione e viaggi',
                TG02: 'Marketing',
                TG03: 'Utenze, bollette e abbonamenti',
                TG04: 'Server',
                TG05: 'Commissioni e spese banca',
                TG06: 'Provvigioni agenti',
                TG07: 'Stipendi',
                TG08: 'Posta e cancelleria',
                TG09: 'Produzione contenuti',
                TG10: 'DN',
                TG11: 'Pagamenti editori',
                TG12: 'Tasse',
                TG13: 'Non definito',
                TG14: 'SSP',
                TG15: 'Software',
            }
        },
        fiscalDocument: {
            vatNature: {
                VN0: '24%',
                VN1: '23%',
                VN2: '22%',
                VN3: '22% - Esigibilita differita Art. 6 comma 5 D.P.R 633/72',
                VN4: '21%',
                VN5: '20%',
                VN6: '8%',
                VN7: '5%',
                VN8: '4%',
                VN9: '0% - N4',
                VN10: '0% - Regime dei minimi - N2.2',
                VN11: '0% - Fuori campo IVA - N2.2',
                VN12: '0% - Oper. non soggetta, art.7 ter - N2.1',
                VN13: '0% - Inversione contabile, art.7 ter - N6.9',
                VN14: '0% - Non Imponibile - N1',
                VN15: '0% - Non Imp. Art.8 - N3.1',
                VN16: '0% - Non Imp. Art.9 1C - N3.4',
                VN17: '0% - Non Imp. Art.14 Legge 537/93 - N3.6',
                VN18: '0% - Non Imp. Art.41 D.P.R. 331/93 - N3.2',
                VN19: '0% - Non Imp. Art.72, D.P.R. 633/72 - N3.4',
                VN20: '0% - Non Imp. Art.74 quotidiani/libri - N2.2',
                VN21: '0% - Escluso Art.10 - N4',
                VN22: '0% - Escluso Art.13 5C DPR 633/72 - N1',
                VN23: '0% - Escluso Art.15 - N1',
                VN24: '0% - Escluso Art.74 ter D.P.R. 633/72 - N5',
                VN25: '0% - Escluso Art.10 comma 1 - N4',
                VN26: '0% - Escluso Art.10 comma 20 - N4',
                VN27: '0% - Non Imp. Art.9 - N3.4',
                VN28: '0% - Escluso Art.10 n.27 D.P.R 633/72 - N4',
                VN29: '0% - Regime del margine art.36 41/95 - N5',
                VN30: '0% - Escluso Art.3 comma 4 D.P.R 633/72 - N1',
                VN31: '0% - Escluso Art.15/1c D.P.R 633/72 - N1',
                VN32: '0% - Non imp. Art.8/c D.P.R. 633/72 - N3.5',
                VN33: '0% - Non Imp. Art.7 ter - N2.1',
                VN34: '0% - Escluso Art.7 D.P.R 633/72 - N2.1',
                VN35: '0% - Escluso Art.10 comma 9 - N4',
                VN36: '0% - Non imp. Art.7 quater DPR 633/72 - N2.1',
                VN37: '0% - Non Imp. Art.8 comma 1A - N3.1',
                VN38: '0% - Fuori Campo IVA Art.7 ter D.P.R 633/72 - N2.1',
                VN39: '0% - Non Imp. Art.10 n.18 DPR 633/72 - N4',
                VN40: '0% - Esente Art.10 DPR 633/72 - N4',
                VN41: '0% - Non imp. art.40 D.L. 427/93 - N3.6',
                VN42: '0% - Non imp. art.41 D.L. 427/93 - N3.2',
                VN43: '0% - Non imp. art.8 DPR 633/72 - N3.1',
                VN44: '0% - Non imp. art.9 DPR 633/72 - N3.4',
                VN45: '0% - Regime minimi 2015 - N2.2',
                VN46: '0% - Non soggetta IVA - N2.2',
                VN47: '0% - R.C. art. 74/7-8 rottami e metalli ferrosi e non - N6.1',
                VN48: '0% - R.C. art. 17/5 materiale oro e argento - N6.2',
                VN49: '0% - R.C. art. 17/6/a settore edile subappalto - N6.3',
                VN50: '0% - R.C. art. 17/6/a-bis fabbricati - N6.4',
                VN51: '0% - R.C. art. 17/6/b telefoni cellulari - N6.5',
                VN52: '0% - R.C. art. 17/6/c prodotti elettronici - N6.6',
                VN53: '0% - R.C. art. 17/6/a-ter servizi comparto edile e settori connessi - N6.7',
                VN54: '0% - R.C. art. 17/6/d-bis,d-ter,d-quater gas/energia elettrica - N6.8',
                VN55: '0% - Non imp. art.71 DPR 633/72 (Vaticano) - N3.4',
                VN56: '0% - Non imp. art.71 DPR 633/72 (RSM) - N3.3',
                VN57: '0% - Contribuenti forfettari - N2.2',
            },
            summaryLine: {
                nature: {
                    'N1' : 'escluse ex art. 15',
                    'N2' : 'non soggette (codice non più valido per le fatture emesse a partire dal primo gennaio 2021 )',
                    'N2-1' : 'non soggette ad IVA ai sensi degli artt. da 7 a 7-septies del DPR 633/72',
                    'N2-2' : 'non soggette - altri casi',
                    'N3' : 'non imponibili (codice non più valido per le fatture emesse a partire dal primo gennaio 2021 )',
                    'N3-1' : 'non imponibili - esportazioni',
                    'N3-2' : 'non imponibili - cessioni intracomunitarie',
                    'N3-3' : 'non imponibili - cessioni verso San Marino',
                    'N3-4' : 'non imponibili - operazioni assimilate alle cessioni all\'esportazione',
                    'N3-5' : 'non imponibili - a seguito di dichiarazioni d\'intento',
                    'N3-6' : 'non imponibili - altre operazioni che non concorrono alla formazione del plafond',
                    'N4' : 'esenti',
                    'N5' : 'regime del margine / IVA non esposta in fattura',
                    'N6' : 'inversione contabile (per le operazioni in reverse charge ovvero nei casi di autofatturazione per acquisti extra UE di servizi ovvero per importazioni di beni nei soli casi previsti) (codice non più valido per le fatture emesse a partire dal primo gennaio 2021 )',
                    'N6-1' : 'inversione contabile - cessione di rottami e altri materiali di recupero',
                    'N6-2' : 'inversione contabile - cessione di oro e argento puro',
                    'N6-3' : 'inversione contabile - subappalto nel settore edile',
                    'N6-4' : 'inversione contabile - cessione di fabbricati',
                    'N6-5' : 'inversione contabile - cessione di telefoni cellulari',
                    'N6-6' : 'inversione contabile - cessione di prodotti elettronici',
                    'N6-7' : 'inversione contabile - prestazioni comparto edile e settori connessi',
                    'N6-8' : 'inversione contabile - operazioni settore energetico',
                    'N6-9' : 'inversione contabile - altri casi',
                    'N7' : 'IVA assolta in altro stato UE (prestazione di servizi di telecomunicazioni, tele-radiodiffusione ed elettronici ex art. 7-sexies lett. f, g, art. 74-sexies DPR 633/72)',
                },
                vatEnforceability: {
                    'I': 'Immediato',
                    'D': 'Differito',
                    'S': 'Pagamento diviso'
                }
            },
            payment: {
                type: {
                    TP01: 'Pagamento a rate',
                    TP02: 'Pagamento completo',
                    TP03: 'Anticipo',
                },
                mode: {
                    MP01: 'Contanti',
                    MP02: 'Assegno',
                    MP03: 'Assegno circolare',
                    MP04: 'Contanti presso Tesoreria',
                    MP05: 'Bonifico',
                    MP06: 'Vaglia cambiario',
                    MP07: 'Bollettino bancario',
                    MP08: 'Carta di pagamento',
                    MP09: 'RID',
                    MP10: 'RID utenze',
                    MP11: 'RID veloce',
                    MP12: 'RIBA',
                    MP13: 'MAV',
                    MP14: 'Quietanza erario',
                    MP15: 'Giroconto su conti di contabilità speciale',
                    MP16: 'Domiciliazione bancaria',
                    MP17: 'Domiciliazione postale',
                    MP18: 'Bollettino di c/c postale',
                    MP19: 'SEPA Direct Debit',
                    MP20: 'SEPA Direct Debit CORE',
                    MP21: 'SEPA Direct Debit B2B',
                    MP22: 'Trattenuta su somme già riscosse',
                    MP23: 'PagoPA',
                }
            },
            document: {
                type: {
                    TD01: 'Fattura',
                    TD02: 'Acconto/anticipo su fattura',
                    TD03: 'Acconto/anticipo su parcella',
                    TD04: 'Nota di credito',
                    TD05: 'Nota di debito',
                    TD06: 'Parcella',
                    TD16: 'Integrazione fattura reverse charge interno',
                    TD17: 'Integrazione/autofattura per acquisto servizi da estero (ex art. 17 c.2 Dpr 633/72)',
                    TD18: 'Integrazione per acquisto beni intracomunitari (art. 46 DL 331/93)',
                    TD19: 'Integrazione/autofattura per acquisto beni (ex art.17 co.2 DPR 633/72)',
                    TD20: 'Autofattura denuncia (regolarizzazione e integrazione delle fatture - art.6 c.8 d.lgs.471/97 o art.46 c.5 D.L.331/93)',
                    TD21: 'Autofattura per splafonamento',
                    TD22: 'Estrazione beni da Deposito IVA',
                    TD23: 'Estrazione beni da Deposito IVA con versamento IVA',
                    TD24: 'Fattura differita di cui all\'art.21 c.4 lett. A',
                    TD25: 'Fattura differita di cui all\'art.21 c.4 3°periodo lett. B',
                    TD26: 'Cessione di beni ammortizzabili e per passaggi interni - art.36 DPR 633/72',
                    TD27: 'Fattura per autoconsumo o per cessioni gratuite senza rivalsa',
                }
            },
            type: {
                ELECTRONIC: 'Elettronica',
                STANDARD: 'Standard'
            },
            status: {
                'DRAFT': 'In lavorazione',
                'CREATED': 'Creata',
                'RECEIVED': 'Ricevuta',
                'ERROR': 'Errore',
                'SENT': 'Inviata',
                'NOT-SENT': 'Non inviata',
                'PAID': 'Pagata',
                'PARTIALLY_PAID': 'Parzialmente pagata',
                'CANCELLED': 'Stornata',
                'DONE': 'Gestito'
            },
            documentType: {
                TD01: 'Fattura',
                TD02: 'Acconto/anticipo su fattura',
                TD03: 'Acconto/anticipo su parcella',
                TD04: 'Nota di credito',
                TD05: 'Nota di debito',
                TD06: 'Parcella',
                TD16: 'Integrazione fattura reverse charge interno',
                TD17: 'Integrazione/autofattura per acquisto servizi da estero (ex art. 17 c.2 DPR 633/72)',
                TD18: 'Integrazione per acquisto beni intracomunitari (art. 46 DL 331/93)',
                TD19: 'Integrazione/autofattura per acquisto beni (ex art.17 co.2 DPR 633/72)',
                TD20: 'Autofattura regolarizzazione e integrazione fatture - art.6 c.8 D.L.471/97 o art.46 c.5 D.L.331/93',
                TD21: 'Autofattura splafonamento',
                TD22: 'Estrazione beni da Deposito IVA',
                TD23: 'Estrazione beni da Deposito IVA con versamento IVA',
                TD24: 'Fattura differita di cui all\'art.21 c.4 lett. A',
                TD25: 'Fattura differita di cui all\'art.21 c.4 3°periodo lett. B',
                TD26: 'Cessione di beni ammortizzabili e per passaggi interni - art.36 DPR 633/72',
                TD27: 'Fattura per autoconsumo o per cessioni gratuite senza rivalsa',
            }
        },
        payments: {
            type: {
                TP01: 'Carta di credito',
                TP02: 'Conto Bancario',
            }
        },
        firstAccounting: {
            documentType: {
                INVOICE: 'Documenti fiscali',
                CASHFLOW: 'Entrate - Uscite'
            }
        },
        registry: {
            type: {
                'PUBLISHER': 'Editore',
                'SSP': 'SSP',
                'SUPPLIER': 'Fornitore',
                'CUSTOMER': 'Cliente',
                'MEDIA-CENTER': 'Centro media',
                'EMPLOYEE': 'Impiegato',
                'UNDEFINED': 'Non definito'
            }
        },
        country: {
            AF: "Afghanistan",
            AL: "Albania",
            DZ: "Algeria",
            AS: "Samoa americane",
            AD: "Andorra",
            AO: "Angola",
            AI: "Anguilla",
            AQ: "Antartide",
            AG: "Antigua e Barbuda",
            AR: "Argentina",
            AM: "Armenia",
            AW: "Aruba",
            AU: "Australia",
            AT: "Austria",
            AZ: "Azerbaigian",
            BS: "Bahamas (the)",
            BH: "Bahrein",
            BD: "Bangladesh",
            BB: "Barbados",
            BY: "Bielorussia",
            BE: "Belgio",
            BZ: "Belize",
            BJ: "Benin",
            BM: "Bermuda",
            BT: "Bhutan",
            BO: "Bolivia (Stato plurinazionale di)",
            BQ: "Bonaire, Sint Eustatius e Saba",
            BA: "Bosnia Erzegovina",
            BW: "Botswana",
            BV: "Isola Bouvet",
            BR: "Brasile",
            IO: "Territorio britannico dell'Oceano Indiano (the)",
            BN: "Brunei Darussalam",
            BG: "Bulgaria",
            BF: "Burkina Faso",
            BI: "Burundi",
            CV: "Cabo Verde",
            KH: "Cambogia",
            CM: "Camerun",
            CA: "Canada",
            KY: "Isole Cayman (le)",
            CF: "Repubblica Centrafricana (la)",
            TD: "Chad",
            CL: "Chile",
            CN: "Cina",
            CX: "Isola di Natale",
            CC: "Isole Cocos (Keeling) (le)",
            CO: "Colombia",
            KM: "Comore (the)",
            CD: "Congo (Repubblica Democratica del)",
            CG: "Congo (il)",
            CK: "Isole Cook (le)",
            CR: "Costa Rica",
            HR: "Croazia",
            CU: "Cuba",
            CW: "Curacao",
            CY: "Cipro",
            CZ: "Repubblica Ceca",
            CI: "Costa d'Avorio",
            DK: "Danimarca",
            DJ: "Gibuti",
            DM: "Dominica",
            DO: "Repubblica Dominicana (la)",
            EC: "Ecuador",
            EG: "Egitto",
            SV: "El Salvador",
            GQ: "Guinea Equatoriale",
            ER: "Eritrea",
            EE: "Estonia",
            SZ: "Eswatini",
            ET: "Etiopia",
            FK: "Isole Falkland (le) [Malvinas]",
            FO: "Isole Fær Øer (le)",
            FJ: "Figi",
            FI: "Finlandia",
            FR: "Francia",
            GF: "Guiana francese",
            PF: "Polinesia francese",
            TF: "Territori francesi meridionali (i)",
            GA: "Gabon",
            GM: "Gambia (the)",
            GE: "Georgia",
            DE: "Germania",
            GH: "Ghana",
            GI: "Gibilterra",
            GR: "Grecia",
            GL: "Groenlandia",
            GD: "Grenada",
            GP: "Guadalupa",
            GU: "Guam",
            GT: "Guatemala",
            GG: "Guernsey",
            GN: "Guinea",
            GW: "Guinea-Bissau",
            GY: "Guyana",
            HT: "Haiti",
            HM: "Heard Island e McDonald Islands",
            VA: "Santa Sede (la)",
            HN: "Honduras",
            HK: "Hong Kong",
            HU: "Ungheria",
            IS: "Islanda",
            IN: "India",
            ID: "Indonesia",
            IR: "Iran (Repubblica Islamica del)",
            IQ: "Iraq",
            IE: "Irlanda",
            IM: "Isola di Man",
            IL: "Israele",
            IT: "Italia",
            JM: "Giamaica",
            JP: "Giappone",
            JE: "Jersey",
            JO: "Giordania",
            KZ: "Kazakistan",
            KE: "Kenya",
            KI: "Kiribati",
            KP: "Corea (Repubblica democratica popolare di)",
            KR: "Corea (Repubblica di)",
            KW: "Kuwait",
            KG: "Kirghizistan",
            LA: "Repubblica democratica popolare del Laos (la)",
            LV: "Lettonia",
            LB: "Libano",
            LS: "Lesotho",
            LR: "Liberia",
            LY: "Libia",
            LI: "Liechtenstein",
            LT: "Lituania",
            LU: "Lussemburgo",
            MO: "Macao",
            MG: "Madagascar",
            MW: "Malawi",
            MY: "Malaysia",
            MV: "Maldive",
            ML: "Mali",
            MT: "Malta",
            MH: "Isole Marshall (the)",
            MQ: "Martinica",
            MR: "Mauritania",
            MU: "Mauritius",
            YT: "Mayotte",
            MX: "Messico",
            FM: "Micronesia (Stati federati di)",
            MD: "Moldova (Repubblica di)",
            MC: "Monaco",
            MN: "Mongolia",
            ME: "Montenegro",
            MS: "Montserrat",
            MA: "Marocco",
            MZ: "Mozambico",
            MM: "Myanmar",
            NA: "Namibia",
            NR: "Nauru",
            NP: "Nepal",
            NL: "Paesi Bassi (i)",
            NC: "Nuova Caledonia",
            NZ: "Nuova Zelanda",
            NI: "Nicaragua",
            NE: "Niger (the)",
            NG: "Nigeria",
            NU: "Niue",
            NF: "Isola Norfolk",
            MP: "Isole Marianne Settentrionali (le)",
            NO: "Norvegia",
            OM: "Oman",
            PK: "Pakistan",
            PW: "Palau",
            PS: "Palestina, Stato di",
            PA: "Panama",
            PG: "Papua Nuova Guinea",
            PY: "Paraguay",
            PE: "Perù",
            PH: "Filippine (the)",
            PN: "Pitcairn",
            PL: "Polonia",
            PT: "Portogallo",
            PR: "Porto Rico",
            QA: "Qatar",
            MK: "Repubblica di Macedonia del Nord",
            RO: "Romania",
            RU: "Federazione Russa (la)",
            RW: "Ruanda",
            RE: "Riunione",
            BL: "Saint Barthélemy",
            SH: "Sant'Elena, Ascensione e Tristan da Cunha",
            KN: "Saint Kitts e Nevis",
            LC: "Santa Lucia",
            MF: "Saint Martin (parte francese)",
            PM: "Saint Pierre e Miquelon",
            VC: "Saint Vincent e Grenadine",
            WS: "Samoa",
            SM: "San Marino",
            ST: "Sao Tome e Principe",
            SA: "Arabia Saudita",
            SN: "Senegal",
            RS: "Serbia",
            SC: "Seychelles",
            SL: "Sierra Leone",
            SG: "Singapore",
            SX: "Sint Maarten (parte olandese)",
            SK: "Slovacchia",
            SI: "Slovenia",
            SB: "Isole Salomone",
            SO: "Somalia",
            ZA: "Sud Africa",
            GS: "Georgia del Sud e isole Sandwich meridionali",
            SS: "Sudan del Sud",
            ES: "Spagna",
            LK: "Sri Lanka",
            SD: "Sudan (the)",
            SR: "Suriname",
            SJ: "Svalbard e Jan Mayen",
            SE: "Svezia",
            CH: "Svizzera",
            SY: "Repubblica Araba Siriana",
            TW: "Taiwan",
            TJ: "Tagikistan",
            TZ: "Tanzania, Repubblica Unita di",
            TH: "Tailandia",
            TL: "Timor-Leste",
            TG: "Andare",
            TK: "Tokelau",
            TO: "Tonga",
            TT: "Trinidad e Tobago",
            TN: "Tunisia",
            TR: "Tacchino",
            TM: "Turkmenistan",
            TC: "Isole Turks e Caicos (le)",
            TV: "Tuvalu",
            UG: "Uganda",
            UA: "Ucraina",
            AE: "Emirati Arabi Uniti (i)",
            GB: "Regno Unito di Gran Bretagna e Irlanda del Nord (the)",
            UM: "Isole minori periferiche degli Stati Uniti (the)",
            US: "Stati Uniti d'America (i)",
            UY: "Uruguay",
            UZ: "Uzbekistan",
            VU: "Vanuatu",
            VE: "Venezuela (Repubblica Bolivariana di)",
            VN: "Viet Nam",
            VG: "Isole Vergini (britanniche)",
            VI: "Isole Vergini (USA)",
            WF: "Wallis e Futuna",
            EH: "Sahara occidentale",
            YE: "Yemen",
            ZM: "Zambia",
            ZW: "Zimbabwe",
            AX: "Isole Aland",
        }
    },
    exceptions: {
        400: {
            default: 'Errore tecnico contattare l\'assistenza.',
            1000: 'Errore generico di autenticazione.',
            1002: 'Credenziali non valide.',
            1003: 'Il token a due fattori non è valido.',
            1004: 'Il codice a due fattori non valido.',
            2000: 'L\'utente ha troppi ruoli associati.',
            3000: 'Impossibile utilizzare il tipo di registro inserito.',
            3001: 'Impossibile utilizzare la partita iva ed identificativo nazionale inseriti.',
            3002: 'Impossibile utilizzare l\'email inserità poichè utilizzata da entità presenti in anagrafica.',
            3003: 'Contatti mancanti per l\'anagrafica richiesta.',
            3005: 'Anagrafica non gestita internamente.',
            3006: 'Esiste già una anagrafica con stessa P.IVA ed identificativo nazionale.',
            3007: 'L\'anagrafica non esiste nel servizio ACUBE.',
            3008: 'Il regime delle tasse inserito non è valido.',
            3009: 'L\'anagrafica è associata con dei documenti fiscali, impossibile cancellare.',
            3010: 'La P.IVA inserita non è valida.',
            3011: 'La lunghezza del codice FE è errata, deve essere lunga almeno 6 caratteri fino ad un massimo 7.',
            3012: 'Il codice FE inserito non è valido per aziende non italiane, provare con XXXXXXX.',
            3013: 'Anagrafica mancante per la coppia P.IVA e Numero identificativo nazionale.',
            3014: 'Sistema numerazione per documenti fiscali mancante.',
            3015: 'Sistema numerazione uscite mancante.',
            3016: 'Anagrafica già esistente con la coppia nome e stato utilizzata.',
            4000: 'Impossibile utilizzare l\'IBAN indicato poichè utilizzato da entità presenti in anagrafica.',
            4001: 'L\'IBAN inserito non è valido.',
            4002: 'Codice a due fattori fornito scaduto o non valido.',
            5000: 'Impossibile cancellare il contatto in quanto è utilizzato da entità presenti in anagrafica.',
            5001: 'Impossibile aggiornate il contatto poichè l\'email è già utilizzata.',
            6000: 'Impossibile cancellare la banca in quanto è utilizzata da entità presenti in anagrafica.',
            6001: 'Impossibile aggiornare i dati della banca poichè lo SWIFT BIC inserito è già utilizzato.',
            8000: 'Impossibile inserire valori negativi.',
            8001: 'Impossibile inserire liste vuote.',
            8002: 'Impossibile inserire percentuali negative.',
            8003: 'Encoding errato per gli allegati.',
            8004: 'Il range di date non è valido.',
            9000: 'La valuta inserità non è valida!',
            9001: 'La coppia numero e mittente già esiste per il documento fiscale in questione',
            9002: 'Il tipo di documento inserito non è valido!',
            9003: 'La data inserita non è valida!',
            9004: 'Il tipo di pagamento inserito non è valido!',
            9005: 'La modalità di pagamento inserita non è valida!',
            9006: 'I giorni di scadenza pagamento inseriti non sono validi!',
            9007: 'Il mittente inserito non è presente!',
            9008: 'La data di scadenza pagamento non è valida!',
            9009: 'Le linee di dettaglio devono essere inserite',
            9010: 'Il pdf non è presente!',
            9011: 'Manca la fattura relativa alla nota di credito',
            9012: 'L\'associazione della fattura non è necessaria per un documento fiscale di tipo fattura',
            9013: 'Il totale inserito nella nota di credito è maggiore rispetto alla fattura relativa selezionata!',
            9014: 'Il destinatario inserito nella nota di credito è differente da quello indicato nella fattura!',
            9015: 'Il mittente inserito nella nota di credito è differente da quello indicato nella fattura!',
            9016: 'Il tipo di fattura relativa seleziona è diffente dal tipo fattura',
            9017: 'La data della fattura fornita deve essere precedente a quella correlata',
            9018: 'Il mittente e destinatario non possono essere identici',
            9019: 'Entrambi mittente e destinatario non appartengono alla nostra gestione',
            9020: 'Il mittente non ha le informazioni bancarie!',
            9021: 'Le linee di dettaglio non possono avere il campo vat differente tra loro',
            9022: 'La transizione di stato non è valida. Contattare l\'assistenza.',
            9023: 'La data di pagamento deve essere successiva alla data di emissione.',
            9024: 'La data di pagamento non è valida.',
            9025: 'Il mittente non ha i campi relativi locazione non compilati!',
            9026: 'Il destinatario non ha i campi relativi locazione non compilati!',
            9027: 'Il numero del documento fiscale non è valido.',
            9028: 'Il destinatario non ha una banca associata.',
            9029: 'Regime fiscale del mittente non definito.',
            9030: 'Stato documento non valido per registrare pagamenti.',
            9031: 'Importo totale mancante nel documento fiscale.',
            9032: 'Natura scelta non valida.',
            9033: 'Coppia IVA e Natura non valida.',
            9034: 'IVA non valida.',
            9035: 'Il documento non può essere re-inviato, poichè lo stato corrente del documento non lo permette.',
            10001: 'La quantità nelle linee di dettaglio non è corretta.',
            11000: 'Il file allegato non è valido, utilizzare un altro tipo di estensione del file.',
            11001: 'Il MIME type del file allegato non è valido.',
            11002: 'Il peso del file supera il limite.',
            11003: 'L\'allegato non può essere eliminato.',
            12000: 'Impossibile validare la P.IVA inserita.',
            12001: 'Impossibile validare la P.IVA inserita, formato inserito non valido.',
            12002: 'Impossibile validare la P.IVA inserita, nazione scelta non è supportata.',
            13000: 'Valuta inserita non valida.',
            13001: 'Tipo di uscita inserito non è valido.',
            13002: 'Il tag inserito non è valido',
            13003: 'Il mittente inserito non è valido, è uguale al destinatario.',
            13004: 'Il destinatario inserito non è valido.',
            13005: 'Il registro inserito non è presente.',
            13006: 'La coppia numero e destinatario è già utilizzata.',
            13007: 'Il numero inserito è già utilizzato.',
            14000: 'Impossibile validare l\'IBAN inserito.',
            14001: 'Impossibile validare l\'IBAN inserito, credenziali non valide.',
            14002: 'Impossibile validare l\'IBAN inserito, iscrizione al servizio terminato.',
            16001: 'Il tipo inserito non è valido.',
            17000: 'Il file allegato non è valido, utilizzare un altro tipo di estensione del file.',
            17001: 'Il MIME type del file allegato non è valido.',
            17002: 'Il peso del file supera il limite.',
            18000: 'L\'esecutività IVA ha un valore errato.',
            19000: 'Data di pagamento non valida.',
            19001: 'Valuta non valida.',
            19002: 'Importo superiore al residuo da pagare.',
            19003: 'Tipo pagamento non valido.',
        },
        default: 'Errore tecnico contattare l\'assistenza.'
    },
    dropzone: {
        dictDefaultMessage: 'Clicca qui per aggiungere allegati.',
        dictFallbackMessage: 'Il browser che stai utilizzando non supporta, questa funzionalità.',
        dictFallbackText: '',
        dictFileTooBig: 'Il file caricato pesa troppo. E\' possibile caricare fino ad un massimo di %{fileMaxSize}.',
        dictInvalidFileType: 'Il tipo di file caricato non è supportato.',
        dictResponseError: 'Non è stato possibile caricare i file.',
        dictCancelUpload: 'Cancella caricamento',
        dictUploadCanceled: 'Caricamento interrotto.',
        dictCancelUploadConfirmation: 'Sicuro di cancellare il caricamento?',
        dictRemoveFile: 'Rimuovi',
        dictRemoveFileConfirmation: 'Sicuro di cancellare il file?',
        dictMaxFilesExceeded: 'Puoi caricare fino a {{maxFiles}} file.',
        dictFileSizeUnits: '',
    }
};
