export const EN_TRANSLATIONS = {
    authentications: {
        login: 'Login',
        wrongCredentials: 'Wrong credentials',
        codeButton: 'Send code',
        invalidCode: 'Invalid inserted code',
        userNotExists: 'Seems that this user doesn\'t exist',
        codeValidity: "The code sent to number %{telephoneNumber} is still valid for %{seconds} at the end of which you can request a new code",
        missingCode: "Didn't the code get to you?",
        retrySendingCodeMessage: "Request a new code",
        codeInputPlaceholder: "Enter the code received via SMS",
        returnToLogin: "Back to login",
        assistanceMessage: "Contact support for technical support.",
        logout: "Logout",
        logoutMessage: "Are you sure to logout?",
        abort: "Abort",
    }
};
