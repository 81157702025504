<template>
    <div>
        <div class="col d-flex justify-content-center justify-content-sm-between flex-wrap p-3">
            <base-input
                v-if="showSearch"
                v-model="searchQuery"
                prepend-icon="fas fa-search"
                class="search-base-table"
                :placeholder="$t('actions.search') + '...'"
                :data-cy="'searchTableData'"
            />
            <el-select
                v-model="pagination.perPage"
                class="select-primary pagination-select col-2"
                placeholder="Per page"
            >
                <el-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    class="select-primary"
                    :label="item"
                    :value="item"
                />
            </el-select>
        </div>

        <el-table
            :data="queriedData"
            :default-sort="defaultSort"
            header-row-class-name="thead-light"
            @sort-change="sortChange"
        >
            <slot />
        </el-table>

        <div
            v-if="showPagination"
            slot="footer"
            class="col d-flex justify-content-center justify-content-sm-between flex-wrap pt-3"
        >
            <div>
                <p class="card-category">
                    {{ $t("generalTerms.searchTerms.from") }}
                    {{ from + 1 }}
                    {{ $t("generalTerms.searchTerms.to") }}
                    {{ to }}
                    {{ $t("generalTerms.searchTerms.on") }}
                    <span
                        :data-cy="'totalItemsCount'"
                    >{{ total }}</span>
                </p>
            </div>
            <base-pagination
                v-model="pagination.currentPage"
                class="pagination-no-border"
                :per-page="pagination.perPage"
                :total="total"
            />
        </div>
    </div>
</template>

<script>
import { Option, Select, Table, TableColumn } from "element-ui";
import clientPaginationMixin from "@/views/Mixins/clientPaginationMixin";
import sortingFunctionsMixin from "@/views/Mixins/sortingFunctionsMixin";

export default {
    name: 'BaseTable',
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
    },
    mixins: [
        clientPaginationMixin,
        sortingFunctionsMixin
    ],
    props: {
        tableData: {
            type: Array,
            required: true
        },
        searchKeys: {
            type: Array,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: []
        },
        searchThreshold: {
            type: Number,
            default: 0
        },
        defaultSort: {
            type: Object
        },
        showSearch: {
            type: Boolean,
            default: true
        },
        showPagination: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            searchQuery: null,
        }
    },
    methods: {
        sortChange(column) {
            if (column.prop) {
                // eslint-disable-next-line vue/no-mutating-props
                this.tableData.sort((a, b) => {
                    if (column.prop.match(/date/gi)) {
                        if (column.order === 'ascending') {
                            return this.sortDateAsc(a[column.prop], b[column.prop]);
                        }

                        if (column.order === 'descending') {
                            return this.sortDatesDesc(a[column.prop], b[column.prop]);
                        }
                    }
                });
            }
        }
    }
}
</script>

<style lang="scss">
.search-base-table {
    div.form-group {
        margin: 0;
    }
}
</style>
