<template>
    <validation-observer
        v-slot="{handleSubmit}"
        ref="formValidator"
    >
        <form
            role="form"
            :data-cy="'stepTwoFactorForm'"
            @submit.prevent="handleSubmit(isAValidCode)"
        >
            <base-input
                ref="inputCode"
                v-model="code"
                alternative
                class="mb-3"
                name="Codice"
                vid="code"
                :rules="{min: AuthenticationConfigurationsData.CODE_LENGTH, max: AuthenticationConfigurationsData.CODE_LENGTH}"
                prepend-icon="ni ni-key-25"
                :placeholder="$t('authentications.codeInputPlaceholder')"
                :input-data-cy="'inputTwoFactorCode'"
                :invalid-data-cy="'inputTwoFactorCodeInvalid'"
                @input="validateCodeInput"
            />

            <code-count-down-timer
                ref="codeCountDownTimer"
                :last-three-number-of-telephone-number="lastThreeNumberOfTelephoneNumber"
            >
                <div class="text-center">
                    <base-button
                        :loading="isLoading"
                        native-type="submit"
                        class="my-4"
                        :disabled="disableCodeButton"
                        :data-cy="'buttonTwoFactorSubmit'"
                    >
                        {{ $t("authentications.codeButton") }}
                    </base-button>
                </div>
            </code-count-down-timer>
        </form>
    </validation-observer>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import {
    AuthenticationConfigurationsData,
    AuthenticationExceptionCodes,
    AuthenticationResponseKeys
} from "@/constants/authenticationConstants";
import { authenticationService } from "@/services/authenticationService";
import { authenticationClient } from "@/clients/authenticationClient";
import CodeCountDownTimer from "@/views/Pages/Authentication/CodeCountDownTimer";
import store from "@/main";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import companyMixins from "@/mixins/companyMixins";

export default {
    name: "TwoFactor",
    components: {
        ValidationObserver,
        CodeCountDownTimer,
    },
    mixins: [exceptionsMixins, companyMixins],
    props: {
        lastThreeNumberOfTelephoneNumber: {
            type: String,
            required: true,
            validator: val => val.length === 3,
        },
    },
    data() {
        return {
            isLoading: false,
            code: null,
            disableCodeButton: true,
            AuthenticationConfigurationsData: AuthenticationConfigurationsData
        };
    },
    mounted() {
        this.$refs.inputCode.$refs.input.focus();
    },
    methods: {
        validateCodeInput() {
            this.$refs.formValidator.setErrors({});
            this.code = this.code.slice(0,AuthenticationConfigurationsData.CODE_LENGTH).toUpperCase();
            this.disableCodeButton = this.code.length !== AuthenticationConfigurationsData.CODE_LENGTH;
        },
        resetCodeAndDisplayError() {
            this.$refs.formValidator.setErrors({
                code: [this.$t("authentications.invalidCode")],
            });
            this.$refs.codeCountDownTimer.immediateCodeExpiration();
        },
        isAValidCode() {
            this.isLoading = true;

            const dataTwoFactorVerification = {
                token: authenticationService.getToken(),
                code: this.code
            };

            authenticationClient
                .twoFactorVerification(dataTwoFactorVerification)
                .then((result) => {
                    authenticationService.setTwoFactorToken(result.data.twoFactorToken);

                    const data = {
                        email: this.$store.state.authentication.email,
                        password: this.$store.state.authentication.password,
                        twoFactorToken: result.data.twoFactorToken,
                        companyName: this.getCompanyName(),
                        applicationClientName: this.getApplicationClientName(),
                    };

                    this.login(data);

                }).catch((error) => {
                    console.log(error)
                    if (error.response.status === 400) {

                        if(error.response.data.status === AuthenticationExceptionCodes.TWO_FACTOR_CODE_INVALID) {
                            this.$refs.formValidator.setErrors({
                                code: [this.$t('authentications.invalidCode')],
                            });
                        }

                        this.manageException(error);

                    } else {
                        store.dispatch("moveToTechnicalAssistanceStep");
                    }
                    this.isLoading = false;

                });
        },
        login(data) {
            authenticationClient
                .login(data)
                .then((result) => {
                    authenticationService.setToken(result.data.token);
                    this.$router.push(this.$route.query.redirect || '/homepage');
                })
                .catch(() => {
                    this.isLoading = false;
                    authenticationService.resetAuthenticationTokens();
                });
        }
    },
};
</script>
