<template>
    <footer class="footer px-4 mx-2">
        <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-6">
                <div class="copyright text-center text-lg-left text-muted">
                    © {{ year }} <a
                        :href="isTalks ? 'https://www.talksmedia.it/' : 'https://www.pubtech.ai/'"
                        class="font-weight-bold ml-1"
                        target="_blank"
                    >{{ isTalks ? 'Talks Media' : 'Pubtech' }}</a>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import companyMixins from "@/mixins/companyMixins";

export default {
    mixins: [
        companyMixins,
    ],
    data() {
        return {
            year: new Date().getFullYear()
        };
    }
};
</script>
<style></style>
