<template>
    <div class="content">
        <Header
            :title="$t('pages.registry.title')"
        >
            <base-button
                type="neutral"
                :data-cy="'buttonCreateRegistry'"
                @click="openNewModal"
            >
                {{ $t("actions.new") }}
            </base-button>
        </Header>

        <div class="container-fluid mt--6">
            <div class="row">
                <div class="col-12">
                    <card
                        class="mt-2 mb-4 p-2"
                        body-classes="p-0"
                        footer-classes="pb-2"
                    >
                        <div class="container-fluid">
                            <div class="row align-items-center m-0">
                                <div class="col-12">
                                    <h2>{{ $t('tables.column.filters') }}</h2>
                                </div>
                                <base-input
                                    :label="$t('forms.fields.type')"
                                    :name="$t('forms.fields.type')"
                                    :placeholder="$t('forms.actions.select') + $t('forms.fields.type')"
                                    input-classes="form-control-alternative"
                                    :data-cy="'typeRegistryFilter'"
                                    class="col-lg-12 col-xl-3"
                                >
                                    <v-select
                                        v-model="filters.type"
                                        class="form-control form-control-alternative"
                                        :options="registryTypeListComplete"
                                        :reduce="type => type.code"
                                    />
                                </base-input>
                                <base-input
                                    :label="$t('forms.fields.country')"
                                    :name="$t('forms.fields.country')"
                                    :placeholder="$t('forms.actions.select') + $t('forms.fields.country')"
                                    input-classes="form-control-alternative"
                                    :data-cy="'countryRegistryFilter'"
                                    class="col-lg-12 col-xl-3"
                                >
                                    <v-select
                                        v-model="filters.country"
                                        class="form-control form-control-alternative"
                                        :options="countryList"
                                        :reduce="country => country.code"
                                    />
                                </base-input>
                                <base-button
                                    native-type="submit"
                                    type="primary"
                                    class="search"
                                    :data-cy="'searchByFilters'"
                                    :disabled="loading"
                                    @click="loadData"
                                >
                                    {{ $t('actions.search') }}
                                </base-button>
                                <base-button
                                    v-if="showResetFilter"
                                    native-type="submit"
                                    type="danger"
                                    :disabled="loading"
                                    @click="clearFilters"
                                >
                                    {{ $t('actions.reset') }}
                                </base-button>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
            <div class="row">
                <RegistryTable
                    :table-data="registryTableData"
                    @data-changed="loadData"
                />
            </div>
        </div>

        <NewRegistry
            :key="newRegistryKey"
            :show-new="showNewRegistry"
            @close-new="showNewRegistry = false;"
            @data-changed="loadData"
        />
        <LoadingBar v-show="loading" />
    </div>
</template>

<script>
import { registryClient } from "@/clients/registryClient";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import choicesMixins from "@/mixins/choicesMixins";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import RegistryTable from "@/views/Pages/Components/Registry/Table";
import Header from "@/components/Header";
import NewRegistry from "@/views/Pages/Components/Registry/Modal/New";
import vSelect from 'vue-select';

export default {
    name: 'Registry',
    components: {
        NewRegistry,
        LoadingBar,
        RegistryTable,
        Header,
        vSelect,
    },
    mixins: [
        exceptionsMixins,
        choicesMixins
    ],
    data() {
        return {
            showNewRegistry: false,
            newRegistryKey: 0,
            loading: false,
            registryTableData: [],
            filters: {
                type: this.$store.getters.registryFilters.type,
                country: this.$store.getters.registryFilters.country,
            }
        };
    },
    computed: {
        showResetFilter() {
            return this.filters.type?.length > 0 ||
            this.filters.country?.length > 0;
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        clearFilters() {
            this.filters.type = null;
            this.filters.country = null;
            this.$store.dispatch("setRegistryFilters", {
                type: null,
                country: null,
            });
        },
        openNewModal() {
            this.newRegistryKey++;
            this.showNewRegistry = true;
        },
        loadData() {
            this.loading = true;
            registryClient.getByFilters(null, false, this.filters.type, this.filters.country).then((response) => {
                this.registryTableData = response.data;
                this.$store.dispatch("setRegistryFilters", {
                    type: this.filters.type,
                    country: this.filters.country,
                });
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loading = false;
            });
        },
    }
}
</script>
