var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col",attrs:{"data-cy":'registryTable'}},[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"p-0","footer-classes":"pb-2"}},[_c('BaseTable',{attrs:{"table-data":_vm.tableData,"search-keys":[
                'name',
                'email',
                'vat',
                'location.country'
            ]}},[_vm._l((_vm.tableColumns),function(column){return _c('el-table-column',_vm._b({key:column.label,attrs:{"formatter":column.formatter}},'el-table-column',column,false))}),_c('el-table-column',{attrs:{"align":"right","label":_vm.$t('tables.column.actions'),"min-width":80},scopedSlots:_vm._u([{key:"default",fn:function({$index, row}){return _c('div',{staticClass:"d-flex flex-wrap"},[_c('base-button',{staticClass:"edit",attrs:{"data-cy":'buttonUpdateRegistryId'+row.id,"type":"primary","size":"sm","icon":""},on:{"click":function($event){return _vm.openEditModal(row.id)}}},[_c('i',{staticClass:"fas fa-edit text-white"})]),_c('base-button',{staticClass:"remove btn-link",attrs:{"data-cy":'buttonDeleteRegistryId'+row.id,"type":"danger","size":"sm","icon":""},on:{"click":function($event){return _vm.openDeleteModal(row.id)}}},[_c('i',{staticClass:"fas fa-trash-alt text-white"})]),_c('base-button',{staticClass:"remove btn-link border",attrs:{"data-cy":'buttonDetailsRegistryId'+row.id,"type":"secondary","size":"sm","icon":""},on:{"click":function($event){return _vm.$router.push(
                            {
                                name: 'REGISTRY-DETAIL',
                                params: {
                                    id: row.id
                                }
                            }
                        );}}},[_c('i',{staticClass:"fas fa-info-circle"})])],1)}}])})],2)],1),_c('DeleteRegistry',{attrs:{"show-delete":_vm.deleteModal.showDeleteModal,"delete-registry-id":_vm.deleteModal.idRegistryToDelete,"registry-name":_vm.deleteModal.registryName},on:{"data-changed":function($event){return _vm.$emit('data-changed')},"close-delete":function($event){_vm.deleteModal.showDeleteModal = false;}}}),_c('EditRegistry',{attrs:{"show-edit":_vm.editModal.showEditModal,"edit-registry-id":_vm.editModal.editRegistryId},on:{"data-changed":function($event){return _vm.$emit('data-changed')},"close-edit":function($event){_vm.editModal.showEditModal = false;}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }