<template>
    <div class="new-modal">
        <modal
            v-if="showNew"
            :show="showNew"
            modal-classes="modal-secondary"
            :size="'lg'"
            :data-cy="'newRegistryModal'"
            @close="closeNew"
        >
            <template slot="header">
                <h1>{{ $t('actions.creating') + ' ' + $t("pages.registry.registry") }} </h1>
            </template>
            <div class="progress-bar-container pb-4">
                <StepProgressBar
                    :active-step="activeStep"
                    :steps="steps"
                />
            </div>
            <div class="step-container">
                <GeneralDataStep
                    v-show="activeStep === 1"
                    :pending-registry="pendingRegistry"
                    @next-step="extractGeneralDataStepAndSetNextStep($event)"
                >
                    <template #default="slotProps">
                        <base-button
                            native-type="submit"
                            type="primary"
                            :data-cy="'nextFirstStepButton'"
                            :loading="slotProps.loading"
                        >
                            {{ $t("actions.next") }}
                        </base-button>
                    </template>
                </GeneralDataStep>
                <BankStep
                    v-show="activeStep === 2"
                    @next-step="extractBankStepAndSetNextStep($event)"
                >
                    <base-button
                        native-type="submit"
                        type="primary"
                        :data-cy="'nextSecondStepButton'"
                    >
                        {{ $t("actions.next") }}
                    </base-button>
                    <base-button
                        type="secondary"
                        :data-cy="'backStepButton'"
                        @click="backStep"
                    >
                        {{ $t("actions.back") }}
                    </base-button>
                </BankStep>
                <ContactStep
                    v-show="activeStep === 3"
                    @next-step="extractContactStepAndSetNextStep($event)"
                >
                    <base-button
                        native-type="submit"
                        type="primary"
                        :data-cy="'nextThirdStepButton'"
                    >
                        {{ $t("actions.next") }}
                    </base-button>
                    <base-button
                        type="secondary"
                        :data-cy="'backBankStepButton'"
                        @click="backStep"
                    >
                        {{ $t("actions.back") }}
                    </base-button>
                </ContactStep>
                <RecapStep
                    v-show="activeStep === 4"
                    :registry="registry"
                >
                    <base-button
                        type="primary"
                        :data-cy="'createButton'"
                        :loading="creating"
                        @click="createRegistry"
                    >
                        {{ $t("actions.new") }}
                    </base-button>
                    <base-button
                        type="secondary"
                        :data-cy="'backStepButton'"
                        @click="backStep"
                    >
                        {{ $t("actions.back") }}
                    </base-button>
                </RecapStep>
            </div>
        </modal>
    </div>
</template>

<script>
import { registryClient } from "@/clients/registryClient";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import BaseButton from "@/components/BaseButton";
import Modal from "@/components/Modal";
import StepProgressBar from "@/views/Pages/Components/StepProgress/StepProgressBar";
import GeneralDataStep from "@/views/Pages/Components/Registry/Modal/Step/GeneralDataStep";
import BankStep from "@/views/Pages/Components/Registry/Modal/Step/BankStep";
import ContactStep from "@/views/Pages/Components/Registry/Modal/Step/ContactStep";
import RecapStep from "@/views/Pages/Components/Registry/Modal/Step/RecapStep";
import RegistryDto from "@/clients/dto/RegistryDto";

export default {
    name: 'NewRegistry',
    components: {
        StepProgressBar,
        BaseButton,
        Modal,
        GeneralDataStep,
        BankStep,
        ContactStep,
        RecapStep
    },
    mixins: [
        exceptionsMixins
    ],
    props: {
        showNew: {
            type: Boolean,
            required: true,
        },
        pendingRegistry: {
            type: Object
        }
    },
    data() {
        return {
            steps: {
                1: 'Step 1',
                2: 'Step 2',
                3: 'Step 3',
                4: 'Step 4',
            },
            activeStep: 1,
            registry: {
                name: null,
                email: null,
                telephone: null,
                type: null,
                vat: null,
                nationalIdentificationNumber: null,
                taxRegime: null,
                feCode: null,
                locationAddress: null,
                locationCity: null,
                locationState: null,
                locationZip: null,
                locationCountry: null,
                administrativeContactId: null,
                technicalContactId: null,
                bankAccountBeneficiaryName: null,
                bankAccountIban: null,
                bankId: null,
                bankName: null,
                administrativeContactName: null,
                technicalContactName: null,
            },
            creating: false,
        }
    },
    methods: {
        closeNew() {
            this.$emit('close-new');
        },
        nextStep() {
            if (this.activeStep < 4) {
                this.activeStep++;
            }
        },
        backStep() {
            if (this.activeStep > 1) {
                this.activeStep--;
            }
        },
        extractGeneralDataStepAndSetNextStep(model) {
            this.registry.name = model.name;
            this.registry.email = model.email;
            this.registry.telephone = model.telephone?.replace(/ /g, '');
            this.registry.type = model.type;
            this.registry.vat = model.vat;
            this.registry.nationalIdentificationNumber = model.nationalIdentificationNumber;
            this.registry.taxRegime = model.taxRegime;
            this.registry.feCode = model.feCode;
            this.registry.locationAddress = model.locationAddress;
            this.registry.locationCity = model.locationCity;
            this.registry.locationState = model.locationState;
            this.registry.locationZip = model.locationZip;
            this.registry.locationCountry = model.locationCountry;

            this.nextStep();
        },
        extractBankStepAndSetNextStep(model) {
            this.registry.bankAccountBeneficiaryName = model.bankAccountBeneficiaryName;
            this.registry.bankAccountIban = model.bankAccountIban;
            this.registry.bankId = model.bankId;
            this.registry.bankName = model.bankName;

            this.nextStep();
        },
        extractContactStepAndSetNextStep(model) {
            this.registry.administrativeContactId = model.administrativeContactId;
            this.registry.technicalContactId = model.technicalContactId;
            this.registry.administrativeContactName = model.administrativeContactName;
            this.registry.technicalContactName = model.technicalContactName;

            this.nextStep();
        },
        createRegistry() {
            this.creating = true;
            const registryDto = new RegistryDto(
                this.registry.name,
                this.registry.email,
                this.registry.telephone,
                this.registry.type,
                this.registry.vat,
                this.registry.nationalIdentificationNumber,
                this.registry.taxRegime,
                this.registry.feCode,
                this.registry.locationAddress,
                this.registry.locationCity,
                this.registry.locationState,
                this.registry.locationZip,
                this.registry.locationCountry,
                this.registry.administrativeContactId,
                this.registry.technicalContactId,
                this.registry.bankAccountBeneficiaryName,
                this.registry.bankAccountIban,
                this.registry.bankId,
                this.pendingRegistry?.id ?? null
            );

            registryClient.createRegistry(registryDto).then((response) => {
                this.$notify({
                    type: "success",
                    icon: "ni ni-notification-70",
                    message: this.$t("pages.registry.notifications.success.created")
                });
                this.$emit('data-changed', response.data.id);
                this.closeNew();
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.creating = false;
            });
        }
    }
}
</script>
