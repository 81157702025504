import { headersBuilder } from "@/clients/headersBuilder";
import axios, { AxiosResponse } from 'axios';

/**
 * validateClient.
 */
export const validateClient = {
    /**
     * Validate vat provided.
     *
     * @param vat {string}
     * @param country {string}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    validateVat(vat, country) {
        return axios.get(process.env.VUE_APP_ADMINISTRATION_BASE_URL + `/api/v1/validate/vat`, {
            headers: headersBuilder.buildAuthenticatedHeaders(),
            params: {
                vat,
                country
            }
        });
    },
    /**
     * Validate iban provided.
     *
     * @param iban {string}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    validateIban(iban) {
        return axios.get(process.env.VUE_APP_ADMINISTRATION_BASE_URL + `/api/v1/validate/iban`, {
            headers: headersBuilder.buildAuthenticatedHeaders(),
            params: {
                iban
            }
        });
    }
}
