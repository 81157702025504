<template>
    <div
        class="content"
        :data-cy="'generalDataStep'"
    >
        <validation-observer
            v-slot="{handleSubmit}"
            ref="formValidator"
        >
            <form
                class="new-event-form"
                @submit.prevent="handleSubmit(nextStep)"
            >
                <div class="form-group container">
                    <div class="row">
                        <base-input
                            :label="$t('forms.fields.type')"
                            :placeholder="$t('forms.actions.select') + $t('forms.fields.type')"
                            input-classes="form-control-alternative"
                            class="col-12"
                            :name="$t('forms.fields.type')"
                            :rules="{
                                required: true,
                                alpha_dash: true
                            }"
                            :data-cy="'newRegistryTypeField'"
                        >
                            <v-select
                                v-model="model.type"
                                class="form-control form-control-alternative"
                                :options="registryTypeList"
                                :reduce="type => type.code"
                            />
                        </base-input>
                        <base-input
                            v-model="model.name"
                            :label="$t('forms.fields.name')"
                            :placeholder="$t('forms.fields.name')"
                            class="col-6"
                            input-classes="form-control-alternative"
                            :name="$t('forms.fields.name')"
                            :rules="{required:true}"
                            :data-cy="'newRegistryNameField'"
                        />
                        <base-input
                            v-model="model.email"
                            :label="$t('forms.fields.email')"
                            :placeholder="$t('forms.fields.email')"
                            class="col-6"
                            input-classes="form-control-alternative"
                            :name="$t('forms.fields.email')"
                            :rules="{
                                email: true
                            }"
                            :data-cy="'newRegistryEmailField'"
                        />
                        <base-input
                            :label="$t('forms.fields.telephoneNumberPlaceholder')"
                            input-classes="form-control-alternative"
                            class="col-12"
                            :name="$t('forms.fields.telephoneNumberPlaceholder')"
                            :rules="{
                                required: false
                            }"
                        >
                            <vue-tel-input
                                v-model="model.telephone"
                                :input-options="{placeholder: $t('forms.fields.telephoneNumberPlaceholder')}"
                                mode="international"
                                class="mb-3"
                                :data-cy="'newRegistryTelephoneField'"
                            />
                        </base-input>
                        <base-input
                            v-model="model.locationAddress"
                            :label="$t('forms.fields.address')"
                            :placeholder="$t('forms.actions.insert') + ' ' + $t('forms.fields.address')"
                            input-classes="form-control-alternative"
                            class="col-6"
                            :name="$t('forms.fields.address')"
                            :rules="{required: true}"
                            :data-cy="'newRegistryAddressField'"
                        />
                        <base-input
                            v-model="model.locationZip"
                            :label="$t('forms.fields.zip')"
                            class="col-6"
                            :placeholder="$t('forms.actions.insert') + ' ' + $t('forms.fields.zip')"
                            input-classes="form-control-alternative"
                            :rules="{
                                max: 20,
                                required: true
                            }"
                            :name="$t('forms.fields.zip')"
                            :data-cy="'newRegistryZipField'"
                        />
                        <base-input
                            v-model="model.locationCity"
                            :label="$t('forms.fields.city')"
                            :placeholder="$t('forms.actions.insert') + ' ' + $t('forms.fields.city')"
                            input-classes="form-control-alternative"
                            class="col-6"
                            :name="$t('forms.fields.city')"
                            :rules="{required: true}"
                            :data-cy="'newRegistryCityField'"
                        />
                        <base-input
                            v-model="model.locationState"
                            :label="$t('forms.fields.state')"
                            class="col-6"
                            :placeholder="$t('forms.actions.insert') + ' ' + $t('forms.fields.state')"
                            input-classes="form-control-alternative"
                            :name="$t('forms.fields.state')"
                            :rules="{
                                min: 2,
                                max: 2,
                                alpha: true
                            }"
                            :data-cy="'newRegistryStateField'"
                        />
                        <base-input
                            :label="$t('forms.fields.country')"
                            class="col-12"
                            :placeholder="$t('forms.actions.select') + $t('forms.fields.country')"
                            input-classes="form-control-alternative"
                            :rules="{
                                required: true,
                                alpha: true
                            }"
                            :name="$t('forms.fields.country')"
                            :data-cy="'newRegistryCountryField'"
                        >
                            <v-select
                                v-model="model.locationCountry"
                                class="form-control form-control-alternative"
                                :options="countryList"
                                :reduce="country => country.code"
                            />
                        </base-input>
                        <base-input
                            v-model="model.vat"
                            :label="$t('forms.fields.vat')"
                            :placeholder="$t('forms.helper.vatWithCountryMessage')"
                            input-classes="form-control-alternative mr-2 flex-w-100"
                            :name="$t('forms.fields.vat')"
                            vid="vatValidation"
                            class="col-12"
                            :loading="vatValidationLoading"
                            :rules="vatValidationRule"
                            :data-cy="'newRegistryVatField'"
                            :disabled="isVatCompilationDisabled"
                            @focusout="checkVat"
                            @change="vatValidation = false"
                        />
                        <base-input
                            :label="$t('forms.fields.taxRegime')"
                            :placeholder="$t('forms.fields.taxRegime')"
                            input-classes="form-control-alternative"
                            class="col-12"
                            :name="$t('forms.fields.taxRegime')"
                            :rules="{required:false}"
                            :data-cy="'newRegistryTaxRegimeField'"
                        >
                            <v-select
                                v-model="model.taxRegime"
                                class="form-control form-control-alternative"
                                :options="taxRegimeChoicesList"
                                :reduce="taxRegime => taxRegime.code"
                            />
                        </base-input>
                        <base-input
                            v-model="model.nationalIdentificationNumber"
                            :label="$t('forms.fields.nationalIdentificationNumber')"
                            :placeholder="$t('forms.fields.nationalIdentificationNumber')"
                            input-classes="form-control-alternative"
                            class="col-12"
                            vid="nationalIdValidation"
                            :name="$t('forms.fields.nationalIdentificationNumber')"
                            :rules="nidValidationRule"
                            :data-cy="'newRegistryNationalIdNumberField'"
                        />
                        <base-input
                            v-model="model.feCode"
                            :label="$t('forms.fields.feCode')"
                            :placeholder="$t('forms.fields.feCode')"
                            class="col-12"
                            input-classes="form-control-alternative"
                            :name="$t('forms.fields.feCode')"
                            :rules="{
                                required: false,
                                alpha_num: true,
                                min: 6,
                                max: 7
                            }"
                            :data-cy="'newRegistryFeCodeField'"
                        />
                    </div>
                </div>
                <div class="d-flex justify-content-start">
                    <slot :loading="vatValidationLoading" />
                </div>
            </form>
        </validation-observer>
    </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { validateClient } from "@/clients/validateClient";
import { registryClient } from "@/clients/registryClient";
import { VueTelInput } from 'vue-tel-input';
import choicesMixins from "@/mixins/choicesMixins";
import 'vue-tel-input/dist/vue-tel-input.css';
import vSelect from 'vue-select';

export default {
    name: 'GeneralDataStep',
    components: {
        VueTelInput,
        ValidationObserver,
        vSelect
    },
    mixins: [
        choicesMixins
    ],
    props: {
        pendingRegistry: {
            type: Object,
            validator: (pendingRegistry) => {
                return (
                    (typeof pendingRegistry.name === 'string') &&
                    (typeof pendingRegistry.vat === 'string' || pendingRegistry.vat === null) &&
                    (typeof pendingRegistry.nationalIdentificationNumber === 'string' || pendingRegistry.nationalIdentificationNumber === null) &&
                    (typeof pendingRegistry.taxRegime === 'string' || pendingRegistry.taxRegime === null) &&
                    (typeof pendingRegistry.address === 'string' || pendingRegistry.address === null) &&
                    (typeof pendingRegistry.city === 'string' || pendingRegistry.city === null) &&
                    (typeof pendingRegistry.state === 'string' || pendingRegistry.state === null) &&
                    (typeof pendingRegistry.zip === 'string' || pendingRegistry.zip === null) &&
                    (typeof pendingRegistry.country === 'string' || pendingRegistry.country === null)
                );
            },
        }
    },
    data() {
        return {
            model: {
                name: this.pendingRegistry?.name ?? null,
                email: null,
                notValidatedTelephone: null,
                telephone: null,
                type: null,
                vat: this.pendingRegistry?.vat ?? null,
                nationalIdentificationNumber: this.pendingRegistry?.nationalIdentificationNumber ?? null,
                taxRegime: this.pendingRegistry?.taxRegime ?? null,
                feCode: null,
                locationAddress: this.pendingRegistry?.address ?? null,
                locationCity: this.pendingRegistry?.city ?? null,
                locationState: this.pendingRegistry?.state ?? null,
                locationZip: this.pendingRegistry?.zip ?? null,
                locationCountry: this.pendingRegistry?.country ?? null,
            },
            vatValidationLoading: false,
            vatValidation: false,
            countryThatNotRequiredFiscalData: [],
        }
    },
    computed: {
        isVatCompilationDisabled() {
            return this.model.locationCountry === null || this.model.locationCountry.length === 0;
        },
        isFiscalDataRequired() {
            return !this.countryThatNotRequiredFiscalData.includes(this.model.locationCountry);
        },
        vatValidationRule() {
            return this.isFiscalDataRequired ? 'vat_or_nid_required:@nationalIdValidation' : '';
        },
        nidValidationRule() {
            return this.isFiscalDataRequired ? 'vat_or_nid_required:@vatValidation' : '';
        }
    },
    async created() {
        await registryClient.getMetadata()
            .then((response) => {
                this.countryThatNotRequiredFiscalData = response.data.countriesThatDoNotRequireFiscalData;
            }).catch((error) => {
                this.manageException(error.response);
            });
    },
    methods: {
        callNextStep() {
            this.$emit('next-step', this.model);
        },
        nextStep() {
            if (this.vatValidationLoading) {
                return;
            }

            if (!this.vatValidation && !(this.isEmptyField(this.model.vat))) {
                this.checkVat(true);
            } else {
                this.callNextStep();
            }
        },
        checkVat(callNextStep) {
            if (this.model.vat) {
                this.model.vat = this.model.vat.replace(/\s/g, '');
            }

            if (this.isEmptyField(this.model.vat) || this.vatValidationLoading) {
                return;
            }

            this.vatValidationLoading = true;
            validateClient.validateVat(this.model.vat, this.model.locationCountry).then((response) => {
                if (response.data.supported) {
                    this.vatValidation = response.data.valid;
                } else {
                    this.vatValidation = true;
                }
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                if (!this.vatValidation) {
                    this.$refs.formValidator.setErrors({
                        vatValidation: [this.$t('forms.validationMessage.invalidVat')],
                    });
                }
                //This parameter can be an event, so check is required to see if it is true
                if (callNextStep === true) {
                    this.callNextStep();
                }
                this.vatValidationLoading = false;
            });
        },
        isEmptyField(field) {
            return !field || field.length === 0;
        }
    }
}
</script>

<style>
.flex-w-100 {
    flex: 0 1 100%;
}
</style>
