import DashboardLayout from "@/views/Layout/DashboardLayout";
import Registry from "@/views/Pages/Registry";
import DetailRegistry from "@/views/Pages/Components/Registry/Detail";

const registryPages = {
    path: "/registry",
    component: DashboardLayout,
    meta: {
        name: "pages.registry.breadcrumb.registries",
    },
    children: [
        {
            path: '',
            name: 'REGISTRY-LIST',
            component: Registry,
            meta: {
                name: "pages.registry.breadcrumb.registryList",
                area: "REGISTRY-REGISTRY"
            }
        },
        {
            path: ':id',
            name: 'REGISTRY-DETAIL',
            component: DetailRegistry,
            meta: {
                name: "pages.registry.breadcrumb.detail",
                area: "REGISTRY-REGISTRY"
            }
        },
    ]
};

export default registryPages;
