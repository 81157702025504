<template>
    <div
        class="col"
        :data-cy="'bankTable'"
    >
        <card
            class="no-border-card"
            body-classes="p-0"
            footer-classes="pb-2"
        >
            <BaseTable
                :table-data="tableData"
                :search-keys="[
                    'name',
                    {
                        name: 'swiftBic',
                        weight: 2
                    }
                ]"
                :search-threshold="0.1"
            >
                <el-table-column
                    v-for="column in tableColumns"
                    :key="column.label"
                    v-bind="column"
                    :formatter="column.formatter"
                />
                <el-table-column
                    align="right"
                    label="Azioni"
                >
                    <div
                        slot-scope="{$index, row}"
                        class="d-flex flex-wrap"
                    >
                        <base-button
                            :data-cy="'buttonUpdateBankId'+row.id"
                            class="edit"
                            type="primary"
                            size="sm"
                            icon
                            @click="openEditModal(row.id)"
                        >
                            <i class="fas fa-edit text-white" />
                        </base-button>
                        <base-button
                            :data-cy="'buttonDeleteBankId'+row.id"
                            class="remove btn-link"
                            type="danger"
                            size="sm"
                            icon
                            @click="openDeleteModal(row.id)"
                        >
                            <i class="fas fa-trash-alt text-white" />
                        </base-button>
                        <base-button
                            :data-cy="'buttonDetailsBankId'+row.id"
                            class="remove btn-link border"
                            type="secondary"
                            size="sm"
                            icon
                            @click="$router.push(
                                {
                                    name:'BANK-DETAIL',
                                    params: {
                                        id: row.id
                                    }
                                }
                            );"
                        >
                            <i class="fas fa-info-circle" />
                        </base-button>
                    </div>
                </el-table-column>
            </BaseTable>
        </card>

        <DeleteBank
            :delete-bank-id="deleteModal.idBankToDelete"
            :show-delete="deleteModal.showDeleteModal"
            :bank-name="deleteModal.bankName"
            @close-delete="deleteModal.showDeleteModal = false;"
            @data-changed="$emit('data-changed');"
        />

        <EditBank
            :edit-bank-id="editModal.editBankId"
            :show-edit="editModal.showEditModal"
            @close-edit="editModal.showEditModal = false;"
            @data-changed="$emit('data-changed');"
        />
    </div>
</template>

<script>
import { Option, Select, Table, TableColumn } from "element-ui";
import BaseButton from "@/components/BaseButton";
import EditBank from "@/views/Pages/Components/Bank/Modal/Edit";
import DeleteBank from "@/views/Pages/Components/Bank/Modal/Delete";
import BaseTable from "@/views/Pages/Components/Table/BaseTable";

export default {
    name: "BankTable",
    components: {
        DeleteBank,
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        EditBank,
        BaseButton,
        BaseTable
    },
    props: {
        tableData: {
            type: Array
        }
    },
    data() {
        return {
            tableColumns: [
                {
                    prop: "name",
                    label: this.$t("tables.column.name"),
                    minWidth: 160,
                    sortable: true,
                },
                {
                    prop: "swiftBic",
                    label: this.$t("tables.column.swiftBic"),
                    minWidth: 160,
                    sortable: true,
                },
            ],
            deleteModal: {
                showDeleteModal: false,
                idBankToDelete: null,
                bankName: '',
                loading: false,
            },
            editModal: {
                showEditModal: false,
                editData: null,
                editBankId: null
            },
        };
    },
    methods: {
        openEditModal(id) {
            this.editModal.showEditModal = true;
            this.editModal.editData = this.tableData.filter((value) => {
                return value.id === id;
            })[0];
            this.editModal.editBankId = id;
        },
        openDeleteModal(id) {
            this.deleteModal.idBankToDelete = id;
            this.deleteModal.showDeleteModal = true;
            this.deleteModal.bankName = (this.tableData.filter((value) => {
                return value.id === id;
            })[0]).name;
        },
    }
};
</script>
