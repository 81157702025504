<template>
    <div
        class="col p-0"
        :data-cy="'detailWithholdingLinesTable'"
    >
        <card
            class="no-border-card"
            body-classes="p-0"
            footer-classes="pb-2"
        >
            <div>
                <el-table
                    :data="queriedData"
                    row-key="lineNumber"
                    header-row-class-name="thead-light"
                >
                    <el-table-column
                        v-for="column in tableColumns"
                        :key="column.label"
                        v-bind="column"
                        :formatter="column.formatter"
                    />
                </el-table>
            </div>
        </card>
    </div>
</template>

<script>
import { Option, Select, Table, TableColumn } from "element-ui";
import clientPaginationMixin from "@/views/Mixins/clientPaginationMixin";
import BaseButton from "@/components/BaseButton";
import Modal from "@/components/Modal";
import formatterMixins from "@/mixins/formatterMixins";

export default {
    name: "DetailWithholdingLinesTable",
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
    },
    mixins: [
        clientPaginationMixin,
        BaseButton,
        Modal
    ],
    props: {
        tableData: {
            type: Array
        },
        currency: {
            type: String
        }
    },
    data() {
        return {
            tableColumns: [
                {
                    prop: "type",
                    label: this.$t("tables.column.type"),
                    minWidth: 160,
                },
                {
                    prop: "amount",
                    label: this.$t("tables.column.amount"),
                    minWidth: 120,
                    sortable: true,
                    formatter: (row) => {
                        return formatterMixins.methods.formatCurrency(this.currency, row.amount);
                    }
                },
                {
                    prop: "rate",
                    label: this.$t("tables.column.rate"),
                    minWidth: 160,
                },
                {
                    prop: "purpose",
                    label: this.$t("tables.column.purpose"),
                    minWidth: 160,
                },
            ],
            searchQuery: "",
            searchKeys: [
                'description',
            ],
            searchThreshold: 0.3,
        };
    },
};
</script>
