import { parseOptions } from "@/components/Charts/optionHelpers";
import Chart from 'chart.js'

export const Charts = {
    mode: 'light',//(themeMode) ? themeMode : 'light';
    fonts: {
        base: 'Open Sans'
    },
    colors: {
        gray: {
            100: '#f6f9fc',
            200: '#e9ecef',
            300: '#dee2e6',
            400: '#ced4da',
            500: '#adb5bd',
            600: '#8898aa',
            700: '#525f7f',
            800: '#32325d',
            900: '#212529'
        },
        theme: {
            'default': '#172b4d',
            'primary': '#5e72e4',
            'secondary': '#f4f5f7',
            'info': '#11cdef',
            'success': '#2dce89',
            'danger': '#f5365c',
            'warning': '#fb6340'
        },
        black: '#12263F',
        white: '#FFFFFF',
        transparent: 'transparent',
    }
};

function chartOptions() {
    let { colors, mode, fonts } = Charts;
    // Options
    let options = {
        defaults: {
            global: {
                responsive: true,
                maintainAspectRatio: false,
                defaultColor: (mode === 'dark') ? colors.gray[700] : colors.gray[600],
                defaultFontColor: (mode === 'dark') ? colors.gray[700] : colors.gray[600],
                defaultFontFamily: fonts.base,
                defaultFontSize: 13,
                layout: {
                    padding: 0
                },
                legend: {
                    display: false,
                    position: 'bottom',
                    labels: {
                        usePointStyle: true,
                        padding: 16
                    }
                },
                elements: {
                    point: {
                        radius: 0,
                        backgroundColor: colors.theme['primary']
                    },
                    line: {
                        tension: .4,
                        borderWidth: 4,
                        borderColor: colors.theme['primary'],
                        backgroundColor: colors.transparent,
                        borderCapStyle: 'rounded'
                    },
                    rectangle: {
                        backgroundColor: colors.theme['warning']
                    },
                    arc: {
                        backgroundColor: colors.theme['primary'],
                        borderColor: (mode == 'dark') ? colors.gray[800] : colors.white,
                        borderWidth: 4
                    }
                },
                tooltips: {
                    enabled: true,
                    mode: 'index',
                    intersect: false,
                }
            },
            pie: {
                tooltips: {
                    mode: 'point'
                }
            },
            doughnut: {
                tooltips: {
                    mode: 'point'
                },
                cutoutPercentage: 83,
                legendCallback: function (chart) {
                    let data = chart.data;
                    let content = '';

                    data.labels.forEach(function (label, index) {
                        let bgColor = data.datasets[0].backgroundColor[index];

                        content += '<span class="chart-legend-item">';
                        content += '<i class="chart-legend-indicator" style="background-color: ' + bgColor + '"></i>';
                        content += label;
                        content += '</span>';
                    });

                    return content;
                }
            }
        }
    };

    // yAxes
    Chart.scaleService.updateScaleDefaults('linear', {
        gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: (mode === 'dark') ? colors.gray[900] : colors.gray[200],
            drawBorder: false,
            drawTicks: true,
            zeroLineWidth: 1,
            zeroLineColor: (mode === 'dark') ? colors.gray[900] : colors.gray[200],
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2]
        },
        ticks: {
            beginAtZero: true,
            padding: 10,
            callback: function (value) {
                if (!(value % 10)) {
                    return value
                }
            }
        }
    });

    // xAxes
    Chart.scaleService.updateScaleDefaults('category', {
        gridLines: {
            drawBorder: false,
            drawOnChartArea: false,
            drawTicks: false,
            lineWidth: 1,
            zeroLineWidth: 1,
        },
        ticks: {
            padding: 20
        },
        maxBarThickness: 10
    });

    return options;
};

let initialized = false

export function initGlobalOptions() {
    if (initialized) {
        return
    }
    parseOptions(Chart, chartOptions());
    initialized = true
}

export const basicOptions = {
    maintainAspectRatio: false,
    legend: {
        display: false
    },
    responsive: true
};
export let blueChartOptions = {
    scales: {
        yAxes: [{
            gridLines: {
                color: Charts.colors.gray[700],
                zeroLineColor: Charts.colors.gray[700]
            }
        }]
    }
};

export let lineChartOptionsBlue = {
    ...basicOptions,
    tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest'
    },
    responsive: true,
    scales: {
        yAxes: [
            {
                barPercentage: 1.6,
                gridLines: {
                    drawBorder: false,
                    color: 'rgba(29,140,248,0.0)',
                    zeroLineColor: 'transparent'
                },
                ticks: {
                    suggestedMin: 60,
                    suggestedMax: 125,
                    padding: 20,
                    fontColor: '#9e9e9e'
                }
            }
        ],

        xAxes: [
            {
                barPercentage: 1.6,
                gridLines: {
                    drawBorder: false,
                    color: 'rgba(29,140,248,0.1)',
                    zeroLineColor: 'transparent'
                },
                ticks: {
                    padding: 20,
                    fontColor: '#9e9e9e'
                }
            }
        ]
    }
};

export let barChartOptionsGradient = {
    ...basicOptions,
    tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest'
    },
    responsive: true,
    scales: {
        yAxes: [
            {
                gridLines: {
                    drawBorder: false,
                    color: 'rgba(253,93,147,0.1)',
                    zeroLineColor: 'transparent'
                },
                ticks: {
                    suggestedMin: 60,
                    suggestedMax: 125,
                    padding: 20,
                    fontColor: '#9e9e9e'
                }
            }
        ],

        xAxes: [
            {
                gridLines: {
                    drawBorder: false,
                    color: 'rgba(253,93,147,0.1)',
                    zeroLineColor: 'transparent'
                },
                ticks: {
                    padding: 20,
                    fontColor: '#9e9e9e'
                }
            }
        ]
    }
};

export let pieChartOptions = {
    ...basicOptions,
    cutoutPercentage: 70,
    tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest'
    },

    scales: {
        yAxes: [
            {
                display: 0,
                ticks: {
                    display: false
                },
                gridLines: {
                    drawBorder: false,
                    zeroLineColor: 'transparent',
                    color: 'rgba(255,255,255,0.05)'
                }
            }
        ],

        xAxes: [
            {
                display: 0,
                barPercentage: 1.6,
                gridLines: {
                    drawBorder: false,
                    color: 'rgba(255,255,255,0.1)',
                    zeroLineColor: 'transparent'
                },
                ticks: {
                    display: false
                }
            }
        ]
    }
};

export let purpleChartOptions = {
    ...basicOptions,
    tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest'
    },
    scales: {
        yAxes: [
            {
                barPercentage: 1.6,
                gridLines: {
                    drawBorder: false,
                    color: 'rgba(29,140,248,0.0)',
                    zeroLineColor: 'transparent'
                },
                ticks: {
                    suggestedMin: 60,
                    suggestedMax: 125,
                    padding: 20,
                    fontColor: '#9a9a9a'
                }
            }
        ],

        xAxes: [
            {
                barPercentage: 1.6,
                gridLines: {
                    drawBorder: false,
                    color: 'rgba(225,78,202,0.1)',
                    zeroLineColor: 'transparent'
                },
                ticks: {
                    padding: 20,
                    fontColor: '#9a9a9a'
                }
            }
        ]
    }
};

export let orangeChartOptions = {
    ...basicOptions,
    tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest'
    },
    scales: {
        yAxes: [
            {
                barPercentage: 1.6,
                gridLines: {
                    drawBorder: false,
                    color: 'rgba(29,140,248,0.0)',
                    zeroLineColor: 'transparent'
                },
                ticks: {
                    suggestedMin: 50,
                    suggestedMax: 110,
                    padding: 20,
                    fontColor: '#ff8a76'
                }
            }
        ],

        xAxes: [
            {
                barPercentage: 1.6,
                gridLines: {
                    drawBorder: false,
                    color: 'rgba(220,53,69,0.1)',
                    zeroLineColor: 'transparent'
                },
                ticks: {
                    padding: 20,
                    fontColor: '#ff8a76'
                }
            }
        ]
    }
};
export let greenChartOptions = {
    ...basicOptions,
    tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest'
    },
    scales: {
        yAxes: [
            {
                barPercentage: 1.6,
                gridLines: {
                    drawBorder: false,
                    color: 'rgba(29,140,248,0.0)',
                    zeroLineColor: 'transparent'
                },
                ticks: {
                    suggestedMin: 50,
                    suggestedMax: 125,
                    padding: 20,
                    fontColor: '#9e9e9e'
                }
            }
        ],

        xAxes: [
            {
                barPercentage: 1.6,
                gridLines: {
                    drawBorder: false,
                    color: 'rgba(0,242,195,0.1)',
                    zeroLineColor: 'transparent'
                },
                ticks: {
                    padding: 20,
                    fontColor: '#9e9e9e'
                }
            }
        ]
    }
};

export let barChartOptions = {
    ...basicOptions,
    tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest'
    },
    scales: {
        yAxes: [
            {
                gridLines: {
                    drawBorder: false,
                    color: 'rgba(29,140,248,0.1)',
                    zeroLineColor: 'transparent'
                },
                ticks: {
                    suggestedMin: 60,
                    suggestedMax: 120,
                    padding: 20,
                    fontColor: '#9e9e9e'
                }
            }
        ],
        xAxes: [
            {
                gridLines: {
                    drawBorder: false,
                    color: 'rgba(29,140,248,0.1)',
                    zeroLineColor: 'transparent'
                },
                ticks: {
                    padding: 20,
                    fontColor: '#9e9e9e'
                }
            }
        ]
    }
};
