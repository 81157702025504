/**
 * localeService.
 */
export const localeService = {
    /**
   * Retrieve the local language if supported otherwise return the default it language.
   *
   * @returns {string}
   */
    getLocale() {
        const supportedLanguages = ['it'];
        const currentLanguage = navigator.language.substring(0, 2);

        return supportedLanguages.includes(currentLanguage) ? currentLanguage : 'it';
    }
};
