<template>
    <div class="content">
        <Header
            :title="$t('pages.fiscalDocument.title')"
        >
            <base-button
                type="neutral"
                :data-cy="'buttonCreateFiscalDocument'"
                @click="openNewFiscalDocumentModal"
            >
                {{ $t("actions.new") }}
            </base-button>
        </Header>

        <div class="container-fluid mt--6">
            <tabs
                tab-nav-classes="nav-fill flex-column flex-sm-row nav-wrapper"
                tab-content-classes="card shadow"
                @activatedTabId="onActivatedTabId"
            >
                <template #between>
                    <card
                        class="mt-2 mb-4 p-2"
                        body-classes="p-0 pb-2 pt-2"
                        footer-classes="pb-2"
                    >
                        <div class="container-fluid">
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <h2>{{ $t('tables.column.filters') }}</h2>
                                </div>
                                <base-input
                                    :label="getLabelRegistryFilter"
                                    :name="getLabelRegistryFilter"
                                    :placeholder="$t('forms.actions.select') + getLabelRegistryFilter"
                                    input-classes="form-control-alternative"
                                    :data-cy="'registryFiscalDocumentFilter'"
                                    class="col-lg-12 col-xl-7"
                                >
                                    <v-select
                                        v-model="filters.registryToFilterId"
                                        label="customLabel"
                                        class="form-control form-control-alternative"
                                        :options="registryToFilterList"
                                        :reduce="registry => registry.id"
                                    />
                                </base-input>
                                <base-input
                                    addon-left-icon="ni ni-calendar-grid-58"
                                    :label="$t('forms.fields.dateRange')"
                                    :name="$t('forms.fields.dateRange')"
                                    :placeholder="$t('forms.actions.select') + $t('forms.fields.dateRange')"
                                    :data-cy="'rangeDateFiscalDocumentFilter'"
                                    class="col-lg-12 col-xl-3"
                                >
                                    <flat-picker
                                        v-model="filters.dates.range"
                                        slot-scope="{focus, blur}"
                                        :config="configsDatePicker"
                                        class="form-control datepicker"
                                        @on-open="focus"
                                        @on-close="blur"
                                        @on-change="formatDates"
                                    />
                                </base-input>
                            </div>
                            <div class="row align-items-center">
                                <base-input
                                    v-model="filters.number"
                                    :label="$t('forms.fields.number')"
                                    :name="$t('forms.fields.number')"
                                    input-classes="form-control-alternative"
                                    :data-cy="'numberFiscalDocumentFilter'"
                                    class="col-lg-6 col-xl-2"
                                />
                                <base-input
                                    :label="$t('forms.fields.status')"
                                    :name="$t('forms.fields.status')"
                                    :placeholder="$t('forms.actions.select') + $t('forms.fields.status')"
                                    input-classes="form-control-alternative"
                                    :data-cy="'statusFiscalDocumentFilter'"
                                    class="col-lg-6 col-xl-4"
                                >
                                    <v-select
                                        v-model="filters.status"
                                        class="form-control form-control-alternative"
                                        :options="fiscalDocumentStatusList.map(status => { return { label: status.label.toUpperCase(), code: status.code }; })"
                                        :reduce="status => status.code"
                                    />
                                </base-input>
                                <base-input
                                    :label="$t('forms.fields.documentType')"
                                    :name="$t('forms.fields.documentType')"
                                    :placeholder="$t('forms.actions.select') + $t('forms.fields.documentType')"
                                    input-classes="form-control-alternative"
                                    :data-cy="'documentTypeFiscalDocumentFilter'"
                                    class="col-lg-12 col-xl-4"
                                >
                                    <v-select
                                        v-model="filters.documentType"
                                        class="form-control form-control-alternative"
                                        :options="fiscalDocumentDocumentTypeList"
                                        :reduce="documentType => documentType.code"
                                    />
                                </base-input>
                                <base-button
                                    native-type="submit"
                                    type="primary"
                                    class="search"
                                    :data-cy="'searchByFilters'"
                                    :disabled="loadingActiveDocuments || loadingPassiveDocuments"
                                    @click="() => {
                                        checkFiltersDatePresent();
                                        loadData();
                                    }"
                                >
                                    {{ $t('actions.search') }}
                                </base-button>
                                <base-button
                                    v-if="showResetFilter"
                                    native-type="submit"
                                    type="danger"
                                    :disabled="loadingActiveDocuments || loadingPassiveDocuments"
                                    @click="clearFilters"
                                >
                                    {{ $t('actions.reset') }}
                                </base-button>
                            </div>
                            <div class="row pt-3 pb-3 pt-xl-0 pb-xl-0">
                                <PresetDate
                                    class="col-12"
                                    @preset-selected="onPresetSelected"
                                />
                            </div>
                        </div>
                    </card>
                </template>
                <tab-pane
                    id="activeFiscalDocument"
                    :data-cy="'activeFiscalDocumentTab'"
                >
                    <span
                        slot="title"
                        :data-cy="'activeFiscalDocumentTabButton'"
                    >
                        <i class="fas fa-arrow-alt-circle-down mr-1" />{{ $t("pages.fiscalDocument.tabs.title.active") }}
                    </span>
                    <ActiveFiscalDocumentTable
                        :table-data="sentFiscalDocumentTable.tableData"
                        :total-elements="sentFiscalDocumentTable.totalElements"
                        :pagination="sentFiscalDocumentTable.pagination"
                        :loading="loadingActiveDocuments"
                        @data-changed="loadData"
                        @update:pagination="changeActivePagination"
                    />
                </tab-pane>

                <tab-pane
                    id="passiveFiscalDocument"
                    :data-cy="'passiveFiscalDocumentTab'"
                >
                    <span
                        slot="title"
                        :data-cy="'passiveFiscalDocumentTabButton'"
                    >
                        <i class="fas fa-arrow-alt-circle-up mr-1" />{{ $t("pages.fiscalDocument.tabs.title.passive") }}</span>
                    <PassiveFiscalDocumentTable
                        :table-data="receivedFiscalDocumentTable.tableData"
                        :total-elements="receivedFiscalDocumentTable.totalElements"
                        :pagination="receivedFiscalDocumentTable.pagination"
                        :loading="loadingPassiveDocuments"
                        @data-changed="loadData"
                        @update:pagination="changePassivePagination"
                    />
                </tab-pane>
            </tabs>
        </div>

        <NewFiscalDocument
            :key="newFiscalDocumentKey"
            :show-new="showNewFiscalDocument"
            @close-new="showNewFiscalDocument = false;"
            @data-changed="loadData"
        />
    </div>
</template>

<script>
import { fiscalDocumentClient } from "@/clients/fiscalDocumentClient";
import { registryClient } from "@/clients/registryClient";
import { localeService } from "@/services/localeService";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import formatterMixins from "@/mixins/formatterMixins";
import choicesMixins from "@/mixins/choicesMixins";
import ActiveFiscalDocumentTable from "@/views/Pages/Components/FiscalDocument/ActiveTable";
import PassiveFiscalDocumentTable from "@/views/Pages/Components/FiscalDocument/PassiveTable";
import Header from "@/components/Header";
import TabPane from "@/components/Tabs/Tab";
import Tabs from "@/components/Tabs/Tabs";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// All the supported languages must be added to let the flatPicker work correctly.
import "flatpickr/dist/l10n/it";
import vSelect from 'vue-select';
import NewFiscalDocument from "@/views/Pages/Components/FiscalDocument/Modal/New";
import PresetDate from "@/views/Pages/Components/PresetDate/PresetDate";
import yearSelectorMixins from "@/mixins/yearSelectorMixins";
import sortingFunctionsMixin from "@/views/Mixins/sortingFunctionsMixin";
import PaginationDto from "@/clients/dto/PaginationDto";

export default {
    name: 'FiscalDocument',
    components: {
        ActiveFiscalDocumentTable,
        PassiveFiscalDocumentTable,
        Header,
        Tabs,
        TabPane,
        flatPicker,
        vSelect,
        NewFiscalDocument,
        PresetDate,
    },
    mixins: [
        exceptionsMixins,
        formatterMixins,
        choicesMixins,
        yearSelectorMixins,
        sortingFunctionsMixin
    ],
    data() {
        return {
            loadingActiveDocuments: false,
            loadingPassiveDocuments: false,
            filters: {
                dates: {
                    range: this.$store.getters.fiscalDocumentFilters.dateRange,
                    fromDateToSend: null,
                    toDateToSend: null,
                },
                status: this.$store.getters.fiscalDocumentFilters.status,
                documentType: this.$store.getters.fiscalDocumentFilters.documentType,
                registryToFilterId: this.$store.getters.fiscalDocumentFilters.registryToFilterId,
                number: this.$store.getters.fiscalDocumentFilters.number,
            },
            configsDatePicker: {
                allowInput: true,
                mode: "range",
                locale: localeService.getLocale(),
                altInput: true,
                altFormat: "d-m-Y",
                showMonths: 2,
            },
            registryToFilterList: [],
            activeTab: 'activeFiscalDocument',
            showNewFiscalDocument: false,
            newFiscalDocumentKey: 0,
            sentFiscalDocumentTable: {
                tableData: [],
                totalElements: 0,
                pagination: {
                    sortBy: ["date"],
                    sortDesc: [ true ],
                    page: 1,
                    itemsPerPage: 30,
                },
            },
            receivedFiscalDocumentTable: {
                tableData: [],
                totalElements: 0,
                pagination: {
                    sortBy: ["date"],
                    sortDesc: [ true ],
                    page: 1,
                    itemsPerPage: 30,
                },
            },
        }
    },
    computed: {
        getLabelRegistryFilter() {
            return this.$t('tables.column.registry');
        },
        showResetFilter() {
            return this.filters.status?.length > 0 ||
            this.filters.documentType?.length > 0 ||
            this.filters.registryToFilterId?.length > 0 ||
            this.filters.number?.length > 0 ||
            this.filters.dates.fromDateToSend?.length > 0 ||
            this.filters.dates.toDateToSend?.length > 0;
        },
        activeSortDir() {
            return this.sentFiscalDocumentTable.pagination.sortDesc[0] ? "DESC" : "ASC";
        },
        passiveSortDir() {
            return this.receivedFiscalDocumentTable.pagination.sortDesc[0] ? "DESC" : "ASC";
        }
    },
    beforeMount() {
        this.loadRegistryForFilter();
    },
    methods: {
        changeActivePagination(pagination) {
            this.checkFiltersDatePresent();
            let fromDate = this.filters.dates.fromDateToSend;
            let toDate = this.filters.dates.toDateToSend?.length === 0 ? fromDate : this.filters.dates.toDateToSend;

            this.sentFiscalDocumentTable.pagination = pagination;
            this.loadActiveFiscalDocuments(fromDate, toDate);
        },
        changePassivePagination(pagination) {
            this.checkFiltersDatePresent();
            let fromDate = this.filters.dates.fromDateToSend;
            let toDate = this.filters.dates.toDateToSend?.length === 0 ? fromDate : this.filters.dates.toDateToSend;

            this.receivedFiscalDocumentTable.pagination = pagination;
            this.loadPassiveFiscalDocuments(fromDate, toDate);
        },
        clearFilters() {
            this.filters.status = null;
            this.filters.documentType = null;
            this.filters.registryToFilterId = null;
            this.filters.number = null;
            this.filters.dates.fromDateToSend = null;
            this.filters.dates.toDateToSend = null;
            this.filters.dates.range = null;
            this.checkFiltersDatePresent();
            this.$store.dispatch("setFiscalDocumentFilters", {
                dateRange: null,
                status: null,
                documentType: null,
                registryToFilterId: null,
                number: null,
            });
        },
        onPresetSelected(dates) {
            this.filters.dates.fromDateToSend = dates.fromDateToSend;
            this.filters.dates.toDateToSend = dates.toDateToSend;
            this.filters.dates.range = dates.range;
        },
        openNewFiscalDocumentModal() {
            this.newFiscalDocumentKey++;
            this.showNewFiscalDocument = true;
        },
        onActivatedTabId(id) {
            this.activeTab = id;
        },
        loadData() {
            this.checkFiltersDatePresent();
            const fromDate = this.filters.dates.fromDateToSend;
            const toDate = this.filters.dates.toDateToSend?.length === 0 ? fromDate : this.filters.dates.toDateToSend;
            this.$store.dispatch("setFiscalDocumentFilters", {
                dateRange: this.filters.dates.range,
                status: this.filters.status,
                documentType: this.filters.documentType,
                registryToFilterId: this.filters.registryToFilterId,
                number: this.filters.number,
            });
            this.loadActiveFiscalDocuments(fromDate, toDate);
            this.loadPassiveFiscalDocuments(fromDate, toDate);
        },
        loadRegistryForFilter() {
            registryClient.getByFilters()
                .then(result => {
                    this.registryToFilterList = result.data.filter(element => element.id !== this.$store.getters.activeRegistryId);
                    this.generateRegistryLabel(this.registryToFilterList);
                }).catch((error) => {
                    this.manageException(error.response);
                });
        },
        loadActiveFiscalDocuments(fromDate, toDate) {
            this.loadingActiveDocuments = true;

            const companySenderFiscalDocumentPaginationDto = new PaginationDto(
                this.sentFiscalDocumentTable.pagination.page,
                this.sentFiscalDocumentTable.pagination.itemsPerPage,
                this.sentFiscalDocumentTable.pagination.sortBy[0],
                this.activeSortDir,
            );

            fiscalDocumentClient.getByFilters(
                companySenderFiscalDocumentPaginationDto,
                this.filters.registryToFilterId,
                this.$store.getters.activeRegistryId,
                this.filters.status,
                this.filters.documentType,
                fromDate,
                toDate,
                this.filters.number
            ).then(result => {
                this.sentFiscalDocumentTable.tableData = result.data.data;
                this.sentFiscalDocumentTable.totalElements = result.data.meta.totalElements;
            }).catch((error) => {
                this.manageException(error);
            }).finally(() => {
                this.loadingActiveDocuments = false;
            });
        },
        loadPassiveFiscalDocuments(fromDate, toDate) {
            this.loadingPassiveDocuments = true;

            const companyRecipientFiscalDocumentPaginationDto = new PaginationDto(
                this.receivedFiscalDocumentTable.pagination.page,
                this.receivedFiscalDocumentTable.pagination.itemsPerPage,
                this.receivedFiscalDocumentTable.pagination.sortBy[0],
                this.passiveSortDir,
            );

            fiscalDocumentClient.getByFilters(
                companyRecipientFiscalDocumentPaginationDto,
                this.$store.getters.activeRegistryId,
                this.filters.registryToFilterId,
                this.filters.status,
                this.filters.documentType,
                fromDate,
                toDate,
                this.filters.number
            ).then(result => {
                this.receivedFiscalDocumentTable.tableData = result.data.data;
                this.receivedFiscalDocumentTable.totalElements = result.data.meta.totalElements;
            }).catch((error) => {
                this.manageException(error.response);
            }).finally(() => {
                this.loadingPassiveDocuments = false;
            });
        },
        formatDates(selectedDates) {
            this.filters.dates.fromDateToSend = this.formatFrontendDate(selectedDates[0]);
            this.filters.dates.toDateToSend = this.formatFrontendDate(selectedDates[1]);
        },
        checkFiltersDatePresent() {
            if (!this.filters.dates.fromDateToSend && !this.filters.dates.toDateToSend) {
                this.onPresetSelected(this.getDefaultDatesRange());
            }
        },
    }
}
</script>
