<template>
    <bread-crumb list-classes="breadcrumb-links breadcrumb-dark">
        <BreadCrumbItem>
            <li class="breadcrumb-item">
                <router-link to="/homepage">
                    <i class="fas fa-home" />
                </router-link>
            </li>
        </BreadCrumbItem>
        <BreadCrumbItem
            v-for="(route, index) in $route.matched.slice()"
            v-show="route.meta.name !== undefined"
            :key="route.name"
            :active="index === $route.matched.length - 1"
        >
            <a
                v-if="index < $route.matched.length - 1"
                :href="route.path"
            >
                {{ $tc(route.meta.name) ? $t(route.meta.name) : route.meta.name }}
            </a>
            <span v-else>{{ $tc(route.meta.name) ? $t(route.meta.name) : route.meta.name }}</span>
        </BreadCrumbItem>
    </bread-crumb>
</template>

<script>
import BreadCrumb from './Breadcrumb';
import BreadCrumbItem from './BreadcrumbItem';

export default {
    name: 'RouteBreadcrumb',
    components: {
        BreadCrumb,
        BreadCrumbItem
    },
};
</script>
