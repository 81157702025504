<template>
    <div class="edit-modal">
        <LoadingBar v-show="loading || updating" />
        <modal
            :show="true"
            modal-classes="modal-secondary"
            :size="'lg'"
            :data-cy="'updateModal'"
            @close="closeEdit"
        >
            <template slot="header">
                <h1>{{ $t("actions.update") + ' ' + typeTitle }}</h1>
            </template>
            <validation-observer
                v-slot="{handleSubmit}"
                ref="formValidator"
            >
                <form
                    class="edit-event-form"
                    @submit.prevent="handleSubmit(update)"
                >
                    <div class="form-group">
                        <base-input
                            v-model="model.registrySenderName"
                            :label="senderLabel"
                            :name="senderLabel"
                            input-classes="form-control-alternative"
                            :rules="{required: true}"
                            :data-cy="'editCashFlowSenderRegistryField'"
                        />
                        <base-input
                            :label="$t('forms.fields.tag')"
                            :placeholder="$t('forms.actions.select') + $t('forms.fields.tag')"
                            input-classes="form-control-alternative"
                            :name="$t('forms.fields.tag')"
                            :rules="{
                                required: true,
                                alpha_dash: true
                            }"
                            :data-cy="'editCashFlowTagField'"
                        >
                            <v-select
                                v-model="model.tag"
                                class="form-control form-control-alternative"
                                :options="cashFlowTagList"
                                :reduce="tag => tag.code"
                            />
                        </base-input>
                        <base-input
                            :label="$t('forms.fields.type')"
                            :placeholder="$t('forms.actions.select') + $t('forms.fields.type')"
                            input-classes="form-control-alternative"
                            :name="$t('forms.fields.type')"
                            :rules="{
                                required: true,
                                alpha_dash: true
                            }"
                            :data-cy="'editCashFlowTypeField'"
                        >
                            <v-select
                                v-model="model.type"
                                class="form-control form-control-alternative"
                                :options="cashFlowTypeList"
                                :reduce="type => type.code"
                            />
                        </base-input>
                        <base-input
                            :label="$t('forms.fields.description')"
                            :name="$t('forms.fields.description')"
                            input-classes="form-control-alternative"
                            :rules="{
                                required: false,
                            }"
                            :data-cy="'editCashFlowDescriptionField'"
                        >
                            <textarea
                                v-model="model.description"
                                class="form-control"
                                rows="3"
                            />
                        </base-input>
                        <div class="row">
                            <base-input
                                :label="$t('forms.fields.currency')"
                                :placeholder="$t('forms.actions.select') + $t('forms.fields.currency')"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.currency')"
                                class="col-lg-6"
                                :rules="{
                                    required: true,
                                    length: 3
                                }"
                                :data-cy="'editCashFlowCurrencyField'"
                            >
                                <v-select
                                    v-model="model.currency"
                                    class="form-control form-control-alternative"
                                    :options="currencyList"
                                    :reduce="currency => currency.code"
                                />
                            </base-input>
                            <base-input
                                v-model="model.vat"
                                :label="$t('forms.fields.vatAmount')"
                                :name="$t('forms.fields.vatAmount')"
                                class="col-lg-6"
                                :rules="{
                                    required: true,
                                    decimal: true
                                }"
                                :data-cy="'editCashFlowVatField'"
                            />
                        </div>
                        <div class="row">
                            <base-input
                                v-model="model.amount"
                                :label="$t('forms.fields.amount')"
                                :name="$t('forms.fields.amount')"
                                class="col-lg-6"
                                :rules="{
                                    required: true,
                                    decimal: true
                                }"
                                :data-cy="'editCashFlowAmountField'"
                            />
                            <base-input
                                addon-left-icon="ni ni-calendar-grid-58"
                                :label="$t('forms.fields.expenseDate')"
                                :name="$t('forms.fields.expenseDate')"
                                :placeholder="$t('forms.actions.select') + $t('forms.fields.expenseDate')"
                                :data-cy="'editCashFlowExpenseDateField'"
                                class="col-6"
                                :rules="{required:true}"
                            >
                                <flat-picker
                                    v-model="model.expenseDate"
                                    slot-scope="{focus, blur}"
                                    :config="configDate"
                                    class="form-control datepicker"
                                    @on-open="focus"
                                    @on-close="blur"
                                    @on-change="formatDate"
                                />
                            </base-input>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <label class="form-control-label">
                                    {{ $t('forms.fields.attachments') }}
                                </label>
                            </div>
                            <ul class="col-12 m-0">
                                <template v-for="attachment in model.attachments">
                                    <li
                                        :key="attachment.id"
                                        class="list-group-item"
                                    >
                                        <div class="row align-items-center">
                                            <div class="col-auto">
                                                <i class="fas fa-file-alt pr-2" />
                                            </div>
                                            <div class="col">
                                                <a
                                                    :href="attachment.url"
                                                    :download="attachment.name"
                                                    target="_blank"
                                                >{{ attachment.name }}</a>
                                            </div>
                                            <div class="col-auto">
                                                <a
                                                    class="btn btn-danger btn-sm"
                                                    :data-cy="`deleteAttachment${attachment.id}`"
                                                    @click="removeAttachment(attachment)"
                                                >
                                                    <i class="fas fa-trash" />
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                            <div class="col-12">
                                <DropzoneFileUpload
                                    :accepted-files="'application/pdf'"
                                    @encodedFilesChanged="encodedFilesChanged"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <base-button
                            native-type="submit"
                            type="primary"
                            class="new-event--add"
                            :data-cy="'update-edit-cashFlow'"
                            :disabled="updating"
                            :loading="updating"
                        >
                            {{ $t("actions.update") }}
                        </base-button>
                        <base-button
                            type="link"
                            :data-cy="'close-edit-cashFlow'"
                            @click="closeEdit"
                        >
                            {{ $t("actions.cancel") }}
                        </base-button>
                    </div>
                </form>
            </validation-observer>
        </modal>
    </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { cashFlowClient } from "@/clients/cashFlowClient";
import { CashFlowTags, CashFlowTypes } from "@/constants/cashFlowContants";
import { localeService } from "@/services/localeService";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import choicesMixins from "@/mixins/choicesMixins";
import formatterMixins from "@/mixins/formatterMixins";
import vatCalculationMixins from "@/mixins/vatCalculationMixins";
import notificationsMixins from "@/mixins/notificationsMixins";
import BaseButton from "@/components/BaseButton";
import Modal from "@/components/Modal";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import CashFlowDto from "@/clients/dto/CashFlowDto";
import vSelect from 'vue-select';
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// All the supported languages must be added to let the flatPicker work correctly.
import "flatpickr/dist/l10n/it";
import DropzoneFileUpload from "@/views/Pages/Components/FileUpload/DropzoneFileUpload";
import CashFlowAttachmentDto from "@/clients/dto/CashFlowAttachmentDto";

export default {
    name: "EditCashFlow",
    components: {
        BaseButton,
        Modal,
        LoadingBar,
        ValidationObserver,
        vSelect,
        flatPicker,
        DropzoneFileUpload,
    },
    mixins: [
        exceptionsMixins,
        choicesMixins,
        formatterMixins,
        vatCalculationMixins,
        notificationsMixins
    ],
    props: {
        editCashFlowId: {
            type: Number,
        }
    },
    data() {
        return {
            configDate: {
                allowInput: true,
                mode: "single",
                locale: localeService.getLocale(),
                altInput: true,
                altFormat: "d-m-Y",
                showMonths: 1,
                maxDate: new Date()
            },
            model: {
                id: null,
                registrySenderName: null,
                tag: null,
                type: null,
                description: null,
                amount: null,
                vat: null,
                currency: null,
                expenseDate: null,
                attachments: [],
            },
            loading: false,
            updating: false,
            isIncome: null,
            encodedFiles: [],
        }
    },
    computed: {
        senderLabel() {
            return this.isIncome ? this.$t("tables.column.debtor") : this.$t("tables.column.creditor");
        },
        typeTitle() {
            return this.isIncome ? this.$t("pages.cashFlow.cashFlow.incoming") : this.$t("pages.cashFlow.cashFlow.outcoming");
        },
        typeList() {
            return Object.values(CashFlowTypes);
        },
        tagList() {
            return Object.values(CashFlowTags);
        }
    },
    mounted() {
        this.loadCashFlow();
    },
    methods: {
        loadCashFlow() {
            if (this.editCashFlowId) {
                this.loading = true;
                cashFlowClient.getById(this.editCashFlowId).then(response => {
                    this.isIncome = response.data.income;
                    this.model.id = response.data.id;
                    this.model.registrySenderName = response.data.sender;
                    this.model.tag = response.data.tag;
                    this.model.type = response.data.type;
                    this.model.description = response.data.description;
                    this.model.amount = response.data.amount;
                    this.model.vat = response.data.vat;
                    this.model.currency = response.data.currency;
                    this.model.expenseDate = this.formatFrontendDate(new Date(response.data.expenseDate));
                    this.model.attachments = response.data.attachments;
                }).catch((error) => {
                    this.manageException(error.response);
                }).then(() => {
                    this.loading = false;
                });
            }
        },
        formatDate(selectedDate) {
            this.model.expenseDate = this.formatFrontendDate(selectedDate[0]);
        },
        generateLabelInList(list) {
            list.forEach(element => element.customLabel = element.name + ' (' + element.email + ')');
        },
        removeAttachment(attachment) {
            this.showSwal(
                'warning',
                this.$t("pages.cashFlow.deleteAttachment.title"),
                null,
                `<p>${this.$t("pages.cashFlow.deleteAttachment.message", {
                    attachmentName: attachment.name
                })}</p> <p>${this.$t("pages.cashFlow.deleteAttachment.warningMessage")}</p>`,
                true
            ).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    cashFlowClient.deleteAttachment(this.model.id, attachment.id).then(response => {
                        this.$notify({
                            type: "success",
                            icon: "ni ni-notification-70",
                            message: this.$t("notifications.success.updated")
                        });
                        this.loadCashFlow();
                    }).catch((error) => {
                        this.manageException(error.response);
                    }).then(() => {
                        this.loading = false;
                    });
                }
            });
        },
        update() {
            this.updating = true;

            const cashFlowDto = new CashFlowDto(
                null,
                this.model.registrySenderName,
                this.$store.getters.activeRegistryId,
                this.model.tag,
                this.model.type,
                this.model.description,
                this.model.amount,
                this.model.vat,
                this.calculateTaxFromAmountWithVat(this.model.amount, this.model.vat),
                this.model.currency,
                this.model.expenseDate
            );

            cashFlowClient.update(this.editCashFlowId, cashFlowDto).then(() => {
                if (this.encodedFiles.length === 0) {
                    this.$notify({
                        type: "success",
                        icon: "ni ni-notification-70",
                        message: this.$t("notifications.success.updated")
                    });
                    this.$emit('data-changed');
                    this.closeEdit();
                }

                this.encodedFiles.forEach((encodedData, index) => {
                    this.updating = true;
                    const attachmentDto = new CashFlowAttachmentDto(encodedData);
                    cashFlowClient.createAttachment(this.model.id, attachmentDto).then(() => {
                    }).catch((error) => {
                        this.manageException(error.response);
                    }).then(() => {
                        if (index === (this.encodedFiles.length - 1)) {
                            this.updating = false;
                            this.$notify({
                                type: "success",
                                icon: "ni ni-notification-70",
                                message: this.$t("notifications.success.updated")
                            });
                            this.$emit('data-changed');
                            this.closeEdit();
                        }
                    });
                });
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.updating = false;
            });
        },
        closeEdit() {
            this.showEditModal = false;
            this.$emit('close-edit');
        },
        encodedFilesChanged(encodedFiles) {
            this.encodedFiles = encodedFiles;
        },
    }
}
</script>
