/**
 * formatterMixins.
 */
export default {
    methods: {
        /**
         * Format the date provided from ISO to dd/mm/YYYY.
         *
         * @param {Date} date
         *
         * @returns {string}
         */
        formatBackendDate(date) {
            if (!date) {
                return '';
            }

            const explodedDate = this.convertDateToObject(date);

            return `${explodedDate.day}/${explodedDate.month}/${explodedDate.year}`;
        },
        /**
         * Format the data provided by backend to be understood by the view model.
         *
         * @param {Date} date
         *
         * @returns {string}
         */
        formatFrontendDate(date) {
            if (!date) {
                return '';
            }

            const explodedDate = this.convertDateToObject(date);

            return `${explodedDate.year}-${explodedDate.month}-${explodedDate.day}`;
        },
        /**
         * Convert Date provided to object with month, day and year attribute.
         *
         * @param {Date} date
         *
         * @returns {{month: string, year: string, day: string}}
         * @internal
         */
        convertDateToObject(date) {
            let month = String(date.getMonth() + 1);
            let day = String(date.getDate());
            const year = String(date.getFullYear());

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return {
                month,
                day,
                year
            };
        },
        /**
         * Format the amount by the currency.
         *
         * @param {string} currency
         * @param {number} amount
         *
         * @returns {string}
         */
        formatCurrency(currency, amount) {
            if (!currency || amount === null || amount === undefined) {
                return '';
            }

            return new Intl.NumberFormat(navigator.language, {
                style: 'currency',
                currency: currency
            }).format(amount)
        },
        /**
         * Format the value provided to be displayed as percentage.
         *
         * @param {number|null} value
         *
         * @returns {string}
         */
        formatPercentage(value) {
            return value === null ? "n/a" : (new Intl.NumberFormat(navigator.language)).format(value) + " %";
        },
    }
}
