import { FiscalDocumentCustomizablePaymentDeadlineDate } from "@/constants/fiscalDocumentCostants";

/**
 * fiscalDocumentPaymentDeadlineMixins.
 */
export default {
    computed: {
        fiscalDocumentPaymentDeadlineDays() {
            let choices = [
                {
                    code: FiscalDocumentCustomizablePaymentDeadlineDate.CUSTOM,
                    value: FiscalDocumentCustomizablePaymentDeadlineDate.CUSTOM,
                    label: this.$t('forms.fields.customizable')
                }
            ];

            if (this.dateSelectedForPaymentDeadlineCalculation == null) {
                return choices;
            }

            return [
                {
                    code: '0',
                    value: 0,
                    label: this.$t('forms.fields.immediate')
                },
                {
                    code: 'EM',
                    value: this.calculatePaymentDeadlineDaysEndOfMonth(0),
                    label: this.$t('forms.fields.endOfMonth')
                },
                {
                    code: '30',
                    value: 30,
                    label: '30'
                },
                {
                    code: '30EM',
                    value: this.calculatePaymentDeadlineDaysEndOfMonth(30),
                    label: `30 ${this.$t('forms.fields.endOfMonth')}`
                },
                {
                    code: '60',
                    value: 60,
                    label: '60'
                },
                {
                    code: '60EM',
                    value: this.calculatePaymentDeadlineDaysEndOfMonth(60),
                    label: `60 ${this.$t('forms.fields.endOfMonth')}`
                },
                {
                    code: '90',
                    value: 90,
                    label: '90'
                },
                {
                    code: '90EM',
                    value: this.calculatePaymentDeadlineDaysEndOfMonth(90),
                    label: `90 ${this.$t('forms.fields.endOfMonth')}`
                },
                {
                    code: '120',
                    value: 120,
                    label: '120'
                },
                {
                    code: '120EM',
                    value: this.calculatePaymentDeadlineDaysEndOfMonth(120),
                    label: `120 ${this.$t('forms.fields.endOfMonth')}`
                },
                {
                    code: '180',
                    value: 180,
                    label: '180'
                },
                {
                    code: '180EM',
                    value: this.calculatePaymentDeadlineDaysEndOfMonth(180),
                    label: `180 ${this.$t('forms.fields.endOfMonth')}`
                },
                ...choices
            ];
        },
    },
    methods: {
        /**
         * Calculate the PaymentDeadlineDaysEndOfMonth by days provided.
         *
         * @param {Number} days
         *
         * @returns {number}
         */
        calculatePaymentDeadlineDaysEndOfMonth(days) {
            let paymentDeadLineDate = new Date(this.dateSelectedForPaymentDeadlineCalculation);
            paymentDeadLineDate.setDate(paymentDeadLineDate.getDate() + (days));
            paymentDeadLineDate = new Date(paymentDeadLineDate.getFullYear(), paymentDeadLineDate.getMonth() + 1, 0);

            return this.calculatePaymentDeadlineDaysByDate(paymentDeadLineDate);
        },
        /**
         * Calculate the PaymentDeadlineDays by date provided.
         *
         * @param {Date} date
         *
         * @returns {number}
         */
        calculatePaymentDeadlineDaysByDate(date) {
            let paymentDeadLineDate = date;

            const toDay = new Date(this.dateSelectedForPaymentDeadlineCalculation);
            toDay.setDate(toDay.getDate());

            const fromDate = Date.UTC(toDay.getFullYear(), toDay.getMonth(), toDay.getDate());
            const toDate = Date.UTC(paymentDeadLineDate.getFullYear(), paymentDeadLineDate.getMonth(), paymentDeadLineDate.getDate());

            return Math.floor((toDate - fromDate) / (1000 * 60 * 60 * 24));
        }

    }
}
