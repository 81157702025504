<template>
    <div class="content-modal">
        <validation-observer
            v-slot="{handleSubmit}"
            ref="formValidator"
        >
            <form
                class="new-event-form"
                @submit.prevent="handleSubmit(create)"
            >
                <div class="form-group">
                    <div class="row">
                        <base-input
                            :label="$t('forms.fields.recipient')"
                            :name="$t('forms.fields.recipient')"
                            class="col-11"
                            :placeholder="$t('forms.actions.select') + $t('forms.fields.recipient')"
                            input-classes="form-control-alternative"
                            :rules="{required: true}"
                            :data-cy="'newFiscalDocumentRecipientRegistryField'"
                        >
                            <v-select
                                v-model="model.registryRecipientId"
                                label="customLabel"
                                class="form-control form-control-alternative"
                                :options="registryList"
                                :reduce="registry => registry.id"
                                @option:selected="(data) => {
                                    loadInvoices();
                                    onChangeRegistry(data);
                                }"
                            />
                        </base-input>
                        <div class="col-1 d-flex justify-content-end align-items-center">
                            <base-button
                                type="primary"
                                :data-cy="'buttonCreateRegistry'"
                                size="sm"
                                class="p-2"
                                @click="openNewRegistryModal"
                            >
                                <span class="btn-inner--icon"><i class="fas fa-plus" /></span>
                            </base-button>
                        </div>
                        <base-checkbox
                            v-model="model.sendEmail"
                            :label="$t('forms.fields.sendEmail')"
                            class="col-12 pl-5 form-group"
                            :data-cy="'newFiscalDocumentSendEmailCheckBox'"
                            :name="$t('forms.fields.sendEmail')"
                        >
                            {{ $t('forms.fields.sendEmail') }}
                        </base-checkbox>
                    </div>

                    <div class="row">
                        <base-input
                            addon-left-icon="ni ni-calendar-grid-58"
                            :label="$t('forms.fields.date')"
                            :name="$t('forms.fields.date')"
                            :placeholder="$t('forms.actions.select') + $t('forms.fields.date')"
                            :data-cy="'newFiscalDocumentDateField'"
                            class="col-6"
                            :rules="{required:true}"
                        >
                            <flat-picker
                                v-model="model.date"
                                slot-scope="{focus, blur}"
                                :config="configDate"
                                class="form-control datepicker"
                                @on-open="focus"
                                @on-close="blur"
                                @on-change="(date) => {
                                    formatDate(date);
                                    fetchFiscalDocumentNumber(date);
                                }"
                            />
                        </base-input>
                        <base-input
                            :label="$t('forms.fields.paymentDeadLineDays')"
                            :name="$t('forms.fields.paymentDeadLineDays')"
                            input-classes="form-control-alternative"
                            class="col-6"
                            :rules="{
                                required: true,
                            }"
                            :data-cy="'newFiscalDocumentPaymentDeadLineDaysField'"
                        >
                            <v-select
                                v-model="paymentDeadLineDays"
                                class="form-control form-control-alternative"
                                :options="fiscalDocumentPaymentDeadlineDays"
                                :reduce="deadlineDays => deadlineDays.code"
                                @option:selected="selectedPaymentDeadlineDays"
                            />
                        </base-input>
                        <base-input
                            v-if="!useCustomizablePaymentDeadlineDays && paymentDeadLineDays !== null"
                            class="col-12"
                            :disabled="true"
                            :label="$t('forms.fields.paymentDeadlineDate')"
                            :type="'Date'"
                            :name="$t('forms.fields.paymentDeadlineDate')"
                            :placeholder="$t('forms.actions.select') + $t('forms.fields.paymentDeadlineDate')"
                            :value="paymentDeadlineDate"
                        />
                        <base-input
                            v-if="useCustomizablePaymentDeadlineDays"
                            addon-left-icon="ni ni-calendar-grid-58"
                            :label="$t('forms.fields.paymentDeadlineDate')"
                            :name="$t('forms.fields.paymentDeadlineDate')"
                            :placeholder="$t('forms.actions.select') + $t('forms.fields.paymentDeadlineDate')"
                            :data-cy="'newFiscalDocumentPaymentDeadLineDateField'"
                            class="col-12"
                            :rules="{required: true}"
                        >
                            <flat-picker
                                v-model="customizedPaymentDeadLineDate"
                                slot-scope="{focus, blur}"
                                :config="configPaymentDate"
                                class="form-control datepicker"
                                @on-open="focus"
                                @on-close="blur"
                                @on-change="(date) => {
                                    formatCustomizedPaymentDeadlineDate(date);
                                }"
                            />
                        </base-input>
                        <base-input
                            v-if="isPassiveCreditNote"
                            v-model="model.invoiceParentId"
                            :label="$t('forms.fields.relatedInvoice')"
                            class="col-6"
                            :name="$t('forms.fields.relatedInvoice')"
                            :data-cy="'newFiscalDocumentRelatedInvoiceField'"
                            :rules="{ required: true }"
                        >
                            <v-select
                                v-model="model.invoiceParentId"
                                class="form-control form-control-alternative"
                                :options="parentInvoiceList"
                                :reduce="invoice => invoice.id"
                                @input="setRelatedInvoice"
                            />
                        </base-input>
                        <base-input
                            :value="fiscalDocumentNumber"
                            disabled
                            :loading="loadingFiscalDocumentNumber"
                            :label="$t('forms.fields.number')"
                            :name="$t('forms.fields.number')"
                            input-classes="form-control-alternative"
                            class="col-6"
                            :data-cy="'newFiscalDocumentNumberField'"
                            :rules="{
                                required: true,
                                alpha_dash: true
                            }"
                        />
                        <base-input
                            :label="$t('forms.fields.paymentType')"
                            :placeholder="$t('forms.actions.select') + $t('forms.fields.paymentType')"
                            input-classes="form-control-alternative"
                            :name="$t('forms.fields.paymentType')"
                            :class="{
                                'col-6': isActiveInvoice,
                                'col-12': isPassiveCreditNote
                            }"
                            :rules="{
                                required: true,
                                alpha_dash: true
                            }"
                            :data-cy="'newFiscalDocumentPaymentTypeField'"
                        >
                            <v-select
                                v-model="model.paymentType"
                                class="form-control form-control-alternative"
                                :options="fiscalDocumentPaymentTypeList"
                                :reduce="paymentType => paymentType.code"
                            />
                        </base-input>
                        <base-input
                            :label="$t('forms.fields.paymentMode')"
                            :placeholder="$t('forms.actions.select') + $t('forms.fields.paymentMode')"
                            input-classes="form-control-alternative"
                            class="col-6"
                            :name="$t('forms.fields.paymentMode')"
                            :rules="{
                                required: true,
                                alpha_dash: true
                            }"
                            :data-cy="'newFiscalDocumentPaymentModeField'"
                        >
                            <v-select
                                v-model="model.paymentMode"
                                class="form-control form-control-alternative"
                                :options="fiscalDocumentPaymentModeList"
                                :reduce="paymentMode => paymentMode.code"
                            />
                        </base-input>
                        <base-input
                            :label="$t('forms.fields.currency')"
                            :placeholder="$t('forms.actions.select') + $t('forms.fields.currency')"
                            input-classes="form-control-alternative"
                            :name="$t('forms.fields.currency')"
                            class="col-6"
                            :rules="{
                                required: true,
                                length: 3
                            }"
                            :data-cy="'newFiscalDocumentCurrencyField'"
                        >
                            <v-select
                                v-model="model.currency"
                                class="form-control form-control-alternative"
                                :options="currencyList"
                                :reduce="currency => currency.code"
                            />
                        </base-input>
                        <base-input
                            :label="$t('forms.fields.vatAmount')"
                            class="col-12"
                            :name="$t('forms.fields.vatAmount')"
                            :data-cy="'newFiscalDocumentVatNatureField'"
                            :rules="{ required: true }"
                        >
                            <v-select
                                v-model="vatNature"
                                class="form-control form-control-alternative h-auto"
                                :options="fiscalDocumentVatNatureList"
                                :reduce="vatNatureChoice => vatNatureChoice.code"
                            />
                        </base-input>
                        <base-checkbox
                            v-model="showRevenueStamp"
                            :label="$t('forms.fields.showRevenueStamp')"
                            class="col-12 pl-5 form-group"
                            :data-cy="'newFiscalDocumentShowRevenueStampCheckBox'"
                            :name="$t('forms.fields.showRevenueStamp')"
                        >
                            {{ $t('forms.fields.showRevenueStamp') }}
                        </base-checkbox>
                        <base-input
                            v-show="showRevenueStamp"
                            v-model="model.revenueStamp"
                            :label="$t('forms.fields.revenueStampAmount')"
                            :name="$t('forms.fields.revenueStampAmount')"
                            input-classes="form-control-alternative"
                            class="col-6"
                            :data-cy="'newFiscalDocumentRevenueStampField'"
                            :rules="{
                                required: showRevenueStamp,
                                decimal: true
                            }"
                        />
                        <base-input
                            :label="$t('forms.fields.purpose')"
                            :name="$t('forms.fields.purpose')"
                            input-classes="form-control-alternative"
                            :rules="{
                                required: true,
                                regex: /^[\u0000-\u00FF]+$/,
                                min: 1,
                                max: 200,

                            }"
                            class="col-12"
                            :data-cy="'newFiscalDocumentPurposeField'"
                        >
                            <textarea
                                v-model="model.purpose"
                                class="form-control"
                                rows="3"
                            />
                        </base-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 pb-3">
                        <div class="row">
                            <div class="col-6">
                                <h2>{{ $t('forms.section.detailLines') }}</h2>
                            </div>
                            <div class="col-6 d-flex justify-content-end">
                                <base-button
                                    type="primary"
                                    :data-cy="'addNewDetailLineButton'"
                                    size="sm"
                                    @click="addDetailLine"
                                >
                                    <span class="btn-inner--icon"><i class="fas fa-plus" /></span>
                                </base-button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <template v-for="detailLine in model.detailLines">
                            <div
                                :key="detailLine.id"
                                class="row"
                            >
                                <div class="col-2">
                                    <base-input
                                        v-model="detailLine.quantity"
                                        :label="$t('forms.fields.quantity')"
                                        :name="$t('forms.fields.quantity')"
                                        input-classes="form-control-alternative"
                                        :data-cy="'newFiscalDocumentDetailLineQuantity' + detailLine.id + 'Field'"
                                        type="number"
                                        :rules="{
                                            required: true,
                                            integer: true,
                                            min_value: 1,
                                        }"
                                    />
                                </div>
                                <div class="col-5">
                                    <base-input
                                        v-model="detailLine.description"
                                        :label="$t('forms.fields.description')"
                                        :name="$t('forms.fields.description')"
                                        input-classes="form-control-alternative"
                                        :data-cy="'newFiscalDocumentDetailLineDescription' + detailLine.id + 'Field'"
                                        :rules="{
                                            required: true,
                                            regex: /^[\u0000-\u00FF]+$/,
                                            min: 1,
                                            max: 100,
                                        }"
                                    />
                                </div>
                                <div class="col-4">
                                    <base-input
                                        v-model="detailLine.unitPrice"
                                        :label="$t('forms.fields.unitPrice')"
                                        :name="$t('forms.fields.unitPrice')"
                                        input-classes="form-control-alternative"
                                        :data-cy="'newFiscalDocumentDetailLineUnitPrice' + detailLine.id + 'Field'"
                                        :rules="{
                                            required: true,
                                            decimal: true
                                        }"
                                    />
                                </div>
                                <div
                                    v-if="showDeleteDetailLine"
                                    class="col-1 align-items-center d-flex"
                                >
                                    <base-button
                                        type="danger"
                                        :icon="true"
                                        size="sm"
                                        :data-cy="'newFiscalDocumentDetailLineRemove' + detailLine.id + 'Button'"
                                        @click="removeDetailLine(detailLine.id)"
                                    >
                                        <span class="btn-inner--icon"><i class="fas fa-times" /></span>
                                    </base-button>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="col-12 pb-3">
                        <div class="row">
                            <div class="col-12">
                                <h2>{{ $t('forms.section.summary') }}</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <dl class="row">
                            <dt class="col-sm-3">
                                {{ $t('forms.fields.taxableImport') }}
                            </dt>
                            <dd
                                class="col-sm-9"
                                :data-cy="'newFiscalDocumentTaxableImportCalculated'"
                            >
                                {{ taxableAmountDetailLines }}
                            </dd>
                            <dt class="col-sm-3">
                                {{ $t('forms.fields.tax') }}
                            </dt>
                            <dd
                                class="col-sm-9"
                                :data-cy="'newFiscalDocumentTaxAmountCalculated'"
                            >
                                {{ taxOfDetailLines }}
                            </dd>
                            <dt class="col-sm-3">
                                {{ $t('forms.fields.invoicePrice') }}
                            </dt>
                            <dd
                                class="col-sm-9"
                                :data-cy="'newFiscalDocumentTotalCalculated'"
                            >
                                {{ totalOfDetailLines }}
                            </dd>
                        </dl>
                    </div>
                    <div class="col-12 pb-4">
                        <label class="form-control-label">
                            {{ $tc('forms.section.attachments') }}
                        </label>
                        <DropzoneFileUpload
                            :accepted-files="'application/pdf'"
                            @encodedFilesChanged="encodedFilesChanged"
                        />
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <base-button
                        native-type="submit"
                        type="primary"
                        class="new-event--add"
                        :data-cy="'createNewFiscalDocumentButton'"
                        :disabled="creating"
                        :loading="creating"
                    >
                        {{ $t("actions.new") }}
                    </base-button>
                    <base-button
                        type="link"
                        @click="closeNew"
                    >
                        {{ $t("actions.cancel") }}
                    </base-button>
                </div>
            </form>
        </validation-observer>
        <LoadingBar v-show="loading" />
        <NewRegistry
            :key="newRegistryKey"
            :show-new="showNewRegistry"
            @close-new="showNewRegistry = false;"
            @data-changed="loadRegistryList"
        />
        <EditRegistry
            :show-edit="registryEdit.show"
            :edit-registry-id="registryEdit.id"
            @data-changed="loadRegistryList"
            @close-edit="registryEdit.show = false;"
        />
    </div>
</template>

<script>
import { fiscalDocumentClient } from "@/clients/fiscalDocumentClient";
import { registryClient } from "@/clients/registryClient";
import {
    FiscalDocumentCustomizablePaymentDeadlineDate,
    FiscalDocumentDocumentType,
    FiscalDocumentExceptionCodes,
    FiscalDocumentVatNatureMapping
} from "@/constants/fiscalDocumentCostants";
import choicesMixins from "@/mixins/choicesMixins";
import fiscalDocumentPaymentDeadlineMixins from "@/mixins/fiscalDocumentPaymentDeadlineMixins";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import formatterMixins from "@/mixins/formatterMixins";
import notificationsMixins from "@/mixins/notificationsMixins";
import vSelect from 'vue-select';
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// All the supported languages must be added to let the flatPicker work correctly.
import "flatpickr/dist/l10n/it";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import FiscalDocumentDetailLineOutcomingDto from "@/clients/dto/FiscalDocumentDetailLineOutcomingDto";
import FiscalDocumentOutcomingDto from "@/clients/dto/FiscalDocumentOutcomingDto";
import NewRegistry from "@/views/Pages/Components/Registry/Modal/New";
import EditRegistry from "@/views/Pages/Components/Registry/Modal/Edit";
import DropzoneFileUpload from "@/views/Pages/Components/FileUpload/DropzoneFileUpload";
import FiscalDocumentAttachmentDto from "@/clients/dto/FiscalDocumentAttachmentDto";
import PaginationDto from "@/clients/dto/PaginationDto";

export default {
    name: 'NewFiscalDocumentOutcoming',
    components: {
        vSelect,
        LoadingBar,
        NewRegistry,
        flatPicker,
        EditRegistry,
        DropzoneFileUpload,
    },
    mixins: [
        choicesMixins,
        exceptionsMixins,
        formatterMixins,
        notificationsMixins,
        fiscalDocumentPaymentDeadlineMixins
    ],
    props: {
        type: {
            type: String,
            required: true,
            validator: type => type === 'activeInvoice' || type === 'passiveCreditNote'
        },
    },
    data() {
        return {
            configDate: {
                allowInput: true,
                mode: "single",
                locale: navigator.language.substring(0, 2),
                altInput: true,
                altFormat: "d-m-Y",
                showMonths: 1,
                maxDate: new Date()
            },
            configPaymentDate: {
                allowInput: true,
                mode: "single",
                locale: navigator.language.substring(0, 2),
                altInput: true,
                altFormat: "d-m-Y",
                showMonths: 1,
            },
            showRevenueStamp: false,
            vatNature: null,
            paymentDeadLineDays: null,
            model: {
                registrySenderId: null,
                registryRecipientId: null,
                documentType: null,
                paymentType: null,
                paymentMode: null,
                paymentDeadLineDays: null,
                customizedPaymentDeadLineDays: null,
                currency: 'EUR',
                date: null,
                number: null,
                detailLines: [],
                sendEmail: false,
                invoiceParentId: null,
                revenueStamp: 2,
                purpose: null,
            },
            dateSelectedForPaymentDeadlineCalculation: null,
            encodedFiles: [],
            detailLineCounter: 0,
            creating: false,
            loading: false,
            parentInvoiceList: [],
            registryList: [],
            newRegistryKey: 0,
            showNewRegistry: false,
            loadingFiscalDocumentNumber: false,
            selectedDates: null,
            registryEdit: {
                show: false,
                id: null,
            },
            useCustomizablePaymentDeadlineDays: false,
            customizedPaymentDeadLineDate: null,
            relatedInvoicePagination: {
                pageElements: 50,
            }
        }
    },
    computed: {
        isActiveInvoice() {
            return this.type === 'activeInvoice';
        },
        isPassiveCreditNote() {
            return this.type === 'passiveCreditNote';
        },
        fiscalDocumentNumber() {
            return this.model.number;
        },
        showDeleteDetailLine() {
            return this.model.detailLines.length > 1;
        },
        calculateTaxableImportOfDetailLines() {
            return this.model.detailLines.reduce((total, item) =>  total + (item.unitPrice * item.quantity), 0);
        },
        calculateTaxOfDetailLines() {
            const vat = FiscalDocumentVatNatureMapping[this.vatNature]?.vat;

            return !vat ? 0 : (this.calculateTaxableImportOfDetailLines * vat) / 100;
        },
        taxOfDetailLines() {
            return this.formatCurrency(this.model.currency, this.calculateTaxOfDetailLines);
        },
        totalOfDetailLines() {
            const total = this.calculateTaxableImportOfDetailLines + this.calculateTaxOfDetailLines;
            return this.formatCurrency(this.model.currency, total);
        },
        taxableAmountDetailLines() {
            return this.formatCurrency(this.model.currency, this.calculateTaxableImportOfDetailLines);
        },
        taxAmountDetailLines() {
            return this.formatCurrency(this.model.currency, this.calculateTaxOfDetailLines);
        },
        paymentDeadlineDate() {
            let paymentDeadLineDate = new Date(this.dateSelectedForPaymentDeadlineCalculation);
            if (this.model.paymentDeadLineDays > 0) {
                paymentDeadLineDate.setDate(paymentDeadLineDate.getDate() + (this.model.paymentDeadLineDays));
            }
            const correctTimeDateInUTC = Date.UTC(paymentDeadLineDate.getFullYear(), paymentDeadLineDate.getMonth(), paymentDeadLineDate.getDate())

            return (new Date(correctTimeDateInUTC)).toISOString().split('T')[0];
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.model.documentType = this.type === 'activeInvoice' ? FiscalDocumentDocumentType.TD01 : FiscalDocumentDocumentType.TD04;
            this.model.registrySenderId = this.$store.getters.activeRegistryId;
            this.addDetailLine();
            this.loadRegistryList();
            this.showNewForm = true;
        },
        onChangeRegistry(registry) {
            if (registry.location === null) {
                this.showSwal(
                    'warning',
                    this.$t("pages.fiscalDocument.creating.registryWithoutLocationTitle"),
                    null,
                    `<p>${this.$t("pages.fiscalDocument.creating.registryWithoutLocationMessage", {
                        registryName: registry.name
                    })}</p> <p>${this.$t("pages.fiscalDocument.creating.registryWithoutLocationCtaMessage")}</p>`
                ).then(result => {
                    if (result.isConfirmed) {
                        this.registryEdit.id = registry.id;
                        this.registryEdit.show = true;
                    }
                });

                this.model.registryRecipientId = null;
            }
        },
        closeNew() {
            this.$emit('close-new');
        },
        addDetailLine() {
            this.model.detailLines.push(this.generateEmptyDetailLine());
        },
        generateEmptyDetailLine() {
            return {
                id: this.detailLineCounter++,
                quantity: 1,
                description: null,
                unitPrice: null,
                vat: null,
            };
        },
        selectedPaymentDeadlineDays(option) {
            this.model.paymentDeadLineDays = option.value;

            this.useCustomizablePaymentDeadlineDays = option.value === FiscalDocumentCustomizablePaymentDeadlineDate.CUSTOM;
        },
        encodedFilesChanged(encodedFiles) {
            this.encodedFiles = encodedFiles;
        },
        removeDetailLine(id) {
            this.model.detailLines = this.model.detailLines.filter(element => element.id !== id);
        },
        loadRegistryList(createdRegistryId) {
            this.loading = true;
            registryClient.getByFilters().then((response) => {
                const data = response.data;
                this.registryList = data.filter(element => element.id !== this.$store.getters.activeRegistryId);
                this.generateRegistryLabel(this.registryList);

                if (createdRegistryId) {
                    this.model.registryRecipientId = createdRegistryId;
                }
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loading = false;
            });
        },
        loadInvoices() {
            if (!this.isPassiveCreditNote) {
                return;
            }

            this.loadPassiveInvoices();
        },
        setRelatedInvoice(optionSelected) {
            if (optionSelected === 'LOAD-MORE') {
                this.model.invoiceParentId = null;
                this.relatedInvoicePagination.pageElements += 50;
                this.loadInvoices();
            }
        },
        loadPassiveInvoices() {
            this.loading = true;

            const paginationDto = new PaginationDto(
                1,
                this.relatedInvoicePagination.pageElements,
                "date",
                "DESC"
            );

            fiscalDocumentClient.getByFilters(
                paginationDto,
                this.model.registryRecipientId,
                this.$store.getters.activeRegistryId
            ).then(response => {
                this.parentInvoiceList = this.generateFiscalDocumentLabel(response.data.data);

                if (response.data.meta.totalElements > this.parentInvoiceList.length) {
                    this.parentInvoiceList.push({label: this.$t('actions.loadMore'), id: 'LOAD-MORE'});
                }
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loading = false;
            });
        },
        openNewRegistryModal() {
            this.newRegistryKey++;
            this.showNewRegistry = true;
        },
        formatDate(selectedDates) {
            // Reset paymentDeadLine select
            this.paymentDeadLineDays = null;
            this.selectedPaymentDeadlineDays({value: null});

            this.selectedDates = selectedDates;
            this.dateSelectedForPaymentDeadlineCalculation = selectedDates[0];
            this.model.date = this.formatFrontendDate(selectedDates[0]);
        },
        fetchFiscalDocumentNumber(selectedDates, callback) {
            const date = selectedDates[0];

            this.loadingFiscalDocumentNumber = true;
            registryClient.getNextFiscalDocumentNumber(
                this.$store.getters.activeRegistryId,
                date.getFullYear(),
                this.model.documentType
            ).then(response => {
                if (callback) {
                    callback(response.data.nextNumber);
                }

                this.model.number = response.data.nextNumber;
            }).catch(error => {
                this.manageException(error.response);
            }).then(() => {
                this.loadingFiscalDocumentNumber = false;
            });
        },
        formatCustomizedPaymentDeadlineDate(selectedDates) {
            this.model.customizedPaymentDeadLineDate = this.formatFrontendDate(selectedDates[0]);

            this.model.customizedPaymentDeadLineDays = this.calculatePaymentDeadlineDaysByDate(selectedDates[0]);
        },
        create() {
            this.creating = true;
            let detailLinesDto = [];

            const vatNatureMapping = FiscalDocumentVatNatureMapping[this.vatNature];

            this.model.detailLines.forEach(detailLine => {
                detailLinesDto.push(
                    new FiscalDocumentDetailLineOutcomingDto(
                        parseInt(detailLine.quantity),
                        detailLine.description,
                        parseFloat(detailLine.unitPrice),
                        vatNatureMapping.vat,
                        vatNatureMapping.nature
                    )
                );
            });

            const paymentDeadLineDays = this.useCustomizablePaymentDeadlineDays === true ? this.model.customizedPaymentDeadLineDays : this.model.paymentDeadLineDays;

            const fiscalDocumentDto = new FiscalDocumentOutcomingDto(
                this.model.registrySenderId,
                this.model.registryRecipientId,
                this.model.documentType,
                this.model.paymentType,
                this.model.paymentMode,
                paymentDeadLineDays,
                this.model.currency,
                this.model.date,
                this.model.number,
                detailLinesDto,
                this.model.sendEmail,
                this.model.invoiceParentId,
                this.showRevenueStamp ? this.model.revenueStamp : null,
                this.model.purpose
            );

            fiscalDocumentClient.createOutcoming(fiscalDocumentDto).then((response) => {
                this.$notify({
                    icon: "ni ni-notification-70",
                    type: "success",
                    message: this.$t("pages.fiscalDocument.notifications.success.created")
                });
                this.$emit('data-changed');
                this.closeNew();

                this.encodedFiles.forEach((encodedData, index) => {
                    this.creating = true;
                    const attachmentDto = new FiscalDocumentAttachmentDto(encodedData);
                    fiscalDocumentClient.createAttachment(response.data.id, attachmentDto).then(() => {
                    }).catch((error) => {
                        this.manageException(error.response);
                    }).then(() => {
                        if (index === (this.encodedFiles.length - 1)) {
                            this.creating = false;
                            this.$notify({
                                type: "success",
                                icon: "ni ni-notification-70",
                                message: this.$t("pages.fiscalDocument.attachmentsAdded")
                            });
                            this.$emit('data-changed');
                            this.closeNew();
                        }
                    });
                });
            }).catch((error) => {
                if (this.getExceptionCodeFromErrorResponse(error) === FiscalDocumentExceptionCodes.NOT_VALID_NUMBER) {
                    const oldNumber = this.model.number;
                    this.fetchFiscalDocumentNumber(this.selectedDates, (nextNumber) => {
                        this.showSwal(
                            'question',
                            oldNumber,
                            this.$t("pages.fiscalDocument.numberUsed", { nextNumber })
                        ).then(result => {
                            if (result.isConfirmed) {
                                this.create();
                            }
                        });
                    });
                } else {
                    this.manageException(error.response);
                }
            }).then(() => {
                this.creating = false;
            });
        },
    }
}
</script>
