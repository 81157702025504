<template>
    <div class="content">
        <Header
            :title="$t('pages.cashFlow.breadcrumb.detail')"
        />

        <LoadingBar v-show="!loading" />
        <div
            v-show="loading"
            class="container-fluid mt--6 pt-4"
            :data-cy="'cashFlowDetailsContainer'"
        >
            <card>
                <div class="d-flex justify-content-between align-items-start">
                    <h1 class="card-title mb-4">
                        {{ $t("pages.cashFlow.detailPage.title") }}
                    </h1>
                    <div>
                        <base-button
                            :data-cy="'buttonUpdateCashFlowId'+$route.params.id"
                            class="edit mb-2 mb-sm-0"
                            type="primary"
                            size="sm"
                            icon
                            @click="openEditModal($route.params.id)"
                        >
                            <i class="fas fa-edit text-white" />
                        </base-button>
                        <base-button
                            :data-cy="'buttonDeleteCashFlowId'+$route.params.id"
                            class="remove btn-link mb-2 mb-sm-0"
                            type="danger"
                            size="sm"
                            icon
                            @click="openDeleteModal($route.params.id)"
                        >
                            <i class="fas fa-trash-alt text-white" />
                        </base-button>
                    </div>
                </div>
                <div class="card-text mb-4">
                    <dl class="row">
                        <dt class="col-sm-2">
                            {{ cashFlow.income ? $t("tables.column.debtor") : $t("tables.column.creditor") }}
                        </dt>
                        <dd class="col-sm-10">
                            {{ cashFlow.registrySenderName }}
                        </dd>
                        <dt class="col-sm-2">
                            {{ $t("forms.fields.tag") }}
                        </dt>
                        <dd class="col-sm-10">
                            {{ getCashFlowTagLabelByChoiceCode(cashFlow.tag) }}
                        </dd>
                        <dt class="col-sm-2">
                            {{ $t("forms.fields.type") }}
                        </dt>
                        <dd class="col-sm-10">
                            {{ getCashFlowTypeLabelByChoiceCode(cashFlow.type) }}
                        </dd>
                    </dl>
                </div>
                <hr>
                <div class="card-text mb-4">
                    <dl class="row">
                        <dt class="col-sm-2">
                            {{ $t("forms.fields.number") }}
                        </dt>
                        <dd class="col-sm-10">
                            {{ cashFlow.number }}
                        </dd>
                        <dt class="col-sm-2">
                            {{ $t("forms.fields.amount") }}
                        </dt>
                        <dd class="col-sm-10">
                            {{ formatCurrency(cashFlow.currency, cashFlow.amount) }}
                        </dd>
                        <dt class="col-sm-2">
                            {{ $t("forms.fields.vatAmount") }}
                        </dt>
                        <dd class="col-sm-10">
                            {{ formatPercentage(cashFlow.vat) }}
                        </dd>
                        <dt class="col-sm-2">
                            {{ $t("forms.fields.tax") }}
                        </dt>
                        <dd class="col-sm-10">
                            {{ formatCurrency(cashFlow.currency, cashFlow.tax) }}
                        </dd>
                        <dt class="col-sm-2">
                            {{ $t("forms.fields.expenseDate") }}
                        </dt>
                        <dd class="col-sm-10">
                            {{ formatBackendDate(new Date(cashFlow.expenseDate)) }}
                        </dd>
                        <dt class="col-sm-2">
                            {{ $t("forms.fields.description") }}
                        </dt>
                        <dd class="col-sm-10">
                            {{ cashFlow.description }}
                        </dd>
                    </dl>
                </div>
                <hr>
                <div
                    v-show="cashFlow.attachments.length > 0"
                    class="card-text mb-4 row"
                >
                    <div class="col">
                        <div class="d-flex justify-content-between align-items-start">
                            <h2 class="card-title mb-4">
                                {{ $t("pages.cashFlow.detailPage.attachments") }}
                            </h2>
                        </div>
                        <Attachments :attachments="cashFlow.attachments" />
                    </div>
                </div>
            </card>
        </div>

        <DeleteCashFlow
            v-if="deleteModal.showDeleteModal"
            :delete-cash-flow-id="deleteModal.idCashFlowToDelete"
            :is-income="deleteModal.isIncome"
            :number="deleteModal.number"
            @close-delete="deleteModal.showDeleteModal = false;"
            @data-changed="backToListing"
        />

        <EditCashFlow
            v-if="editModal.showEditModal"
            :edit-cash-flow-id="editModal.editCashFlowId"
            @close-edit="editModal.showEditModal = false;"
            @data-changed="loadData"
        />
    </div>
</template>

<script>
import { cashFlowClient } from "@/clients/cashFlowClient";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import choicesMixins from "@/mixins/choicesMixins";
import formatterMixins from "@/mixins/formatterMixins";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import DeleteCashFlow from "@/views/Pages/Components/CashFlow/Modal/Delete";
import EditCashFlow from "@/views/Pages/Components/CashFlow/Modal/Edit";
import Attachments from "@/views/Pages/Components/Attachments/Attachments";
import Header from "@/components/Header";

export default {
    name: 'DetailCashFlow',
    components: {
        LoadingBar,
        DeleteCashFlow,
        EditCashFlow,
        Header,
        Attachments,
    },
    mixins: [
        exceptionsMixins,
        choicesMixins,
        formatterMixins
    ],
    data() {
        return {
            cashFlow: {
                number: null,
                registrySenderName: null,
                registryRecipient: null,
                tag: null,
                type: null,
                description: null,
                amount: null,
                vat: null,
                tax: null,
                currency: null,
                expenseDate: null,
                income: null,
                attachments: []
            },
            loading: false,
            deleteModal: {
                showDeleteModal: false,
                idCashFlowToDelete: null,
                loading: false,
                isIncome: null,
                number: null,
            },
            editModal: {
                showEditModal: false,
                editCashFlowId: null
            },
        };
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.loading = false;
            cashFlowClient.getById(this.$route.params.id).then((result) => {
                this.cashFlow.number = result.data.number;
                this.cashFlow.registrySenderName = result.data.sender;
                this.cashFlow.registryRecipient = result.data.recipient;
                this.cashFlow.tag = result.data.tag;
                this.cashFlow.type = result.data.type;
                this.cashFlow.description = result.data.description;
                this.cashFlow.amount = result.data.amount;
                this.cashFlow.vat = result.data.vat;
                this.cashFlow.tax = result.data.tax;
                this.cashFlow.currency = result.data.currency;
                this.cashFlow.expenseDate = result.data.expenseDate;
                this.cashFlow.income = result.data.income;
                this.cashFlow.attachments = result.data.attachments;
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loading = true;
            });
        },
        openEditModal(id) {
            this.editModal.showEditModal = true;
            this.editModal.editCashFlowId = Number(id);
        },
        openDeleteModal(id) {
            this.deleteModal.idCashFlowToDelete = Number(id);
            this.deleteModal.showDeleteModal = true;
            this.deleteModal.isIncome = this.cashFlow.income;
            this.deleteModal.number = this.cashFlow.number;
        },
        backToListing() {
            this.$router.push({name:'CASHFLOW-LIST'});
        }
    }
}
</script>
