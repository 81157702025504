/**
 * exceptionsMixins.
 */
import { authenticationService } from "@/services/authenticationService";
import store from "@/main";

export default {
    methods: {
        /**
         * Method that manage the exceptions.
         *
         * @param {*} error
         */
        manageException(error) {
            if (typeof error === "undefined") {
                return;
            }

            let errorResponse = error;
            if (error.hasOwnProperty('response') && error.response !== undefined) {
                errorResponse = error.response;
            }

            if (errorResponse.status === 401) {
                authenticationService.removeAuthenticationToken();
                store.dispatch('moveToLogin', {});
                this.$router.push("/login");
            }

            if (errorResponse.status === 400) {
                let message = this.$i18n.t("exceptions.400.default");
                if (errorResponse?.data) {
                    if (!errorResponse.data.status) {
                        console.error('The exception must return a status code.');
                    }

                    const messageKey = `exceptions.400.${errorResponse.data.status}`;

                    message = this.$i18n.te(messageKey) ? this.$i18n.t(messageKey) : this.$i18n.t("exceptions.400.default");
                }

                this.$notify({
                    type: "warning",
                    icon: "ni ni-notification-70",
                    message: message,
                });
            }

            if (errorResponse.status > 400) {
                this.$notify({
                    type: "warning",
                    icon: "ni ni-notification-70",
                    message: this.$i18n.t("exceptions.default"),
                });
            }
        },
        /**
         * Return the exception code from a response.
         *
         * @param {*} errorResponse
         *
         * @returns {number}
         */
        getExceptionCodeFromErrorResponse(errorResponse) {
            return errorResponse.response.data.status
        }
    },
};
