<template>
    <div class="edit-modal">
        <LoadingBar v-show="showEdit && !loaded" />
        <modal
            :show="showEdit && loaded"
            modal-classes="modal-secondary"
            :size="'lg'"
            :data-cy="'updateModal'"
            @close="closeEdit"
        >
            <template slot="header">
                <h1>{{ $t("actions.update") + ' ' + $t("pages.bank.bank") }}</h1>
            </template>
            <validation-observer
                v-slot="{handleSubmit}"
                ref="formValidator"
            >
                <form
                    class="edit-event-form"
                    @submit.prevent="handleSubmit(updateBank)"
                >
                    <div class="form-group container">
                        <div class="row">
                            <base-input
                                v-model="model.name"
                                :label="$t('forms.fields.name')"
                                :placeholder="$t('forms.fields.name')"
                                class="col-12"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.name')"
                                :rules="{required:true}"
                                :data-cy="'editBankNameField'"
                            />
                            <base-input
                                v-model="model.swiftBic"
                                :label="$t('forms.fields.swiftBic')"
                                class="col-6"
                                :placeholder="$t('forms.fields.swiftBic')"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.swiftBic')"
                                :rules="{required:true}"
                                :data-cy="'editBankSwiftBicField'"
                            />
                            <base-input
                                v-model="model.locationAddress"
                                :label="$t('forms.fields.address')"
                                class="col-6"
                                :placeholder="$t('forms.actions.insert') + ' ' + $t('forms.fields.address')"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.address')"
                                :data-cy="'editBankAddressField'"
                            />
                            <base-input
                                v-model="model.locationCity"
                                :label="$t('forms.fields.city')"
                                class="col-6"
                                :placeholder="$t('forms.actions.insert') + ' ' + $t('forms.fields.city')"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.city')"
                                :data-cy="'editBankCityField'"
                            />
                            <base-input
                                v-model="model.locationZip"
                                :label="$t('forms.fields.zip')"
                                class="col-6"
                                :placeholder="$t('forms.actions.insert') + ' ' + $t('forms.fields.zip')"
                                input-classes="form-control-alternative"
                                :rules="{max:20}"
                                :name="$t('forms.fields.zip')"
                                :data-cy="'editBankZipField'"
                            />
                            <base-input
                                v-model="model.locationState"
                                :label="$t('forms.fields.state')"
                                class="col-6"
                                :placeholder="$t('forms.actions.insert') + ' ' + $t('forms.fields.state')"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.state')"
                                :rules="{min:2,max:2}"
                                :data-cy="'editBankStateField'"
                            />
                            <base-input
                                :label="$t('forms.fields.country')"
                                class="col-6"
                                :placeholder="$t('forms.actions.select') + ' ' + $t('forms.fields.country')"
                                input-classes="form-control-alternative"
                                :data-cy="'editBankCountryField'"
                            >
                                <v-select
                                    v-model="model.locationCountry"
                                    class="form-control form-control-alternative"
                                    :options="countryList"
                                    :reduce="country => country.code"
                                />
                            </base-input>
                        </div>
                        <div class="d-flex justify-content-end">
                            <base-button
                                native-type="submit"
                                type="primary"
                                class="new-event--add"
                                :data-cy="'update-edit-bank'"
                                :disabled="updating"
                                :loading="updating"
                            >
                                {{ $t("actions.update") }}
                            </base-button>
                            <base-button
                                type="link"
                                :data-cy="'close-edit-bank'"
                                @click="closeEdit"
                            >
                                {{ $t("actions.cancel") }}
                            </base-button>
                        </div>
                    </div>
                </form>
            </validation-observer>
        </modal>
    </div>
</template>

<script>
import { bankClient } from "@/clients/bankClient";
import { countryList } from "@/util/country-list";
import { ValidationObserver } from "vee-validate";
import BaseButton from "@/components/BaseButton";
import Modal from "@/components/Modal";
import BankDto from "@/clients/dto/BankDto";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import vSelect from 'vue-select';

export default {
    name: "EditBank",
    components: {
        BaseButton,
        Modal,
        LoadingBar,
        ValidationObserver,
        vSelect
    },
    mixins: [
        exceptionsMixins
    ],
    props: {
        editBankId: {
            type: Number,
        },
        showEdit: {
            type: Boolean,
        }
    },
    data() {
        return {
            model: {
                name: null,
                swiftBic: null,
                locationAddress: null,
                locationCity: null,
                locationState: null,
                locationZip: null,
                locationCountry: null,
            },
            loaded: false,
            updating: false,
        }
    },
    computed: {
        countryList() {
            return countryList;
        }
    },
    watch: {
        showEdit() {
            if (this.editBankId) {
                let bank = null;
                this.loaded = false;
                bankClient.getById(this.editBankId).then((result) => {
                    bank = result.data;
                    this.model.name = bank.name;
                    this.model.swiftBic = bank.swiftBic;
                    if (bank.location !== null) {
                        this.model.locationAddress = bank.location.address ? bank.location.address : null;
                        this.model.locationCity = bank.location.city ? bank.location.city : null;
                        this.model.locationState = bank.location.state ? bank.location.state : null;
                        this.model.locationZip = bank.location.zip ? bank.location.zip : null;
                        this.model.locationCountry = bank.location.country ? bank.location.country : null;
                    } else {
                        this.model.locationAddress = null;
                        this.model.locationCity = null;
                        this.model.locationState = null;
                        this.model.locationZip = null;
                        this.model.locationCountry = null;
                    }
                }).catch((error) => {
                    this.manageException(error.response);
                }).then(() => {
                    this.loaded = true;
                });
            }
        },
    },
    mounted() {
        this.$emit('close-edit');
    },
    methods: {
        closeEdit() {
            this.showEditModal = false;
            this.$emit('close-edit');
        },
        updateBank() {
            this.updating = true;
            const bankDto = new BankDto(
                this.model.name,
                this.model.swiftBic,
                this.model.locationAddress,
                this.model.locationCity,
                this.model.locationState,
                this.model.locationZip,
                this.model.locationCountry
            );

            bankClient.updateBank(this.editBankId, bankDto).then((response) => {
                this.$notify({
                    type: "success",
                    icon: "ni ni-notification-70",
                    message: this.$t("notifications.success.updated")
                });
                this.$emit('data-changed');
                this.closeEdit();
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.updating = false;
            });
        }
    }
}
</script>
