/**
 * companyMixins.
 */
export default {
    computed: {
        isPubtech() {
            return this.$store.getters.activeRegistryEmail === process.env.VUE_APP_EMAIL_PUBTECH;
        },
        isTalks() {
            return this.$store.getters.activeRegistryEmail === process.env.VUE_APP_EMAIL_TALKS;
        },
        getLogoImgPath() {
            return this.$store.getters.activeRegistryEmail === process.env.VUE_APP_EMAIL_PUBTECH ?
                "/img/brand/logo-pubtech.png" :
                "/img/brand/logo-talksmedia.png";
        },
    },
    methods: {
        getCompanyName() {
            return process.env.VUE_APP_COMPANY === 'TALKS' ? 'Talks Media' : 'Pubtech';
        },
        getApplicationClientName() {
            return process.env.VUE_APP_APPLICATION_CLIENT_NAME;
        },
    }
};
