export default class BankDto {
  #name;
  #swiftBic;
  #locationAddress;
  #locationCity;
  #locationState;
  #locationZip;
  #locationCountry;

  /**
   * Constructor.
   *
   * @param name {string}
   * @param swiftBic {string}
   * @param locationAddress {string|null}
   * @param locationCity {string|null}
   * @param locationState {string|null}
   * @param locationZip {string|null}
   * @param locationCountry {string|null}
   */
  constructor(name, swiftBic, locationAddress, locationCity, locationState, locationZip, locationCountry) {
      this.#name = name;
      this.#swiftBic = swiftBic;
      this.#locationAddress = locationAddress;
      this.#locationCity = locationCity;
      this.#locationState = locationState;
      this.#locationZip = locationZip;
      this.#locationCountry = locationCountry;
  }

  get name() {
      return this.#name;
  }

  get swiftBic() {
      return this.#swiftBic;
  }

  get locationAddress() {
      return this.#locationAddress;
  }

  get locationCity () {
      return this.#locationCity;
  }

  get locationState () {
      return this.#locationState;
  }

  get locationZip () {
      return this.#locationZip;
  }

  get locationCountry () {
      return this.#locationCountry;
  }
}
