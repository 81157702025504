export default class RegistryDto {
    #name;
    #email;
    #telephone;
    #type;
    #vat;
    #nationalIdentificationNumber;
    #taxRegime;
    #feCode;
    #locationAddress;
    #locationCity;
    #locationState;
    #locationZip;
    #locationCountry;
    #administrativeContactId;
    #technicalContactId;
    #bankAccountBeneficiaryName;
    #bankAccountIban;
    #bankId;
    #pendingRegistryId;

    /**
     * Constructor.
     *
     * @param name {String}
     * @param email {String}
     * @param telephone {String}
     * @param type {String}
     * @param vat {String|null}
     * @param nationalIdentificationNumber {String|null}
     * @param taxRegime {String|null}
     * @param feCode {String|null}
     * @param locationAddress {String}
     * @param locationCity {String}
     * @param locationState {String|null}
     * @param locationZip {String}
     * @param locationCountry {String}
     * @param administrativeContactId {Number|null}
     * @param technicalContactId {Number|null}
     * @param bankAccountBeneficiaryName {String|null}
     * @param bankAccountIban {String|null}
     * @param bankId {String|null}
     * @param pendingRegistryId {String|null}
     */
    constructor(
        name,
        email,
        telephone,
        type,
        vat= null,
        nationalIdentificationNumber= null,
        taxRegime = null,
        feCode = null,
        locationAddress,
        locationCity,
        locationState= null,
        locationZip,
        locationCountry,
        administrativeContactId= null,
        technicalContactId= null,
        bankAccountBeneficiaryName = null,
        bankAccountIban = null,
        bankId = null,
        pendingRegistryId= null
    ) {
        this.#name = name;
        this.#email = email;
        this.#telephone = telephone;
        this.#type = type;
        this.#vat = vat;
        this.#nationalIdentificationNumber = nationalIdentificationNumber;
        this.#taxRegime = taxRegime;
        this.#feCode = feCode;
        this.#locationAddress = locationAddress;
        this.#locationCity = locationCity;
        this.#locationState = locationState;
        this.#locationZip = locationZip;
        this.#locationCountry = locationCountry;
        this.#administrativeContactId = administrativeContactId;
        this.#technicalContactId = technicalContactId;
        this.#bankAccountBeneficiaryName = bankAccountBeneficiaryName;
        this.#bankAccountIban = bankAccountIban;
        this.#bankId = bankId;
        this.#pendingRegistryId = pendingRegistryId;
    }

    get name() {
        return this.#name;
    }

    get email() {
        return this.#email;
    }

    get telephone() {
        return this.#telephone;
    }

    get type() {
        return this.#type;
    }

    get vat() {
        return this.#vat;
    }

    get nationalIdentificationNumber() {
        return this.#nationalIdentificationNumber;
    }

    get taxRegime() {
        return this.#taxRegime;
    }

    get feCode() {
        return this.#feCode;
    }

    get locationAddress() {
        return this.#locationAddress;
    }

    get locationCity() {
        return this.#locationCity;
    }

    get locationState() {
        return this.#locationState;
    }

    get locationZip() {
        return this.#locationZip;
    }

    get locationCountry() {
        return this.#locationCountry;
    }

    get administrativeContactId() {
        return this.#administrativeContactId;
    }

    get technicalContactId() {
        return this.#technicalContactId;
    }

    get bankAccountBeneficiaryName() {
        return this.#bankAccountBeneficiaryName;
    }

    get bankAccountIban() {
        return this.#bankAccountIban;
    }

    get bankId() {
        return this.#bankId;
    }

    get pendingRegistryId() {
        return this.#pendingRegistryId;
    }
}
