<template>
    <div class="content">
        <Header
            :title="$t('pages.firstAccounting.title')"
        />

        <div class="container-fluid mt--6">
            <div class="row">
                <div class="col-12">
                    <card
                        class="mt-2 mb-4 p-2"
                        body-classes="p-0 pb-2 pt-2"
                        footer-classes="pb-2"
                    >
                        <div class="container-fluid">
                            <div class="row align-items-center m-0">
                                <div class="col-12">
                                    <h2>{{ $t('tables.column.filters') }}</h2>
                                </div>
                                <base-input
                                    :label="$t('forms.fields.type')"
                                    :name="$t('forms.fields.type')"
                                    :placeholder="$t('forms.actions.select') + $t('forms.fields.type')"
                                    input-classes="form-control-alternative"
                                    :data-cy="'typeFirstAccountingFilter'"
                                    class="col-lg-12 col-xl-3"
                                >
                                    <v-select
                                        v-model="filters.documentType"
                                        class="form-control form-control-alternative"
                                        :options="firstAccountingDocumentTypeList"
                                        :reduce="documentType => documentType.code"
                                    />
                                </base-input>
                                <base-input
                                    addon-left-icon="ni ni-calendar-grid-58"
                                    :label="$t('forms.fields.dateRange')"
                                    :name="$t('forms.fields.dateRange')"
                                    :placeholder="$t('forms.actions.select') + $t('forms.fields.dateRange')"
                                    :data-cy="'rangeDateFirstAccountingFilter'"
                                    class="col-lg-12 col-xl-3"
                                >
                                    <flat-picker
                                        v-model="filters.dates.range"
                                        slot-scope="{focus, blur}"
                                        :config="filters.dates.configs"
                                        class="form-control datepicker"
                                        @on-open="focus"
                                        @on-close="blur"
                                        @on-change="formatDates"
                                    />
                                </base-input>
                                <base-button
                                    native-type="submit"
                                    type="primary"
                                    class="search"
                                    :data-cy="'searchByFilters'"
                                    :disabled="loading"
                                    @click="loadData"
                                >
                                    {{ $t('actions.search') }}
                                </base-button>
                                <base-button
                                    v-if="showResetFilter"
                                    native-type="submit"
                                    type="danger"
                                    :disabled="loading"
                                    @click="clearFilters"
                                >
                                    {{ $t('actions.reset') }}
                                </base-button>
                            </div>
                            <div class="row pt-3 pb-3 pt-xl-0 pb-xl-0">
                                <PresetDate
                                    class="col-12"
                                    @preset-selected="onPresetSelected"
                                />
                            </div>
                        </div>
                    </card>
                </div>
            </div>
            <div class="row">
                <FirstAccountingTable
                    :table-data="tableData"
                />
            </div>
        </div>

        <LoadingBar v-show="loading" />
    </div>
</template>

<script>
import { firstAccountingClient } from "@/clients/firstAccountingClient";
import { localeService } from "@/services/localeService";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import Header from "@/components/Header";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import formatterMixins from "@/mixins/formatterMixins";
import choicesMixins from "@/mixins/choicesMixins";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// All the supported languages must be added to let the flatPicker work correctly.
import "flatpickr/dist/l10n/it";
import vSelect from 'vue-select';
import FirstAccountingTable from "@/views/Pages/Components/FirstAccounting/Table";
import PresetDate from "@/views/Pages/Components/PresetDate/PresetDate";
import sortingFunctionsMixin from "@/views/Mixins/sortingFunctionsMixin";
import yearSelectorMixins from "@/mixins/yearSelectorMixins";

export default {
    name: 'FirstAccounting',
    components: {
        LoadingBar,
        Header,
        flatPicker,
        vSelect,
        FirstAccountingTable,
        PresetDate
    },
    mixins: [
        exceptionsMixins,
        formatterMixins,
        choicesMixins,
        sortingFunctionsMixin,
        yearSelectorMixins,
    ],
    data() {
        return {
            tableData: [],
            loading: false,
            filters: {
                documentType: null,
                dates: {
                    range: null,
                    fromDateToSend: this.formatFrontendDate(this.getDefaultFromDate()),
                    toDateToSend: this.formatFrontendDate(this.getDefaultToDate()),
                    configs: {
                        allowInput: true,
                        mode: "range",
                        locale: localeService.getLocale(),
                        altInput: true,
                        altFormat: "d-m-Y",
                        showMonths: 2,
                        minDate: this.getDefaultFromDate(),
                        maxDate: this.getDefaultToDate(),
                        defaultDate: [
                            this.getDefaultFromDate(),
                            this.getDefaultToDate()
                        ]
                    },
                },
            }
        }
    },
    computed: {
        showResetFilter() {
            return this.filters.documentType?.length > 0 ||
            this.filters.dates.fromDateToSend?.length > 0 ||
            this.filters.dates.toDateToSend?.length > 0;
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        clearFilters() {
            this.filters.documentType = null;
            this.filters.dates.fromDateToSend = null;
            this.filters.dates.toDateToSend = null;
            this.filters.dates.range = null;
            this.checkFiltersDatePresent();
        },
        onPresetSelected(dates) {
            this.filters.dates.fromDateToSend = dates.fromDateToSend;
            this.filters.dates.toDateToSend = dates.toDateToSend;
            this.filters.dates.range = dates.range;
        },
        loadData() {
            this.checkFiltersDatePresent();

            this.loading = true;

            const fromDate = this.filters.dates.fromDateToSend;
            const toDate = this.filters.dates.toDateToSend?.length === 0 ? fromDate : this.filters.dates.toDateToSend;

            firstAccountingClient.getByFilters(this.$store.getters.activeRegistryId, this.filters.documentType, fromDate, toDate)
                .then((response) => {
                    this.tableData = response.data.sort((a, b) => {
                        return this.sortDatesDesc(a.date, b.date);
                    });
                }).catch((error) => {
                    this.manageException(error.response);
                }).then(() => {
                    this.loading = false;
                });
        },
        formatDates(selectedDates) {
            this.filters.dates.fromDateToSend = this.formatFrontendDate(selectedDates[0]);
            this.filters.dates.toDateToSend = this.formatFrontendDate(selectedDates[1]);
        },
        checkFiltersDatePresent() {
            if (!this.filters.dates.fromDateToSend && !this.filters.dates.toDateToSend) {
                this.onPresetSelected(this.getDefaultDatesRange());
            }
        },
    }
}
</script>
