import contactPages from "@/routes/pages/contact";
import bankPages from "@/routes/pages/bank";
import registryPages from "@/routes/pages/registry";
import pendingRegistryPages from "@/routes/pages/pendingRegistry";
import cashFlowPages from "@/routes/pages/cashFlow";
import authPage from "@/routes/pages/authentication";
import fiscalDocumentPages from "@/routes/pages/fiscalDocument";
import firstAccountingPage from "@/routes/pages/firstAccounting";
import expectedInvoicesPages from "@/routes/pages/expectedInvoice";
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import NotFound from '@/views/ErrorPages/NotFoundPage.vue';
import Homepage from '@/views/Pages/Home.vue';

const otherPages = {
    path: '/',
    component: DashboardLayout,
    children: [
        {
            path: '/homepage',
            component: Homepage,
            name: 'HOMEPAGE',
            meta: {
                area: 'HOMEPAGE',
            }
        },
        {
            path: '*',
            component: NotFound
        }
    ]
};

const routes = [
    {
        path: '/',
        redirect: '/login',
        meta: {
            area: 'LOGIN'
        }
    },
    authPage,
    bankPages,
    contactPages,
    registryPages,
    pendingRegistryPages,
    cashFlowPages,
    fiscalDocumentPages,
    firstAccountingPage,
    expectedInvoicesPages,
    otherPages,
];

export default routes;
