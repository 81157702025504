<template>
    <div
        class="content"
        :data-cy="'bankStep'"
    >
        <validation-observer
            v-slot="{handleSubmit}"
            ref="formValidator"
        >
            <form
                class="new-event-form"
                @submit.prevent="handleSubmit(nextStep)"
            >
                <div class="form-group">
                    <base-input
                        v-model="model.bankAccountBeneficiaryName"
                        :label="$t('forms.fields.beneficiaryName')"
                        :placeholder="$t('forms.fields.beneficiaryName')"
                        input-classes="form-control-alternative"
                        :name="$t('forms.fields.beneficiaryName')"
                        :rules="{required: false}"
                        :data-cy="'newRegistryBeneficiaryNameField'"
                    />
                    <base-input
                        v-model="model.bankAccountIban"
                        :label="$t('forms.fields.iban')"
                        :placeholder="$t('forms.fields.iban')"
                        input-classes="form-control-alternative"
                        :name="$t('forms.fields.iban')"
                        :rules="{
                            required: false,
                            alpha_num: true
                        }"
                        vid="ibanValidation"
                        :data-cy="'newRegistryIbanField'"
                        :loading="ibanValidationLoading"
                        @focusout="checkIban"
                        @change="ibanValidation = false"
                    />
                    <dl v-show="ibanValidation">
                        <dt>{{ $t('forms.fields.name') }}</dt>
                        <dd>{{ bankInformation.name }}</dd>
                        <dt>{{ $t('forms.fields.city') }}</dt>
                        <dd>{{ bankInformation.branch }}</dd>
                        <dt>{{ $t('forms.fields.swiftBic') }}</dt>
                        <dd>{{ bankInformation.bic }}</dd>
                        <dt>{{ $t('forms.fields.country') }}</dt>
                        <dd>{{ bankInformation.countryIso }}</dd>
                    </dl>
                </div>
                <div class="d-flex justify-content-start">
                    <slot />
                </div>
            </form>
        </validation-observer>
        <LoadingBar v-show="loading" />
    </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { bankClient } from "@/clients/bankClient";
import { validateClient } from "@/clients/validateClient";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import BankDto from "@/clients/dto/BankDto";

export default {
    name: 'BankStep',
    components: {
        ValidationObserver,
        LoadingBar,
    },
    mixins: [
        exceptionsMixins
    ],
    data() {
        return {
            model: {
                bankAccountBeneficiaryName: null,
                bankAccountIban: null,
                bankId: null,
                bankName: null,
            },
            bankList: [],
            showNewBank: false,
            loading: false,
            ibanValidationLoading: false,
            ibanValidation: false,
            bankInformation: {
                name: null,
                branch: null,
                bic: null,
                countryIso: null,
            }
        }
    },
    created() {
        this.loadBanks();
    },
    methods: {
        nextStep() {
            this.model.bankName = (this.bankList.filter((value) => {
                return value.id === this.model.bankId;
            })[0])?.name;

            if (!this.ibanValidation && this.model.bankAccountIban) {
                this.setIbanError();
                return;
            }

            this.$emit('next-step', this.model);
        },
        loadBanks(selectedBankId) {
            this.loading = true;
            bankClient.getByFilters().then((response) => {
                this.bankList = response.data;
                if (selectedBankId !== undefined) {
                    this.model.bankId = selectedBankId;
                }
                this.generateLabelInList(this.bankList);
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loading = false;
            })
        },
        generateLabelInList(list) {
            list.forEach(element => element.customLabel = element.name + ' ('+element.swiftBic+')');
        },
        checkIban() {
            if (this.model.bankAccountIban) {
                this.model.bankAccountIban = this.model.bankAccountIban.replace(/\s/g, '');
            }

            if (!this.model.bankAccountIban || this.model.bankAccountIban.length === 0) {
                return;
            }

            this.ibanValidationLoading = true;
            validateClient.validateIban(this.model.bankAccountIban).then((response) => {
                if (response.data.supported) {
                    this.ibanValidation = response.data.valid;

                    if (response.data.valid) {
                        this.bankInformation.name = response.data.bank.bank;
                        this.bankInformation.bic = response.data.bank.bic;
                        this.bankInformation.branch = response.data.bank.branch;
                        this.bankInformation.countryIso = response.data.bank.countryIso;

                        this.setOrCreateBank(response.data.bank);
                    }
                } else {
                    this.ibanValidation = true;
                }
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                if (!this.ibanValidation) {
                    this.setIbanError();
                }
                this.ibanValidationLoading = false;
            });
        },
        setOrCreateBank(dataBank) {
            this.loading = true
            const bankSearched = this.bankList.find(bank => bank.swiftBic === dataBank.bic);

            if (bankSearched) {
                this.model.bankId = bankSearched.id;

                this.loading = false;
                return;
            }

            const bankDto = new BankDto(
                dataBank.bank,
                dataBank.bic,
                null,
                dataBank.city,
                dataBank.state,
                null,
                dataBank.countryIso
            );

            bankClient.createBank(bankDto).then((response) => {
                this.loadBanks(response.data.id);
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loading = false;
            });
        },
        setIbanError() {
            this.$refs.formValidator.setErrors({
                ibanValidation: [this.$t('forms.validationMessage.invalidIban')],
            });
        }
    }
}
</script>
