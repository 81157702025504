// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import '@/polyfills';
// Notifications plugin. Used on Notifications page
import Notifications from '@/components/NotificationPlugin';
// Validation plugin used to validate forms
import { configure } from 'vee-validate';
// A plugin file where you could register global components used across the app
import GlobalComponents from './globalComponents';
// A plugin file where you could register global directives
import GlobalDirectives from './globalDirectives';
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from '@/components/SidebarPlugin';
// Import localeService to get current lang of the user
import { localeService } from "@/services/localeService";

// element ui language configuration
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
locale.use(lang);

// asset imports
import '@/assets/sass/argon.scss';
import '@/assets/css/nucleo/css/nucleo.css';
import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import it from 'vee-validate/dist/locale/it.json';
import en from 'vee-validate/dist/locale/en.json';

const veeMessages = {
    'it' : it.messages,
    'en' : en.messages
};

let messages = en.messages;
if (Object.keys(veeMessages).includes(localeService.getLocale())) {
    messages = veeMessages[localeService.getLocale()];
}

Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule], // copies rule configuration
        message: messages[rule] // assign message
    });
});

extend('vat_or_nid_required', {
    params: ['target'],
    validate(value, { target }) {
        return (value !== null && value !== undefined && value !== '') || (target !== null && target !== undefined && target !== '' );
    },
    computesRequired: true,
    lazy: false,
    message: 'Specificare la P.IVA oppure l\'Identificativo nazionale'
});

extend("decimal", {
    validate: (value, { decimals = '*', separator = '.' } = {}) => {
        if (value === null || value === undefined || value === '') {
            return {
                valid: false
            };
        }
        if (Number(decimals) === 0) {
            return {
                valid: /^-?\d*$/.test(value),
            };
        }
        const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
        const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

        return {
            valid: regex.test(value),
        };
    },
    message: 'Specificare solo valori decimali'
});

export default {
    install(Vue) {
        Vue.use(GlobalComponents);
        Vue.use(GlobalDirectives);
        Vue.use(SideBar);
        Vue.use(Notifications);
        configure({
            classes: {
                valid: 'is-valid',
                invalid: 'is-invalid',
                dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
            }
        })
    }
};
