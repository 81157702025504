import { headersBuilder } from "@/clients/headersBuilder";
import axios, { AxiosResponse } from 'axios';
import dataCleaner from "@/clients/support/dataCleaner";

export default {
    /**
     * Make a post call to administration.
     *
     * @param {string} uri
     * @param {object} data
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    post(uri, data = {}) {
        return axios.post(process.env.VUE_APP_ADMINISTRATION_BASE_URL + `/api/v1/${uri}`,
            dataCleaner(data),
            {
                headers: headersBuilder.buildAuthenticatedHeaders()
            });
    },
    /**
     * Make a put call to administration.
     *
     * @param {string} uri
     * @param {object} data
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    put(uri, data) {
        return axios.put(process.env.VUE_APP_ADMINISTRATION_BASE_URL + `/api/v1/${uri}`,
            dataCleaner(data), {
                headers: headersBuilder.buildAuthenticatedHeaders(),
            });
    },
    /**
     * Make a delete call to administration.
     *
     * @param {string} uri
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete(uri) {
        return axios.delete(
            process.env.VUE_APP_ADMINISTRATION_BASE_URL +
            `/api/v1/${uri}`,
            {
                headers: headersBuilder.buildAuthenticatedHeaders()
            });
    }
};
