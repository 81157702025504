import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from "@/main";
import { authenticationService } from "@/services/authenticationService";
import { userClient } from "@/clients/userClient";
import exceptionsMixins from "../mixins/exceptionsMixins";

Vue.use(VueRouter);

const router = new VueRouter({
    routes,
    mode: 'history',
    linkActiveClass: 'active',
    scrollBehavior: (to, from ,savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { selector: to.hash };
        }
        return { x: 0, y: 0 };
    }
});

router.beforeEach((to, from, next) => {
    const token          = authenticationService.getToken();
    const twoFactorToken = authenticationService.getTwoFactorToken();

    const missingToken = !token || !twoFactorToken;

    //////////////////////////////////////////////////////////////////////
    //                      NOT AUTHENTICATED USER                      //
    //////////////////////////////////////////////////////////////////////

    const areasThatCanBeAccessedIfNotAuthenticated = ["LOGIN"];

    if (areasThatCanBeAccessedIfNotAuthenticated.includes(to.name) && missingToken) {
        next();

        return;
    }

    //////////////////////////////////////////////////////////////////////
    //                        AUTHENTICATE USER                         //
    //////////////////////////////////////////////////////////////////////

    if (missingToken) {
        next({ name: "LOGIN" });

        return;
    }

    //////////////////////////////////////////////////////////////////////
    //                          AUTHORIZE USER                          //
    //////////////////////////////////////////////////////////////////////

    const areasThatCanBeAccessedWithAnyRole = ["HOMEPAGE"];

    userClient
        .getUserAreas()
        .then((result) => {
            let allowedAreas = result.data.map(item => item.area);

            allowedAreas = allowedAreas.concat(areasThatCanBeAccessedWithAnyRole);

            store.dispatch("setEnabledAreas", allowedAreas);

            if (to.meta.area === undefined) {
                console.error('Routes not defined!');
            }

            if (allowedAreas.includes(to.meta.area)) {
                store.dispatch("setHistoryPreviousRoutePath", to.fullPath);
                next();
            } else {
                if (store.getters.previousRoutePath !== null) {
                    next(store.getters.previousRoutePath);
                } else {
                    next({name: "HOMEPAGE"});
                }
            }
        }).catch((error) => {
            exceptionsMixins.methods.manageException.call({$router: router}, error);
        });
});

export default router;
