<template>
    <div class="vertical-center">
        <notifications />
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-7">
                    <div class="card bg-secondary border-0 mb-0">
                        <div class="card-body px-lg-5 py-lg-5">
                            <div style="margin-bottom: 30px; text-align:center;">
                                <img
                                    class="w-100"
                                    src="/img/brand/logo-talksmedia.png"
                                    alt="logo-brand"
                                >
                            </div>

                            <login v-if="$store.getters.isLoginEnabled" />

                            <two-factor
                                v-if="$store.getters.isTwoFactorEnabled"
                                :last-three-number-of-telephone-number="$store.state.authentication.twoFactor.obscuredTelephoneNumber"
                            />

                            <div
                                v-if="$store.getters.isTechnicalAssistanceEnabled"
                                :data-cy="'technicalAssistance'"
                            >
                                <p>{{ $t("authentications.assistanceMessage") }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-6">
                            <a
                                v-if="canSeeBackToLoginButton"
                                href="#"
                                class="text-light"
                                @click="backToLogin"
                            >
                                <small>{{ $t("authentications.returnToLogin") }}</small>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Login from "@/views/Pages/Authentication/Login";
import TwoFactor from "@/views/Pages/Authentication/TwoFactor";

export default {
    components: {
        Login,
        TwoFactor,
    },
    computed: {
        canSeeBackToLoginButton() {
            return this.$store.getters.isLoginEnabled === false;
        },
    },
    methods: {
        backToLogin() {
            this.$store.dispatch("moveToLogin");
        }
    }
};
</script>

<style>
.vertical-center {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
</style>
