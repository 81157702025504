import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import * as components from 'vuetify/lib/components';
import * as directives from 'vuetify/lib/directives';
import { it, en } from 'vuetify/es5/locale';

Vue.use(Vuetify, { components, directives });

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        icons: {
            iconfont: "mdi",
        },
        themes: {
            light: {
                primary: "#2196F3",
                secondary: "#424242",
                accent: "#82B1FF",
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFC107"
            },
        },
    },
    lang: {
        locales: {
            it,
            en
        },
        current: navigator.language.substring(0, 2),
    },
});
