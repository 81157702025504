<template>
    <div class="edit-modal">
        <LoadingBar v-show="showEdit && !loaded" />
        <modal
            :show="showEdit && loaded"
            modal-classes="modal-secondary"
            :size="'lg'"
            :data-cy="'updateModal'"
            @close="closeEdit"
        >
            <template slot="header">
                <h1>{{ $t("actions.update") + ' ' + $t("pages.registry.registry") }}</h1>
            </template>
            <validation-observer
                v-slot="{handleSubmit}"
                ref="formValidator"
            >
                <form
                    class="new-event-form"
                    @submit.prevent="handleSubmit(updateRegistry)"
                >
                    <div class="container">
                        <div class="form-group row">
                            <base-input
                                :label="$t('forms.fields.type')"
                                class="col-12"
                                :placeholder="$t('forms.actions.select') + $t('forms.fields.type')"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.type')"
                                :rules="{
                                    required: true,
                                    alpha_dash: true
                                }"
                                :data-cy="'editRegistryTypeField'"
                            >
                                <v-select
                                    v-model="model.type"
                                    class="form-control form-control-alternative"
                                    :options="registryTypeList"
                                    :reduce="type => type.code"
                                />
                            </base-input>
                            <base-input
                                v-model="model.name"
                                :label="$t('forms.fields.name')"
                                class="col-6"
                                :placeholder="$t('forms.fields.name')"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.name')"
                                :rules="{required:true}"
                                :data-cy="'editRegistryNameField'"
                            />
                            <base-input
                                v-model="model.email"
                                :label="$t('forms.fields.email')"
                                class="col-6"
                                :placeholder="$t('forms.fields.email')"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.email')"
                                :rules="{email: true}"
                                :data-cy="'editRegistryEmailField'"
                            />
                            <base-input
                                :label="$t('forms.fields.telephoneNumberPlaceholder')"
                                input-classes="form-control-alternative"
                                class="col-12"
                                :name="$t('forms.fields.telephoneNumberPlaceholder')"
                                :rules="{
                                    required: false
                                }"
                            >
                                <vue-tel-input
                                    v-model="model.telephone"
                                    :input-options="{placeholder: $t('forms.fields.telephoneNumberPlaceholder')}"
                                    mode="international"
                                    class="mb-3"
                                    :data-cy="'editRegistryTelephoneField'"
                                />
                            </base-input>
                            <base-input
                                v-model="model.vat"
                                :label="$t('forms.fields.vat')"
                                :placeholder="$t('forms.fields.vat')"
                                class="col-12"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.vat')"
                                vid="vatValidation"
                                :loading="vatValidationLoading"
                                :rules="vatValidationRule"
                                :data-cy="'editRegistryVatField'"
                                @focusout="checkVat"
                                @change="vatValidation = false; vatChanged = true;"
                            />
                            <base-input
                                :label="$t('forms.fields.taxRegime')"
                                :placeholder="$t('forms.fields.taxRegime')"
                                class="col-12"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.taxRegime')"
                                :rules="{required:false}"
                                :data-cy="'editRegistryTaxRegimeField'"
                            >
                                <v-select
                                    v-model="model.taxRegime"
                                    class="form-control form-control-alternative"
                                    :options="taxRegimeChoicesList"
                                    :reduce="taxRegime => taxRegime.code"
                                />
                            </base-input>
                            <base-input
                                v-model="model.nationalIdentificationNumber"
                                :label="$t('forms.fields.nationalIdentificationNumber')"
                                :placeholder="$t('forms.fields.nationalIdentificationNumber')"
                                class="col-6"
                                input-classes="form-control-alternative"
                                vid="nationalIdValidation"
                                :name="$t('forms.fields.nationalIdentificationNumber')"
                                :rules="nidValidationRule"
                                :data-cy="'editRegistryNationalIdNumberField'"
                            />
                            <base-input
                                v-model="model.feCode"
                                :label="$t('forms.fields.feCode')"
                                :placeholder="$t('forms.fields.feCode')"
                                class="col-6"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.feCode')"
                                :rules="{
                                    required: true,
                                    alpha_num: true,
                                    min: 6,
                                    max: 7
                                }"
                                :data-cy="'editRegistryFeCodeField'"
                            />
                        </div>
                        <tabs
                            tab-nav-classes="nav-fill flex-column flex-sm-row nav-wrapper"
                            tab-content-classes="card shadow"
                        >
                            <tab-pane
                                id="location"
                                :data-cy="'locationTab'"
                                class="container"
                            >
                                <span
                                    slot="title"
                                    :data-cy="'locationTabButton'"
                                >
                                    <i class="fas fa-map-marked-alt mr-1" />{{ $t("pages.registry.editTabs.location") }}
                                </span>
                                <div class="card-body row pl-0 pr-0">
                                    <base-input
                                        v-model="model.locationAddress"
                                        :label="$t('forms.fields.address')"
                                        :placeholder="$t('forms.actions.insert') + ' ' + $t('forms.fields.address')"
                                        class="col-6"
                                        input-classes="form-control-alternative"
                                        :name="$t('forms.fields.address')"
                                        :rules="{required: true}"
                                        :data-cy="'editRegistryAddressField'"
                                    />
                                    <base-input
                                        v-model="model.locationCity"
                                        :label="$t('forms.fields.city')"
                                        class="col-6"
                                        :placeholder="$t('forms.actions.insert') + ' ' + $t('forms.fields.city')"
                                        input-classes="form-control-alternative"
                                        :name="$t('forms.fields.city')"
                                        :rules="{required: true}"
                                        :data-cy="'editRegistryCityField'"
                                    />
                                    <base-input
                                        v-model="model.locationZip"
                                        :label="$t('forms.fields.zip')"
                                        class="col-6"
                                        :placeholder="$t('forms.actions.insert') + ' ' + $t('forms.fields.zip')"
                                        input-classes="form-control-alternative"
                                        :rules="{
                                            max:20,
                                            required: true
                                        }"
                                        :name="$t('forms.fields.zip')"
                                        :data-cy="'editRegistryZipField'"
                                    />
                                    <base-input
                                        v-model="model.locationState"
                                        :label="$t('forms.fields.state')"
                                        class="col-6"
                                        :placeholder="$t('forms.actions.insert') + ' ' + $t('forms.fields.state')"
                                        input-classes="form-control-alternative"
                                        :name="$t('forms.fields.state')"
                                        :rules="{
                                            min:2,
                                            max:2,
                                            alpha: true
                                        }"
                                        :data-cy="'editRegistryStateField'"
                                    />
                                    <base-input
                                        :label="$t('forms.fields.country')"
                                        class="col-12"
                                        :placeholder="$t('forms.actions.select') + $t('forms.fields.country')"
                                        input-classes="form-control-alternative"
                                        :rules="{
                                            required: true,
                                            alpha: true
                                        }"
                                        :name="$t('forms.fields.country')"
                                        :data-cy="'editRegistryCountryField'"
                                    >
                                        <v-select
                                            v-model="model.locationCountry"
                                            class="form-control form-control-alternative"
                                            :options="countryList"
                                            :reduce="country => country.code"
                                        />
                                    </base-input>
                                </div>
                            </tab-pane>

                            <tab-pane
                                id="bank"
                                :data-cy="'bankTab'"
                                class="container"
                            >
                                <span
                                    slot="title"
                                    :data-cy="'bankTabButton'"
                                >
                                    <i class="fas fa-hand-holding-usd mr-1" />{{ $t("pages.registry.editTabs.bank") }}</span>
                                <div class="card-body row pl-0 pr-0">
                                    <base-input
                                        v-model="model.bankAccountBeneficiaryName"
                                        :label="$t('forms.fields.beneficiaryName')"
                                        class="col-12"
                                        :placeholder="$t('forms.fields.beneficiaryName')"
                                        input-classes="form-control-alternative"
                                        :name="$t('forms.fields.beneficiaryName')"
                                        :rules="{required: false}"
                                        :data-cy="'editRegistryBeneficiaryNameField'"
                                    />
                                    <base-input
                                        v-model="model.bankAccountIban"
                                        :label="$t('forms.fields.iban')"
                                        class="col-12"
                                        :placeholder="$t('forms.fields.iban')"
                                        input-classes="form-control-alternative"
                                        :name="$t('forms.fields.iban')"
                                        :rules="{
                                            required: false,
                                            alpha_num: true
                                        }"
                                        vid="ibanValidation"
                                        :data-cy="'editRegistryIbanField'"
                                        :loading="ibanValidationLoading"
                                        @focusout="checkIban"
                                        @change="ibanValidation = false"
                                    />
                                    <dl
                                        v-show="ibanValidation"
                                        class="col-12"
                                    >
                                        <dt>{{ $t('forms.fields.name') }}</dt>
                                        <dd>{{ bankInformation.name }}</dd>
                                        <dt>{{ $t('forms.fields.city') }}</dt>
                                        <dd>{{ bankInformation.branch }}</dd>
                                        <dt>{{ $t('forms.fields.swiftBic') }}</dt>
                                        <dd>{{ bankInformation.bic }}</dd>
                                        <dt>{{ $t('forms.fields.country') }}</dt>
                                        <dd>{{ bankInformation.countryIso }}</dd>
                                    </dl>
                                </div>
                            </tab-pane>

                            <tab-pane
                                id="contact"
                                :data-cy="'contactTab'"
                                class="container"
                            >
                                <span
                                    slot="title"
                                    :data-cy="'contactTabButton'"
                                >
                                    <i class="fas fa-address-card mr-1" />{{ $t("pages.registry.editTabs.contact") }}
                                </span>
                                <div class="card-body row pl-0 pr-0">
                                    <base-input
                                        :label="$t('forms.fields.administrativeContact')"
                                        :name="$t('forms.fields.administrativeContact')"
                                        class="col-12"
                                        :placeholder="$t('forms.actions.select') + $t('forms.fields.administrativeContact')"
                                        input-classes="form-control-alternative"
                                        :data-cy="'editRegistryAdminContactField'"
                                    >
                                        <v-select
                                            v-model="model.administrativeContactId"
                                            label="customLabel"
                                            class="form-control form-control-alternative"
                                            :options="contactList"
                                            :reduce="contact => contact.id"
                                        />
                                    </base-input>
                                    <base-input
                                        :label="$t('forms.fields.technicalContact')"
                                        :name="$t('forms.fields.technicalContact')"
                                        class="col-12"
                                        :placeholder="$t('forms.actions.select') + $t('forms.fields.technicalContact')"
                                        input-classes="form-control-alternative"
                                        :data-cy="'editRegistryTechContactField'"
                                    >
                                        <v-select
                                            v-model="model.technicalContactId"
                                            label="customLabel"
                                            class="form-control form-control-alternative"
                                            :options="contactList"
                                            :reduce="contact => contact.id"
                                        />
                                    </base-input>
                                    <div class="col-12">
                                        <div class="row align-items-center pb-2 ">
                                            <div class="col-lg-8 col-9">
                                                <h6 class="d-inline-block mb-0">
                                                    <em>{{ $t("pages.registry.cantFindContactQuestion") }}</em>
                                                </h6>
                                            </div>
                                            <div class="col-lg-4 col-3 text-right">
                                                <base-button
                                                    size="sm"
                                                    type="neutral"
                                                    :data-cy="'buttonCreateContact'"
                                                    @click="openNewContactModal"
                                                >
                                                    {{ $t("actions.add") }}
                                                </base-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </tab-pane>
                        </tabs>
                        <div class="d-flex justify-content-end">
                            <base-button
                                native-type="submit"
                                type="primary"
                                :data-cy="'updateEditRegistry'"
                                :disabled="updating"
                                :loading="updating"
                            >
                                {{ $t("actions.update") }}
                            </base-button>
                            <base-button
                                type="link"
                                :data-cy="'closeEditRegistry'"
                                @click="closeEdit"
                            >
                                {{ $t("actions.cancel") }}
                            </base-button>
                        </div>
                    </div>
                </form>
            </validation-observer>
        </modal>
        <NewContact
            :key="'contact' + newContactKey"
            :show-new="showNewContact"
            @close-new="showNewContact = false;"
            @data-changed="loadContacts"
        />
    </div>
</template>

<script>
import { registryClient } from "@/clients/registryClient";
import { contactClient } from "@/clients/contactClient";
import { bankClient } from "@/clients/bankClient";
import { ValidationObserver } from "vee-validate";
import { validateClient } from "@/clients/validateClient";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import choicesMixins from "@/mixins/choicesMixins";
import RegistryDto from "@/clients/dto/RegistryDto";
import BaseButton from "@/components/BaseButton";
import Modal from "@/components/Modal";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import vSelect from 'vue-select';
import TabPane from "@/components/Tabs/Tab";
import Tabs from "@/components/Tabs/Tabs";
import NewContact from "@/views/Pages/Components/Contact/Modal/New";
import BankDto from "@/clients/dto/BankDto";

export default {
    name: 'EditRegistry',
    components: {
        BaseButton,
        Modal,
        LoadingBar,
        ValidationObserver,
        VueTelInput,
        vSelect,
        TabPane,
        Tabs,
        NewContact
    },
    mixins: [
        exceptionsMixins,
        choicesMixins
    ],
    props: {
        editRegistryId: {
            type: Number,
        },
        showEdit: {
            type: Boolean,
        }
    },
    data() {
        return {
            loaded: false,
            updating: false,
            tab: 'location',
            contactList: [],
            bankList: [],
            model: {
                name: null,
                email: null,
                notValidatedTelephone: null,
                telephone: '',
                type: null,
                vat: null,
                nationalIdentificationNumber: null,
                taxRegime: null,
                feCode: null,
                locationAddress: null,
                locationCity: null,
                locationState: null,
                locationZip: null,
                locationCountry: null,
                administrativeContactId: null,
                technicalContactId: null,
                bankAccountBeneficiaryName: null,
                bankAccountIban: null,
                bankId: null,
                bankName: null,
                administrativeContactName: null,
                technicalContactName: null,
            },
            showNewContact: false,
            newContactKey: 0,
            vatValidationLoading: false,
            vatValidation: false,
            vatChanged: false,
            ibanValidationLoading: false,
            ibanValidation: false,
            ibanChanged: false,
            bankInformation: {
                name: null,
                branch: null,
                bic: null,
                countryIso: null,
            },
            countryThatNotRequiredFiscalData: [],
        }
    },
    computed: {
        isFiscalDataRequired() {
            return !this.countryThatNotRequiredFiscalData.includes(this.model.locationCountry);
        },
        vatValidationRule() {
            return this.isFiscalDataRequired ? 'vat_or_nid_required:@nationalIdValidation' : '';
        },
        nidValidationRule() {
            return this.isFiscalDataRequired ? 'vat_or_nid_required:@vatValidation' : '';
        }
    },
    watch: {
        showEdit() {
            if (this.editRegistryId) {
                this.loadRegistryMetadata();

                this.loaded = false;
                this.tab = 'location';
                this.model.notValidatedTelephone = null;
                this.vatValidationLoading = false;
                this.vatValidation = false;
                this.vatChanged = false;
                this.ibanValidationLoading = false;
                this.ibanValidation = false;
                this.ibanChanged = false;
                registryClient.getById(this.editRegistryId).then((result) => {
                    this.model.name = result.data.name;
                    this.model.email = result.data.email;
                    this.model.telephone = result.data.telephone ? String(result.data.telephone) : '';
                    this.model.notValidatedTelephone = result.data.telephone;
                    this.model.type = result.data.type;
                    this.model.vat = result.data.vat;
                    this.model.nationalIdentificationNumber = result.data.nationalIdentificationNumber;
                    this.model.taxRegime = result.data.taxRegime;
                    this.model.feCode = result.data.feCode;
                    this.model.locationAddress = result.data.location.address;

                    if (result.data.location) {
                        this.model.locationCity = result.data.location.city;
                        this.model.locationState = result.data.location.state;
                        this.model.locationZip = result.data.location.zip;
                        this.model.locationCountry = result.data.location.country;
                    }

                    this.model.administrativeContactId = (result.data.administrativeContact) ? result.data.administrativeContact.id : '';
                    this.model.technicalContactId = (result.data.technicalContact) ? result.data.technicalContact.id : '';

                    if (result.data.bankAccount) {
                        this.model.bankAccountBeneficiaryName = result.data.bankAccount.name;
                        this.model.bankAccountIban = result.data.bankAccount.iban;
                        this.model.bankId = result.data.bankAccount.bank.id;
                    }


                    return contactClient.getByFilters();
                }).then((response) => {
                    this.contactList = response.data;
                    this.generateContactLabelInList(this.contactList);
                    return bankClient.getByFilters();
                }).then((response) => {
                    this.bankList = response.data;
                    this.generateBankLabelInList(this.bankList);
                }).catch((error) => {
                    this.manageException(error.response);
                }).then(() => {
                    this.loaded = true;
                });
            }
        },
    },
    methods: {
        closeEdit() {
            this.$emit('close-edit');
        },
        async loadRegistryMetadata() {
            await registryClient.getMetadata()
                .then((response) => {
                    this.countryThatNotRequiredFiscalData = response.data.countriesThatDoNotRequireFiscalData;
                }).catch((error) => {
                    this.manageException(error.response);
                });
        },
        updateRegistry() {
            if (!this.vatValidation && this.vatChanged && this.isFiscalDataRequired) {
                this.setVatError();
                return;
            }

            if (!this.ibanValidation && this.ibanChanged) {
                this.setIbanError();
                return;
            }

            this.updating = true;
            let registryDto = new RegistryDto(
                this.model.name,
                this.model.email,
                this.model.telephone?.replace(/ /g, ''),
                this.model.type,
                this.model.vat,
                this.model.nationalIdentificationNumber,
                this.model.taxRegime,
                this.model.feCode,
                this.model.locationAddress,
                this.model.locationCity,
                this.model.locationState,
                this.model.locationZip,
                this.model.locationCountry,
                this.model.administrativeContactId,
                this.model.technicalContactId,
                this.model.bankAccountBeneficiaryName,
                this.model.bankAccountIban,
                this.model.bankId
            );

            registryClient.updateRegistry(this.editRegistryId, registryDto).then((response) => {
                this.$notify({
                    type: "success",
                    icon: "ni ni-notification-70",
                    message: this.$t("notifications.success.updated")
                });
                this.$emit('data-changed', response.data.id);
                this.closeEdit();
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.updating = false;
            });
        },
        generateContactLabelInList(list) {
            list.forEach(element => element.customLabel = element.name + ' (' + element.email + ')');
        },
        generateBankLabelInList(list) {
            list.forEach(element => element.customLabel = element.name + ' (' + element.swiftBic + ')');
        },
        openNewContactModal() {
            this.newContactKey++;
            this.showNewContact = true;
        },
        loadContacts() {
            this.loading = true;
            contactClient.getByFilters().then((response) => {
                this.contactList = response.data;
                this.generateContactLabelInList(this.contactList);
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loading = false;
            });
        },
        loadBanks(selectedBankId) {
            this.loading = true;
            bankClient.getByFilters().then((response) => {
                this.bankList = response.data;
                if (selectedBankId !== undefined) {
                    this.model.bankId = selectedBankId;
                }
                this.generateLabelInList(this.bankList);
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loading = false;
            })
        },
        checkVat() {
            if (!this.model.vat || this.model.vat.length === 0) {
                return;
            }

            this.vatValidationLoading = true;
            validateClient.validateVat(this.model.vat, this.model.locationCountry).then((response) => {
                if (response.data.supported) {
                    this.vatValidation = response.data.valid;
                } else {
                    this.vatValidation = true;
                }
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                if (!this.vatValidation) {
                    this.setVatError();
                }
                this.vatValidationLoading = false;
            });
        },
        checkIban() {
            if (!this.model.bankAccountIban || this.model.bankAccountIban.length === 0) {
                return;
            }

            this.ibanValidationLoading = true;
            validateClient.validateIban(this.model.bankAccountIban).then((response) => {
                if (response.data.supported) {
                    this.ibanValidation = response.data.valid;

                    if (response.data.valid) {
                        this.bankInformation.name = response.data.bank.bank;
                        this.bankInformation.bic = response.data.bank.bic;
                        this.bankInformation.branch = response.data.bank.branch;
                        this.bankInformation.countryIso = response.data.bank.countryIso;

                        this.setOrCreateBank(response.data.bank);
                    }
                } else {
                    this.ibanValidation = true;
                }
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                if (!this.ibanValidation) {
                    this.setIbanError();
                }
                this.ibanValidationLoading = false;
            });
        },
        setOrCreateBank(dataBank) {
            this.loading = true;
            const bankSearched = this.bankList.find(bank => bank.swiftBic === dataBank.bic);

            if (bankSearched) {
                this.model.bankId = bankSearched.id;
                this.loading = false;
                return;
            }

            const bankDto = new BankDto(
                dataBank.bank,
                dataBank.bic,
                null,
                dataBank.city,
                dataBank.state,
                null,
                dataBank.countryIso
            );

            bankClient.createBank(bankDto).then((response) => {
                this.loadBanks(response.data.id);
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loading = false;
            });
        },
        setVatError() {
            this.$refs.formValidator.setErrors({
                vatValidation: [this.$t('forms.validationMessage.invalidVat')],
            });
        },
        setIbanError() {
            this.$refs.formValidator.setErrors({
                ibanValidation: [this.$t('forms.validationMessage.invalidIban')],
            });
        }
    }
}
</script>

<style lang="scss">
.btn-tab {
    margin: 0 !important;
    background: #fff;
    box-shadow: none;
    border-radius: 0 !important;

    & .selected {
        background: #4163A9;
        color: #fff;
    }

    &:hover, &:focus {
        box-shadow: none;
    }
}
</style>
