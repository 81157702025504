export default class FiscalDocumentOutcomingDto {
    #registrySenderId;
    #registryRecipientId;
    #documentType;
    #paymentType;
    #paymentMode;
    #paymentDeadLineDays;
    #currency;
    #date;
    #number;
    #detailLines;
    #sendEmail;
    #invoiceParentId;
    #revenueStamp;
    #purpose;

    /**
     * Constructor.
     *
     * @param registrySenderId {number}
     * @param registryRecipientId {number}
     * @param documentType {string}
     * @param paymentType {string}
     * @param paymentMode {string}
     * @param paymentDeadLineDays {number}
     * @param currency {string}
     * @param date {Date}
     * @param number {number}
     * @param detailLines {FiscalDocumentDetailLineOutcomingDto[]}
     * @param sendEmail {boolean}
     * @param invoiceParentId {number|null}
     * @param revenueStamp {number}
     * @param purpose {string}
     */
    constructor(
        registrySenderId,
        registryRecipientId,
        documentType,
        paymentType,
        paymentMode,
        paymentDeadLineDays,
        currency,
        date,
        number,
        detailLines,
        sendEmail,
        invoiceParentId,
        revenueStamp,
        purpose
    ) {
        this.#registrySenderId = registrySenderId;
        this.#registryRecipientId = registryRecipientId;
        this.#documentType = documentType;
        this.#paymentType = paymentType;
        this.#paymentMode = paymentMode;
        this.#paymentDeadLineDays = paymentDeadLineDays;
        this.#currency = currency;
        this.#date = date;
        this.#number = number;
        this.#detailLines = detailLines;
        this.#sendEmail = sendEmail;
        this.#invoiceParentId = invoiceParentId;
        this.#revenueStamp = revenueStamp;
        this.#purpose = purpose;
    }

    get registrySenderId() {
        return this.#registrySenderId;
    }

    get registryRecipientId() {
        return this.#registryRecipientId;
    }

    get documentType() {
        return this.#documentType;
    }

    get paymentType() {
        return this.#paymentType;
    }

    get paymentMode() {
        return this.#paymentMode;
    }

    get paymentDeadLineDays() {
        return this.#paymentDeadLineDays;
    }

    get currency() {
        return this.#currency;
    }

    get date() {
        return this.#date;
    }

    get number() {
        return this.#number;
    }

    get detailLines() {
        return this.#detailLines;
    }

    get sendEmail() {
        return this.#sendEmail;
    }

    get invoiceParentId() {
        return this.#invoiceParentId;
    }

    get revenueStamp() {
        return this.#revenueStamp;
    }

    get purpose() {
        return this.#purpose;
    }
}
