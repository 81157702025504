/**
 * authorizationsMixins.
 */
export default {
    methods: {
        /**
         * Method that check if an area is enabled for the current user.
         *
         * @param {string} area
         * @returns {boolean}
         */
        isGranted(area) {
            return this.$store.state.authorization.areas.includes(area);
        },
    }
}
