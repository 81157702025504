export const mutations = {
    setAuthenticationEmail(state, email) {
        state.authentication.email = email;
    },
    setAuthenticationPassword(state, password) {
        state.authentication.password = password;
    },
    setTechnicalAssistance(state, status) {
        state.authentication.technicalAssistance = status;
    },
    setAllStepsEnabled(state, status) {
        state.authentication.login.stepEnabled = status;
        state.authentication.twoFactor.stepEnabled = status;
        state.authentication.technicalAssistance = status;
    },
    setStepLogin(state, status) {
        state.authentication.login.stepEnabled = status;
    },
    setStepTwoFactor(state, status) {
        state.authentication.twoFactor.stepEnabled = status;
    },
    setTwoFactorLastThreeCharTelephoneNumber(state, obscuredTelephoneNumber) {
        state.authentication.twoFactor.obscuredTelephoneNumber = obscuredTelephoneNumber;
    },
    setActiveRegistryId(state, id) {
        state.company.registryId = id;
    },
    setActiveRegistryEmail(state, email) {
        state.company.registryEmail = email;
    },
    setAuthorizationAreas(state, areas) {
        state.authorization.areas = areas;
    },
    setPreviousRoutePath(state, route) {
        state.previousRoutePath = route;
    },
    setYearSelectedFilter(state, year) {
        state.yearSelectedFilter = year;
    },
    updateKeyReloaderContents(state) {
        state.keyReloader = state.keyReloader + 1;
    },
    setFiscalDocumentFilters(state, filter) {
        state.fiscalDocumentFilters = filter;
    },
    setRegistryFilters(state, filter) {
        state.registryFilters = filter;
    },
    setCashFlowFilters(state, filter) {
        state.cashFlowFilters = filter;
    },
};
