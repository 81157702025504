<template>
    <div class="content">
        <Header
            :title="$t('pages.contact.title')"
        >
            <base-button
                type="neutral"
                :data-cy="'buttonCreateContact'"
                @click="openNewModal"
            >
                {{ $t("actions.new") }}
            </base-button>
        </Header>

        <div class="container-fluid mt--6">
            <ContactTable
                :table-data="contactTableData"
                @data-changed="loadData"
            />
        </div>

        <NewContact
            :key="newContactKey"
            :show-new="showNewContact"
            @close-new="showNewContact = false;"
            @data-changed="loadData"
        />
        <LoadingBar v-show="loading" />
    </div>
</template>

<script>
import { contactClient } from "@/clients/contactClient";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import ContactTable from "@/views/Pages/Components/Contact/Table";
import NewContact from "@/views/Pages/Components/Contact/Modal/New";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import Header from "@/components/Header";

export default {
    name: 'Contact',
    components: {
        ContactTable,
        LoadingBar,
        NewContact,
        Header,
    },
    mixins: [
        exceptionsMixins,
    ],
    data() {
        return {
            showNewContact: false,
            newContactKey: 0,
            contactTableData: [],
            loading: false,
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        openNewModal() {
            this.showNewContact = true;
            this.newContactKey++;
        },
        loadData() {
            this.loading = true;
            contactClient.getByFilters().then((response) => {
                this.contactTableData = response.data;
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loading = false;
            });
        }
    }
}
</script>
