import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import Vuex from 'vuex';
import VueI18n from "vue-i18n";
import { IT_TRANSLATIONS } from "./translations/it.js";
import { EN_TRANSLATIONS } from "./translations/en.js";
import { localeService } from "@/services/localeService";
import vuetify from '@/plugins/vuetify';

import { store } from "./store/store";
export default store;

// Router setup
import router from './routes/router';

// Plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueI18n);
Vue.use(Vuex);

const languagesTranslationsSupported = {
    it: IT_TRANSLATIONS,
    en: EN_TRANSLATIONS
};

const i18n = new VueI18n({
    locale: localeService.getLocale(),
    messages: languagesTranslationsSupported,
    fallbackLocale: 'it-IT',
});

/* eslint-disable no-new */
new Vue({
    el: '#app',
    render: h => h(App),
    vuetify,
    router,
    i18n,
    store,
});
