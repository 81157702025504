import { baseEntityClient } from "@/clients/baseEntityClient";
import { SupportedEntity } from "@/clients/constants/clientConstants";
import { AxiosResponse } from "axios";

/**
 * bankClient.
 */
export const bankClient = {
    /**
     * Call get bank endpoint with provided id.
     *
     * @param bankId {number}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getById(bankId) {
        return baseEntityClient.getEntityById(SupportedEntity.BANK, bankId);
    },
    /**
     * Call get bank endpoint with filters.
     *
     * @param swiftBic {string|null}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getByFilters(swiftBic = null) {
        const filters = {
            swiftBic: swiftBic
        };

        return baseEntityClient.getEntityByFilters(SupportedEntity.BANK, filters);
    },
    /**
     * Call the delete bank endpoint.
     *
     * @param id {number}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteBank(id) {
        return baseEntityClient.deleteEntityById(SupportedEntity.BANK, id);
    },
    /**
     * Call update bank endpoint.
     *
     * @param id {number}
     * @param bankDto {BankDto}
     */
    updateBank(id, bankDto) {
        const data = {
            name: bankDto.name,
            swiftBic: bankDto.swiftBic,
            locationAddress: bankDto.locationAddress,
            locationCity: bankDto.locationCity,
            locationState: bankDto.locationState,
            locationZip: bankDto.locationZip,
            locationCountry: bankDto.locationCountry,
        };

        return baseEntityClient.updateEntity(SupportedEntity.BANK, id, data);
    },
    /**
     * Call create bank endpoint.
     *
     * @param bankDto {BankDto}
     */
    createBank(bankDto) {
        const data = {
            name: bankDto.name,
            swiftBic: bankDto.swiftBic,
            locationAddress: bankDto.locationAddress,
            locationCity: bankDto.locationCity,
            locationState: bankDto.locationState,
            locationZip: bankDto.locationZip,
            locationCountry: bankDto.locationCountry,
        };

        return baseEntityClient.createEntity(SupportedEntity.BANK, data);
    }
}
