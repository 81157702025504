<template>
    <div :class="classes">
        <base-button
            size="sm"
            type="primary"
            :data-cy="'presetToday'"
            :disabled="presetDatesDisabled"
            @click="emitPresetDate(presetDates.TODAY)"
        >
            {{ $t("dates.periods.today") }}
        </base-button>
        <base-button
            size="sm"
            type="primary"
            :data-cy="'presetYesterday'"
            :disabled="presetDatesDisabled || isYesterdayDisabled"
            @click="emitPresetDate(presetDates.YESTERDAY)"
        >
            {{ $t("dates.periods.yesterday") }}
        </base-button>
        <base-button
            size="sm"
            type="primary"
            :data-cy="'presetSevenDays'"
            :disabled="presetDatesDisabled || isSevenDaysDisabled"
            @click="emitPresetDate(presetDates.SEVEN_DAYS)"
        >
            {{ $t("dates.periods.sevenDays") }}
        </base-button>
        <base-button
            size="sm"
            type="primary"
            :data-cy="'presetOneMonth'"
            :disabled="presetDatesDisabled || isOneMonthDisabled"
            @click="emitPresetDate(presetDates.ONE_MONTH)"
        >
            {{ $t("dates.periods.oneMonth") }}
        </base-button>
        <base-button
            size="sm"
            type="primary"
            :data-cy="'presetThisMonth'"
            :disabled="presetDatesDisabled"
            @click="emitPresetDate(presetDates.THIS_MONTH)"
        >
            {{ $t("dates.periods.thisMonth") }}
        </base-button>
        <base-button
            size="sm"
            type="primary"
            :data-cy="'presetLastMonth'"
            :disabled="presetDatesDisabled || isLastMonthDisabled"
            @click="emitPresetDate(presetDates.LAST_MONTH)"
        >
            {{ $t("dates.periods.lastMonth") }}
        </base-button>
    </div>
</template>

<script>
import { PresetDates } from "@/constants/presetDatesConstants";
import presetDatesMixins from "@/mixins/presetDatesMixins";
import formatterMixins from "@/mixins/formatterMixins";

export default {
    name: 'PresetDate',
    mixins: [
        presetDatesMixins,
        formatterMixins,
    ],
    props: {
        classes: {
            type: String,
            default: 'col preset-filter-container'
        }
    },
    computed: {
        presetDates() {
            return PresetDates;
        },
        presetDatesDisabled() {
            return Number(this.$store.getters.yearSelectedFilter) !== (new Date()).getFullYear();
        },
        isYesterdayDisabled() {
            return this.checkIfPresetDateIsDisabled(PresetDates.YESTERDAY);
        },
        isSevenDaysDisabled() {
            return this.checkIfPresetDateIsDisabled(PresetDates.SEVEN_DAYS);
        },
        isOneMonthDisabled() {
            return this.checkIfPresetDateIsDisabled(PresetDates.ONE_MONTH);
        },
        isLastMonthDisabled() {
            return this.checkIfPresetDateIsDisabled(PresetDates.LAST_MONTH);
        }
    },
    methods: {
        checkIfPresetDateIsDisabled(type) {
            const calculatedDates = this.calculateFromAndToDateBasedOnRequiredPeriod(type);

            const firstDayOfYear = new Date((new Date()).getFullYear(), 0, 1);

            return calculatedDates.fromDate < firstDayOfYear;
        },
        emitPresetDate(type = PresetDates.THIS_MONTH) {
            const calculatedDates = this.calculateFromAndToDateBasedOnRequiredPeriod(type);

            const dates = {};

            dates.fromDateToSend = this.formatFrontendDate(calculatedDates.fromDate);
            dates.toDateToSend = this.formatFrontendDate(calculatedDates.toDate);

            dates.range = dates.fromDateToSend + this.$t("timeTerms.to") + dates.toDateToSend;

            this.$emit('preset-selected', dates);
        },
    }
}
</script>

<style lang="scss">
.preset-filter-container {
    button {
        min-width: 106px;
    }
}
</style>
