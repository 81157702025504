/**
 * vatCalculationMixins.
 */
export default {
    methods: {
        /**
         * Calculate the tax from an amount with vat applied.
         *
         * @param {number|string} amountWithVat
         * @param {number} vatAmount
         *
         * @returns {number}
         */
        calculateTaxFromAmountWithVat(amountWithVat, vatAmount) {
            return parseFloat((parseFloat(amountWithVat) - this.calculateAmountWithoutTax(amountWithVat, vatAmount)).toFixed(2));
        },
        /**
         * Calculate the amount by removing the tax part.
         *
         * @param {number|string} amountWithVat
         * @param {number|string} vatAmount
         *
         * @returns {number}
         */
        calculateAmountWithoutTax(amountWithVat, vatAmount) {
            return (100 * parseFloat(amountWithVat)) / (100 + parseFloat(vatAmount));
        }
    }
};
