<template>
    <div
        class="col"
        :data-cy="'registryTable'"
    >
        <card
            class="no-border-card"
            body-classes="p-0"
            footer-classes="pb-2"
        >
            <BaseTable
                :table-data="tableData"
                :search-keys="[
                    'name',
                    'email',
                    'vat',
                    'location.country'
                ]"
            >
                <el-table-column
                    v-for="column in tableColumns"
                    :key="column.label"
                    v-bind="column"
                    :formatter="column.formatter"
                />
                <el-table-column
                    align="right"
                    :label="$t('tables.column.actions')"
                    :min-width="80"
                >
                    <div
                        slot-scope="{$index, row}"
                        class="d-flex flex-wrap"
                    >
                        <base-button
                            :data-cy="'buttonUpdateRegistryId'+row.id"
                            class="edit"
                            type="primary"
                            size="sm"
                            icon
                            @click="openEditModal(row.id)"
                        >
                            <i class="fas fa-edit text-white" />
                        </base-button>
                        <base-button
                            :data-cy="'buttonDeleteRegistryId'+row.id"
                            class="remove btn-link"
                            type="danger"
                            size="sm"
                            icon
                            @click="openDeleteModal(row.id)"
                        >
                            <i class="fas fa-trash-alt text-white" />
                        </base-button>
                        <base-button
                            :data-cy="'buttonDetailsRegistryId'+row.id"
                            class="remove btn-link border"
                            type="secondary"
                            size="sm"
                            icon
                            @click="$router.push(
                                {
                                    name: 'REGISTRY-DETAIL',
                                    params: {
                                        id: row.id
                                    }
                                }
                            );"
                        >
                            <i class="fas fa-info-circle" />
                        </base-button>
                    </div>
                </el-table-column>
            </BaseTable>
        </card>

        <DeleteRegistry
            :show-delete="deleteModal.showDeleteModal"
            :delete-registry-id="deleteModal.idRegistryToDelete"
            :registry-name="deleteModal.registryName"
            @data-changed="$emit('data-changed')"
            @close-delete="deleteModal.showDeleteModal = false;"
        />
        <EditRegistry
            :show-edit="editModal.showEditModal"
            :edit-registry-id="editModal.editRegistryId"
            @data-changed="$emit('data-changed')"
            @close-edit="editModal.showEditModal = false;"
        />
    </div>
</template>

<script>
import { Option, Select, Table, TableColumn } from "element-ui";
import BaseButton from "@/components/BaseButton";
import DeleteRegistry from "@/views/Pages/Components/Registry/Modal/Delete";
import EditRegistry from "@/views/Pages/Components/Registry/Modal/Edit";
import BaseTable from "@/views/Pages/Components/Table/BaseTable";

export default {
    name: "RegistryTable",
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        DeleteRegistry,
        EditRegistry,
        BaseButton,
        BaseTable
    },
    props: {
        tableData: {
            type: Array
        }
    },
    data() {
        return {
            tableColumns: [
                {
                    prop: "name",
                    label: this.$t("tables.column.name"),
                    minWidth: 130,
                    sortable: true,
                },
                {
                    prop: "email",
                    label: this.$t("tables.column.email"),
                    minWidth: 100,
                },
                {
                    prop: "type",
                    label: this.$t("tables.column.type"),
                    minWidth: 40,
                    formatter: (row) => {
                        return this.$t(`choices.registry.type.${row.type}`)
                    }
                },
                {
                    prop: "vat",
                    label: this.$t("tables.column.vat"),
                    minWidth: 60,
                },
                {
                    prop: "location.country",
                    label: this.$t("tables.column.country"),
                    minWidth: 40,
                    formatter: (row) => {
                        return row.location?.country ? this.$t(`choices.country.${row.location.country}`) : '';
                    }
                }
            ],
            deleteModal: {
                showDeleteModal: false,
                idRegistryToDelete: null,
                registryName: '',
                loading: false,
            },
            editModal: {
                showEditModal: false,
                editRegistryId: null,
                key: 0,
            },
        };
    },
    methods: {
        openEditModal(id) {
            this.editModal.key++;
            this.editModal.editRegistryId = Number(id);
            this.editModal.showEditModal = true;
        },
        openDeleteModal(id) {
            this.deleteModal.idRegistryToDelete = Number(id);
            this.deleteModal.showDeleteModal = true;
            this.deleteModal.registryName = (this.tableData.filter((value) => {
                return value.id === id;
            })[0]).name;
        },
    }
};
</script>
