export const getters = {
    previousRoutePath: (state) => {
        return state.previousRoutePath;
    },
    activeRegistryId: state => {
        return state.company.registryId;
    },
    activeRegistryEmail: state => {
        return state.company.registryEmail;
    },
    email: state => {
        return state.authentication.email;
    },
    isLoginEnabled: state => {
        return state.authentication.login.stepEnabled;
    },
    isTwoFactorEnabled: state => {
        return state.authentication.twoFactor.stepEnabled;
    },
    twoFactorObscuredTelephone: state => {
        return state.authentication.twoFactor.obscuredTelephoneNumber;
    },
    isTechnicalAssistanceEnabled: state => {
        return state.authentication.technicalAssistance;
    },
    yearSelectedFilter: (state) => {
        return state.yearSelectedFilter;
    },
    keyReloaderContent: (state) => {
        return state.keyReloader;
    },
    fiscalDocumentFilters: (state) => {
        return state.fiscalDocumentFilters;
    },
    registryFilters: (state) => {
        return state.registryFilters;
    },
    cashFlowFilters: (state) => {
        return state.cashFlowFilters;
    }
};
