<template>
    <div
        class="content"
        :data-cy="'recapStep'"
    >
        <h2 class="mb-4">
            {{ $t("pages.registry.recapStepTitle") }}
        </h2>
        <div class="card-text mb-4 row">
            <div class="col">
                <dl class="row">
                    <dt class="col-sm-4">
                        {{ $t("forms.fields.name") }}
                    </dt>
                    <dd class="col-sm-8">
                        {{ registry.name }}
                    </dd>
                    <dt class="col-sm-4">
                        {{ $t("forms.fields.email") }}
                    </dt>
                    <dd class="col-sm-8">
                        {{ registry.email }}
                    </dd>
                    <dt class="col-sm-4">
                        {{ $t("forms.fields.telephone") }}
                    </dt>
                    <dd class="col-sm-8">
                        {{ registry.telephone }}
                    </dd>
                    <dt class="col-sm-4">
                        {{ $t("forms.fields.type") }}
                    </dt>
                    <dd class="col-sm-8">
                        {{ registry.type }}
                    </dd>
                    <dt class="col-sm-4">
                        {{ $t("forms.fields.vat") }}
                    </dt>
                    <dd class="col-sm-8">
                        {{ registry.vat }}
                    </dd>
                    <dt class="col-sm-4">
                        {{ $t("forms.fields.nationalIdentificationNumber") }}
                    </dt>
                    <dd class="col-sm-8">
                        {{ registry.nationalIdentificationNumber }}
                    </dd>
                    <dt class="col-sm-4">
                        {{ $t("forms.fields.taxRegime") }}
                    </dt>
                    <dd class="col-sm-8">
                        {{ registry.taxRegime }}
                    </dd>
                    <dt class="col-sm-4">
                        {{ $t("forms.fields.feCode") }}
                    </dt>
                    <dd class="col-sm-8">
                        {{ registry.feCode }}
                    </dd>
                </dl>
            </div>
            <div class="col">
                <dl class="row">
                    <dt class="col-sm-4">
                        {{ $t("forms.fields.address") }}
                    </dt>
                    <dd class="col-sm-8">
                        {{ registry.locationAddress }}
                    </dd>
                    <dt class="col-sm-4">
                        {{ $t("forms.fields.city") }}
                    </dt>
                    <dd class="col-sm-8">
                        {{ registry.locationCity }}
                    </dd>
                    <dt class="col-sm-4">
                        {{ $t("forms.fields.state") }}
                    </dt>
                    <dd class="col-sm-8">
                        {{ registry.locationState }}
                    </dd>
                    <dt class="col-sm-4">
                        {{ $t("forms.fields.zip") }}
                    </dt>
                    <dd class="col-sm-8">
                        {{ registry.locationZip }}
                    </dd>
                    <dt class="col-sm-4">
                        {{ $t("forms.fields.country") }}
                    </dt>
                    <dd class="col-sm-8">
                        {{ registry.locationCountry }}
                    </dd>
                </dl>
            </div>
        </div>
        <hr>
        <div class="card-text mb-4 row">
            <div class="col">
                <h2>{{ $t("pages.registry.detailPage.bankDetails") }}</h2>
                <dl class="row">
                    <dt class="col-sm-3">
                        {{ $t("forms.fields.beneficiaryName") }}
                    </dt>
                    <dd class="col-sm-9">
                        {{ registry.bankAccountBeneficiaryName }}
                    </dd>
                    <dt class="col-sm-3">
                        {{ $t("forms.fields.iban") }}
                    </dt>
                    <dd class="col-sm-9">
                        {{ registry.bankAccountIban }}
                    </dd>
                    <dt
                        v-show="registry.bankId !== ''"
                        class="col-sm-3"
                    >
                        {{ $t("forms.fields.bank") }}
                    </dt>
                    <dd
                        v-show="registry.bankId !== ''"
                        class="col-sm-9"
                    >
                        {{ registry.bankName }}
                    </dd>
                </dl>
            </div>
        </div>
        <hr>
        <div class="card-text mb-4 row">
            <div class="col">
                <h2>{{ $t("pages.registry.detailPage.contactsAssociated") }}</h2>
                <dl class="row">
                    <dt class="col-sm-3">
                        {{ $t("forms.fields.administrativeContact") }}
                    </dt>
                    <dd class="col-sm-9">
                        {{ registry.administrativeContactName }}
                    </dd>
                    <dt class="col-sm-3">
                        {{ $t("forms.fields.technicalContact") }}
                    </dt>
                    <dd class="col-sm-9">
                        {{ registry.technicalContactName }}
                    </dd>
                </dl>
            </div>
        </div>
        <div class="d-flex justify-content-start">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'RecapStep',
    props: {
        registry: {
            type: Object
        }
    }
}
</script>
