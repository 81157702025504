import { baseEntityClient } from "@/clients/baseEntityClient";
import { SupportedEntity } from "@/clients/constants/clientConstants";
import { AxiosResponse } from "axios";

/**
 * pendingRegistryClient.
 */
export const pendingRegistryClient = {
    /**
     * Call get pending registry endpoint with filters.
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getByFilters() {
        return baseEntityClient.getEntityByFilters(SupportedEntity.PENDING_REGISTRY, null);
    }
}
