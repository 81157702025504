<template>
    <ul class="pl-3">
        <template v-for="attachment in attachments">
            <li :key="attachment.id ? attachment.id : attachment.url">
                <i class="fas fa-file-alt pr-2" />
                <a
                    :href="attachment.url"
                    :download="attachment.name"
                    target="_blank"
                >{{ attachment.name }}</a>
            </li>
        </template>
    </ul>
</template>

<script>
export default {
    name: 'Attachments',
    props: {
        attachments: {
            type: Array
        }
    }
}
</script>
