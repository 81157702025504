import NotFound from "@/views/ErrorPages/NotFoundPage";
import Authentication from "@/views/Pages/Authentication.vue";

const authPage = {
    path: "/login",
    component: Authentication,
    name: "LOGIN",
    children: [
        {
            path: "*",
            component: NotFound
        }
    ]
};

export default authPage;
