<template>
    <div class="new-modal">
        <modal
            modal-classes="modal-secondary"
            :size="'lg'"
            :data-cy="'newFiscalDocumentModal'"
            :show="showNew"
            @close="closeNew"
        >
            <template slot="header">
                <h1 v-show="!showNewForm">
                    {{ $t("pages.fiscalDocument.creationQuestion") }}
                </h1>
                <h1 v-show="showNewForm">
                    {{ documentTypeLabel }}
                </h1>
            </template>

            <div v-show="!showNewForm">
                <div class="row">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12">
                                <h2>{{ $t("pages.fiscalDocument.active") }}</h2>
                            </div>
                            <div class="col-12">
                                <base-button
                                    native-type="submit"
                                    type="primary"
                                    :data-cy="'activeInvoiceChoiceButton'"
                                    @click="onActiveInvoiceChoice"
                                >
                                    {{ $t("pages.fiscalDocument.emitInvoice") }}
                                </base-button>
                                <base-button
                                    native-type="submit"
                                    type="primary"
                                    :data-cy="'activeCreditNoteChoiceButton'"
                                    @click="onActiveCreditNoteChoice"
                                >
                                    {{ $t("pages.fiscalDocument.registerCreditNote") }}
                                </base-button>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12">
                                <h2>{{ $t("pages.fiscalDocument.passive") }}</h2>
                            </div>
                            <div class="col-12">
                                <base-button
                                    native-type="submit"
                                    type="primary"
                                    :data-cy="'passiveInvoiceChoiceButton'"
                                    @click="onPassiveInvoiceChoice"
                                >
                                    {{ $t("pages.fiscalDocument.registerInvoice") }}
                                </base-button>
                                <base-button
                                    native-type="submit"
                                    type="primary"
                                    :data-cy="'passiveCreditNoteChoiceButton'"
                                    @click="onPassiveCreditNoteChoice"
                                >
                                    {{ $t("pages.fiscalDocument.emitCreditNote") }}
                                </base-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <NewFiscalDocumentOutcoming
                    v-if="showOutComing"
                    :type="type"
                    @data-changed="$emit('data-changed')"
                    @close-new="closeNew"
                />
            </div>

            <div>
                <NewFiscalDocumentIncoming
                    v-if="showIncoming"
                    :type="type"
                    @data-changed="$emit('data-changed')"
                    @close-new="closeNew"
                />
            </div>
        </modal>
    </div>
</template>

<script>
import choicesMixins from "@/mixins/choicesMixins";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import formatterMixins from "@/mixins/formatterMixins";
import notificationsMixins from "@/mixins/notificationsMixins";
import NewFiscalDocumentOutcoming from "@/views/Pages/Components/FiscalDocument/Modal/NewFiscalDocumentOutcoming";
import NewFiscalDocumentIncoming from "@/views/Pages/Components/FiscalDocument/Modal/NewFiscalDocumentIncoming";

export default {
    name: 'NewFiscalDocument',
    components: {
        NewFiscalDocumentOutcoming,
        NewFiscalDocumentIncoming
    },
    mixins: [
        choicesMixins,
        exceptionsMixins,
        formatterMixins,
        notificationsMixins
    ],
    props: {
        showNew: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            showNewForm: false,
            type: null,
        }
    },
    computed: {
        showOutComing() {
            return this.showNewForm && (this.isActiveInvoice || this.isPassiveCreditNote);
        },
        showIncoming() {
            return this.showNewForm && (this.isPassiveInvoice || this.isActiveCreditNote);
        },
        isActiveInvoice() {
            return this.type === 'activeInvoice';
        },
        isPassiveInvoice() {
            return this.type === 'passiveInvoice';
        },
        isActiveCreditNote() {
            return this.type === 'activeCreditNote';
        },
        isPassiveCreditNote() {
            return this.type === 'passiveCreditNote';
        },
        documentTypeLabel() {
            let label = '';

            switch (this.type) {
            case 'activeInvoice':
                label = this.$t('pages.fiscalDocument.creating.emitInvoice');
                break;
            case 'passiveInvoice':
                label = this.$t('pages.fiscalDocument.creating.registerInvoice');
                break;
            case 'activeCreditNote':
                label = this.$t('pages.fiscalDocument.creating.registerCreditNote');
                break;
            case 'passiveCreditNote':
                label = this.$t('pages.fiscalDocument.creating.emitCreditNote');
                break;
            }

            return label;
        },
    },
    methods: {
        onActiveInvoiceChoice() {
            this.type = 'activeInvoice';
            this.showNewForm = true;
        },
        onPassiveInvoiceChoice() {
            this.type = 'passiveInvoice';
            this.showNewForm = true;
        },
        onActiveCreditNoteChoice() {
            this.type = 'activeCreditNote';
            this.showNewForm = true;
        },
        onPassiveCreditNoteChoice() {
            this.type = 'passiveCreditNote';
            this.showNewForm = true;
        },
        closeNew() {
            this.$emit('close-new');
        },
    }
}
</script>
