<template>
    <div
        class="vue-dropzone-container"
    >
        <vue-dropzone
            id="drop1"
            :options="dropOptions"
            :data-cy="'dropzone'"
            @vdropzone-file-added="afterAdded"
            @vdropzone-removed-file="afterRemoved"
        />
    </div>
</template>
<script>
import vueDropzone from "vue2-dropzone";

export default {
    name: 'DropzoneFileUpload',
    components: {
        vueDropzone
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        acceptedFiles: String,
        multiple: {
            type: Boolean,
            default: true
        },
        maxFilesize: {
            type: Number,
            default: 3
        }
    },
    data() {
        return {
            currentFile: null,
            files: [],
            encodedFiles: [],
            showList: false,
            dropOptions: {
                url: '/',
                maxFilesize: this.maxFilesize, // MB
                maxFiles: null,
                thumbnailWidth: 150, // px
                thumbnailHeight: 150,
                addRemoveLinks: true,
                autoProcessQueue: false,
                acceptedFiles: this.acceptedFiles,
                dictDefaultMessage: this.$tc('dropzone.dictDefaultMessage'),
                dictFallbackMessage: this.$tc('dropzone.dictFallbackMessage'),
                dictFileTooBig: this.$t('dropzone.dictFileTooBig', { fileMaxSize: `${this.maxFilesize}MB` }),
                dictInvalidFileType: this.$tc('dropzone.dictInvalidFileType'),
                dictResponseError: this.$tc('dropzone.dictResponseError'),
                dictCancelUpload: this.$tc('dropzone.dictCancelUpload'),
                dictUploadCanceled: this.$tc('dropzone.dictUploadCanceled'),
                dictRemoveFile: this.$tc('dropzone.dictRemoveFile'),
                dictMaxFilesExceeded: this.$tc('dropzone.dictMaxFilesExceeded'),
            },
            reader: new FileReader(),
        }
    },
    mounted() {
        this.initDropzoneEncoding();
    },
    methods: {
        initDropzoneEncoding() {
            this.reader.onload = (event) => {
                const base64 = event.target.result;

                this.encodedFiles.push(base64.replace(`data:${this.acceptedFiles};base64,`,''));
                this.$emit('encodedFilesChanged', this.encodedFiles);
            };
        },
        afterAdded(file) {
            if (file.size < this.dropOptions.maxFilesize * 1024 * 1024) {
                this.files.push(file);
                this.reader.readAsDataURL(file);
            }
        },
        afterRemoved(file) {
            this.encodedFiles = [];
            let index = this.files.findIndex(f => f.upload.uuid === file.upload.uuid)
            if (index !== -1) {
                this.files.splice(index, 1);
            }

            if (this.files.length === 0) {
                this.$emit('encodedFilesChanged', this.encodedFiles);
            }

            this.files.forEach(file => {
                this.reader.readAsDataURL(file);
            });
        }
    }
}
</script>
<style>
.vue-dropzone > .dz-preview .dz-remove {
    top: 10px;
    right: 10px;
    bottom: unset;
}
.dropzone .dz-preview.dz-error .dz-error-message,
.vue-dropzone > .dz-preview .dz-remove{
    opacity: 1;
}
.vue-dropzone .dz-progress,
.vue-dropzone .dz-success-mark,
.vue-dropzone .dz-error-mark {
    display: none!important;
}
.vue-dropzone .dz-preview {
    width: 100%;
    margin: 0;
}
</style>
