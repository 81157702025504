import { baseEntityClient } from "@/clients/baseEntityClient";
import { SupportedEntity } from "@/clients/constants/clientConstants";
import { AxiosResponse } from "axios";

/**
 * contactClient.
 */
export const contactClient = {
    /**
     * Call get contact endpoint with provided id.
     *
     * @param contactId {number}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getById(contactId) {
        return baseEntityClient.getEntityById(SupportedEntity.CONTACT, contactId);
    },
    /**
     * Call get contact endpoint with filters.
     *
     * @param email {string|null}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getByFilters(email = null) {
        const filters = {
            email: email
        };

        return baseEntityClient.getEntityByFilters(SupportedEntity.CONTACT, filters);
    },
    /**
     * Call the delete contact endpoint.
     *
     * @param id {number}
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteContact(id) {
        return baseEntityClient.deleteEntityById(SupportedEntity.CONTACT, id);
    },
    /**
     * Call update contact endpoint.
     *
     * @param id {number}
     * @param contactDto {ContactDto}
     */
    updateContact(id, contactDto) {
        const data = {
            name: contactDto.name,
            email: contactDto.email,
            telephone: contactDto.telephone,
        };

        return baseEntityClient.updateEntity(SupportedEntity.CONTACT, id, data);
    },
    /**
     * Call create contact endpoint.
     *
     * @param contactDto {ContactDto}
     */
    createContact(contactDto) {
        const data = {
            name: contactDto.name,
            email: contactDto.email,
            telephone: contactDto.telephone,
        };

        return baseEntityClient.createEntity(SupportedEntity.CONTACT, data);
    }
}
