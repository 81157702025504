<template>
    <div
        class="col p-0"
    >
        <card
            class="m-0"
            body-classes="p-0"
            footer-classes="pb-2"
        >
            <BaseTable
                :table-data="tableData"
                :default-sort="{prop: 'expenseDate', order: 'descending'}"
                :search-keys="[
                    'sender',
                    'recipient.name',
                    'tag',
                    'type',
                ]"
                :search-threshold="0.1"
            >
                <el-table-column
                    v-for="column in tableColumns"
                    :key="column.label"
                    v-bind="column"
                    :formatter="column.formatter"
                />
                <el-table-column
                    align="right"
                    :label="$t('tables.column.actions')"
                    :min-width="80"
                >
                    <div
                        slot-scope="{$index, row}"
                        class="d-flex flex-wrap"
                    >
                        <base-button
                            :data-cy="'buttonUpdateCashFlowId'+row.id"
                            class="edit"
                            type="primary"
                            size="sm"
                            icon
                            @click="openEditModal(row.id)"
                        >
                            <i class="fas fa-edit text-white" />
                        </base-button>
                        <base-button
                            :data-cy="'buttonDeleteCashFlowId'+row.id"
                            class="remove btn-link"
                            type="danger"
                            size="sm"
                            icon
                            @click="openDeleteModal(row.id, row.income, row.number)"
                        >
                            <i class="fas fa-trash-alt text-white" />
                        </base-button>
                        <base-button
                            :data-cy="'buttonDetailsCashFlowId'+row.id"
                            class="remove btn-link border"
                            type="secondary"
                            size="sm"
                            icon
                            @click="$router.push(
                                {
                                    name:'CASHFLOW-DETAIL',
                                    params: {
                                        id: row.id
                                    }
                                }
                            );"
                        >
                            <i class="fas fa-info-circle" />
                        </base-button>
                    </div>
                </el-table-column>
            </BaseTable>
        </card>

        <DeleteCashFlow
            v-if="deleteModal.showDeleteModal"
            :delete-cash-flow-id="deleteModal.idCashFlowToDelete"
            :is-income="deleteModal.isIncome"
            :number="deleteModal.number"
            @close-delete="deleteModal.showDeleteModal = false;"
            @data-changed="$emit('data-changed');"
        />

        <EditCashFlow
            v-if="editModal.showEditModal"
            :edit-cash-flow-id="editModal.editCashFlowId"
            @close-edit="editModal.showEditModal = false;"
            @data-changed="$emit('data-changed');"
        />
    </div>
</template>

<script>
import { Option, Select, Table, TableColumn } from "element-ui";
import formatterMixins from "@/mixins/formatterMixins";
import BaseButton from "@/components/BaseButton";
import DeleteCashFlow from "@/views/Pages/Components/CashFlow/Modal/Delete";
import EditCashFlow from "@/views/Pages/Components/CashFlow/Modal/Edit";
import choicesMixins from "@/mixins/choicesMixins";
import BaseTable from "@/views/Pages/Components/Table/BaseTable";

export default {
    name: "CashFlowTable",
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        DeleteCashFlow,
        EditCashFlow,
        BaseButton,
        BaseTable
    },
    mixins: [
        choicesMixins
    ],
    props: {
        tableData: {
            type: Array
        },
        senderLabel: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            tableColumns: [
                {
                    prop: "sender",
                    label: this.senderLabel,
                    minWidth: 150,
                },
                {
                    prop: "number",
                    label: this.$t("tables.column.number"),
                    minWidth: 40,
                    sortable: true,
                },
                {
                    prop: "amount",
                    label: this.$t("tables.column.amount"),
                    minWidth: 40,
                    sortable: true,
                    formatter: (row) => {
                        return formatterMixins.methods.formatCurrency(row.currency, row.amount);
                    }
                },
                {
                    prop: "tag",
                    label: this.$t("tables.column.tag"),
                    minWidth: 50,
                    formatter: (row) => {
                        return this.getCashFlowTagLabelByChoiceCode(row.tag);
                    }
                },
                {
                    prop: "type",
                    label: this.$t("tables.column.type"),
                    minWidth: 50,
                    formatter: (row) => {
                        return this.getCashFlowTypeLabelByChoiceCode(row.type);
                    }
                },
                {
                    prop: "expenseDate",
                    label: this.$t("tables.column.expenseDate"),
                    minWidth: 50,
                    sortable: true,
                    formatter: (row) => {
                        const date = new Date(row.expenseDate);
                        return formatterMixins.methods.formatBackendDate(date);
                    },
                },
            ],
            deleteModal: {
                showDeleteModal: false,
                idCashFlowToDelete: null,
                loading: false,
                isIncome: null,
                number: null,
            },
            editModal: {
                showEditModal: false,
                editCashFlowId: null
            },
        };
    },
    methods: {
        openEditModal(id) {
            this.editModal.showEditModal = true;
            this.editModal.editCashFlowId = Number(id);
        },
        openDeleteModal(id, income, number) {
            this.deleteModal.idCashFlowToDelete = Number(id);
            this.deleteModal.isIncome = income;
            this.deleteModal.number = number;
            this.deleteModal.showDeleteModal = true;
        },

    }
};
</script>
