import { AuthenticationLocalStorageKeys } from '@/constants/authenticationConstants';

/**
 * AuthenticationService.
 */
export const authenticationService = {
    /**
   * Retrieve token.
   *
   * @returns {string|null}
   */
    getToken() {
        return localStorage.getItem(AuthenticationLocalStorageKeys.KEY_TOKEN);
    },
    /**
   * Retrieve twoFactorToken.
   *
   * @returns {string|null}
   */
    getTwoFactorToken() {
        return localStorage.getItem(AuthenticationLocalStorageKeys.KEY_TWO_FACTOR_TOKEN);
    },
    /**
   * Save the token.
   *
   * @param token {string}
   */
    setToken(token) {
        localStorage.setItem(AuthenticationLocalStorageKeys.KEY_TOKEN, token);
    },
    /**
   * Save the twoFactorToken.
   *
   * @param twoFactorToken {string}
   */
    setTwoFactorToken(twoFactorToken) {
        localStorage.setItem(AuthenticationLocalStorageKeys.KEY_TWO_FACTOR_TOKEN, twoFactorToken);
    },
    /**
   * Remove token from localStorage to simulate the logout action.
   */
    removeAuthenticationToken() {
        localStorage.removeItem(AuthenticationLocalStorageKeys.KEY_TOKEN);
    },
    /**
   * Remove token and twoFactorToken from localStorage.
   */
    resetAuthenticationTokens() {
        localStorage.removeItem(AuthenticationLocalStorageKeys.KEY_TOKEN);
        localStorage.removeItem(AuthenticationLocalStorageKeys.KEY_TWO_FACTOR_TOKEN);
    }
};
