<template>
    <base-header
        class="pb-6 pl-3 pr-3"
        :type="'brand-color'"
    >
        <div class="row align-items-center py-3">
            <div class="col-lg-6 col-7">
                <h6 class="h2 text-white d-inline-block mb-0">
                    {{ title }}
                </h6>
            </div>
            <div class="col-lg-6 col-5 text-right">
                <slot />
            </div>
        </div>
        <nav
            aria-label="breadcrumb"
            class="d-none d-md-inline-block m-auto py-3"
        >
            <route-bread-crumb />
        </nav>
    </base-header>
</template>

<script>
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';

export default {
    name: "Header",
    components: {
        RouteBreadCrumb
    },
    props: {
        title: {
            type: String,
            required: true,
        }
    }
}
</script>
