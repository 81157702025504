var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col",attrs:{"data-cy":'contactTable'}},[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"p-0","footer-classes":"pb-2"}},[_c('BaseTable',{attrs:{"table-data":_vm.tableData,"search-keys":[
                'name',
                'email',
                'phoneNumber'
            ],"search-threshold":0.1}},[_vm._l((_vm.tableColumns),function(column){return _c('el-table-column',_vm._b({key:column.label,attrs:{"formatter":column.formatter}},'el-table-column',column,false))}),_c('el-table-column',{attrs:{"label":_vm.$t('pages.contact.associatedRegistries'),"min-width":160,"class-name":'registriesColumn'},scopedSlots:_vm._u([{key:"default",fn:function({row}){return _vm._l((row.registries),function(registry,index){return _c('badge',{key:'n' + index + 'registry-id-' + registry.id,staticClass:"badge-dot mr-4",attrs:{"type":""}},[_c('i',{class:`bg-brand-color label-contact-area`},[_vm._v(_vm._s(registry.area.charAt(0).toUpperCase()))]),_c('a',{staticClass:"registry text-dark",attrs:{"href":'/registry/'+ registry.id}},[_vm._v(_vm._s(registry.name))])])})}}])}),_c('el-table-column',{attrs:{"align":"right","label":_vm.$t('tables.column.actions'),"min-width":100},scopedSlots:_vm._u([{key:"default",fn:function({$index, row}){return _c('div',{staticClass:"d-flex flex-wrap"},[_c('base-button',{staticClass:"edit",attrs:{"data-cy":'buttonUpdateContactId'+row.id,"type":"primary","size":"sm","icon":""},on:{"click":function($event){return _vm.openEditModal(row.id)}}},[_c('i',{staticClass:"fas fa-edit text-white"})]),_c('base-button',{staticClass:"remove btn-link",attrs:{"data-cy":'buttonDeleteContactId'+row.id,"type":"danger","size":"sm","icon":""},on:{"click":function($event){return _vm.openDeleteModal(row.id)}}},[_c('i',{staticClass:"fas fa-trash-alt text-white"})]),_c('base-button',{staticClass:"remove btn-link border",attrs:{"data-cy":'buttonDetailsContactId'+row.id,"type":"secondary","size":"sm","icon":""},on:{"click":function($event){return _vm.$router.push(
                            {
                                name:'CONTACT-DETAIL',
                                params: {
                                    id: row.id
                                }
                            }
                        );}}},[_c('i',{staticClass:"fas fa-info-circle"})])],1)}}])})],2)],1),_c('DeleteContact',{attrs:{"delete-contact-id":_vm.deleteModal.idContactToDelete,"show-delete":_vm.deleteModal.showDeleteModal,"contact-name":_vm.deleteModal.contactName},on:{"close-delete":function($event){_vm.deleteModal.showDeleteModal = false;},"data-changed":function($event){return _vm.$emit('data-changed');}}}),_c('EditContact',{attrs:{"edit-contact-id":_vm.editModal.editContactId,"show-edit":_vm.editModal.showEditModal},on:{"close-edit":function($event){_vm.editModal.showEditModal = false;},"data-changed":function($event){return _vm.$emit('data-changed');}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }