var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col",attrs:{"data-cy":'bankTable'}},[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"p-0","footer-classes":"pb-2"}},[_c('BaseTable',{attrs:{"table-data":_vm.tableData,"search-keys":[
                'name',
                {
                    name: 'swiftBic',
                    weight: 2
                }
            ],"search-threshold":0.1}},[_vm._l((_vm.tableColumns),function(column){return _c('el-table-column',_vm._b({key:column.label,attrs:{"formatter":column.formatter}},'el-table-column',column,false))}),_c('el-table-column',{attrs:{"align":"right","label":"Azioni"},scopedSlots:_vm._u([{key:"default",fn:function({$index, row}){return _c('div',{staticClass:"d-flex flex-wrap"},[_c('base-button',{staticClass:"edit",attrs:{"data-cy":'buttonUpdateBankId'+row.id,"type":"primary","size":"sm","icon":""},on:{"click":function($event){return _vm.openEditModal(row.id)}}},[_c('i',{staticClass:"fas fa-edit text-white"})]),_c('base-button',{staticClass:"remove btn-link",attrs:{"data-cy":'buttonDeleteBankId'+row.id,"type":"danger","size":"sm","icon":""},on:{"click":function($event){return _vm.openDeleteModal(row.id)}}},[_c('i',{staticClass:"fas fa-trash-alt text-white"})]),_c('base-button',{staticClass:"remove btn-link border",attrs:{"data-cy":'buttonDetailsBankId'+row.id,"type":"secondary","size":"sm","icon":""},on:{"click":function($event){return _vm.$router.push(
                            {
                                name:'BANK-DETAIL',
                                params: {
                                    id: row.id
                                }
                            }
                        );}}},[_c('i',{staticClass:"fas fa-info-circle"})])],1)}}])})],2)],1),_c('DeleteBank',{attrs:{"delete-bank-id":_vm.deleteModal.idBankToDelete,"show-delete":_vm.deleteModal.showDeleteModal,"bank-name":_vm.deleteModal.bankName},on:{"close-delete":function($event){_vm.deleteModal.showDeleteModal = false;},"data-changed":function($event){return _vm.$emit('data-changed');}}}),_c('EditBank',{attrs:{"edit-bank-id":_vm.editModal.editBankId,"show-edit":_vm.editModal.showEditModal},on:{"close-edit":function($event){_vm.editModal.showEditModal = false;},"data-changed":function($event){return _vm.$emit('data-changed');}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }