<template>
    <button
        type="button"
        class="navbar-toggler collapsed"
        data-toggle="collapse"
        data-target="#navbar"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
    >
        <span class="navbar-toggler-bar bar1" />
        <span class="navbar-toggler-bar bar2" />
        <span class="navbar-toggler-bar bar3" />
    </button>
</template>
<script>
export default {
    name: 'NavbarToggleButton'
};
</script>
<style></style>
