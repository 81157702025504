<template>
    <div class="row">
        <div class="col-xl-6 col-lg-12">
            <card
                header-classes="bg-transparent"
            >
                <div
                    slot="header"
                    class="row align-items-center"
                >
                    <div class="col">
                        <h6 class="text-light text-uppercase ls-1 mb-1">
                            {{ $tc('pages.homepage.grossRevenuesAndCost') }}
                        </h6>
                    </div>
                </div>
                <line-chart
                    ref="grossRevenueAndCost"
                    :height="350"
                    :chart-data="grossRevenuesAndCost.data"
                    :extra-options="chartsExtraOptions"
                />
            </card>
        </div>
        <div class="col-xl-6 col-lg-12">
            <card
                header-classes="bg-transparent"
            >
                <div
                    slot="header"
                    class="row align-items-center"
                >
                    <div class="col">
                        <h6 class="text-light text-uppercase ls-1 mb-1">
                            {{ $tc('pages.homepage.netRevenues') }}
                        </h6>
                    </div>
                </div>
                <line-chart
                    ref="netRevenues"
                    :height="350"
                    :chart-data="profits.data"
                    :extra-options="chartsExtraOptions"
                />
            </card>
        </div>
    </div>
</template>

<script>
import LineChart from '@/components/Charts/LineChart';
import formatterMixins from "@/mixins/formatterMixins";

export default {
    name: 'StatisticsCharts',
    components: {
        LineChart
    },
    mixins: [
        formatterMixins
    ],
    props: {
        labels: {
            type: Array
        },
        grossRevenuesData: {
            type: Array
        },
        costsData: {
            type: Array
        },
        profitsData: {
            type: Array
        },
        currency: {
            type: String
        }
    },
    data() {
        return {
            grossRevenuesAndCost: {
                data: {
                    datasets: [
                        {
                            label: this.$tc('pages.homepage.legends.grossRevenues'),
                            data: null,
                            borderColor: "#2dce89",
                            pointBackgroundColor: "#2dce89",
                            tension: 0.1
                        },
                        {
                            label: this.$tc('pages.homepage.legends.cost'),
                            data: null,
                            borderColor: "#f5365c",
                            pointBackgroundColor: "#f5365c",
                            tension: 0.1
                        }
                    ],
                    labels: null,
                },
            },
            profits: {
                data: {
                    datasets: [
                        {
                            label: this.$tc('pages.homepage.legends.netRevenues'),
                            data: null,
                            borderColor: "#5e72e4",
                            pointBackgroundColor: "#5e72e4",
                            tension: 0.1
                        },
                    ],
                    labels: null,
                },
            },
            chartsExtraOptions: {
                tooltips: {
                    position: "nearest",
                    callbacks: {
                        label: (tooltipItems, data) => {
                            const dataset = data.datasets[tooltipItems.datasetIndex];
                            const index = tooltipItems.index;

                            return this.formatCurrency(this.currency, dataset.data[index]);
                        }
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            callback: (value) => {
                                return this.formatCurrency(
                                    this.currency,
                                    value
                                );
                            }
                        },
                        gridLines: {
                            display: true
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                            display: false
                        }
                    }]
                },
                legend: {
                    display: true
                },
            },
        }
    },
    watch: {
        labels: function (val) {
            this.grossRevenuesAndCost.data.labels = val;
            this.profits.data.labels = val;
        },
        grossRevenuesData: function (val) {
            this.grossRevenuesAndCost.data.datasets[0].data = val;
        },
        costsData: function (val) {
            this.grossRevenuesAndCost.data.datasets[1].data = val;
        },
        profitsData: function (val) {
            this.profits.data.datasets[0].data = val;
        },
    }
}
</script>
