import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

/**
 * notificationsMixins.
 */
export default {
    methods: {
        showSwal(type, title, text, html, showCancelButton = false) {
            if (type === 'basic') {
                return swal.fire({
                    title: title,
                    text: text,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            } else if (type === 'info') {
                return swal.fire({
                    icon: 'info',
                    title: title,
                    text: text,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-info'
                    }
                });
            } else if (type === 'success') {
                return swal.fire({
                    title: title,
                    text: text,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-success'
                    },
                    icon: 'success'
                });
            } else if (type === 'warning') {
                return swal.fire({
                    title: title,
                    text: text,
                    html: html,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-warning',
                        cancelButton: 'btn btn-link',
                    },
                    showCancelButton,
                    icon: 'warning'
                })
            } else if (type === 'question') {
                return swal.fire({
                    title: title,
                    text: text,
                    html: html,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-default'
                    },
                    icon: 'question'
                })
            }
        }
    },
};
