export const SupportedEntity = Object.freeze({
    REGISTRY: 'registry',
    REGISTRY_METADATA: 'registry/metadata',
    PENDING_REGISTRY: 'pending-registry',
    BANK: 'bank',
    CONTACT: 'contact',
    CASH_FLOW: 'cashflow',
    INVOICE: 'invoice',
    INVOICE_OUTCOMING: 'invoice/outcoming',
    INVOICE_INCOMING: 'invoice/incoming',
});
