<template>
    <div class="new-modal">
        <modal
            :show="showNew"
            modal-classes="modal-secondary"
            :size="'lg'"
            :data-cy="'newContactModal'"
            @close="closeNew"
        >
            <template slot="header">
                <h1>{{ $t('actions.creating') + ' ' + $t("pages.contact.contact") }} </h1>
            </template>
            <validation-observer
                v-slot="{handleSubmit}"
                ref="formValidator"
            >
                <form
                    class="new-event-form"
                    @submit.prevent="handleSubmit(newContact)"
                >
                    <div class="form-group container">
                        <div class="row">
                            <base-input
                                v-model="model.name"
                                :label="$t('forms.fields.name')"
                                class="col-6"
                                :placeholder="$t('forms.fields.name')"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.name')"
                                :rules="{required:true}"
                                :data-cy="'newContactNameField'"
                            />
                            <base-input
                                v-model="model.email"
                                :label="$t('forms.fields.email')"
                                class="col-6"
                                :placeholder="$t('forms.fields.email')"
                                input-classes="form-control-alternative"
                                :name="$t('forms.fields.email')"
                                :rules="{required:true, email: true}"
                                :data-cy="'newContactEmailField'"
                            />
                            <base-input
                                :label="$t('forms.fields.telephoneNumberPlaceholder')"
                                input-classes="form-control-alternative"
                                class="col-12"
                                :name="$t('forms.fields.telephoneNumberPlaceholder')"
                                :rules="{
                                    required: false
                                }"
                            >
                                <vue-tel-input
                                    v-model="model.telephone"
                                    :input-options="{placeholder: $t('forms.fields.telephoneNumberPlaceholder')}"
                                    mode="international"
                                    class="mb-3"
                                    :data-cy="'newContactTelephoneField'"
                                />
                            </base-input>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <base-button
                            native-type="submit"
                            type="primary"
                            class="new-event--add"
                            :data-cy="'createNewContactButton'"
                            :disabled="creating"
                            :loading="creating"
                        >
                            {{ $t("actions.new") }}
                        </base-button>
                        <base-button
                            type="link"
                            @click="closeNew"
                        >
                            {{ $t("actions.cancel") }}
                        </base-button>
                    </div>
                </form>
            </validation-observer>
        </modal>
    </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { contactClient } from "@/clients/contactClient";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import BaseButton from "@/components/BaseButton";
import Modal from "@/components/Modal";
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import ContactDto from "@/clients/dto/ContactDto";

export default {
    name: "NewContact",
    components: {
        BaseButton,
        Modal,
        ValidationObserver,
        VueTelInput
    },
    mixins: [
        exceptionsMixins
    ],
    props: {
        showNew: {
            type: Boolean,
        }
    },
    data() {
        return {
            model: {
                name: null,
                email: null,
                telephone: null,
            },
            loaded: false,
            creating: false,
        }
    },
    methods: {
        closeNew() {
            this.$emit('close-new');
        },
        newContact() {
            this.creating = true;
            const contactDto = new ContactDto(
                this.model.name,
                this.model.email,
                this.model.telephone?.replace(/ /g, '')
            );

            contactClient.createContact(contactDto).then(() => {
                this.$notify({
                    type: "success",
                    icon: "ni ni-notification-70",
                    message: this.$t("pages.contact.notifications.success.created")
                });
                this.$emit('data-changed');
                this.closeNew();
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.creating = false;
            });
        }
    }
}
</script>
