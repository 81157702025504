<template>
    <div class="content">
        <Header
            :title="$t('pages.cashFlow.title')"
        >
            <base-button
                type="neutral"
                :data-cy="'buttonCreateCashFlow'"
                @click="openNewModal"
            >
                {{ $t("actions.new") }}
            </base-button>
        </Header>
        <div class="container-fluid mt--6">
            <tabs
                tab-nav-classes="nav-fill flex-column flex-sm-row nav-wrapper"
                tab-content-classes="card shadow"
                @activatedTabId="onActivatedTabId"
            >
                <template #between>
                    <card
                        class="mt-2 mb-4 p-2"
                        body-classes="p-0 pb-2 pt-2"
                        footer-classes="pb-2"
                    >
                        <div class="container-fluid">
                            <div class="row align-items-center m-0">
                                <div class="col-12">
                                    <h2>{{ $t('tables.column.filters') }}</h2>
                                </div>
                                <base-input
                                    :label="senderFilterLabel"
                                    :name="senderFilterLabel"
                                    input-classes="form-control-alternative"
                                    :data-cy="'senderRegistryCashFlowFilter'"
                                    class="col-lg-12 col-xl-3"
                                >
                                    <v-select
                                        v-model="filters.senderRegistryName"
                                        class="form-control form-control-alternative"
                                        :options="debtorCreditorSelectChoices"
                                    />
                                </base-input>
                                <base-input
                                    :label="$t('forms.fields.tag')"
                                    :name="$t('forms.fields.tag')"
                                    :placeholder="$t('forms.actions.select') + $t('forms.fields.tag')"
                                    input-classes="form-control-alternative"
                                    :data-cy="'tagCashFlowFilter'"
                                    class="col-lg-12 col-xl-6"
                                >
                                    <v-select
                                        v-model="filters.tag"
                                        class="form-control form-control-alternative"
                                        :options="cashFlowTagListComplete"
                                        :reduce="tag => tag.code"
                                    />
                                </base-input>
                            </div>
                            <div class="row align-items-center m-0">
                                <base-input
                                    addon-left-icon="ni ni-calendar-grid-58"
                                    :label="$t('forms.fields.dateRange')"
                                    :name="$t('forms.fields.dateRange')"
                                    :placeholder="$t('forms.actions.select') + $t('forms.fields.dateRange')"
                                    :data-cy="'rangeDateCashFlowFilter'"
                                    class="col-lg-12 col-xl-3"
                                >
                                    <flat-picker
                                        v-model="filters.dates.range"
                                        slot-scope="{focus, blur}"
                                        :config="configsDatePicker"
                                        class="form-control datepicker"
                                        @on-open="focus"
                                        @on-close="blur"
                                        @on-change="formatDates"
                                    />
                                </base-input>
                                <base-input
                                    v-model="filters.number"
                                    :label="$t('forms.fields.number')"
                                    :name="$t('forms.fields.number')"
                                    input-classes="form-control-alternative"
                                    :data-cy="'numberCashFlowFilter'"
                                    class="col-lg-12 col-xl-2"
                                />
                                <base-input
                                    :label="$t('forms.fields.type')"
                                    :name="$t('forms.fields.type')"
                                    :placeholder="$t('forms.actions.select') + $t('forms.fields.type')"
                                    input-classes="form-control-alternative"
                                    :data-cy="'typeCashFlowFilter'"
                                    class="col-lg-12 col-xl-3"
                                >
                                    <v-select
                                        v-model="filters.type"
                                        class="form-control form-control-alternative"
                                        :options="cashFlowTypeListComplete"
                                        :reduce="type => type.code"
                                    />
                                </base-input>
                                <base-button
                                    native-type="submit"
                                    type="primary"
                                    class="search"
                                    :data-cy="'searchByFilters'"
                                    :disabled="loading"
                                    @click="() => {
                                        storeFilters();
                                        loadData();
                                    }"
                                >
                                    {{ $t('actions.search') }}
                                </base-button>
                                <base-button
                                    v-if="showResetFilter"
                                    native-type="submit"
                                    type="danger"
                                    :disabled="loading"
                                    @click="clearFilters"
                                >
                                    {{ $t('actions.reset') }}
                                </base-button>
                            </div>
                            <div class="row pt-3 pb-3 pt-xl-0 pb-xl-0">
                                <PresetDate
                                    class="col-12"
                                    @preset-selected="onPresetSelected"
                                />
                            </div>
                        </div>
                    </card>
                </template>
                <tab-pane id="incomingCashFlow">
                    <span
                        slot="title"
                        :data-cy="'incomingCashFlowTabButton'"
                    >
                        <i class="fas fa-arrow-alt-circle-down mr-1" />{{ $t("pages.cashFlow.cashFlow.incoming") }}
                    </span>

                    <CashFlowTable
                        :data-cy="'incomingCashFlow'"
                        :table-data="incomingCashFlow"
                        :sender-label="$t('tables.column.debtor')"
                        @data-changed="loadData"
                    />
                </tab-pane>
                <tab-pane id="outcomingCashFlow">
                    <span
                        slot="title"
                        :data-cy="'outcomingCashFlowTabButton'"
                    >
                        <i class="fas fa-arrow-alt-circle-down mr-1" />{{ $t("pages.cashFlow.cashFlow.outcoming") }}
                    </span>
                    <CashFlowTable
                        :data-cy="'outcomingCashFlow'"
                        :table-data="outcomeCashFlow"
                        :sender-label="$t('tables.column.creditor')"
                        @data-changed="loadData"
                    />
                </tab-pane>
            </tabs>
        </div>

        <NewCashFlow
            v-if="showNewCashFlow"
            :key="newCashFlowKey"
            @close-new="showNewCashFlow = false;"
            @data-changed="loadData"
        />
        <LoadingBar v-show="loading" />
    </div>
</template>

<script>
import { cashFlowClient } from "@/clients/cashFlowClient";
import { localeService } from "@/services/localeService";
import exceptionsMixins from "@/mixins/exceptionsMixins";
import choicesMixins from "@/mixins/choicesMixins";
import formatterMixins from "@/mixins/formatterMixins";
import NewCashFlow from "@/views/Pages/Components/CashFlow/Modal/New";
import LoadingBar from "@/views/Pages/Components/Loading/LoadingBar";
import Header from "@/components/Header";
import vSelect from 'vue-select';
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// All the supported languages must be added to let the flatPicker work correctly.
import "flatpickr/dist/l10n/it";
import CashFlowTable from "@/views/Pages/Components/CashFlow/Table";
import TabPane from "@/components/Tabs/Tab";
import Tabs from "@/components/Tabs/Tabs";
import PresetDate from "@/views/Pages/Components/PresetDate/PresetDate";
import sortingFunctionsMixin from "@/views/Mixins/sortingFunctionsMixin";
import yearSelectorMixins from "@/mixins/yearSelectorMixins";

export default {
    name: 'CashFlow',
    components: {
        CashFlowTable,
        LoadingBar,
        NewCashFlow,
        Header,
        vSelect,
        flatPicker,
        TabPane,
        Tabs,
        PresetDate,
    },
    mixins: [
        exceptionsMixins,
        choicesMixins,
        formatterMixins,
        sortingFunctionsMixin,
        yearSelectorMixins,
    ],
    data() {
        return {
            showNewCashFlow: false,
            newCashFlowKey: 0,
            incomingCashFlow: [],
            outcomeCashFlow: [],
            loading: false,
            activeTab: 'incomingCashFlow',
            filters: {
                senderRegistryName: this.$store.getters.cashFlowFilters.senderRegistryName,
                number: this.$store.getters.cashFlowFilters.number,
                type: this.$store.getters.cashFlowFilters.type,
                tag: this.$store.getters.cashFlowFilters.tag,
                dates: {
                    fromDateToSend: null,
                    toDateToSend: null,
                    range: this.$store.getters.cashFlowFilters.dateRange,
                },
            },
            configsDatePicker: {
                allowInput: true,
                mode: "range",
                locale: localeService.getLocale(),
                altInput: true,
                altFormat: "d-m-Y",
                showMonths: 2,
            },
            debtorSelectChoices: [],
            creditorSelectChoices: []
        }
    },
    computed: {
        senderFilterLabel() {

            if (this.activeTab === 'incomingCashFlow') {
                return this.$t('tables.column.debtor');
            }
            return this.$t('tables.column.creditor');
        },
        debtorCreditorSelectChoices() {
            if (this.activeTab === 'incomingCashFlow') {
                return this.debtorSelectChoices;
            }
            return this.creditorSelectChoices;
        },
        showResetFilter() {
            return this.filters.senderRegistryName?.length > 0 ||
                this.filters.number?.length > 0 ||
                this.filters.type?.length > 0 ||
                this.filters.tag?.length > 0 ||
                this.filters.dates.fromDateToSend?.length > 0 ||
                this.filters.dates.toDateToSend?.length > 0;
        }
    },
    watch: {
        activeTab: function(newActiveTab, oldActiveTab) {
            this.clearFilters();
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        clearFilters() {
            this.filters.senderRegistryName = null;
            this.filters.number = null;
            this.filters.type = null;
            this.filters.tag = null;
            this.filters.dates.fromDateToSend = null;
            this.filters.dates.toDateToSend = null;
            this.filters.dates.range = null;
            this.checkFiltersDatePresent();
            this.resetFilters();
        },
        onPresetSelected(dates) {
            this.filters.dates.fromDateToSend = dates.fromDateToSend;
            this.filters.dates.toDateToSend = dates.toDateToSend;
            this.filters.dates.range = dates.range;
        },
        onActivatedTabId(id) {
            this.activeTab = id;
        },
        openNewModal() {
            this.showNewCashFlow = true;
            this.newCashFlowKey++;
        },
        resetFilters() {
            this.$store.dispatch("setCashFlowFilters", {
                senderRegistryName: null,
                number: null,
                type: null,
                tag: null,
                dateRange: null,
            });
        },
        storeFilters() {
            this.$store.dispatch("setCashFlowFilters", {
                senderRegistryName: this.filters.senderRegistryName,
                number: this.filters.number,
                type: this.filters.type,
                tag: this.filters.tag,
                dateRange: this.filters.dates.range,
            });
        },
        loadData() {
            this.checkFiltersDatePresent();
            this.loading = true;
            const fromDate = this.filters.dates.fromDateToSend;
            const toDate = this.filters.dates.toDateToSend?.length === 0 ? fromDate : this.filters.dates.toDateToSend;

            cashFlowClient.getByFilters(
                this.$store.getters.activeRegistryId,
                this.filters.senderRegistryName,
                this.filters.number,
                this.filters.tag,
                this.filters.type,
                fromDate,
                toDate
            ).then((response) => {
                this.incomingCashFlow = response.data.filter(cashFlow => cashFlow.income).sort((a, b) => {
                    return this.sortDatesDesc(a.date, b.date);
                });

                this.outcomeCashFlow = response.data.filter(cashFlow => !cashFlow.income).sort((a, b) => {
                    return this.sortDatesDesc(a.date, b.date);
                });

                this.debtorSelectChoices = this.incomingCashFlow.reduce((result, element) => {
                    if (result.includes(element.sender)) {
                        return result;
                    }

                    result.push(element.sender);
                    return result;
                }, []);

                this.creditorSelectChoices = this.outcomeCashFlow.reduce((result, element) => {
                    if (result.includes(element.sender)) {
                        return result;
                    }

                    result.push(element.sender);
                    return result;
                }, []);

                this.debtorSelectChoices.sort((a, b) => a.localeCompare(b));
                this.creditorSelectChoices.sort((a, b) => a.localeCompare(b));
            }).catch((error) => {
                this.manageException(error.response);
            }).then(() => {
                this.loading = false;
            });
        },
        formatDates(selectedDates) {
            this.filters.dates.fromDateToSend = this.formatFrontendDate(selectedDates[0]);
            this.filters.dates.toDateToSend = this.formatFrontendDate(selectedDates[1]);
        },
        checkFiltersDatePresent() {
            if (!this.filters.dates.fromDateToSend && !this.filters.dates.toDateToSend) {
                this.onPresetSelected(this.getDefaultDatesRange());
            }
        },
    }
}
</script>
