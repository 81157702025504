import { baseEntityClient } from "@/clients/baseEntityClient";
import { SupportedEntity } from "@/clients/constants/clientConstants";
import { AxiosResponse } from "axios";
import httpClient from "@/clients/httpClient";

/**
 * fiscalDocumentClient.
 */
export const fiscalDocumentClient = {
    /**
     * Call get invoice endpoint with provided id.
     *
     * @param {number} id
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getById(id) {
        return baseEntityClient.getEntityById(SupportedEntity.INVOICE, id);
    },
    /**
     * Call get invoice endpoint with filters.
     *
     * @param {PaginationDto} pagination
     * @param {number|null} recipientRegistryId
     * @param {number|null} senderRegistryId
     * @param {string|null} status
     * @param {string|null} documentType
     * @param {string|null} fromDate (Y-m-d)
     * @param {string|null} toDate (Y-m-d)
     * @param {string|null} number
     *
     * @returns {Promise<AxiosResponse<*>>}
     */
    getByFilters(
        pagination,
        recipientRegistryId = null,
        senderRegistryId = null,
        status = null,
        documentType = null,
        fromDate = null,
        toDate = null,
        number = null,
    ) {

        const filters = {
            page: pagination.page,
            pageElements: pagination.pageElements,
            sortingField: pagination.sortingField,
            sortingDirection: pagination.sortingDirection,
            recipientRegistryId,
            senderRegistryId,
            status,
            documentType,
            fromDate,
            toDate,
            number
        };

        return baseEntityClient.getEntityByFilters(SupportedEntity.INVOICE, filters);
    },
    /**
     * Call create invoice outcoming endpoint.
     *
     * @param {FiscalDocumentOutcomingDto} fiscalDocumentDto
     *
     * @returns {Promise<AxiosResponse<*>>}
     */
    createOutcoming(fiscalDocumentDto) {
        let invoiceDetailLineData = [];

        fiscalDocumentDto.detailLines.forEach(detailLine => {
            invoiceDetailLineData.push({
                quantity: parseInt(detailLine.quantity),
                description: detailLine.description,
                unitPrice: parseFloat(detailLine.unitPrice),
                vat: parseFloat(detailLine.vat),
                nature: detailLine.nature
            });
        });

        const data = {
            senderRegistryId: fiscalDocumentDto.registrySenderId,
            recipientRegistryId: fiscalDocumentDto.registryRecipientId,
            documentType: fiscalDocumentDto.documentType,
            paymentType: fiscalDocumentDto.paymentType,
            paymentMode: fiscalDocumentDto.paymentMode,
            paymentDeadlineDays: parseInt(fiscalDocumentDto.paymentDeadLineDays),
            currency: fiscalDocumentDto.currency,
            date: fiscalDocumentDto.date,
            number: fiscalDocumentDto.number,
            detailLines: invoiceDetailLineData,
            sendEmail: fiscalDocumentDto.sendEmail,
            invoiceParentId: fiscalDocumentDto.invoiceParentId,
            revenueStamp: parseFloat(fiscalDocumentDto.revenueStamp),
            purpose: fiscalDocumentDto.purpose,
        };

        return baseEntityClient.createEntity(SupportedEntity.INVOICE_OUTCOMING, data);
    },
    /**
     * Call create invoice incoming endpoint.
     *
     * @param {FiscalDocumentIncomingDto} fiscalDocumentDto
     *
     * @returns {Promise<AxiosResponse<*>>}
     */
    createIncoming(fiscalDocumentDto) {
        let invoiceDetailLineData = [];

        fiscalDocumentDto.detailLines.forEach(detailLine => {
            invoiceDetailLineData.push({
                lineNumber: parseInt(detailLine.lineNumber),
                quantity: parseInt(detailLine.quantity),
                description: detailLine.description,
                unitPrice: parseFloat(detailLine.unitPrice),
                vat: parseFloat(detailLine.vat),
                totalPrice: parseFloat(detailLine.totalPrice),
                nature: detailLine.nature
            });
        });

        let invoiceSummaryLineData = [];

        fiscalDocumentDto.summaryLines.forEach(summaryLine => {
            invoiceSummaryLineData.push({
                nature: summaryLine.nature,
                taxableImport: parseFloat(summaryLine.taxableImport),
                tax: parseFloat(summaryLine.tax),
                vat: parseFloat(summaryLine.vat),
                vatEnforceability: summaryLine.vatEnforceability
            });
        });

        const data = {
            senderRegistryId: fiscalDocumentDto.senderRegistryId,
            recipientRegistryId: fiscalDocumentDto.recipientRegistryId,
            number: fiscalDocumentDto.number,
            documentType: fiscalDocumentDto.documentType,
            paymentType: fiscalDocumentDto.paymentType,
            paymentMode: fiscalDocumentDto.paymentMode,
            paymentAmount: parseFloat(fiscalDocumentDto.paymentAmount),
            currency: fiscalDocumentDto.currency,
            iban: fiscalDocumentDto.iban,
            date: fiscalDocumentDto.date,
            paymentDeadlineDate: fiscalDocumentDto.paymentDeadlineDate,
            detailLines: invoiceDetailLineData,
            summaryLines: invoiceSummaryLineData,
            revenueStamp: parseFloat(fiscalDocumentDto.revenueStamp),
            invoiceParentId: parseInt(fiscalDocumentDto.invoiceParentId),
            purpose: fiscalDocumentDto.purpose
        };

        return baseEntityClient.createEntity(SupportedEntity.INVOICE_INCOMING, data);
    },
    /**
     * Create a payment for the fiscal document id provided.
     *
     * @param {number} id
     * @param {string} paymentType
     * @param {string} paymentDate (Y-m-d)
     * @param {number|string} paymentAmount
     * @param {string} paymentCurrency
     * @param {string|null} bankFeeCurrency
     * @param {number|null} bankFeeAmount
     * @param {number|null} bankFeeVat
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    createPayment(id, paymentType, paymentDate, paymentAmount, paymentCurrency, bankFeeCurrency, bankFeeAmount, bankFeeVat) {
        const data = {
            paymentType: paymentType,
            paymentDate: paymentDate,
            paymentAmount: parseFloat(paymentAmount),
            paymentCurrency: paymentCurrency,
            bankFeeCurrency: bankFeeCurrency,
            bankFeeAmount : parseFloat(bankFeeAmount),
            bankFeeVat: parseFloat(bankFeeVat)
        };

        return httpClient.post(`${SupportedEntity.INVOICE}/${id}/payment`, data);
    },
    /**
     * Update a payment.
     *
     * @param {number} fiscalDocumentId
     * @param {number} paymentId
     * @param {string} paymentType
     * @param {string} paymentDate (Y-m-d)
     * @param {number|string} paymentAmount
     * @param {string} paymentCurrency
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    updatePayment(fiscalDocumentId, paymentId, paymentType, paymentDate, paymentAmount, paymentCurrency) {
        const data = {
            paymentType: paymentType,
            paymentDate: paymentDate,
            paymentAmount: parseFloat(paymentAmount),
            paymentCurrency: paymentCurrency,
        };

        return httpClient.put(`${SupportedEntity.INVOICE}/${fiscalDocumentId}/payment/${paymentId}`, data);
    },
    /**
     * Call delete cashFlowAttachment endpoint.
     *
     * @param {number} fiscalDocumentId
     * @param {number} paymentId
     */
    deletePayment(fiscalDocumentId, paymentId) {
        return httpClient.delete(`${SupportedEntity.INVOICE}/${fiscalDocumentId}/payment/${paymentId}`);
    },
    /**
     * Call create fiscal document attachment endpoint.
     *
     * @param {number} fiscalDocumentId
     * @param {FiscalDocumentAttachmentDto} fiscalDocumentAttachmentDto
     */
    createAttachment(fiscalDocumentId, fiscalDocumentAttachmentDto) {
        return httpClient.post(`${SupportedEntity.INVOICE}/${fiscalDocumentId}/attachment`, {
            encodedData: fiscalDocumentAttachmentDto.encodedData
        });
    },
    /**
     * Call cancel invoice endpoint.
     *
     * @param {number} invoiceId
     * @param {number} creditNoteId
     *
     * @returns {Promise<AxiosResponse<*>>}
     */
    cancelFiscalDocument(invoiceId, creditNoteId) {
        return httpClient.post(`invoice/${invoiceId}/cancel/${creditNoteId}`);
    }
}
