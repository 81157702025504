import {AuthenticationStoreKeys} from "@/constants/authenticationConstants";

export const actions = {
    moveToTwoFactorStep({commit}, data) {
        commit("setAllStepsEnabled", false);
        commit("setTwoFactorLastThreeCharTelephoneNumber", data[AuthenticationStoreKeys.KEY_LAST_TREE_CHAR_TELEPHONE_NUMBER]);
        commit("setStepTwoFactor", true);
    },
    moveToTechnicalAssistanceStep({commit}) {
        commit("setAllStepsEnabled", false);
        commit("setTechnicalAssistance", true);
    },
    moveToForgotPasswordStep({commit}) {
        commit("setAllStepsEnabled", false);
        commit("setStepForgotPassword", true);
    },
    moveToLogin({commit}) {
        commit("setAllStepsEnabled", false);
        commit("setStepLogin", true);
    },
    setCredentialsForAutoLogin({commit}, data) {
        commit("setAuthenticationEmail", data[AuthenticationStoreKeys.KEY_LOGIN_EMAIL]);
        commit("setAuthenticationPassword", data[AuthenticationStoreKeys.KEY_LOGIN_PASSWORD]);
    },
    resetAuthenticationsParameters({commit}) {
        commit("setAuthenticationEmail", "");
        commit("setAuthenticationPassword", "");
    },
    setCredentials({commit}, data) {
        commit("setAuthenticationEmail", data[AuthenticationStoreKeys.KEY_LOGIN_EMAIL]);
    },
    setActiveRegistry({commit}, data) {
        commit("setActiveRegistryId", data.id);
        commit("setActiveRegistryEmail", data.email);
    },
    setEnabledAreas({commit}, data) {
        commit("setAuthorizationAreas", data);
    },
    setHistoryPreviousRoutePath({commit}, data) {
        commit("setPreviousRoutePath", data);
    },
    setYearSelected({commit}, data) {
        commit("setYearSelectedFilter", data);
        commit('updateKeyReloaderContents');
    },
    setFiscalDocumentFilters({commit}, data) {
        commit("setFiscalDocumentFilters", data);
    },
    setRegistryFilters({commit}, data) {
        commit("setRegistryFilters", data);
    },
    setCashFlowFilters({commit}, data) {
        commit("setCashFlowFilters", data);
    },
};
