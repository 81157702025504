var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"modal-classes":"modal-secondary","data-cy":'updatePaymentModal',"show":_vm.showModal},on:{"close":_vm.closeModal}},[_c('template',{slot:"header"},[_c('h1',[_vm._v(_vm._s(_vm.$t("pages.fiscalDocument.updatePayment")))])]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"col-12",attrs:{"data-cy":'updatePaymentMessage'}},[_vm._v(" "+_vm._s(_vm.$t("pages.fiscalDocument.updatePaymentMessage") + _vm.fiscalDocumentNumber)+" ")]),_c('base-input',{staticClass:"col-6",attrs:{"label":_vm.$t('forms.fields.paymentType'),"placeholder":_vm.$t('forms.actions.select') + _vm.$t('forms.fields.paymentType'),"input-classes":"form-control-alternative","name":_vm.$t('forms.fields.paymentType'),"rules":{
                required: true,
                length: 4,
            },"data-cy":'updatePaymentTypeField'}},[_c('v-select',{staticClass:"form-control form-control-alternative",attrs:{"options":_vm.paymentTypes,"reduce":paymentType => paymentType.code},model:{value:(_vm.model.paymentType),callback:function ($$v) {_vm.$set(_vm.model, "paymentType", $$v)},expression:"model.paymentType"}})],1),_c('base-input',{staticClass:"col-12",attrs:{"addon-left-icon":"ni ni-calendar-grid-58","label":_vm.$t('forms.fields.date'),"name":_vm.$t('forms.fields.date'),"placeholder":_vm.$t('forms.actions.select') + _vm.$t('forms.fields.date'),"data-cy":'updatePaymentDateField',"rules":{required:true}},scopedSlots:_vm._u([{key:"default",fn:function({focus, blur}){return _c('flat-picker',{staticClass:"form-control datepicker payment-date",attrs:{"config":_vm.configDate},on:{"on-open":focus,"on-close":blur,"on-change":_vm.formatDate},model:{value:(_vm.model.paymentDate),callback:function ($$v) {_vm.$set(_vm.model, "paymentDate", $$v)},expression:"model.paymentDate"}})}}])}),_c('base-input',{staticClass:"col-6",attrs:{"label":_vm.$t('forms.fields.currency'),"placeholder":_vm.$t('forms.actions.select') + _vm.$t('forms.fields.currency'),"input-classes":"form-control-alternative","name":_vm.$t('forms.fields.currency'),"rules":{
                required: true,
                length: 3
            },"data-cy":'updatePaymentCurrencyField'}},[_c('v-select',{staticClass:"form-control form-control-alternative",attrs:{"options":_vm.currencyList,"reduce":currency => currency.code},model:{value:(_vm.model.paymentCurrency),callback:function ($$v) {_vm.$set(_vm.model, "paymentCurrency", $$v)},expression:"model.paymentCurrency"}})],1),_c('base-input',{staticClass:"col-6",attrs:{"label":_vm.$t('forms.fields.paymentAmount'),"name":_vm.$t('forms.fields.paymentAmount'),"input-classes":"form-control-alternative","data-cy":'updatePaymentAmountField',"rules":{
                required: true,
                decimal: true,
                max_value: _vm.calculateFiscalDocumentAmountMaxAmount
            }},model:{value:(_vm.model.paymentAmount),callback:function ($$v) {_vm.$set(_vm.model, "paymentAmount", $$v)},expression:"model.paymentAmount"}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pb-4"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('pages.fiscalDocument.residualImport') + ': ' + _vm.formatCurrency(_vm.model.paymentCurrency, _vm.calculateFiscalDocumentAmountResidual)))])])]),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"native-type":"submit","type":"primary","loading":_vm.loading,"data-cy":'buttonUpdatePayment'},on:{"click":_vm.updatePayment}},[_vm._v(" "+_vm._s(_vm.$t("actions.update"))+" ")]),_c('base-button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-cy":'buttonStop'},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t("actions.cancel"))+" ")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }